import { ColumnsType } from 'antd/lib/table';
import { IndexPageWrapper, AntdDataTable, usePageProvider } from '@eigen3m/react-base-frontend';
import { Card } from 'antd';
import { useAccessRight } from 'shared/hooks';

const columns: ColumnsType<any> = [
  {
    title: 'Email Type',
    key: 'email_type',
    width: 200,
    dataIndex: 'email_type',
    ellipsis: true,
    sorter: true,
  },
  {
    title: 'Email Subject',
    key: 'email_subject',
    width: 200,
    dataIndex: 'email_subject',
    ellipsis: true,
    sorter: true,
  },
];

export default function IndexPage() {
  const { moduleKey } = usePageProvider();
  const { accessCreate, accessView, accessEdit, accessConfirmProcess } = useAccessRight({ moduleKey });

  let actionIgnored: any[] = ['deactivate', 'delete', 'duplicate', 'detail'];
  if (!accessView) actionIgnored = [...actionIgnored, 'detail'];
  if (!accessEdit) actionIgnored = [...actionIgnored, 'update'];
  if (!accessConfirmProcess) actionIgnored = [...actionIgnored, 'confirm_process'];

  return (
    <IndexPageWrapper
      pageToolbarProps={{
        pageTitle: 'Email Template',
        showNewData: false,
        showFilter: false,
        showSearch: false,
        showBack: false,
      }}
    >
      <Card>
        <AntdDataTable
          columns={columns as any}
          defaultColumnIgnored={['status']}
          rowActionProps={{ actionIgnored: actionIgnored }}
          rowSelection={null}
          onRow={null}
          defaultFilterRequestData={{
            statuses: ['active'],
          }}
        />
      </Card>
    </IndexPageWrapper>
  );
}
