import { ActionProps, IndexPageWrapper, SinglePage, usePageProvider } from '@eigen3m/react-base-frontend';
import { useState } from 'react';
import { VenueForm } from '../components/venue.form';
import { columnsVenue } from '../helpers';
import { mockCompanies } from '@states';
import { useRecoilState } from 'recoil';
import VenueDetail from '../components/venue.detail';
import { Card } from 'antd';
import { useAccessRight } from 'shared/hooks';

export default function IndexPage() {
  const [visibleDrawerForm, setVisibleDrawerForm] = useState(false);
  const [actionForm, setActionForm] = useState<ActionProps>('create');
  const [visibleDrawerDetail, setVisibleDrawerDetail] = useState(false);
  const { dataSource, moduleKey } = usePageProvider();
  const [companies] = useRecoilState(mockCompanies);

  const { accessCreate, accessEdit, accessChangeStatus, accessDelete, accessView, accessPrint } = useAccessRight({
    moduleKey,
  });
  let actionIgnored: any[] = ['confirm_process'];
  if (!accessCreate) actionIgnored = [...actionIgnored, 'duplicate'];
  if (!accessEdit) actionIgnored = [...actionIgnored, 'update'];
  if (!accessChangeStatus) actionIgnored = [...actionIgnored, 'activate', 'deactivate'];
  if (!accessView) actionIgnored = [...actionIgnored, 'detail'];
  if (!accessDelete) actionIgnored = [...actionIgnored, 'delete'];

  async function handleAfterSave(response: any, configs) {
    await handleConfirmProcess(response, configs);
  }

  async function handleConfirmProcess(payload, configs): Promise<void> {
    await dataSource.handleConfirmProcess(payload, {
      onSuccess: () => {
        if (configs?.onReloadTable) configs?.onReloadTable();
        if (!configs?.isCreateAnother) if (configs.onClose) configs.onClose(undefined);
        if (configs?.form) configs.form.resetFields();
      },
      onFailed: () => {
        console.log('failed');
      },
    });
  }

  const onShowDrawerForm = (action: ActionProps) => {
    setVisibleDrawerForm(true);
    setActionForm(action);
  };
  const onCloseDrawerForm = () => {
    setVisibleDrawerForm(false);
  };

  const onShowDrawerDetail = () => {
    setVisibleDrawerDetail(true);
  };
  const onCloseDrawerDetail = () => {
    setVisibleDrawerDetail(false);
  };

  return (
    <IndexPageWrapper
      pageToolbarProps={{
        pageTitle: 'Venue',
        handleClickNewData() {
          onShowDrawerForm('create');
        },
        showFilter: false,
        showConfirmProcess: false,
        disableActivate: !accessChangeStatus,
        disableDeactivate: !accessChangeStatus,
        disableNewData: !accessCreate,
        disableDelete: !accessDelete,
        showBack: false,
      }}
    >
      <Card>
        <SinglePage
          drawerFormProps={{
            title: 'Venue',
            action: actionForm,
            visible: visibleDrawerForm,
            onClose: onCloseDrawerForm,
            forceRequest: true,
            children: <VenueForm />,
            onShow: onShowDrawerForm,
            handleAfterSave: handleAfterSave,
            initialValues: { company: !!companies?.length ? companies[0] : null },
            showDataTypeSelector: false,
          }}
          dataTableProps={{
            columns: columnsVenue,
            rowActionProps: {
              actionIgnored: actionIgnored,
            },
          }}
          drawerDetailProps={{
            title: 'Venue',
            visible: visibleDrawerDetail,
            onClose: onCloseDrawerDetail,
            children: <VenueDetail />,
            onShow: onShowDrawerDetail,
            showDataTypeSelector: false,
          }}
          autoConfirmAfterAction={true}
        />
      </Card>
    </IndexPageWrapper>
  );
}
