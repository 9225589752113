export function RegistrationFormHeader({
  registration,
  withOnlyAdmin = true,
}: {
  registration: any;
  withOnlyAdmin?: boolean;
}): JSX.Element {
  const registrationConfig = registration?.registration_config;
  const isOnlyAdmin = !!withOnlyAdmin && registration?.only_admin;

  const descriptionAllSchedule = registrationConfig === 'Registration per Event' && (
    <p>Registration is open for all schedule in this event except sub event (if it has)</p>
  );

  return (
    <div className="event-form-header">
      <h1>Registration</h1>
      {isOnlyAdmin && <p>For registration, contact admin.</p>}
      {!isOnlyAdmin && (
        <p>
          Please input the information needed so we can know you better. Payment confirmation email will be sent after
          submission.
        </p>
      )}
      {!isOnlyAdmin && descriptionAllSchedule}
    </div>
  );
}
