import { RenderImage } from '@eigen3m/react-base-frontend';
import { TableColumnsType } from 'antd';

export const BankAccountColumns: TableColumnsType<any> = [
  {
    title: 'Account No',
    key: 'account_no',
    width: 100,
    dataIndex: 'account_no',
    ellipsis: true,
    sorter: true,
  },
  {
    title: 'On Behalf of',
    key: 'on_behalf_of',
    width: 100,
    dataIndex: 'on_behalf_of',
    ellipsis: true,
    sorter: true,
  },
  {
    title: 'QR Code',
    key: 'document_url',
    width: 60,
    dataIndex: 'document_url',
    sorter: false,
    ellipsis: true,
    render(record) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ width: '40px' }}>
            <RenderImage
              imageUrl={record}
              preview={false}
              style={{ width: '100%', textAlign: 'center', margin: '5px 0' }}
            />
          </div>
        </div>
      );
    },
  },
];
