import { PageProvider } from '@eigen3m/react-base-frontend';
import { PageController } from '@eigen3m/react-base-frontend';
import { InitialValuePageProviderEntity } from '@eigen3m/react-base-frontend';

import { ApiUrlData, moduleKey, WebUrlData } from '@base-configs';

import { BankEntity } from '../../domain/entities';
import { makeBankTransformer } from '../../domain/factories';

import PageIndex from '../pages';

export default function FactoryPage() {
  const transformer = makeBankTransformer<BankEntity>();
  const InitialValue: InitialValuePageProviderEntity<BankEntity> = {
    transformer,
    moduleKey: moduleKey.BANK_ACCOUNT,
    webUrl: WebUrlData.bank_accounts,
    apiUrl: ApiUrlData.banks,
  };

  return (
    <PageProvider initialValue={InitialValue}>
      <PageController PageIndex={PageIndex} />
    </PageProvider>
  );
}
