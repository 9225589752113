import { ActionProps } from '@eigen3m/react-base-frontend';
import { Form, Radio } from 'antd';
import React from 'react';
import BankAccountsField from './bak-account-field';
import BankField from './bank-field';

export default function BankForm({ actionForm }: { actionForm: ActionProps }) {
  return (
    <React.Fragment>
      <Form.Item noStyle name="company"></Form.Item>
      <Form.Item noStyle name="data_id"></Form.Item>
      <Form.Item noStyle name="key"></Form.Item>
      <Form.Item noStyle name="document_id"></Form.Item>
      <Form.Item name="field_type" hidden={actionForm === 'update'}>
        <Radio.Group>
          <Radio value="bank">New Bank</Radio>
          <Radio value="bank_account">New Bank Accounts</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item shouldUpdate noStyle>
        {({ getFieldValue }) => {
          const documentID = getFieldValue('document_id');
          const fieldType = getFieldValue('field_type');
          if (fieldType === 'bank') return <BankField documentID={documentID} />;
          return <BankAccountsField documentID={documentID} />;
        }}
      </Form.Item>
    </React.Fragment>
  );
}
