import { ApiUrlData, WebUrlData } from '@base-configs';
import { PageProvider } from '@eigen3m/react-base-frontend';
import { PageController } from '@eigen3m/react-base-frontend';
import { InitialValuePageProviderEntity } from '@eigen3m/react-base-frontend';

import { PaymentConfirmationEntity } from '../../domain/entities';

import PageIndex from '../pages';

export default function FactoryPage() {
  const InitialValue: InitialValuePageProviderEntity<PaymentConfirmationEntity> = {
    moduleKey: 'data-event-activity-page',
    webUrl: WebUrlData.invoice,
    apiUrl: ApiUrlData.payment,
    baseApiUrl: process.env.REACT_APP_BASE_SERVICE_URL,
  };

  return (
    <PageProvider initialValue={InitialValue}>
      <PageController PageIndex={PageIndex} />
    </PageProvider>
  );
}
