import { ApiUrlData } from '@base-configs';
import { usePageProvider } from '@eigen3m/react-base-frontend';
import { Card, Form, notification, Spin, Steps } from 'antd';
import { useEffect, useState } from 'react';

import {
  transformerGetDataAttendanceOfficer,
  transformerGetDataParticipant,
  transformerGetDataPaymentAndPricing,
  transformerGetDataRegistration,
  transformerGetDataSchedules,
} from '../../../domain/transformer/transformer.get';
import {
  FormAttendanceOfficers,
  FormEventInformation,
  FormParticipants,
  FormPaymentAndPricing,
  FormRegistration,
  FormSchedules,
} from '../form/form-tabs/form-component';
import { STEPKEY } from '../../helpers';

export function StepField({ setFieldValue, currentStep, stepItem, stepContent }: any): JSX.Element {
  const [forceRender, setForceRender] = useState<boolean>(false);
  const { id, dataSource, isUpdate, isDuplicate } = usePageProvider();
  useEffect(() => {
    if (id) handleGetDataDetailEventData(id);
  }, [id]);

  const handleGetDataDetailEventData = async (id: string) => {
    setForceRender(false);
    //Get data participants
    await dataSource.handleCustomRequest({
      paramRequest: {
        method: 'GET',
        url: process.env.REACT_APP_BASE_SERVICE_URL + ApiUrlData.eventData + `/${id}/participants`,
      },
      onSuccess({ response }: any) {
        const participants = response ? transformerGetDataParticipant(response) : null;
        if (participants) {
          setFieldValue(STEPKEY.participants, participants);
        }
      },
      onFailed({ message }: any) {
        notification.error({
          message: 'Get data participant error',
          description: message,
        });
      },
    });

    //Get data schedules
    await dataSource.handleCustomRequest({
      paramRequest: {
        method: 'GET',
        url: process.env.REACT_APP_BASE_SERVICE_URL + ApiUrlData.eventData + `/${id}/schedules`,
      },
      onSuccess({ response }: any) {
        const schedules = response ? transformerGetDataSchedules(response) : null;
        if (schedules) {
          setFieldValue(STEPKEY.schedules, schedules);
        }
      },
      onFailed({ message }: any) {
        notification.error({
          message: 'Get data schedules error',
          description: message,
        });
      },
    });

    //Get data registrations
    await dataSource.handleCustomRequest({
      paramRequest: {
        method: 'GET',
        url: process.env.REACT_APP_BASE_SERVICE_URL + ApiUrlData.eventData + `/${id}/registrations`,
      },
      onSuccess({ response }: any) {
        console.log(response);
        const registrations = response ? transformerGetDataRegistration(response) : null;
        if (registrations) {
          setFieldValue(STEPKEY.registrations, registrations);
        }
      },
      onFailed({ message }: any) {
        notification.error({
          message: 'Get data registration error',
          description: message,
        });
      },
    });

    //Get data payment and pricings
    await dataSource.handleCustomRequest({
      paramRequest: {
        method: 'GET',
        url: process.env.REACT_APP_BASE_SERVICE_URL + ApiUrlData.eventData + `/${id}/payments`,
        params: {
          is_speaker: true,
        },
      },
      onSuccess({ response }: any) {
        const paymentPricings = response ? transformerGetDataPaymentAndPricing(response) : null;
        if (paymentPricings) {
          setFieldValue(STEPKEY.paymentPricings, paymentPricings);
        }
      },
      onFailed({ message }: any) {
        notification.error({
          message: 'Get data payment and pricings error',
          description: message,
        });
      },
    });

    //Get data attendance officer
    await dataSource.handleCustomRequest({
      paramRequest: {
        method: 'GET',
        url: process.env.REACT_APP_BASE_SERVICE_URL + ApiUrlData.eventData + `/${id}/attendances`,
      },
      onSuccess({ response }: any) {
        const attendancesOfficer = response ? transformerGetDataAttendanceOfficer(response) : null;
        if (attendancesOfficer) {
          setFieldValue(STEPKEY.attendanceOfficers, attendancesOfficer);
        }
      },
      onFailed({ message }: any) {
        notification.error({
          message: 'Get data attendance officer error',
          description: message,
        });
      },
    });
    setForceRender(true);
  };

  return (
    <Card className="gx-card" bordered>
      <Spin spinning={isUpdate || isDuplicate ? !forceRender : false}>
        <div style={{ background: '#f9f9f9', margin: '-25px -25px 0' }}>
          <Steps type="navigation" size="small" current={currentStep} items={stepItem} />
        </div>
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) => {
            const dataInformation = getFieldValue('information');
            const dataParticipant = getFieldValue('participants');
            const dataSchedule = getFieldValue('schedules');
            const dataRegistration = getFieldValue('registrations');
            const dataPricing = getFieldValue('payment_pricings');
            const registConfig = dataRegistration?.registration_config;

            return (
              <div style={{ marginTop: 20 }}>
                <div hidden={stepContent[currentStep]?.key === STEPKEY.information ? false : true}>
                  <FormEventInformation />
                </div>
                <div hidden={stepContent[currentStep]?.key === STEPKEY.participants ? false : true}>
                  <FormParticipants />
                </div>

                <div hidden={stepContent[currentStep]?.key === STEPKEY.schedules ? false : true}>
                  <FormSchedules />
                </div>
                <div hidden={stepContent[currentStep]?.key === STEPKEY.registrations ? false : true}>
                  <FormRegistration
                    dataInformation={dataInformation}
                    dataParticipant={dataParticipant}
                    dataSchedule={dataSchedule}
                    dataRegistration={dataRegistration}
                    setFieldValue={setFieldValue}
                  />
                </div>
                <div hidden={stepContent[currentStep]?.key === STEPKEY.paymentPricings ? false : true}>
                  <FormPaymentAndPricing
                    dataParticipant={dataParticipant}
                    dataSchedule={dataSchedule}
                    dataRegistration={dataRegistration}
                    dataInformation={dataInformation}
                  />
                </div>

                <div hidden={stepContent[currentStep]?.key === STEPKEY.attendanceOfficers ? false : true}>
                  <FormAttendanceOfficers
                    dataInformation={dataInformation}
                    dataSchedule={dataSchedule}
                    registConfig={registConfig}
                    dataPricing={dataPricing}
                  />
                </div>
              </div>
            );
          }}
        </Form.Item>
      </Spin>
    </Card>
  );
}
