import { BaseEntity, IBaseTransformerRepository } from '@eigen3m/react-base-frontend';
import { ParticipantEntity } from '../entities';
import { ParticipantTransformer } from '../transformer/participant-transformer';

const defaultFilterKey = ['event_ids'];
export function makeParticipantTransformer<E extends BaseEntity = ParticipantEntity>(
  defaultFilter?: any,
): IBaseTransformerRepository<E> {
  const transformer = new ParticipantTransformer<E>();
  transformer.transformerFilterIndexTable = (payload) => {
    const filter = {};
    Object.keys(defaultFilter).forEach((item) => {
      if (defaultFilterKey.includes(item)) {
        Object.assign(filter, { [item]: defaultFilter[item] });
      }
    });
    if (!payload)
      return {
        ...payload,
        ...filter,
      };
    return {
      ...payload,
      day: payload?.day?.no_day ?? undefined,
      session: payload?.session?.no_session ?? undefined,
      workshop: payload?.workshop?.name ?? undefined,
      ...filter,
    };
  };
  return transformer;
}
