import {
  IndexPageWrapper,
  AntdDataTable,
  usePageProvider,
  notificationSuccess,
  notificationFailed,
} from '@eigen3m/react-base-frontend';
import { Card, Spin } from 'antd';
import { columnsEvent } from '../helpers';
import { BiX } from 'react-icons/bi';
import { Filter } from '../components/filter';
import { useAccessRight } from 'shared/hooks';
import { ApiUrlData } from '@base-configs';
import { useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';

export default function IndexPage() {
  const { dataSource } = usePageProvider();
  const [showContent, setShowContent] = useState(true);
  const [loading, setLoading] = useState(false);

  async function handleCancel(payload): Promise<void> {
    setShowContent(false);
    setLoading(true);
    await dataSource.handleCustomRequest({
      paramRequest: {
        method: 'PATCH',
        url: process.env.REACT_APP_BASE_URL + ApiUrlData.eventData + `/${payload?.id}/confirm-cancel`,
      },
      onSuccess: () => {
        notificationSuccess(['Successfully cancel data.']);
      },
      onFailed: ({ message }: any) => {
        notificationFailed([message]);
      },
    });
    setShowContent(true);
    setLoading(false);
  }

  async function handleConfirmRequest(payload): Promise<void> {
    setShowContent(false);
    setLoading(true);
    await dataSource.handleCustomRequest({
      paramRequest: {
        method: 'PATCH',
        url: process.env.REACT_APP_BASE_URL + ApiUrlData.eventData + `/${payload?.id}/confirm-request`,
      },
      onSuccess: () => {
        notificationSuccess(['Successfully reprocess data.']);
      },
      onFailed: ({ message }: any) => {
        notificationFailed([message]);
      },
    });
    setShowContent(true);
    setLoading(false);
  }

  const { moduleKey } = usePageProvider();
  const {
    accessCreate,
    accessEdit,
    accessImport,
    accessChangeStatus,
    accessDelete,
    accessView,
    accessPrint,
    accessConfirmProcess,
    accessCancel,
  } = useAccessRight({
    moduleKey,
  });
  let actionIgnored: any[] = [];
  if (!accessCreate) actionIgnored = [...actionIgnored, 'duplicate'];
  if (!accessEdit) actionIgnored = [...actionIgnored, 'update'];
  if (!accessChangeStatus) actionIgnored = [...actionIgnored, 'activate', 'deactivate'];
  if (!accessView) actionIgnored = [...actionIgnored, 'detail'];
  if (!accessDelete) actionIgnored = [...actionIgnored, 'delete'];
  if (!accessConfirmProcess) actionIgnored = [...actionIgnored, 'confirm_process'];

  const additionalActions = (row: any[]): any[] => {
    return [
      {
        key: 'cancel',
        icon: <BiX />,
        label: 'Cancel',
        onClick(e) {
          e.domEvent.stopPropagation();
          handleCancel(row);
        },
      },
      {
        key: 'reprocess',
        icon: <BiX />,
        label: 'Reprocess',
        onClick(e) {
          e.domEvent.stopPropagation();
          handleConfirmRequest(row);
        },
      },
    ];
  };
  return (
    <Spin spinning={loading} indicator={<LoadingOutlined />}>
      {!showContent && <div style={{ minHeight: '50vh' }}></div>}
      {showContent && (
        <IndexPageWrapper
          pageToolbarProps={{
            pageTitle: 'Event Data',
            showImport: false,
            showPrint: true,
            disableNewData: !accessCreate,
            disablePrint: !accessPrint,
            disableImport: !accessImport,
            disableActivate: !accessChangeStatus,
            disableDeactivate: !accessChangeStatus,
            disableDelete: !accessDelete,
            disableConfirmProcess: !accessConfirmProcess,
            showFilterBadge: true,
            showBack: false,
          }}
          drawerFilterProp={{
            formBuilderProps: {
              columns: Filter,
            },
          }}
        >
          <Card>
            <AntdDataTable
              columns={columnsEvent}
              rowActionProps={{
                actionIgnored: actionIgnored,
                customActionIgnored: (row) =>
                  ['closed', 'cancel'].includes(row?.status)
                    ? ['update', 'activate', 'deactivate', 'confirm_process']
                    : row?.status === 'in_process'
                    ? ['activate', 'delete', 'deactivate', 'confirm_process']
                    : row?.status === 'active'
                    ? ['confirm_process']
                    : [],
                additionalActions(row: any) {
                  return ['in_process', 'in_progress'].includes(row?.status) && accessCancel
                    ? additionalActions(row)?.filter((item: any) => item?.key === 'cancel')
                    : ['cancel']?.includes(row?.status)
                    ? additionalActions(row)?.filter((item) => item?.key === 'reprocess')
                    : [];
                },
              }}
            />
          </Card>
        </IndexPageWrapper>
      )}
    </Spin>
  );
}
