import { PageProvider } from '@eigen3m/react-base-frontend';
import { PageController } from '@eigen3m/react-base-frontend';
import { InitialValuePageProviderEntity } from '@eigen3m/react-base-frontend';

import PageIndex from '../pages';
import PageForm from '../pages/form';
import PageDetail from '../pages/detail';

import { ReportEntity } from '../../domain/entities';
import { makeReportTransformer } from '../../domain/factories';
import { ApiUrlData, moduleKey, WebUrlData } from '@base-configs';

export default function FactoryPage() {
  const transformer = makeReportTransformer<ReportEntity>();
  const InitialValue: InitialValuePageProviderEntity<ReportEntity> = {
    transformer,
    moduleKey: moduleKey.REPORT,
    webUrl: WebUrlData.report,
    apiUrl: ApiUrlData.report,
  };

  return (
    <PageProvider initialValue={InitialValue}>
      <PageController
        PageIndex={PageIndex}
        PageDetail={PageDetail}
        PageCreate={PageForm}
        PageUpdate={PageForm}
        PageDuplicate={PageForm}
      />
    </PageProvider>
  );
}
