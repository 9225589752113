import { dateFormatter, RenderCurrency, RenderText } from '@eigen3m/react-base-frontend';
import { renderRegisteredSchedule } from '@helpers';
import { ColumnsType } from 'antd/lib/table';
import { typeOf } from 'mathjs';
import moment from 'moment';
import { generateAge } from '../../components/form-tabs/tab-participant/components/drawer-form';

export const columns: ColumnsType<any> = [
  {
    title: 'Start Date',
    key: 'schedules.date_start',
    width: 100,
    dataIndex: ['schedules', 'date_start'],
    ellipsis: true,
    sorter: true,
    render: (value) => (value ? dateFormatter(value).parseEpoch('DD-MM-YYYY') : '-'),
  },
  {
    title: 'End Date',
    key: 'schedules.date_end',
    width: 100,
    dataIndex: ['schedules', 'date_end'],
    sorter: true,
    ellipsis: true,
    render: (value) => (value ? dateFormatter(value).parseEpoch('DD-MM-YYYY') : '-'),
  },
  // {
  //   title: 'Session',
  //   key: 'session',
  //   width: 100,
  //   dataIndex: 'session',
  //   sorter: true,
  //   ellipsis: true,
  //   // render: (value) => value.length,
  // },
  // {
  //   title: 'Time',
  //   key: 'time',
  //   width: 100,
  //   dataIndex: 'time',
  //   sorter: true,
  //   ellipsis: true,
  // },
  {
    title: 'Event Name',
    key: 'information.name',
    width: 250,
    dataIndex: ['information', 'name'],
    sorter: true,
    ellipsis: true,
    render: (value) => value ?? '-',
  },
  {
    title: 'Department',
    key: 'department.name',
    width: 120,
    dataIndex: ['information', 'department'],
    sorter: true,
    ellipsis: true,
    render: (value) => value?.name ?? '-',
  },
  {
    title: 'Class',
    key: 'department_class.name',
    width: 120,
    dataIndex: ['information', 'department_class'],
    sorter: true,
    ellipsis: true,
    render: (value) => value?.name ?? '-',
  },
  {
    title: 'Highest Price',
    key: 'payment_pricings.highest_price',
    width: 150,
    dataIndex: ['payment_pricings', 'highest_price'],
    sorter: true,
    ellipsis: true,
    render: (value) => (value ? RenderCurrency({ value }) : '-'),
  },
  {
    title: 'Max Participant',
    key: 'registrations.max_registrant',
    width: 100,
    dataIndex: ['registrations', 'max_registrant'],
    sorter: true,
    ellipsis: true,
    render: (value) => value ?? '-',
  },
  // {
  //   title: '# Registrants',
  //   key: 'registrants',
  //   width: 100,
  //   dataIndex: 'registrants',
  //   sorter: true,
  //   ellipsis: true,
  // },
  // {
  //   title: '# Participants',
  //   key: 'participants',
  //   width: 100,
  //   dataIndex: 'participants',
  //   sorter: true,
  //   ellipsis: true,
  // },
  // {
  //   title: '# Check in Participants',
  //   key: 'check_in_participants',
  //   width: 100,
  //   dataIndex: 'check_in_participants',
  //   sorter: true,
  //   ellipsis: true,
  // },
  // {
  //   title: '# Not Come',
  //   key: 'not_come',
  //   width: 100,
  //   dataIndex: 'not_come',
  //   sorter: true,
  //   ellipsis: true,
  // },
  // {
  //   title: '# Officers',
  //   key: 'total_officer',
  //   width: 100,
  //   dataIndex: 'total_officer',
  //   sorter: true,
  //   ellipsis: true,
  // },
  // {
  //   title: '# Check In Officers',
  //   key: 'check_in_officers',
  //   width: 100,
  //   dataIndex: 'check_in_officers',
  //   sorter: true,
  //   ellipsis: true,
  // },
  // {
  //   title: '# Invoices',
  //   key: 'invoices',
  //   width: 100,
  //   dataIndex: 'invoices',
  //   sorter: true,
  //   ellipsis: true,
  //   // render: (value) => RenderCurrency({ value }),
  // },
  // {
  //   title: '# Payments',
  //   key: 'payments',
  //   width: 100,
  //   dataIndex: 'payments',
  //   sorter: true,
  //   ellipsis: true,
  //   // render: (value) => RenderCurrency({ value }),
  // },
  {
    title: 'Event Type',
    key: 'information.event_type',
    width: 100,
    dataIndex: ['information', 'event_type'],
    sorter: true,
    ellipsis: true,
    render: (value) => value ?? '-',
  },
  {
    title: 'Venue',
    key: 'venue.name',
    width: 200,
    dataIndex: ['information', 'venue'],
    sorter: true,
    ellipsis: true,
    render: (value) => value?.name ?? '-',
  },
  {
    title: 'Participant Type',
    key: 'participants.participant_type',
    width: 120,
    dataIndex: ['participants', 'participant_type'],
    sorter: true,
    ellipsis: true,
    render: (value) => value ?? '-',
  },
  {
    title: 'Age',
    key: 'participants.age_min',
    width: 100,
    dataIndex: 'age',
    sorter: true,
    ellipsis: true,
    render: (_, record: any) => {
      return !!record?.participants?.age_min && !!record?.participants?.age_max
        ? `${record?.participants?.age_min} - ${record?.participants?.age_max}`
        : !!record?.participants?.age_min && !record?.participants?.age_max
        ? `${record?.participants?.age_min} - above`
        : '-';
    },
  },
  {
    title: 'Gender',
    key: 'participants.gender',
    width: 100,
    dataIndex: ['participants', 'gender'],
    sorter: true,
    ellipsis: true,
  },
  {
    title: 'Marital Status',
    key: 'participants.marital_status',
    width: 100,
    dataIndex: ['participants', 'marital_status'],
    sorter: true,
    ellipsis: true,
  },
  // {
  //   title: 'QR Code',
  //   key: 'qr_code',
  //   width: 100,
  //   dataIndex: ['attendance_officers', 'use_qr'],
  //   sorter: true,
  //   ellipsis: true,
  //   render: (value) => (value ? 'Yes' : 'No'),
  // },
  {
    title: 'Attendance',
    key: 'attendance_officers.attendance_configuration',
    width: 150,
    dataIndex: ['attendance_officers', 'attendance_configuration'],
    sorter: true,
    ellipsis: true,
    render: (value) => value ?? '-',
  },
];

export const columnTree: ColumnsType<any> = [
  {
    title: 'Time',
    key: 'time',
    width: 100,
    dataIndex: 'time',
    sorter: true,
    ellipsis: true,
  },
  {
    title: 'Workshop Theme',
    key: 'workshop_theme',
    width: 200,
    dataIndex: 'workshop_theme',
    sorter: true,
    ellipsis: true,
  },
  {
    title: 'Max Participant',
    key: 'registrations.max_registrant',
    width: 150,
    dataIndex: 'max_participant',
    sorter: true,
    ellipsis: true,
  },
  {
    title: '# Registrants',
    key: 'registrants',
    width: 100,
    dataIndex: 'registrants',
    sorter: true,
    ellipsis: true,
  },
  {
    title: '# Participants',
    key: 'participants',
    width: 100,
    dataIndex: 'participants',
    sorter: true,
    ellipsis: true,
  },
  {
    title: '# Check in Participants',
    key: 'check_in_participants',
    width: 100,
    dataIndex: 'check_in_participants',
    sorter: true,
    ellipsis: true,
  },
  {
    title: '# Not Come',
    key: 'not_come',
    width: 100,
    dataIndex: 'not_come',
    sorter: true,
    ellipsis: true,
  },
  {
    title: '# Officers',
    key: 'officers',
    width: 100,
    dataIndex: 'officers',
    sorter: true,
    ellipsis: true,
  },
  {
    title: '# Check In Officers',
    key: 'check_in_officers',
    width: 100,
    dataIndex: 'check_in_officers',
    sorter: true,
    ellipsis: true,
  },
  {
    title: '# Invoices',
    key: 'invoices',
    width: 100,
    dataIndex: 'invoices',
    sorter: true,
    ellipsis: true,
    render: (value) => RenderCurrency({ value }),
  },
  {
    title: '# Payments',
    key: 'payments',
    width: 100,
    dataIndex: 'payments',
    sorter: true,
    ellipsis: true,
    render: (value) => RenderCurrency({ value }),
  },
];

export const participantColumns: ColumnsType<any> = [
  {
    title: 'Registration Date',
    key: 'registration_date',
    width: 150,
    dataIndex: 'registran',
    sorter: true,
    ellipsis: true,
    render: (value) => {
      return dateFormatter(value?.registration_date).parseEpoch('DD MMM YYYY, HH:mm');
    },
  },
  {
    title: 'Registration ID',
    key: 'code',
    width: 180,
    dataIndex: 'code',
    sorter: true,
    ellipsis: true,
  },
  {
    title: 'Registered Schedule',
    key: 'registered_schedule',
    width: 250,
    dataIndex: 'registered_schedule',
    sorter: false,
    ellipsis: true,
    render: (value) => renderRegisteredSchedule({ value }),
  },
  {
    title: 'Participants Name',
    key: 'registrans',
    width: 150,
    dataIndex: 'registran',
    sorter: true,
    ellipsis: true,
    render: (value) => {
      return value?.name;
    },
  },
  {
    title: 'Mobile Phone',
    key: 'phone_number',
    width: 150,
    dataIndex: 'registran',
    sorter: true,
    ellipsis: true,
    render: (value) => {
      return value?.phone_number ?? '-';
    },
  },
  {
    title: 'Email',
    key: 'email',
    width: 150,
    dataIndex: 'registran',
    sorter: true,
    ellipsis: true,
    render: (value) => {
      return value?.email ?? '-';
    },
  },
  {
    title: 'Member',
    key: 'is_member',
    width: 100,
    dataIndex: 'is_member',
    sorter: true,
    ellipsis: true,
    render: (value) => (value ? 'Yes' : 'No'),
  },
  {
    title: 'Date of Birth',
    key: 'date_of_birth',
    width: 150,
    dataIndex: 'registran',
    sorter: true,
    ellipsis: true,
    render: (value) => {
      return value?.date_of_birth ? moment(value?.date_of_birth, 'YYYY-MM-DD').format('DD MMM YYYY') : '-';
    },
  },
  {
    title: 'Age',
    key: 'age',
    width: 120,
    dataIndex: 'registran',
    sorter: true,
    ellipsis: true,
    render: (value) => {
      if (!value) return '-';
      const dateOfBirth = value?.date_of_birth ? moment(value?.date_of_birth, 'YYYY-MM-DD') : null;
      const age = dateOfBirth ? generateAge(dateOfBirth) : null;
      return typeOf(age) === 'number' && age >= 0 ? `${age} years old` : '-';
    },
  },
  {
    title: 'Gender',
    key: 'gender',
    width: 80,
    dataIndex: 'registran',
    sorter: true,
    ellipsis: true,
    render: (value) => {
      if (!value?.gender) return '-';
      return value?.gender;
    },
  },
  {
    title: 'Marital Status',
    key: 'marital_status',
    width: 150,
    dataIndex: 'registran',
    sorter: true,
    ellipsis: true,
    render: (value) => {
      if (!value?.marital_status) return '-';
      return value?.marital_status;
    },
  },
  {
    title: 'Address',
    key: 'address',
    width: 250,
    dataIndex: 'registran',
    sorter: true,
    ellipsis: true,
    render: (value) => {
      if (!value?.address) return '-';
      return value?.address?.address;
    },
  },
];

export const paymentsColumn: ColumnsType<any> = [
  // {
  //   title: 'Payment Date',
  //   key: 'payment_date',
  //   dataIndex: 'payment_date',
  //   sorter: true,
  //   width: 150,
  //   render: (value) => (value ? dateFormatter(value).parseEpoch('DD MMM YYYY, HH:mm') : '-'),
  // },
  {
    title: 'Registration ID',
    key: 'code',
    dataIndex: 'code',
    sorter: true,
    width: 150,
    ellipsis: true,
    render: (value) => {
      return value ?? '-';
    },
  },
  {
    title: 'Participants Name',
    key: 'participants_name',
    width: 150,
    dataIndex: 'registrans',
    ellipsis: true,
    render: (value) => {
      return !!value && !!value?.length ? value[0]?.name : '-';
    },
  },
  {
    title: 'Mobile Phone',
    key: 'mobile_phone',
    width: 150,
    dataIndex: 'registrans',
    sorter: true,
    ellipsis: true,
    render: (value) => {
      return !!value && !!value?.length ? value[0]?.phone_number ?? '-' : '-';
    },
  },
  {
    title: 'Email',
    key: 'email',
    width: 150,
    dataIndex: 'registrans',
    sorter: true,
    ellipsis: true,
    render: (value) => {
      return !!value && !!value?.length ? value[0]?.email : '-';
    },
  },
  // {
  //   title: 'Pricing Type',
  //   key: 'pricing_type',
  //   width: 150,
  //   dataIndex: 'registered_schedule',
  //   sorter: true,
  //   ellipsis: true,
  //   render: (value) => value?.price?.name ?? '-',
  // },
  {
    title: 'Total Invoice',
    key: 'total_invoice',
    dataIndex: 'total_invoice',
    sorter: true,
    ellipsis: true,
    width: 150,
    render: (value) => (value ? RenderCurrency({ value }) : '-'),
  },
  // {
  //   title: 'Total Payment',
  //   key: 'total_payment',
  //   dataIndex: 'total_payment',
  //   sorter: true,
  //   ellipsis: true,
  //   width: 150,
  //   render: (value) => (value ? RenderCurrency({ value }) : '-'),
  // },
  // {
  //   title: 'Payment Via',
  //   key: 'payment_type',
  //   dataIndex: 'payment_type',
  //   sorter: true,
  //   ellipsis: true,
  //   width: 150,
  //   render: (value) => value ?? '-',
  // },
  // {
  //   title: 'Payer Name',
  //   key: 'payer_name',
  //   dataIndex: 'payer_name',
  //   sorter: true,
  //   ellipsis: true,
  //   width: 150,
  //   render: (value) => value ?? '-',
  // },
  // {
  //   title: 'Payer Account No',
  //   key: 'no_payer_account',
  //   dataIndex: 'no_payer_account',
  //   sorter: true,
  //   ellipsis: true,
  //   width: 150,
  //   render: (value) => value ?? '-',
  // },
];

export const paymentsAttendanceColumn: ColumnsType<any> = [
  {
    title: 'Check in Date Time',
    key: 'check_in_date',
    width: 150,
    dataIndex: 'check_in_date',
    sorter: true,
    ellipsis: true,
    render(value) {
      return value ? `${dateFormatter(value).parseEpoch('DD MMM YYYY, HH:mm')}` : '-';
    },
  },
  {
    title: 'Registration ID',
    key: 'code',
    width: 150,
    dataIndex: 'participant',
    sorter: true,
    ellipsis: true,
    render: (value) => value?.code ?? '-',
  },
  {
    title: 'Schedule',
    key: 'registered_schedule',
    width: 200,
    dataIndex: 'registered_schedule',
    sorter: true,
    ellipsis: true,
    render: (value) => renderRegisteredSchedule({ value }),
  },
  // {
  //   title: 'Event Schedule',
  //   key: 'schedule',
  //   width: 100,
  //   // dataIndex: ['registran', 'schedule'],
  //   sorter: true,
  //   ellipsis: true,
  //   render: (value) => value ?? '-',
  // },
  {
    title: 'Participants Name',
    key: 'name',
    width: 150,
    dataIndex: 'participant',
    sorter: true,
    ellipsis: true,
    render: (value) => value?.registran?.name ?? '-',
  },
  {
    title: 'Mobile Phone',
    key: 'phone_number',
    width: 150,
    dataIndex: 'participant',
    sorter: true,
    ellipsis: true,
    render: (value) => value?.registran?.phone_number ?? '-',
  },
  {
    title: 'Email',
    key: 'email',
    width: 150,
    dataIndex: 'participant',
    sorter: true,
    ellipsis: true,
    render: (value) => value?.registran?.email ?? '-',
  },
  {
    title: 'Member',
    key: 'member',
    width: 100,
    dataIndex: ['participant', 'is_member'],
    sorter: true,
    ellipsis: true,
    render: (value) => (value ? 'Yes' : 'No'),
  },
];

export const officersAttendanceColumn: ColumnsType<any> = [
  {
    title: 'Check in Time',
    key: 'check_in_date',
    width: 100,
    dataIndex: 'check_in_date',
    sorter: true,
    ellipsis: true,
    render(value) {
      return value ? `${dateFormatter(value).parseEpoch('DD MMM YYYY, HH:mm')}` : '-';
    },
  },
  {
    title: 'Schedule',
    key: 'schedule',
    width: 100,
    dataIndex: 'schedule',
    sorter: true,
    ellipsis: true,
    render: (value) => renderRegisteredSchedule({ value }),
  },
  {
    title: 'Officers Name',
    key: 'officer',
    width: 100,
    dataIndex: 'officer',
    sorter: true,
    ellipsis: true,
    render: (value) => value?.name ?? '-',
  },
  {
    title: 'Email',
    key: 'officer',
    width: 100,
    dataIndex: 'officer',
    sorter: true,
    ellipsis: true,
    render: (value) => value?.email ?? '-',
  },
];
