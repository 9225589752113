import { FormBuilder } from '@eigen3m/react-base-frontend';
import { renderRegisteredSchedule } from '@helpers';
import { Form } from 'antd';
import React from 'react';

export default function DrawerForm(): JSX.Element {
  return (
    <React.Fragment>
      <Form.Item name="detail_data" hidden noStyle />
      <Form.Item name="registered_schedule" hidden noStyle />
      <FormBuilder
        columns={[
          {
            fields: [
              {
                name: ['participant', 'code'],
                label: 'Registration ID',
                fieldType: 'inputText',
                gridColumn: { span: 12 },
                inputTextProps: {
                  placeholder: 'Registration ID',
                  disabled: true,
                },
              },
              {
                name: ['participant', 'registran', 'name'],
                label: 'Participant Name',
                fieldType: 'inputText',
                gridColumn: { span: 12 },
                inputTextProps: {
                  disabled: true,
                },
              },
              {
                name: ['participant', 'registran', 'phone_number'],
                label: 'Mobile Phone',
                fieldType: 'inputText',
                gridColumn: { span: 12 },
                inputTextProps: {
                  disabled: true,
                },
              },
              {
                name: ['participant', 'registran', 'email'],
                label: 'Email',
                fieldType: 'inputText',
                gridColumn: { span: 12 },
                inputTextProps: {
                  disabled: true,
                },
              },
              {
                label: 'Schedule',
                gridColumn: { span: 12 },
                renderField({ getFieldsValue }) {
                  const values = getFieldsValue();
                  const registSchedule = values?.registered_schedule;
                  if (!registSchedule) return '-';
                  return <Form.Item label="Schedule">{renderRegisteredSchedule({ value: registSchedule })}</Form.Item>;
                },
              },
              {
                name: 'check_in_date',
                label: 'Check In Time',
                fieldType: 'datePicker',
                gridColumn: { span: 12 },
                datePickerProps: {
                  showTime: true,
                  format: 'YYYY-MM-DD HH:mm',
                  style: {
                    width: '100%',
                  },
                },
              },
            ],
          },
        ]}
      />
    </React.Fragment>
  );
}
