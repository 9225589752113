import React from 'react';
import { Form } from 'antd';
import { FormBuilder } from '@eigen3m/react-base-frontend';
import { renderRegisteredSchedule } from '@helpers';

export default function DrawerForm(): JSX.Element {
  return (
    <React.Fragment>
      <Form.Item name="detail_data" hidden noStyle />
      <Form.Item name="schedule" hidden noStyle />
      <FormBuilder
        columns={[
          {
            fields: [
              {
                name: ['officer', 'name'],
                label: 'Officer Name',
                fieldType: 'inputText',
                gridColumn: { span: 12 },
                inputTextProps: {
                  disabled: true,
                  placeholder: 'Officer Name',
                },
              },
              {
                name: ['officer', 'email'],
                label: 'Email',
                fieldType: 'inputText',
                gridColumn: { span: 12 },
                inputTextProps: {
                  disabled: true,
                  placeholder: 'Email Address',
                },
              },
              {
                label: 'Schedule',
                gridColumn: { span: 12 },
                renderField({ getFieldsValue }) {
                  const values = getFieldsValue();
                  const schedule = values?.schedule;
                  if (!schedule) return '-';
                  return <Form.Item label="Schedule">{renderRegisteredSchedule({ value: schedule })}</Form.Item>;
                },
              },
              {
                name: 'check_in_date',
                label: 'Check In Time',
                fieldType: 'datePicker',
                gridColumn: { span: 12 },
                datePickerProps: {
                  showTime: true,
                  format: 'DD-MM-YYYY HH:mm',
                  style: {
                    width: '100%',
                  },
                },
              },
            ],
          },
        ]}
      />
    </React.Fragment>
  );
}
