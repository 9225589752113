import React, { useEffect, useState } from 'react';
import { DetailBuilder, usePageProvider } from '@eigen3m/react-base-frontend';
import { Checkbox, Form, notification, Spin, Table, Tabs } from 'antd';
import { columnsDaySchedule, columnsSessionSchedule, columnsWorkshopSchedule } from '../../../../helpers';
import { ApiUrlData } from '@base-configs';
import { transformerGetDataSchedules } from '@admin-app/pages/events/event-data/domain/transformer/transformer.get';

interface Props {
  value?: any;
  onChange?: any;
}
export function DetailSchedules(props: Props) {
  const { onChange } = props;
  const [form] = Form.useForm();
  const { id, dataSource } = usePageProvider();
  const [loading, setLoading] = useState(false);
  const [dataSchedule, setDataSchedule] = useState([]);

  const handleGetDataSchedule = async (id: string) => {
    setLoading(true);
    await dataSource.handleCustomRequest({
      paramRequest: {
        method: 'GET',
        url: process.env.REACT_APP_BASE_SERVICE_URL + ApiUrlData.eventData + `/${id}/schedules`,
      },
      onSuccess(response: any) {
        const dataSchedule = response?.response ? transformerGetDataSchedules(response?.response) : null;
        setDataSchedule(dataSchedule?.days ?? []);
        form.setFieldsValue({ ...dataSchedule });
      },
      onFailed(response: any) {
        notification.error({
          message: 'Get data schedules error',
          description: response,
        });
      },
    });
    setLoading(false);
  };

  useEffect(() => {
    if (id) {
      handleGetDataSchedule(id);
    }
  }, [id]);

  const onValuesChange = (item, values) => {
    let newValues = values;
    if (Object.keys(item).includes('public_event')) {
      newValues = {
        ...values,
        departments: null,
        ministries: null,
        job_positions: null,
      };
    }
    if (newValues && onChange) {
      onChange(newValues);
    }
    return newValues;
  };

  return (
    <React.Fragment>
      <Spin spinning={loading}>
        <Form form={form} onValuesChange={onValuesChange}>
          <DetailBuilder
            columns={[
              {
                fields: [
                  {
                    gridColumn: { span: 24 },
                    label: 'Schedule Description',
                    name: ['description'],
                    fieldType: 'text',
                    renderTextProps: {
                      renderValue(value) {
                        return value;
                      },
                      defaultRender: () => '-',
                    },
                  },
                  {
                    gridColumn: { span: 24 },
                    label: 'Day Held',
                    name: ['day_held'],
                    fieldType: 'text',
                    renderTextProps: {
                      renderValue(value) {
                        return value;
                      },
                      defaultRender: () => '-',
                    },
                  },
                  {
                    gridColumn: { xl: 20, lg: 20, md: 24, sm: 24, xs: 24 },
                    renderField() {
                      return (
                        <Form.Item label="Showing Schedules">
                          <Form.Item name={['showing_schedule', 'show_day']} noStyle valuePropName="checked">
                            <Checkbox disabled>Day</Checkbox>
                          </Form.Item>
                          <Form.Item name={['showing_schedule', 'show_session']} noStyle valuePropName="checked">
                            <Checkbox disabled>Session</Checkbox>
                          </Form.Item>
                          <Form.Item name={['showing_schedule', 'show_workshop']} noStyle valuePropName="checked">
                            <Checkbox disabled>Workshop</Checkbox>
                          </Form.Item>
                        </Form.Item>
                      );
                    },
                    customHidden: () => {
                      return true;
                    },
                  },
                ],
              },
            ]}
          />
          <Tabs>
            <Tabs.TabPane tab="Schedules">
              <Form.Item noStyle shouldUpdate>
                {({}) => {
                  return (
                    <Form.Item name={['days']} noStyle>
                      <Table
                        className="antd-table-day"
                        columns={columnsDaySchedule}
                        dataSource={dataSchedule}
                        expandable={{
                          rowExpandable: (record) => (record?.sessions?.length >= 1 ? true : false),
                          expandedRowRender: (record) => {
                            const sessions = record?.sessions ?? [];
                            return (
                              <Table
                                className="antd-table-session"
                                columns={columnsSessionSchedule}
                                dataSource={sessions}
                                expandable={{
                                  rowExpandable: (record) => {
                                    const workshops = record?.workshops ?? [];
                                    return workshops?.length >= 1 ? true : false;
                                  },
                                  expandedRowRender: (recordSession) => {
                                    const workshops =
                                      recordSession?.workshops?.length >= 1 ? recordSession?.workshops : [];
                                    return (
                                      <Table
                                        className="antd-table-workshop"
                                        columns={columnsWorkshopSchedule}
                                        dataSource={workshops}
                                        pagination={false}
                                        style={{ marginLeft: '50px' }}
                                      />
                                    );
                                  },
                                }}
                                pagination={false}
                                bordered
                                size="small"
                              />
                            );
                          },
                        }}
                        pagination={false}
                        bordered
                        size="small"
                        style={{ borderColor: 'black' }}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Tabs.TabPane>
          </Tabs>
        </Form>
      </Spin>
    </React.Fragment>
  );
}
