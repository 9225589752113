import React from 'react';
import { FormBuilder } from '@eigen3m/react-base-frontend';
import { capitalize } from 'lodash';
import { Form } from 'antd';

export function EventCategoryForm() {
  return (
    <FormBuilder
      columns={[
        {
          fields: [
            {
              gridColumn: { span: 24 },
              customHidden: () => true,
              renderField() {
                return <Form.Item name="company" noStyle />;
              },
            },
            {
              label: 'Event Category Name',
              gridColumn: { span: 24 },
              name: ['name'],
              fieldType: 'inputText',
              rules: [{ required: true, message: 'Event Category Name is required!' }],
              inputTextProps: {
                placeholder: 'Input Name',
                maxLength: 125,
              },
            },
            {
              label: 'Show to Home Page Menu',
              gridColumn: { span: 24 },
              name: ['show_to_home'],
              fieldType: 'select',
              rules: [{ required: true, message: 'Show to Home Page Menu is required!' }],
              selectProps: {
                classNamePrefix: 'react-select-custom-prefix-radius',
                isClearable: false,
                customLabel(row) {
                  return capitalize(row);
                },
                placeholder: 'Choose',
                options: [
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                ],
              },
            },
          ],
        },
      ]}
    />
  );
}
