export enum DataTabKey {
  information = 'information',
  schedules = 'schedules',
  participants = 'participants',
  registrations = 'registrations',
  paymentPricings = 'payment_pricings',
  attendanceOfficers = 'attendance_officers',
}

export enum STEPKEY {
  information = 'information',
  schedules = 'schedules',
  participants = 'participants',
  registrations = 'registrations',
  paymentPricings = 'payment_pricings',
  attendanceOfficers = 'attendance_officers',
}
