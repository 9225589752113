import { omit } from 'lodash';
import { ReportEntity } from '../entities';
import { BaseEntity, BaseTransformer } from '@eigen3m/react-base-frontend';

export class ReportTransformer<E extends BaseEntity = ReportEntity> extends BaseTransformer<E> {
  transformerUpdate(payload: E): E {
    if (!payload) return payload;
    const cleanVal: any = omit(payload, ['id', 'form_action']);
    Object.assign(cleanVal, { title_id: 1 });
    return cleanVal;
  }

  transformerCreate(payload: E): E {
    const cleanVal = this.transformerUpdate(payload);
    return cleanVal;
  }

  transformerDuplicate(payload: E): E {
    return this.transformerCreate(payload);
  }
}
