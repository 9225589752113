import { useState } from 'react';
import { mockCompanies } from '@states';
import {
  ActionProps,
  IndexPageWrapper,
  makeCommonDataSource,
  notificationFailed,
  notificationSuccess,
  SinglePage,
  usePageProvider,
} from '@eigen3m/react-base-frontend';
import { useRecoilState } from 'recoil';
import { BankColumns } from '../helpers';
import BankForm from '../components/bank-form';
import BankDetail from '../components/bank-detail';
import { ApiUrlData } from '@base-configs';
import { v4 as uuidV4 } from 'uuid';
import { omit } from 'lodash';
import BankAccountTable from '../components/bank-account';
import { Card } from 'antd';
import { useAccessRight } from 'shared/hooks';

export default function IndexPage(): JSX.Element {
  const { dataSource, moduleKey } = usePageProvider();
  const accountDataSource = makeCommonDataSource({ apiUrl: ApiUrlData.accounts });

  const [visibleDrawerForm, setVisibleDrawerForm] = useState(false);
  const [actionForm, setActionForm] = useState<ActionProps>('create');
  const [visibleDrawerDetail, setVisibleDrawerDetail] = useState(false);
  const [companies] = useRecoilState(mockCompanies);
  const [keyRenderAccount, setKeyRenderAccount] = useState();

  const { accessCreate, accessEdit, accessImport, accessChangeStatus, accessDelete, accessView, accessPrint } =
    useAccessRight({
      moduleKey,
    });
  let actionIgnored: any[] = ['confirm_process'];
  if (!accessCreate) actionIgnored = [...actionIgnored, 'duplicate'];
  if (!accessEdit) actionIgnored = [...actionIgnored, 'update'];
  if (!accessChangeStatus) actionIgnored = [...actionIgnored, 'activate', 'deactivate'];
  if (!accessView) actionIgnored = [...actionIgnored, 'detail'];
  if (!accessDelete) actionIgnored = [...actionIgnored, 'delete'];

  const onShowDrawerForm = (action: ActionProps) => {
    setVisibleDrawerForm(true);
    setActionForm(action);
  };
  const onCloseDrawerForm = () => {
    setVisibleDrawerForm(false);
  };

  const onShowDrawerDetail = () => {
    setVisibleDrawerDetail(true);
  };
  const onCloseDrawerDetail = () => {
    setVisibleDrawerDetail(false);
  };

  async function functionCreate(payload: any, configs: any): Promise<void> {
    let httpDataSource = undefined;
    if (payload.field_type === 'bank_account') httpDataSource = accountDataSource;
    else if (payload.field_type === 'bank') httpDataSource = dataSource;
    const newPayload = omit(payload, ['field_type', 'key', 'id', 'data_id']);
    Object.assign(newPayload, { all_region: 'false' });

    await httpDataSource.handleCreate(newPayload, {
      onSuccess: async (res) => {
        await handleConfirmProcess(res.response, configs);
        notificationSuccess(['Successfully created data.']);
      },
      onFailed: ({ message }: any) => {
        notificationFailed(message);
      },
    });
  }

  async function handleConfirmProcess(payload, configs): Promise<void> {
    await dataSource.handleConfirmProcess(payload, {
      onSuccess: ({ response }: any) => {
        if (configs?.onReloadTable) configs?.onReloadTable();
        if (!configs?.isCreateAnother) if (configs.onClose) configs.onClose(undefined);
        if (configs?.form) configs.form.resetFields();
        setKeyRenderAccount(uuidV4());
      },
      onFailed: ({ message }: any) => {
        console.log('failed');
      },
    });
  }

  async function functionUpdate(payload: any, configs: any): Promise<void> {
    let httpDataSource = undefined;
    if (payload.field_type === 'bank_account') httpDataSource = accountDataSource;
    else if (payload.field_type === 'bank') httpDataSource = dataSource;
    const newPayload = omit(payload, ['field_type', 'key', 'id', 'data_id']);
    Object.assign(newPayload, { all_region: 'false' });

    await dataSource.handleUpdate(payload?.data_id, newPayload, {
      onSuccess: async (res) => {
        await handleConfirmProcess(res.response, configs);
        notificationSuccess(['Successfully updated data.']);
      },
      onFailed: ({ message }: any) => {
        notificationFailed(message);
      },
    });
  }

  return (
    <IndexPageWrapper
      pageToolbarProps={{
        showFilter: false,
        showSearch: false,
        showConfirmProcess: false,
        showBack: false,

        disablePrint: !accessPrint,
        disableImport: !accessImport,
        disableNewData: !accessCreate,
        disableDelete: !accessDelete,
        disableActivate: !accessChangeStatus,
        disableDeactivate: !accessChangeStatus,

        pageTitle: 'Bank & Accounts',
        handleClickNewData() {
          onShowDrawerForm('create');
        },
      }}
    >
      <Card>
        <SinglePage
          // Card ICA-111
          autoConfirmAfterAction={true}
          // Card ICA-111
          drawerFormProps={{
            // ignoreKeyDuplicate: ['name'],
            functionCreate,
            functionUpdate,
            title: 'Bank & Account',
            action: actionForm,
            visible: visibleDrawerForm,
            onClose: onCloseDrawerForm,
            forceRequest: true,
            children: <BankForm actionForm={actionForm} />,
            onShow: onShowDrawerForm,
            initialValues: {
              company: !!companies?.length ? companies[0] : null,
              field_type: 'bank',
              document_id: uuidV4(),
            },
          }}
          drawerDetailProps={{
            title: 'Bank & Account',
            visible: visibleDrawerDetail,
            onClose: onCloseDrawerDetail,
            forceRequest: true,
            children: <BankDetail />,
            onShow: onShowDrawerDetail,
          }}
          dataTableProps={{
            columns: BankColumns,
            rowActionProps: {
              actionIgnored,
            },
            expandable: {
              expandedRowRender: (row) => {
                const status = row?.status;
                const triggerRequest = row?.status + '_' + keyRenderAccount;
                return <BankAccountTable bankId={row.id} status={status} triggerRequest={triggerRequest} />;
              },
            },
          }}
        />
      </Card>
    </IndexPageWrapper>
  );
}
