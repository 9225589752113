import { authHelper, FormBuilder, FormPageWrapper, usePageProvider } from '@eigen3m/react-base-frontend';
import { Card, notification } from 'antd';
import { CubeQueryBuilder } from '../components/cube-query-builder';
import { cubejsApi } from '../helpers';

export default function FormPage() {
  const { isCreate, isUpdate, isDuplicate, navigation, dataSource } = usePageProvider();
  const title = isCreate ? 'New' : isUpdate ? 'Update' : isDuplicate ? 'Duplicate' : '';

  async function handleConfirm(payload) {
    await dataSource.handleConfirmProcess(payload, {
      onSuccess(params) {
        console.log({});
      },
      onFailed({ message }: any) {
        notification.error({
          message: 'Failed',
          description: message?.join(', '),
        });
      },
    });
  }

  async function handleAfterCreate(payload) {
    await handleConfirm(payload);
    navigation.goToDetail({ id: payload?.id });
  }

  async function handleAfterUpdate(account) {
    await handleConfirm(account);

    const existAccount = authHelper.getAccount();
    const accountId = account?.id;
    const existAccountId = existAccount?.id;
    if (account && accountId === existAccountId) authHelper.updateAccount(account);
    navigation.goToDetail({ id: account.id });
  }

  const onChangeFormValues = (item: any, values: any): any => {
    let newValues = null;
    if (item?.configuration?.hasOwnProperty('modules')) {
      newValues = {
        ...values,
        configuration: {
          ...values?.configuration,
          query: {
            ...values?.configuration?.query,
            measures: [],
            dimensions: [],
            timeDimensions: [],
          },
        },
      };
    }
    return newValues;
  };

  return (
    <FormPageWrapper
      handleAfterCreate={handleAfterCreate}
      handleAfterUpdate={handleAfterUpdate}
      pageToolbarProps={{
        pageTitle: `${title} Report`,
        // showBack: false
      }}
      ignoreKeyUpdate={['password', 'region', 'sub_region']}
      ignoreKeyDuplicate={['password', 'email', 'region', 'sub_region']}
      formInstance={{
        initialValues: {
          configuration: {
            chartType: 'table',
            query: {
              limit: 500,
            },
          },
        },
      }}
      onChangeFormValues={onChangeFormValues}
    >
      <Card>
        <FormBuilder
          columns={[
            {
              // title: 'GENERAL INFORMATION',
              fields: [
                {
                  label: 'Report Name',
                  name: 'name',
                  fieldType: 'inputText',
                  rules: [{ required: true, message: 'Name is required.' }],
                  inputTextProps: {
                    allowClear: true,
                  },
                },
              ],
            },
          ]}
        />
      </Card>
      <div style={{ marginBottom: 10 }}></div>
      <CubeQueryBuilder cubejsApi={cubejsApi} ignoreChartType={['area', 'bar', 'line', 'pie']} />
    </FormPageWrapper>
  );
}
