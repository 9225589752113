import { ApiUrlData } from '@base-configs';
import { Card, Col, Row } from 'antd';
import { usePageProvider } from '@eigen3m/react-base-frontend';
import { useDataIndex } from '@admin-app/pages/events/event-activity/presentation/hooks/useDataIndex';

export function ParticipantSummary(): JSX.Element {
  const { id } = usePageProvider();

  const { data } = useDataIndex(id, ApiUrlData.eventActivities + '/summary');

  return (
    <Card style={{ marginBottom: 30 }}>
      {data ? (
        data?.map((participant) => (
          <Row key={participant?.id}>
            {/* <Col span={5}>
              <h3># Active Participant</h3>
              <span>{participant?.total_active_participant ?? '-'}</span>
            </Col> */}
            <Col xl={6} lg={6} md={12} sm={12} xs={24}>
              <h3># Checked In</h3>
              <span>{participant?.total_check_in_participant_attandance ?? '0'}</span>
            </Col>
            <Col xl={6} lg={6} md={12} sm={12} xs={24}>
              <h3># Not Mandatory</h3>
              <span>{participant?.total_not_mandatory_participant_attandance ?? '0'}</span>
            </Col>
            <Col xl={6} lg={6} md={12} sm={12} xs={24}>
              <h3># Waiting</h3>
              <span>{participant?.total_waiting_participant_attandance ?? '0'}</span>
            </Col>
            <Col xl={6} lg={6} md={12} sm={12} xs={24}>
              <h3># Not Come</h3>
              <span>{participant?.total_not_come_participant_attandance ?? '0'}</span>
            </Col>
          </Row>
        ))
      ) : (
        <h2 style={{ textAlign: 'center' }}>No Data</h2>
      )}
    </Card>
  );
}
