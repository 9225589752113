import { useEffect, useState } from 'react';
import { Table, Tabs } from 'antd';
import { AntdIconWrapper, RenderText, stringFormatter } from '@eigen3m/react-base-frontend';
import { MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank } from 'react-icons/md';
import { ColumnsType } from 'antd/lib/table';

type DetailTabsProps = {
  value?: any;
};

function checkGroupKey(item: any, row: any, key: any): string {
  if (row.group_key === key) return stringFormatter.capitalizeEachWord(item);
  return ' ';
}

export default function DetailTabs({ value }: DetailTabsProps): JSX.Element {
  const [activeTab, setActiveTab] = useState<string>('all');
  const [dataTable, setDataTable] = useState<any[]>([]);
  const [tabName, setTabName] = useState<any[]>([]);

  function onTabsChange(key: string): void {
    setActiveTab(key);
  }

  function filterDataTable(data: any[] = [], moduleTab: string = activeTab) {
    if (!data) return [];
    return data.filter((item) => item.name === moduleTab);
  }

  function renderCheckbox({ value, row, action }) {
    if (value)
      return (
        <AntdIconWrapper>
          <MdOutlineCheckBox size={18} fill="#096dd9" />
        </AntdIconWrapper>
      );
    else
      return (
        <AntdIconWrapper>
          <MdOutlineCheckBoxOutlineBlank size={18} />
        </AntdIconWrapper>
      );
  }

  useEffect(() => {
    const newValue = value ?? [];
    const itemTabName = newValue?.map((item: any) => item.name);

    setDataTable(newValue);
    setTabName(itemTabName);
  }, [value]);

  const dataTabColumn: ColumnsType<any> = [
    {
      title: 'Module',
      ellipsis: true,
      width: 100,
      key: 'name',
      dataIndex: 'name',
      fixed: true,
      render: (item, row): JSX.Element => RenderText({ value: checkGroupKey(item, row, 'module') }),
    },
    {
      title: 'Sub Module',
      ellipsis: true,
      width: 120,
      key: 'name',
      dataIndex: 'name',
      fixed: true,
      render: (item, row): JSX.Element =>
        RenderText({
          value: checkGroupKey(item, row, 'sub_module'),
        }),
    },
    {
      title: 'Activity',
      ellipsis: true,
      width: 120,
      key: 'name',
      dataIndex: 'name',
      fixed: true,
      render: (item, row): JSX.Element =>
        RenderText({
          value: checkGroupKey(item, row, 'activity'),
        }),
    },
    {
      title: 'Create',
      align: 'center',
      ellipsis: true,
      width: 70,
      key: 'create',
      dataIndex: 'create',
      render: (item, row): JSX.Element => renderCheckbox({ value: item, row, action: 'create' }),
    },

    {
      title: 'View',
      align: 'center',
      ellipsis: true,
      width: 70,
      key: 'view',
      dataIndex: 'view',
      render: (item, row): JSX.Element => renderCheckbox({ value: item, row, action: 'view' }),
    },
    {
      title: 'Edit',
      align: 'center',
      ellipsis: true,
      width: 70,
      key: 'edit',
      dataIndex: 'edit',
      render: (item, row): JSX.Element => renderCheckbox({ value: item, row, action: 'edit' }),
    },
    {
      title: 'Delete',
      align: 'center',
      ellipsis: true,
      width: 70,
      key: 'delete',
      dataIndex: 'delete',
      render: (item, row): JSX.Element => renderCheckbox({ value: item, row, action: 'delete' }),
    },

    {
      title: 'Cancel',
      align: 'center',
      ellipsis: true,
      width: 70,
      key: 'cancel',
      dataIndex: 'cancel',
      render: (item, row): JSX.Element => renderCheckbox({ value: item, row, action: 'cancel' }),
    },

    // {
    //   title: 'Rollback',
    //   align: 'center',
    //   ellipsis: true,
    //   width: 70,
    //   key: 'reverse',
    //   dataIndex: 'reverse',
    //   render: (item, row): JSX.Element => renderCheckbox({ value: item, row, action: 'reverse' }),
    // },

    {
      title: 'Confirm',
      align: 'center',
      ellipsis: true,
      width: 70,
      key: 'confirm_process',
      dataIndex: 'confirm_process',
      render: (item, row): JSX.Element => renderCheckbox({ value: item, row, action: 'confirm_process' }),
    },

    // {
    //   title: 'Print',
    //   align: 'center',
    //   ellipsis: true,
    //   width: 70,
    //   key: 'print',
    //   dataIndex: 'print',
    //   render: (item, row): JSX.Element => renderCheckbox({ value: item, row, action: 'print' }),
    // },
    // {
    //   title: 'Export',
    //   align: 'center',
    //   ellipsis: true,
    //   width: 70,
    //   key: 'export',
    //   dataIndex: 'export',
    //   render: (item, row): JSX.Element => renderCheckbox({ value: item, row, action: 'export' }),
    // },
    // {
    //   title: 'Import',
    //   align: 'center',
    //   ellipsis: true,
    //   width: 70,
    //   key: 'import',
    //   dataIndex: 'import',
    //   render: (item, row): JSX.Element => renderCheckbox({ value: item, row, action: 'import' }),
    // },
    {
      title: 'Change Status',
      align: 'center',
      ellipsis: true,
      width: 120,
      key: 'change_status',
      dataIndex: 'change_status',
      render: (item, row): JSX.Element => renderCheckbox({ value: item, row, action: 'change_status' }),
    },
    // {
    //   title: 'View Log',
    //   align: 'center',
    //   ellipsis: true,
    //   width: 100,
    //   key: 'view_log',
    //   dataIndex: 'view_log',
    //   render: (item, row): JSX.Element => renderCheckbox({ value: item, row, action: 'view_log' }),
    // },
    // {
    //   title: 'View notes',
    //   align: 'center',
    //   ellipsis: true,
    //   width: 100,
    //   key: 'view_notes',
    //   dataIndex: 'view_notes',
    //   render: (item, row): JSX.Element => renderCheckbox({ value: item, row, action: 'view_notes' }),
    // },
  ];

  return (
    <Tabs tabPosition="left" onChange={onTabsChange} defaultActiveKey="all">
      {tabName?.map((tab) => (
        <Tabs.TabPane tab={stringFormatter.capitalizeEachWord(tab)} key={tab}>
          <Table
            columns={dataTabColumn}
            style={{ minHeight: '300px' }}
            dataSource={filterDataTable(dataTable, activeTab)}
            pagination={false}
            sticky={true}
            size="small"
            scroll={{ x: 'fit-content' }}
            expandable={{
              expandedRowKeys: dataTable.map((data) => data?.key),
              showExpandColumn: false,
            }}
          />
        </Tabs.TabPane>
      ))}
    </Tabs>
  );
}
