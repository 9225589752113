import React from 'react';
import { ApiUrlData } from '@base-configs';
import { authHelper, FormBuilder, usePageProvider } from '@eigen3m/react-base-frontend';
import { phoneNumberPrefixOptions } from '@helpers';
import { Card, Form, Select } from 'antd';

const { Option } = Select;

interface Props {
  isCreate?: boolean;
}

const prefixPhoneSelector = (
  <Form.Item name="prefix_phone" noStyle>
    <Select
      style={{ width: 70 }}
      defaultValue={'+62'}
      showSearch
      filterOption={(input, option) =>
        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
      }
    >
      {/* <Option value="+62">+62</Option> */}
      {phoneNumberPrefixOptions?.map((item, index) => {
        return (
          <Option key={index} value={item?.dial_code}>
            {item?.dial_code}
          </Option>
        );
      })}
    </Select>
  </Form.Item>
);

const eventOptions = ['Internal', 'External'].map((item) => {
  return {
    label: item,
    value: item,
  };
});

const superAdminId = '982f9a63-cf46-4d0c-9a96-01fa33dee7a1';

export function UserForm(props: Props) {
  const { isUpdate } = usePageProvider();
  const provider = usePageProvider();
  const isDisableName = provider?.isUpdate && provider?.detailData?.user_exist_id;
  const isDisableEmail = provider?.isUpdate && provider?.detailData?.user_exist_id;
  const account = authHelper.getAccount();
  const isSuperAdmin = account?.user?.user_category?.uuid === superAdminId;
  const gridColumn = { xxl: 4, xl: 4, lg: 8, md: 8, sm: 12, xs: 24 };
  return (
    <React.Fragment>
      <Form.Item shouldUpdate noStyle>
        {({ getFieldsValue, setFieldsValue }) => {
          const values = getFieldsValue();
          const hasPassword = values?.password;
          return (
            <Card>
              <FormBuilder
                columns={[
                  {
                    title: 'INDIVIDUAL INFORMATION',
                    fields: [
                      {
                        label: 'Name',
                        name: 'name',
                        fieldType: 'inputText',
                        rules: [{ required: true }],
                        inputTextProps: {
                          allowClear: true,
                          disabled: isDisableName,
                        },
                        gridColumn,
                      },
                      {
                        label: 'User Category',
                        name: 'user_category',
                        fieldType: 'selectPaginate',
                        rules: [{ required: true }],
                        selectPaginateProps: {
                          classNamePrefix: 'react-select-custom-prefix-radius',
                          dataSourceUrl: ApiUrlData.user_category,
                          baseDataSourceUrl: process.env.REACT_APP_ACCESS_CONTROL_URL,
                          transformOptions(options) {
                            return isSuperAdmin
                              ? [
                                  {
                                    code: 'ADM',
                                    name: 'superadmin',
                                    uuid: '982f9a63-cf46-4d0c-9a96-01fa33dee7a1',
                                    id: '982f9a63-cf46-4d0c-9a96-01fa33dee7a1',
                                  },
                                  ...options,
                                ]
                              : options;
                          },
                          customFilterRequest() {
                            return {
                              statuses: ['active'],
                            };
                          },
                          customLabel(row) {
                            return `${row?.name} (${row?.code})`;
                          },
                          keySearch: 'search',
                        },
                        gridColumn,
                      },
                      {
                        label: 'Event',
                        name: 'event_categories',
                        gridColumn,
                        fieldType: 'selectPaginate',
                        rules: [{ required: true }],
                        selectPaginateProps: {
                          // isDisabled: !isSuperAdmin,
                          isMulti: true,
                          classNamePrefix: 'react-select-custom-prefix-radius',
                          dataSourceUrl: ApiUrlData.event_categories,
                          useOptionAllScheme: true,
                          customFilterRequest(payload) {
                            return {
                              statuses: ['active'],
                            };
                          },
                          customLabel(row) {
                            return row?.name;
                          },
                          keySearch: 'search',
                        },
                      },
                      {
                        label: 'Email',
                        name: 'email',
                        fieldType: 'inputText',
                        rules: [{ type: 'email' }, { required: true }],
                        inputTextProps: {
                          allowClear: true,
                          disabled: isDisableEmail,
                        },
                        gridColumn,
                      },
                      {
                        label: 'Password',
                        name: 'password',
                        fieldType: 'inputPassword',
                        rules: [
                          {
                            required: isUpdate ? (hasPassword ? true : false) : true,
                            message: 'Password is required.',
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              const action = getFieldValue('form_action');
                              if (action === 'create' || action === 'duplicate') {
                                if (!value) return Promise.reject(new Error('Password is required!'));
                                else return Promise.resolve();
                              } else return Promise.resolve();
                            },
                          }),
                        ],

                        inputPasswordProps: {
                          placeholder: 'Password',
                          allowClear: true,
                          autoComplete: 'new-password',
                        },
                        gridColumn,
                      },
                      {
                        label: 'Confirm Password',
                        name: 'retype_password',
                        fieldType: 'inputPassword',
                        dependencies: ['password'],
                        hasFeedback: true,
                        rules: [
                          { required: isUpdate ? (hasPassword ? true : false) : true },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              const password = getFieldValue('password');
                              if (!password) return Promise.resolve();
                              else if (password === value) return Promise.resolve();
                              else if (!value) return Promise.reject(new Error('Please confirm your password!'));
                              else return Promise.reject(new Error("Passwords doesn't match!"));
                            },
                          }),
                        ],
                        inputPasswordProps: {
                          placeholder: 'Confirm Password',
                          allowClear: true,
                          autoComplete: 'new-password',
                        },
                        gridColumn,
                      },
                    ],
                  },
                ]}
              />
            </Card>
          );
        }}
      </Form.Item>
    </React.Fragment>
  );
}
