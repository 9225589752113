import moment from 'moment';

export function RegistrationList({ value }: any) {
  const registConfig = value?.registration_config?.toLowerCase();
  const registDetails =
    !!value?.registration_details && !!value?.registration_details?.length ? value?.registration_details : [];
  const registOpen = value?.registration_information?.registration_open
    ? moment(value?.registration_information?.registration_open).format('DD MMM YYYY')
    : null;
  const registClose = value?.registration_information?.registration_close
    ? moment(value?.registration_information?.registration_close).format('DD MMM YYYY')
    : null;

  const maxParticipant = value?.registration_information?.max_registrants;
  const RegistrationDetails = ({ registConfig, registDetails, registOpen, registClose }: any) => {
    return registConfig?.includes('event') ? (
      <div>
        {!!registOpen && !!registClose ? (
          <div>
            <strong style={{ display: 'block' }}>
              Registration Date : {registOpen} - {registClose}
            </strong>
            <strong style={{ display: 'block' }}>Max Participant : {maxParticipant ?? '-'}</strong>
          </div>
        ) : (
          '-'
        )}
      </div>
    ) : ['registration per event', 'registration per each day', 'registration per each session'].includes(
        registConfig,
      ) ? (
      <ul style={{ listStyleType: 'none', padding: 0 }}>
        {(registConfig?.includes('day') || registConfig?.includes('session')) &&
          registDetails?.map((item, key) => {
            const registOpen = item?.registration_open ? moment(item?.registration_open).format('DD MMM YYYY') : null;
            const registClose = item?.registration_close
              ? moment(item?.registration_close).format('DD MMM YYYY')
              : null;
            const isSameDate = !!registOpen && !!registClose ? (registOpen === registClose ? true : false) : false;
            const time =
              !!registOpen && !!registClose
                ? `${moment(item?.registration_open)?.format('HH:mm')} - ${moment(item?.registration_close)?.format(
                    'HH:mm',
                  )}`
                : null;
            const sessions = item?.sessions ?? [];
            return (
              <li key={key}>
                <div style={{ display: 'block' }}>
                  <strong>
                    Day {item?.no} {item?.name ? `: ${item?.name}` : ''}
                    {registOpen &&
                      registClose &&
                      ` - Registration Date (${
                        isSameDate ? registOpen + ', ' + time : registOpen + ' - ' + registClose
                      })${!!item?.max_registrants ? ', Max Participant ' + item?.max_registrants + ' Person' : ''}`}
                  </strong>
                  {!!item?.description && <p>{item?.description}</p>}
                </div>
                {registConfig?.includes('session') && !!sessions?.length && (
                  <ul style={{ listStyleType: 'none' }}>
                    {sessions?.map((itemSession, keySession) => {
                      const registOpen = itemSession?.registration_open
                        ? moment(itemSession?.registration_open).format('DD MMM YYYY')
                        : null;
                      const registClose = itemSession?.registration_close
                        ? moment(itemSession?.registration_close).format('DD MMM YYYY')
                        : null;
                      const isSameDate =
                        !!registOpen && !!registClose ? (registOpen === registClose ? true : false) : false;
                      const time =
                        !!registOpen && !!registClose
                          ? `${moment(itemSession?.registration_open)?.format('HH:mm')} - ${moment(
                              itemSession?.registration_close,
                            )?.format('HH:mm')}`
                          : null;

                      const workshops = itemSession?.workshops?.length ? itemSession?.workshops : [];
                      return (
                        <li key={keySession}>
                          <div style={{ display: 'block' }}>
                            <strong>
                              Session {itemSession?.no} {itemSession?.name ? `: ${itemSession?.name}` : ''}
                              {registOpen &&
                                registClose &&
                                ` - Registration Date (${
                                  isSameDate ? registOpen + ', ' + time : registOpen + ' - ' + registClose
                                }) ${
                                  !!itemSession?.max_registrants
                                    ? ', Max Participant ' + itemSession?.max_registrants + ' Person'
                                    : ''
                                }`}
                            </strong>
                            {!!itemSession?.description && <p>{itemSession?.description}</p>}
                          </div>
                          {!!workshops?.length && (
                            <ul style={{ marginBottom: '10px', marginLeft: 0 }}>
                              {workshops?.map((itemWorkshop, keyWorkshop) => {
                                return (
                                  <li key={keyWorkshop}>
                                    <div style={{ display: 'block' }}>
                                      {itemWorkshop?.name} :
                                      {!!itemWorkshop?.max_registrants
                                        ? ' Max Participant ' + itemWorkshop?.max_registrants + ' Person'
                                        : ''}
                                    </div>
                                  </li>
                                );
                              })}
                            </ul>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                )}
              </li>
            );
          })}
      </ul>
    ) : (
      <div>-</div>
    );
  };
  return (
    <>
      {!!value?.description && <p>{value?.description}</p>}
      {RegistrationDetails({ registConfig, registDetails, registOpen, registClose })}
    </>
  );
}
