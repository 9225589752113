import React, { useEffect, useState } from 'react';
import { usePageProvider } from '@eigen3m/react-base-frontend';
import { ApiUrlData } from '@base-configs';
import { Col, Layout, Row, Skeleton, Spin } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { EventActivitiesDetail } from '@components/event-activities-detail';
import RegistrationForm from '@components/registration-form';
import { typeOf } from 'mathjs';
const { Content } = Layout;
const assetUrl = process.env.REACT_APP_BASE_ASSET_URL;

export default function DetailPage() {
  const { dataSource } = usePageProvider();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const { id } = useParams();

  const handleGetData = async (id) => {
    setLoading(true);
    await dataSource.handleCustomRequest({
      paramRequest: {
        method: 'GET',
        url: process.env.REACT_APP_BASE_SERVICE_URL.includes('gateway')
          ? process.env.REACT_APP_BASE_SERVICE_URL.replace('gateway', 'api') + ApiUrlData.eventActivities + `/${id}`
          : process.env.REACT_APP_BASE_SERVICE_URL + ApiUrlData.eventActivities + `/${id}`,
      },
      onSuccess({ response }: any) {
        document.title = `${response?.information?.name} - IFGF Semarang`;
        if (typeOf(response) === 'Object') setData(response);
        else navigate('/404');
      },
      onFailed() {
        navigate('/404');
      },
    });
    setLoading(false);
  };

  useEffect(() => {
    handleGetData(id);
  }, [id]);

  const bannerUrl = data?.information?.banner_url ?? data?.information?.document_url;
  const heroImageUrl = bannerUrl ? assetUrl + bannerUrl : '';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout
    // style={{ marginTop: '60px' }}
    >
      <Content
        className="content-detail-width"
        style={{
          // width: '1200px',
          margin: '0 auto',
          // padding: '40px 0',
        }}
      >
        {loading ? (
          <div style={{ width: '100%', background: '#fff', padding: '20px 20px 10px', borderRadius: '4px' }}>
            <div
              style={{
                position: 'relative',
                // width: '100%',
                background: '#f0f0f0',
                margin: '-20px -20px 20px',
                overflow: 'hidden',
                objectFit: 'cover',
              }}
            >
              <Skeleton.Image
                active
                style={{
                  width: '100vw',
                  maxWidth: '1200px',
                  height: '350px',
                }}
              />
            </div>
            <Skeleton active paragraph={{ rows: 10 }} />
          </div>
        ) : (
          <div id="event-detail">
            <div className="hero-image" style={{ overflow: 'hidden' }}>
              <img
                title={data?.information?.name}
                src={heroImageUrl}
                style={{ width: '100%', height: '450px', borderRadius: '8px 8px 0 0', objectFit: 'cover' }}
              />
              {/* <div className="title">
                <h1>
                  <strong>{data?.information?.name}</strong>
                </h1>
              </div> */}
            </div>
            <div className="event-detail-body" style={{ borderTop: '1px solid #f0f0f0' }}>
              <Row gutter={[32, 8]}>
                <Col xl={12} lg={12} md={24} sm={24} style={{ borderRight: '1px solid #f0f0f0' }}>
                  <div>
                    <div style={{ fontSize: 30 }}>
                      <strong>{data?.information?.name}</strong>
                    </div>
                    <div style={{ marginBottom: 20 }}></div>
                  </div>
                  <EventActivitiesDetail value={data} />
                </Col>
                <Col xl={12} lg={12} md={24} sm={24}>
                  {!!data && (
                    <RegistrationForm
                      registration={data?.registrations}
                      payments={data?.payment_pricings}
                      id={id}
                      participants={data?.participants}
                      withOnlyAdmin
                    />
                  )}
                </Col>
              </Row>
            </div>
          </div>
        )}
      </Content>
    </Layout>
  );
}
