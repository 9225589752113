import { makeCommonDataSource } from '@eigen3m/react-base-frontend';
import { useEffect, useState } from 'react';

export function useDataIndex(id, apiUrl) {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | any>('');

  const dataSource = makeCommonDataSource({
    apiUrl,
  });

  async function getIndexData(id): Promise<void> {
    setLoading(true);
    await dataSource.handleGetIndex({
      params: {
        event_ids: id,
      },
      onSuccess({ response }: any): void {
        setLoading(false);
        setData(response ?? []);
      },
      onFailed({ message }: any): void {
        setLoading(false);
        setError(message);
      },
    });
  }

  useEffect(() => {
    getIndexData(id);
  }, [id]);

  return { data, loading, error } as const;
}
