import React, { useEffect, useState } from 'react';
import { DetailBuilder, usePageProvider } from '@eigen3m/react-base-frontend';
import { Form, notification, Spin } from 'antd';
import { transformerGetDataParticipant } from '@admin-app/pages/events/event-data/domain/transformer/transformer.get';
import { ApiUrlData } from '@base-configs';

interface Props {
  value?: any;
  onChange?: (value: any) => void;
}
export function DetailParticipants(props: Props) {
  const { onChange } = props;
  const [form] = Form.useForm();
  const { id, dataSource } = usePageProvider();
  const [loading, setLoading] = useState(false);

  const handleGetDataParticipant = async (id: string) => {
    setLoading(true);
    await dataSource.handleCustomRequest({
      paramRequest: {
        method: 'GET',
        url: process.env.REACT_APP_BASE_SERVICE_URL + ApiUrlData.eventData + `/${id}/participants`,
      },
      onSuccess(response: any) {
        const dataParticipant = response?.response ? transformerGetDataParticipant(response?.response) : null;
        form.setFieldsValue({ ...dataParticipant });
      },
      onFailed(response: any) {
        notification.error({
          message: 'Get data participant error',
          description: response,
        });
      },
    });
    setLoading(false);
  };

  useEffect(() => {
    if (id) {
      handleGetDataParticipant(id);
    }
  }, [id]);

  const onValuesChange = (item, values) => {
    let newValues = values;
    if (Object.keys(item).includes('public_event')) {
      newValues = {
        ...values,
        departments: null,
        ministries: null,
        job_positions: null,
      };
    }
    if (newValues && onChange) {
      onChange(newValues);
    }
    return newValues;
  };
  return (
    <Spin spinning={loading}>
      <Form form={form} onValuesChange={onValuesChange}>
        <DetailBuilder
          title="Participant Configuration"
          columns={[
            {
              fields: [
                {
                  gridColumn: { span: 24 },
                  label: 'Participant Description',
                  name: ['description'],
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue(value) {
                      return value;
                    },
                    defaultRender: () => '-',
                  },
                },
                {
                  label: 'Participant Type',
                  gridColumn: { xl: 4, lg: 4, md: 12, sm: 24, xs: 24 },
                  name: ['participant_type'],
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue(value) {
                      return value;
                    },
                    defaultRender: () => '-',
                  },
                },
                {
                  label: 'Departments',
                  gridColumn: { xl: 4, lg: 4, md: 12, sm: 24, xs: 24 },
                  name: ['departments'],
                  fieldType: 'tag',
                  renderTagProps: {
                    renderValue(value) {
                      return value?.name;
                    },
                    defaultRender: () => '-',
                    renderType: 'multi',
                  },
                  customHidden(form) {
                    const participantType = form?.getFieldValue('participant_type');
                    return ['Member', 'Volunteer']?.includes(participantType) ? false : true;
                  },
                },
                {
                  label: 'Ministries',
                  gridColumn: { xl: 4, lg: 4, md: 12, sm: 24, xs: 24 },
                  name: ['ministries'],
                  fieldType: 'tag',
                  renderTagProps: {
                    renderValue(value) {
                      return value?.name;
                    },
                    defaultRender: () => '-',
                    renderType: 'multi',
                  },
                  customHidden(form) {
                    const participantType = form?.getFieldValue('participant_type');
                    return ['Volunteer']?.includes(participantType) ? false : true;
                  },
                },
                // {
                //   label: 'Positions',
                //   gridColumn: { xl: 6, lg: 6, md: 12, sm: 24, xs: 24 },
                //   name: ['position'],
                //   fieldType: 'tag',
                //   renderTagProps: {
                //     renderValue(value) {
                //       return value?.name;
                //     },
                //     defaultRender: () => '-',
                //   },
                //   customHidden(form) {
                //     const publicEvent = form?.getFieldValue('public_event');
                //     return publicEvent === 'true' ? true : false;
                //   },
                // },
                {
                  gridColumn: { xl: 4, lg: 4, md: 6, sm: 24, xs: 24 },
                  renderField(form) {
                    const ageMin = form.getFieldValue(['age_min']);
                    const ageMax = form.getFieldValue(['age_max']);
                    return (
                      <Form.Item label="Age">
                        {ageMin && ageMax
                          ? `${ageMin} - ${ageMax} years old`
                          : ageMin
                          ? `above ${ageMin} years old`
                          : ageMax
                          ? `below ${ageMax} years old`
                          : '-'}
                      </Form.Item>
                    );
                  },
                },
                {
                  label: 'Gender',
                  gridColumn: { xl: 3, lg: 4, md: 12, sm: 24, xs: 24 },
                  name: ['gender'],
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue(value) {
                      return value;
                    },
                    defaultRender: () => '-',
                  },
                },
                {
                  label: 'Marital Status',
                  gridColumn: { xl: 3, lg: 4, md: 12, sm: 24, xs: 24 },
                  name: ['marital_status'],
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue(value) {
                      return value;
                    },
                    defaultRender: () => '-',
                  },
                },
              ],
            },
          ]}
        />
      </Form>
    </Spin>
  );
}
