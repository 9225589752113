import { FC } from 'react';
import { FormBuilder } from '@eigen3m/react-base-frontend';

export const FormBuilderOnline: FC = () => {
  return (
    <FormBuilder
      className="mt-0"
      columns={[
        {
          fields: [
            {
              label: 'Event Link',
              gridColumn: { xl: 8, lg: 12, md: 24 },
              name: ['event_link'],
              rules: [{ required: true, message: 'Event Link is required' }],
              fieldType: 'inputText',
            },
            {
              label: 'Event Code',
              gridColumn: { xl: 8, lg: 12, md: 24 },
              name: ['event_code'],
              rules: [{ required: true, message: 'Event Code is required' }],
              fieldType: 'inputText',
            },
            {
              label: 'Event Password',
              name: ['event_password'],
              rules: [{ required: true, message: 'Event Password is required' }],
              gridColumn: { xl: 8, lg: 12, md: 24 },
              fieldType: 'inputText',
            },
          ],
        },
      ]}
    />
  );
};
