import { useEffect, useState } from 'react';
import { FormBuilder, FormPageWrapper, makeCommonDataSource, usePageProvider } from '@eigen3m/react-base-frontend';
import { Card, Form } from 'antd';

import { transformUserCategory } from '../helpers';
import FormTabs from '../components/form-tabs';
import { useRecoilState } from 'recoil';
import { mockCompanies } from '@states';

export default function FormPage(): JSX.Element {
  const [tabName, setTabName] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [formState, setFormState] = useState(undefined);
  const [companies] = useRecoilState(mockCompanies);

  const { isCreate, isUpdate } = usePageProvider();

  const dataSourceUserCategory = makeCommonDataSource({
    apiUrl: 'v1/user-category/generate-format?tab_names[]=access_right',
    // baseUrl: 'https://api.testing.erp.eigen.co.id/api/',
  });

  const titleForm = ` ${isCreate ? 'New' : isUpdate ? 'Update' : ''} User Category`;

  async function getDataModule() {
    setLoading(true);
    await dataSourceUserCategory.handleGetIndex({
      onSuccess: ({ response }: any) => {
        const newData = transformUserCategory(response?.access_right);
        const newTabName = newData?.map((item: any) => item?.name);

        if (isCreate && formState) {
          formState.setFieldsValue({
            access_right: newData,
          });
        }

        setTabName(newTabName);
        setLoading(false);
      },
      onFailed: ({ message }: any) => {
        console.error(message);
        setLoading(false);
      },
    });
  }

  useEffect(() => {
    getDataModule();
  }, [isCreate, formState]);

  const params = {
    tab_names: ['access_right'],
  };

  return (
    <FormPageWrapper
      paramGetData={params}
      pageToolbarProps={{
        pageTitle: titleForm,
        // showBack: false
        showDataTypeSelector: false,
      }}
      getFormInstance={setFormState}
      formInstance={{
        initialValues: {
          companies: companies ?? [],
          company: !!companies?.length ? companies[0] : null,
        },
      }}
      autoConfirmAfterAction={true}
    >
      <Card style={{ marginBottom: 12 }}>
        <FormBuilder
          columns={[
            {
              fields: [
                {
                  name: 'code',
                  label: 'Code',
                  fieldType: 'inputText',
                  rules: [{ required: true }],
                  gridColumn: { span: 6 },
                  inputTextProps: {
                    placeholder: 'Input Code',
                    style: { textTransform: 'uppercase' },
                  },
                },
                {
                  name: 'name',
                  label: 'Name',
                  fieldType: 'inputText',
                  rules: [{ required: true }],
                  gridColumn: { span: 6 },
                  inputTextProps: {
                    placeholder: 'Input Name',
                  },
                },
              ],
            },
          ]}
        />
      </Card>

      <Card>
        <FormBuilder
          columns={[
            {
              fields: [
                {
                  gridColumn: { span: 24 },
                  renderField(form) {
                    const dataGeneral = form.getFieldsValue();

                    return (
                      <Form.Item name="access_right">
                        <FormTabs dataGeneral={dataGeneral} tabName={tabName} loading={loading} />
                      </Form.Item>
                    );
                  },
                },
              ],
            },
          ]}
        />
      </Card>
      <Form.Item name="companies" hidden></Form.Item>
      <Form.Item name="company" hidden></Form.Item>
    </FormPageWrapper>
  );
}
