function overrideRules(data: any, action: any) {
  const result = data.map((item) => {
    const newItem = {};
    let children = [];

    if (item?.children) {
      children = overrideRules(item.children, action);
    }

    if (action === 'view') {
      if (!item.view) {
        Object.assign(newItem, { create: false, edit: false });
      }
    } else if (item.create || item.edit) {
      Object.assign(newItem, { view: true });
    } else if (!item.create && !item.edit) {
      Object.assign(newItem, { view: false });
    } else if (!item.view) {
      Object.assign(newItem, { create: false, edit: false });
    }

    return { ...item, ...newItem, children };
  });

  return result;
}

function checkDataModule(data: any, action: any) {
  if (!data) return [];
  const newData = data.filter((item: any) => item.name !== 'all');
  const findChildTrue = newData.find((itemModule) => itemModule[action] === false);
  const actionValue = !findChildTrue;

  console.log({ newData, findChildTrue, actionValue });

  return data.map((item) => {
    if (item.name !== 'all') {
      return item;
    }
    return {
      ...item,
      [`${action}`]: actionValue ? actionValue : item[action],
    };
  });
}

function checkDataSubModule(data, row, action) {
  if (!data) return [];
  return data.map((item) => {
    if (item.moduleKey !== row.moduleKey) return item;
    const findChildTrue = item.children.find((itemChild) => itemChild[action] === true);
    return {
      ...item,
      [`${action}`]: findChildTrue ? !!findChildTrue : item[action],
    };
  });
}

function checkDataActivity(data, row, action) {
  if (!data) return [];
  return data.map((item) => {
    if (item.moduleKey !== row.moduleKey) return item;
    const findChildTrue = item.children.find((itemChild) => itemChild[action] === true);

    return {
      ...item,
      [`${action}`]: !!findChildTrue,
      children: item.children.map((itemSub) => {
        if (itemSub.subModuleKey !== row.subModuleKey) return itemSub;
        const findChildSubTrue = itemSub.children.find((itemChildSub) => itemChildSub[action] === true);
        return {
          ...itemSub,
          [`${action}`]: findChildSubTrue ? !!findChildSubTrue : itemSub[action],
        };
      }),
    };
  });
}

export const changeDataModule = ({ data, row, action, value }) => {
  const newData = data.map((itemModule) => {
    if (row.name === 'all') {
      return {
        ...itemModule,
        [`${action}`]: value,
        children: itemModule.children.map((itemSubModule) => {
          return {
            ...itemSubModule,
            [`${action}`]: value,
            children: itemSubModule.children.map((itemActivity) => {
              return {
                ...itemActivity,
                [`${action}`]: value,
              };
            }),
          };
        }),
      };
    } else if (itemModule.name !== row.name) {
      return itemModule;
    }

    return {
      ...itemModule,
      [`${action}`]: value,
      children: itemModule.children.map((itemSubModule) => {
        return {
          ...itemSubModule,
          [`${action}`]: value,
          children: itemSubModule.children.map((itemAc) => {
            return {
              ...itemAc,
              [`${action}`]: value,
            };
          }),
        };
      }),
    };
  });

  const result = checkDataModule(newData, action);
  return overrideRules(result, action);
};

export const changeDataSubModule = ({ data, row, action, value }) => {
  const newData = data.map((itemModule) => {
    if (itemModule.module_key !== row.module_key) return itemModule;
    return {
      ...itemModule,
      children: itemModule.children.map((itemSubModule) => {
        if (itemSubModule.sub_module_key !== row.sub_module_key) return itemSubModule;
        return {
          ...itemSubModule,
          [`${action}`]: value,
          children: itemSubModule.children.map((itemAct) => {
            return {
              ...itemAct,
              [`${action}`]: value,
            };
          }),
        };
      }),
    };
  });

  const result = checkDataModule(checkDataSubModule(newData, row, action), action);
  return overrideRules(result, action);
};

export const changeDataActivity = ({ data, row, action, value }) => {
  const newData = data.map((itemModule) => {
    if (itemModule.module_key !== row.module_key) return itemModule;
    return {
      ...itemModule,
      children: itemModule.children.map((itemSubModule) => {
        if (itemSubModule.sub_module_key !== row.sub_module_key) return itemSubModule;
        return {
          ...itemSubModule,
          children: itemSubModule.children.map((itemAct) => {
            if (itemAct.activity_key !== row.activity_key) return itemAct;
            return {
              ...itemAct,
              [`${action}`]: value,
            };
          }),
        };
      }),
    };
  });

  const result = checkDataModule(checkDataSubModule(checkDataActivity(newData, row, action), row, action), action);
  return overrideRules(result, action);
};
