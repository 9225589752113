import { ApiUrlData, subModuleKey } from '@base-configs';
import { IndexPageWrapper, SelectPaginate, SinglePage, usePageProvider } from '@eigen3m/react-base-frontend';
import { OptionsYesOrNo } from '@helpers';
import {
  paymentsAttendanceColumn,
  statusesAttendanceOptions,
} from '@admin-app/pages/events/event-activity/presentation/helpers';
import { Form } from 'antd';
import { useState } from 'react';
import DrawerForm from './drawer-form';
import { ParticipantSummary } from './participant-summary';

export default function IndexTable(): JSX.Element {
  const { dataSource, id: eventId } = usePageProvider();
  const [visibleDrawerForm, setVisibleDrawerForm] = useState(false);
  const toggleDrawerForm = () => {
    setVisibleDrawerForm(!visibleDrawerForm);
  };

  return (
    <IndexPageWrapper
      showPageHeader={false}
      padding={[]}
      showPageHeaderSimple={true}
      drawerFilterProp={{
        width: 600,
        formBuilderProps: {
          columns: [
            {
              fields: [
                {
                  label: 'Day',
                  name: 'day',
                  gridColumn: { xl: 8 },
                  fieldType: 'selectPaginate',
                  selectPaginateProps: {
                    dataSourceUrl: ApiUrlData.eventData + `/${eventId}/schedules/day`,
                    classNamePrefix: 'react-select-custom-prefix-radius',
                    customLabel: (row) => `Day ${row?.no_day}`,
                    isClearable: true,
                  },
                },
                {
                  gridColumn: { xl: 8 },
                  renderField(form) {
                    const day = form.getFieldValue('day');
                    const dayId = day?.id;
                    const hasSession = day?.has_session;
                    return (
                      <Form.Item name="session" label="Session">
                        <SelectPaginate
                          classNamePrefix="react-select-custom-prefix-radius"
                          dataSourceUrl={ApiUrlData.eventData + `/${dayId}/schedules/session`}
                          customLabel={(row) => `Session ${row?.no_session}`}
                          filterRequest={{ event_id: eventId }}
                          isDisabled={!hasSession}
                          isClearable
                        />
                      </Form.Item>
                    );
                  },
                },
                {
                  gridColumn: { xl: 8 },
                  renderField(form) {
                    const session = form.getFieldValue('session');
                    const sessionId = session?.id;
                    const hasWorkshop = session?.has_workshop;
                    return (
                      <Form.Item name="workshop" label="Workshop">
                        <SelectPaginate
                          classNamePrefix="react-select-custom-prefix-radius"
                          dataSourceUrl={ApiUrlData.eventData + `/${sessionId}/schedules/workshop`}
                          customLabel={(row) => row?.name}
                          filterRequest={{ event_id: eventId }}
                          isDisabled={!hasWorkshop}
                          isClearable
                        />
                      </Form.Item>
                    );
                  },
                },
                {
                  label: 'Member',
                  name: 'member',
                  gridColumn: { xl: 8 },
                  fieldType: 'select',
                  selectProps: {
                    options: OptionsYesOrNo,
                    classNamePrefix: 'react-select-custom-prefix-radius',
                    customLabel: (value) => OptionsYesOrNo?.find((item) => item.value === value).label,
                  },
                },
                {
                  label: 'Check In',
                  name: 'check_in',
                  gridColumn: { xl: 8 },
                  fieldType: 'select',
                  selectProps: {
                    options: OptionsYesOrNo,
                    classNamePrefix: 'react-select-custom-prefix-radius',
                    customLabel: (value) => OptionsYesOrNo?.find((item) => item.value === value).label,
                  },
                },
                {
                  label: 'Statuses',
                  name: 'statuses',
                  gridColumn: { xl: 8 },
                  fieldType: 'select',
                  selectProps: {
                    isMulti: true,
                    options: statusesAttendanceOptions,
                    classNamePrefix: 'react-select-custom-prefix-radius',
                    customLabel: (value) => statusesAttendanceOptions?.find((item) => item.value === value).label,
                  },
                },
              ],
            },
          ],
        },
      }}
      pageToolbarProps={{
        showNewData: false,
        showImport: false,
        showPrint: false,
        showBack: false,
        showActivate: false,
        showDeactivate: false,
        showConfirmProcess: false,
      }}
      drawerImportProps={{
        customModuleKey: subModuleKey.event_activity.PARTICIPANT_ATTENDANCE,
      }}
    >
      <div className="no-print">
        <ParticipantSummary />
      </div>
      <SinglePage
        dataTableProps={{
          columns: paymentsAttendanceColumn,
          // defaultColumnIgnored: ['status'],
          rowActionProps: {
            actionIgnored: ['duplicate', 'delete', 'detail', 'confirm_process', 'activate', 'deactivate'],
          },
        }}
        drawerFormProps={{
          open: visibleDrawerForm,
          action: 'update',
          children: <DrawerForm />,
          onShow: toggleDrawerForm,
          destroyOnClose: true,
          onClose: toggleDrawerForm,
          forceRequest: true,
          showDataTypeSelector: false,
          handleAfterSave: async (payload, { onReloadTable }) => {
            await dataSource.handleConfirmProcess(payload, {
              onSuccess: () => {
                onReloadTable();
              },
              onFailed: () => {
                console.log('failed');
              },
            });
          },
        }}
        autoConfirmAfterAction={true}
      />
    </IndexPageWrapper>
  );
}
