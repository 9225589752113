import { ApiUrlData, WebUrlData } from '@base-configs';
import {
  authHelper,
  FormBuilder,
  FormPageWrapper,
  usePageProvider,
  PageToolbar,
  PageToolbarProps,
  makeCommonDataSource,
} from '@eigen3m/react-base-frontend';
import { Button, Drawer, Form, Input, notification, Popover, Space, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { mockCompanies } from '@states';
import { LoginOutlined } from '@ant-design/icons';
import { UserTransformer } from '../../domain/transformer';
import { Link, useNavigate } from 'react-router-dom';
import { UserForm } from 'shared/components';

export default function FormPage() {
  const navigate = useNavigate();
  const { isCreate, isUpdate, isDuplicate, navigation } = usePageProvider();
  const [loading, setLoading] = useState(false);
  const userDataSource = makeCommonDataSource({
    baseUrl: process.env.REACT_APP_BASE_SERVICE_URL,
    apiUrl: ApiUrlData.contact,
  });
  const [companies] = useRecoilState(mockCompanies);
  const [form] = Form.useForm();
  const title = isCreate ? 'New' : isUpdate ? 'Update' : isDuplicate ? 'Duplicate' : '';

  function handleAfterUpdate(account) {
    const existAccount = authHelper.getAccount();
    const accountId = account?.id;
    const existAccountId = existAccount?.id;
    if (account && accountId === existAccountId) authHelper.updateAccount(account);
    navigation.goToDetail({ id: account.id });
  }

  function onChangeForm(item, values) {
    console.log({ values });
    // logic for override form values
    // return undefined;
  }

  const initialValues = {
    is_leader: false,
    is_hcc: false,
    is_ihc: false,
    is_icc: false,
    is_speaker: false,
    prefix_phone: '+62',
    has_attend_ifgf: 'false',
    has_join_care_group: 'false',
    company: !!companies?.length ? companies[0] : null,
    relatives: [],
  };

  const toolbarProps: PageToolbarProps = {
    showBack: false,
    showReload: false,
    showSearch: false,
    showDataTypeSelector: false,
    showConfirmProcess: false,
    showApproval: false,
    showUpdate: false,
    showDuplicate: false,
    showDelete: false,
    showActivate: false,
    showDeactivate: false,
    showExport: false,
    showImport: false,
    showPrint: false,
    showFilter: false,
    showNewData: false,
    pageTitle: 'Register User',
  };

  const onReset = () => {
    form.resetFields();
    hide();
  };

  const [open, setOpen] = useState(false);

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  async function onFinish(payload?: any) {
    setLoading(true);
    if (payload) {
      const transformer = new UserTransformer();
      const data = transformer.transformerCreate(payload);
      await userDataSource.handleCustomRequest({
        paramRequest: {
          method: 'POST',
          url: process.env.REACT_APP_BASE_SERVICE_URL + ApiUrlData.contact,
          data: data,
        },
        onSuccess(params) {
          notification.success({
            message: 'Success',
            description: `User has been registered`,
          });
          navigate(WebUrlData.login);
        },
        onFailed(params: any) {
          notification.error({
            message: 'Failed',
            description: params,
          });
        },
      });
    }
    setLoading(false);
  }

  useEffect(() => {
    if (isCreate) form.setFieldsValue(initialValues);
  }, [isCreate]);

  return (
    <div>
      {/* <PageToolbar {...toolbarProps}></PageToolbar> */}
      <Drawer
        title="User Registration"
        closable={false}
        closeIcon={null}
        width={'100%'}
        visible={true}
        extra={
          <Space>
            <Link to={'/auth/login'}>
              <Button ghost icon={<LoginOutlined />} type="primary">
                Login
              </Button>
            </Link>
          </Space>
        }
        footer={
          <div style={{ display: 'flex' }}>
            <Popover
              content={
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <Button size="small" onClick={hide} style={{ marginRight: '10px' }}>
                    Cancel
                  </Button>
                  <Button size="small" danger ghost onClick={onReset}>
                    Yes
                  </Button>
                </div>
              }
              title="Are you sure?"
              trigger="click"
              open={open}
              onOpenChange={handleOpenChange}
            >
              <Button danger style={{ marginLeft: 'auto' }} onClick={() => handleOpenChange(true)}>
                Reset
              </Button>
            </Popover>
            <Button type="primary" style={{ marginLeft: 10 }} onClick={() => form.submit()}>
              Sign Up
            </Button>
          </div>
        }
      >
        <Spin spinning={loading}>
          <Form
            initialValues={initialValues}
            onValuesChange={onChangeForm}
            layout="vertical"
            form={form}
            onFinish={onFinish}
          >
            <Form.Item noStyle name="company"></Form.Item>
            <UserForm isCreate={isCreate} />
          </Form>
        </Spin>
      </Drawer>
    </div>
  );
}
