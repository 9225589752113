import React, { useEffect, useState } from 'react';
import { FormBuilder } from '@eigen3m/react-base-frontend';
import { Button, Checkbox, Drawer, Form } from 'antd';
import { DrawerScheduleFormProps } from './drawer-schedule.entity';
import { v4 as uuidV4 } from 'uuid';
import { capitalize } from 'lodash';
import { ApiUrlData } from '@base-configs';

const createTitle = (action: string, type): string => {
  const title = action ? capitalize(action) : 'Create';
  if (type === 'day') {
    return `${title} Day`;
  } else if (type === 'session') {
    return `${title} Session`;
  } else if (type === 'workshop') {
    return `${title} Workshop`;
  } else {
    return `${title} Data`;
  }
};

export function DrawerScheduleForm(props: DrawerScheduleFormProps) {
  const { type, action, data, onChangeDrawer, visible, onClose, days } = props;
  const [form] = Form.useForm();
  const [isCreateAnother, setIsCreateAnother] = useState(false);
  const drawerTitle = createTitle(action, type);

  //Set initial number
  let number = 0;
  const sessions = !!days?.length ? days?.find((item) => item?.key === data?.schedule?.day_key)?.sessions : [];
  const itemSession = !!sessions?.length ? sessions?.find((item) => item?.key === data?.schedule?.session_key) : [];
  const workshops = !!itemSession && !!itemSession?.workshops?.length ? itemSession?.workshops : [];

  const initialNoDay =
    action === 'create' ? (!!days?.length ? Math.max(...days?.map((item) => item?.no)) + 1 : 1) : data?.no;
  const initialNoSession =
    action === 'create' ? (!!sessions?.length ? Math.max(...sessions?.map((item) => item?.no)) + 1 : 1) : data?.no;
  const initialNoWorkshop =
    action === 'create' ? (!!workshops?.length ? Math.max(...workshops?.map((item) => item?.no)) + 1 : 1) : data?.no;

  if (type === 'day') number = initialNoDay;
  else if (type === 'session') number = initialNoSession;
  else if (type === 'workshop') number = initialNoWorkshop;

  useEffect(() => {
    if (data) form.setFieldsValue(data);
  }, [data, visible]);

  const onFinish = (values: any) => {
    const payload = {
      ...values,
      key: data?.key ?? data?.id ?? uuidV4(),
    };
    onChangeDrawer(action, type, payload);
    form.resetFields();
    if (isCreateAnother) form.setFieldsValue(data);
    if (!isCreateAnother) onClose(undefined);
  };

  async function onReset() {
    form.resetFields();
  }

  function FooterDrawer() {
    return (
      <div
        style={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <Checkbox
            checked={isCreateAnother}
            onChange={(e) => setIsCreateAnother(e.target.checked)}
            {...(action === 'update' ? { style: { display: 'none' } } : {})}
          >
            Create Another
          </Checkbox>
        </div>
        <div>
          <Button
            style={{ marginRight: '5px' }}
            onClick={() => {
              onReset();
            }}
          >
            Reset
          </Button>
          <Button type="primary" onClick={() => form.submit()}>
            Save
          </Button>
        </div>
      </div>
    );
  }
  return (
    <Drawer
      width={500}
      title={drawerTitle}
      visible={visible}
      onClose={onClose}
      footer={<FooterDrawer />}
      footerStyle={{ height: '50px' }}
    >
      <Form form={form} layout="vertical" onFinish={onFinish} initialValues={{ type }}>
        <FormBuilder
          columns={[
            {
              fields: [
                {
                  customHidden: () => {
                    return true;
                  },
                  renderField() {
                    return (
                      <>
                        <Form.Item name="key" noStyle />
                        <Form.Item name="id" noStyle />
                        <Form.Item name={['schedule', 'day_key']} noStyle />
                        <Form.Item name={['schedule', 'session_key']} noStyle />
                        <Form.Item name={['schedule', 'agenda_key']} noStyle />
                        <Form.Item name={['schedule', 'workshop_key']} noStyle />
                      </>
                    );
                  },
                },
                {
                  renderField() {
                    return <Form.Item label={capitalize(type)}>{number}</Form.Item>;
                  },
                  customHidden() {
                    return !!number ? false : true;
                  },
                },
                {
                  gridColumn: { xl: 6, lg: 6, md: 6, sm: 24 },
                  label: capitalize(type),
                  name: ['no'],
                  fieldType: 'inputNumber',
                  inputNumberProps: {
                    min: 1,
                  },
                  customHidden: () => {
                    return true;
                  },
                },
                {
                  gridColumn: { xl: 24, lg: 24, md: 24, sm: 24 },
                  label: 'Name',
                  name: ['name'],
                  fieldType: 'inputText',
                  rules: [
                    {
                      required: type === 'workshop' ? true : false,
                      message: 'Name is required.',
                    },
                  ],
                },
                {
                  gridColumn: { xl: 24, lg: 24, md: 24, sm: 24 },
                  label: 'Description',
                  name: ['description'],
                  fieldType: 'textArea',
                  // rules: [
                  //   {
                  //     required: true,
                  //     message: 'Description is required.',
                  //   },
                  // ],
                  textAreaProps: { rows: 2, placeholder: 'Input' },
                },
                {
                  gridColumn: { xl: 8, lg: 8, md: 8, sm: 24 },
                  label: 'Date',
                  name: ['date'],
                  fieldType: 'datePicker',
                  datePickerProps: {
                    style: { width: '100%' },
                  },
                  customHidden() {
                    return type === 'day' ? false : true;
                  },
                  rules: [{ required: type === 'day' ? true : false, message: 'Date is required.' }],
                },
                {
                  gridColumn: {
                    xl: type === 'day' ? 16 : 24,
                    lg: type === 'day' ? 16 : 24,
                    md: type === 'day' ? 16 : 24,
                    sm: 24,
                  },
                  label: 'Time',
                  name: ['time'],
                  fieldType: 'timeRangePicker',
                  rules: [{ required: ['day', 'session'].includes(type) ? true : false, message: 'Time is required.' }],
                  timeRangePickerProps: {
                    format: 'HH:mm',
                    style: { width: '100%' },
                  },
                  customHidden: () => {
                    return type === 'workshop' ? true : false;
                  },
                },
                {
                  gridColumn: { xl: 24, lg: 24, md: 24, sm: 24 },
                  label: 'Speakers',
                  name: ['speakers'],
                  fieldType: 'selectPaginate',
                  selectPaginateProps: {
                    isMulti: true,
                    classNamePrefix: 'react-select-custom-prefix-radius',
                    dataSourceUrl: ApiUrlData.speaker,
                    customFilterRequest: () => {
                      return {
                        is_speaker: true,
                        statuses: ['active'],
                      };
                    },
                    customLabel(row) {
                      return row?.name;
                    },
                    keySearch: 'name',
                  },
                },
              ],
            },
          ]}
        />
      </Form>
    </Drawer>
  );
}
