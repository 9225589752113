export const makeLabelValue = ({
  values,
  keyLabel,
  typeLabel,
}: {
  values: any;
  keyLabel: string;
  typeLabel: 'Day' | 'Session' | 'Workshop' | 'Agenda';
}): any[] => {
  if (!!values && Array.isArray(values) && !!values?.length) {
    const newValues = values?.map((item) => {
      return {
        // label: `${typeLabel}  ${item[keyLabel]}`,
        label: typeLabel + ' ' + item[keyLabel],
        value: item,
      };
    });
    return newValues;
  }
  return values;
};
