import { ApiUrlData } from '@base-configs';
import { CustomUploadImage } from '@components/custom-upload-image';
import { FormBuilder } from '@eigen3m/react-base-frontend';
import { Col, Form, Row } from 'antd';
import React from 'react';

type Props = {
  documentID: string;
};

export default function BankAccountsField(props: Props): JSX.Element {
  const { documentID } = props;
  return (
    <React.Fragment>
      <Row gutter={[32, 32]}>
        <Col
          span={32}
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <FormBuilder
            columns={[
              {
                fields: [
                  {
                    gridColumn: { span: 24 },
                    renderField() {
                      return (
                        <Form.Item name="document_url" label="QR Code" valuePropName="imageUrl">
                          <CustomUploadImage
                            showRemove
                            className="avatar-uploader"
                            errorSizeMessage="Maximum Size is 500kb!"
                            imageSize={0.7}
                            imageType={['image/jpeg', 'image/png']}
                            notes="Max size 500kb, Image type .JPG and .PNG"
                            requestFileKey={'document_url'}
                            requestUrl={ApiUrlData.account_upload}
                            transformPayloadRequest={(payload) => {
                              const formData = new FormData();
                              formData.append('document_url', payload);
                              formData.append('document_id', documentID);

                              return formData;
                            }}
                          />
                        </Form.Item>
                      );
                    },
                  },
                ],
              },
            ]}
          />
        </Col>
        <Col span={24}>
          <FormBuilder
            columns={[
              {
                fields: [
                  {
                    name: ['bank'],
                    label: 'Bank',
                    gridColumn: { span: 24 },
                    fieldType: 'selectPaginate',
                    rules: [{ required: true, message: 'Bank  is required ' }],

                    selectPaginateProps: {
                      keySearch: 'name',
                      classNamePrefix: 'react-select-custom-prefix-radius',
                      dataSourceUrl: ApiUrlData.banks,
                      customLabel(row) {
                        return row?.name;
                      },
                      customFilterRequest() {
                        return {
                          statuses: ['active'],
                        };
                      },
                    },
                  },
                  {
                    name: ['account_no'],
                    label: 'Account No',
                    gridColumn: { span: 24 },
                    rules: [{ required: true, message: 'Account no is required ' }],
                    fieldType: 'inputText',
                    inputTextProps: {
                      placeholder: 'Input Account No',
                    },
                  },
                  {
                    name: ['on_behalf_of'],
                    label: 'On Behalf of',
                    gridColumn: { span: 24 },
                    fieldType: 'inputText',
                    rules: [{ required: true, message: 'On behalf of is required ' }],

                    inputTextProps: {
                      placeholder: 'Input Name',
                    },
                  },
                ],
              },
            ]}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
}
