import { DetailBuilder } from '@eigen3m/react-base-frontend';

export default function DetailSetting(): JSX.Element {
  return (
    <DetailBuilder
      columns={[
        {
          fields: [
            {
              name: ['email_sender'],
              label: 'Email Sender',
              fieldType: 'text',
              gridColumn: { span: 6 },
              renderTextProps: {
                defaultRender() {
                  return '-';
                },
              },
            },
            {
              name: ['gmail_token'],
              label: 'Gmail Auth Token',
              fieldType: 'text',
              gridColumn: { span: 6 },
              renderTextProps: {
                defaultRender() {
                  return '-';
                },
              },
            },
          ],
        },
      ]}
    />
  );
}
