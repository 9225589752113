import { notificationFailed } from '@eigen3m/react-base-frontend';
import { FormInstance } from 'antd';
import { STEPKEY } from './enum';

interface StepContent {
  key: string;
  title: string;
  content?: React.ReactNode;
  description?: string;
}

export type Steps = StepContent[];

enum StepKey {
  information = 0,
  participant = 1,
  schedule = 2,
  registration = 3,
  paymentPricing = 4,
  attendance = 5,
}

export const stepContent: Steps = [
  {
    key: STEPKEY.information,
    title: 'Information',
    description: 'Information Configuration',
  },
  {
    key: STEPKEY.participants,
    title: 'Participants',
    description: 'Participants Configuration',
  },
  {
    key: STEPKEY.schedules,
    title: 'Schedules',
    description: 'Schedules Configuration',
  },
  {
    key: STEPKEY.registrations,
    title: 'Registration',
    description: 'Registration Configuration',
  },
  {
    key: STEPKEY.paymentPricings,
    title: 'Payment & Pricings',
    description: 'Payment Configuration',
  },
  {
    key: STEPKEY.attendanceOfficers,
    title: 'Attendance',
    description: 'Attendance Configuration',
  },
];

export async function validationFormByStepper({ step, form }: { step: number; form: FormInstance }): Promise<boolean> {
  let values = null;
  let isValid = true;
  try {
    if (step === StepKey.information) {
      values = await form.validateFields([
        ['information', 'department'],
        ['information', 'department_class'],
        ['information', 'event_category'],
        ['information', 'main_banner'],
        ['information', 'name'],
        ['information', 'banner_url'],
        ['information', 'event_type'],
        ['information', 'venue'],
        ['information', 'description'],
        ['information', 'term_and_conditions'],
      ]);
    } else if (step === StepKey.participant) {
      values = await form.validateFields([
        ['participants', 'description'],
        ['participants', 'participant_type'],
        ['participants', 'departments'],
        ['participants', 'ministries'],
        ['participants', 'age_min'],
        ['participants', 'age_max'],
        ['participants', 'gender'],
        ['participants', 'marital_status'],
      ]);
    } else if (step === StepKey.schedule) {
      values = await form.validateFields([
        ['schedules', 'description'],
        ['schedules', 'days'],
      ]);
      isValid = !!values && values?.schedules?.days?.length >= 1 ? true : false;
      if (!isValid) notificationFailed(['Please fill in the schedule data, the event must have at least one schedule']);
    } else if (step === StepKey.registration) {
      values = await form.validateFields([
        ['registrations', 'registration_config'],
        ['registrations', 'only_admin'],
        ['registrations', 'description'],
        ['registrations', 'registration_information', 'registration_open'],
        ['registrations', 'registration_information', 'registration_close'],
        ['registrations', 'registration_information', 'max_registrants'],
        ['registrations', 'registration_information', 'event_link'],
        ['registrations', 'registration_information', 'event_code'],
        ['registrations', 'registration_information', 'event_password'],
      ]);
    } else if (step === StepKey.paymentPricing) {
      values = await form.validateFields([
        ['payment_pricings', 'price_configuration'],
        ['payment_pricings', 'pricing_sub_required'],
        ['payment_pricings', 'payment_information'],
        ['payment_pricings', 'pricing_sub_required'],
        ['payment_pricings', 'payment_pricing_datas'],
        ['payment_pricings', 'payment_pricing_subs'],
      ]);
      if (
        ['true'].includes(values?.payment_pricings?.pricing_sub_required) &&
        values?.payment_pricings?.payment_pricing_datas?.length < 1 &&
        values?.payment_pricings?.payment_pricing_subs?.length < 1
      ) {
        isValid = false;
        notificationFailed(['Payment & Pricing must have at least one main pricing']);
        notificationFailed(['Payment & Pricing must have at least one sub pricing, cause need  sub pricing is Yes']);
      } else if (values?.payment_pricings?.payment_pricing_datas?.length < 1) {
        isValid = false;
        notificationFailed(['Payment & Pricing must have at least one main pricing']);
      } else if (
        ['true'].includes(values?.payment_pricings?.pricing_sub_required) &&
        values?.payment_pricings?.payment_pricing_subs?.length < 1
      ) {
        isValid = false;
        notificationFailed(['Payment & Pricing must have at least one sub pricing, cause need  sub pricing is Yes']);
      }
    }
    return !!values && isValid ? true : false;
  } catch (error) {
    return false;
  }
}
