import { PageProvider } from '@eigen3m/react-base-frontend';
import { InitialValuePageProviderEntity } from '@eigen3m/react-base-frontend';

import PageIndex from '../pages';

import { CategoriEntity } from '../../domain/entities';
import { ApiUrlData, WebUrlData } from '@base-configs';
import { useParams } from 'react-router-dom';

export default function FactoryPage() {
  const InitialValue: InitialValuePageProviderEntity<CategoriEntity> = {
    moduleKey: 'data-categori',
    webUrl: WebUrlData.category,
    apiUrl: ApiUrlData.speaker,
  };

  const { slug } = useParams();
  return <PageProvider initialValue={InitialValue}>{!!slug && <PageIndex />}</PageProvider>;
}
