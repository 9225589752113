import { InvoiceDetail } from '@components/invoice-detail';
import { Col, Form, Row } from 'antd';
import React from 'react';
import PaymentDescription from './description';
import FormField from './form-field';

export function DrawerForm(): JSX.Element {
  return (
    <React.Fragment>
      <Form.Item name={'paymentId'} shouldUpdate noStyle></Form.Item>
      <Form.Item name={'code'} shouldUpdate noStyle></Form.Item>
      <Form.Item name={'payment_date'} shouldUpdate noStyle></Form.Item>
      <Form.Item name={'total_invoice'} shouldUpdate noStyle></Form.Item>
      <Form.Item name={'is_free'} shouldUpdate noStyle></Form.Item>
      <Form.Item name={'need_confirm'} shouldUpdate noStyle></Form.Item>
      <Form.Item name={'total_payment'} shouldUpdate noStyle></Form.Item>
      <Form.Item name={'payment_type'} shouldUpdate noStyle></Form.Item>
      <Form.Item name={'payer_name'} shouldUpdate noStyle></Form.Item>
      <Form.Item name={'no_payer_account'} shouldUpdate noStyle></Form.Item>
      <Form.Item name={'destination_bank'} shouldUpdate noStyle></Form.Item>
      <Form.Item name={'bank_account'} shouldUpdate noStyle></Form.Item>
      <Form.Item name={'image_url'} shouldUpdate noStyle></Form.Item>
      <Form.Item name={'registrans'} shouldUpdate noStyle></Form.Item>
      <Form.Item name={'event_id'} shouldUpdate noStyle></Form.Item>
      <Form.Item name={'status'} shouldUpdate noStyle></Form.Item>

      <Row gutter={[16, 4]}>
        <Col span={24}>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldsValue }) => {
              const data = getFieldsValue();
              return <InvoiceDetail data={data} type="form" />;
            }}
          </Form.Item>
        </Col>
        {/* <Col span={10}>
          <PaymentDescription />
        </Col>
        <Col span={14}>
          <FormField />
        </Col> */}
      </Row>
    </React.Fragment>
  );
}
