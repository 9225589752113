import React from 'react';
import { DetailBuilder } from '@eigen3m/react-base-frontend';

export default function EventCategoryDetail() {
  return (
    <React.Fragment>
      <DetailBuilder
        columns={[
          {
            fields: [
              {
                label: 'Event Category Name',
                gridColumn: { span: 24 },
                name: ['detail_data', 'name'],
                fieldType: 'text',
                renderTextProps: {
                  defaultRender: () => '-',
                },
              },
              {
                label: 'Show to Home Page Menu',
                gridColumn: { span: 24 },
                name: ['detail_data', 'show_to_home'],
                fieldType: 'text',
                renderTextProps: {
                  renderValue(value) {
                    return value ? 'Yes' : 'No';
                  },
                  defaultRender: () => '-',
                },
              },
            ],
          },
        ]}
      />
    </React.Fragment>
  );
}
