import { RenderAddress } from '@eigen3m/react-base-frontend';
import { TableColumnsType } from 'antd';

export const columnsVenue: TableColumnsType<any> = [
  {
    title: 'Name',
    key: 'name',
    width: 200,
    dataIndex: 'name',
    ellipsis: true,
    sorter: true,
  },
  {
    title: 'Address',
    key: 'address',
    width: 250,
    dataIndex: 'address',
    sorter: false,
    ellipsis: true,
    render(value) {
      return RenderAddress({ value: value?.address });
    },
  },
  {
    title: 'Description',
    key: 'address_description',
    width: 200,
    dataIndex: 'address_description',
    ellipsis: true,
    sorter: true,
    render: (value) => value ?? '-',
  },
];
