import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IndexPageWrapper, notificationFailed, usePageProvider } from '@eigen3m/react-base-frontend';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import Iframe from 'react-iframe';
const iconSpinning = <LoadingOutlined style={{ fontSize: 40 }} spin />;

export default function IndexPage() {
  const { dataSource } = usePageProvider();
  const { token } = useParams();
  const [invoiceURL, setInvoiceURL] = useState(null);
  const [loading, setLoading] = useState(false);

  async function getPaymentInformation(token) {
    setLoading(true);
    try {
      await dataSource.handleGetData(token, {
        async onSuccess({ response }: any) {
          const newInvoiceURL =
            response?.invoice_url ??
            'http://51.79.242.222:8014/bills/invoice/tkgDcjTo6oIvRyTsMpYk?accessToken=eivEGcxP8hfcXSWdewMKzmRSWoRlyv26Z2sPkOM6TTnf6jXcFt';
          setInvoiceURL(newInvoiceURL);
        },
        async onFailed({ message }) {
          notificationFailed([message as string]);
        },
      });
    } catch (error) {
      notificationFailed([error as string]);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (token) {
      const decodeToken = decodeURIComponent(token);
      getPaymentInformation(decodeToken);
      document.title = 'Invoice Payment - IFGF Semarang';
    }
  }, [token]);

  return (
    <IndexPageWrapper
      padding={[]}
      showPageHeader={false}
      pageToolbarProps={{
        showBack: false,
        showNewData: false,
        showSearch: false,
        showFilter: false,
      }}
    >
      <div style={{ minHeight: '300px', overflow: 'hidden', width: '100%', textAlign: 'center' }}>
        <Spin spinning={loading} indicator={iconSpinning}>
          <div style={{ height: '100%', width: '100%', overflow: 'hidden', margin: '0 0 -10px' }}>
            <Iframe url={invoiceURL} height="1200px" width="100%" frameBorder={0} />
            {/* <iframe src={invoiceURL} height="1200px" width="100%" frameBorder={0} /> */}
          </div>
        </Spin>
      </div>
    </IndexPageWrapper>
  );
}
