import { chain, uniqBy } from 'lodash';

export enum DataTabKey {
  information = 'information',
  schedules = 'schedules',
  participants = 'participants',
  registrations = 'registrations',
  paymentPricings = 'payment_pricings',
  attendanceOfficers = 'attendance_officers',
}

export const checkHasWorkshop = ({ days }: { days: any[] }): boolean => {
  let hasWorkshop = false;
  let shouldSkip = false;
  if (!!days?.length) {
    days?.forEach((day) => {
      const sessions = !!day?.sessions?.length ? day?.sessions : [];
      sessions?.forEach((session) => {
        const workshops = !!session?.agendas?.length ? session?.agendas[0]?.workshops : [];
        if (shouldSkip) {
          return true;
        }
        if (!!workshops?.length && workshops?.length > 1) {
          hasWorkshop = true;
          shouldSkip = true;
        }
      });
    });
  }
  return hasWorkshop;
};

function makeGroupingSchedule(data) {
  return chain(data)
    .groupBy('no')
    .map((sessions = [], no) => {
      const newSession = [];
      sessions.forEach((e) => {
        newSession.push(...e.sessions);
      });
      return {
        no: parseInt(no),
        sessions: chain(newSession)
          .groupBy('no')
          .map((workshops = [], no) => {
            const newWorkshops = [];
            workshops.forEach((e) => {
              newWorkshops.push(...e.workshops);
            });
            const uniqueWorkshops = uniqBy(newWorkshops, 'name');
            return { no: parseInt(no), workshops: uniqueWorkshops };
          })
          .value(),
      };
    })
    .value();
}

export function getSchedulePricing(pricings: any[]): any[] {
  if (pricings?.length >= 1) {
    let schedules = [];
    pricings?.map((itemOfficer) => {
      const daySessionWorkshops = itemOfficer?.day_session_workshops ?? [];
      daySessionWorkshops?.map((itemSchedule) => {
        const day = itemSchedule?.day;
        const session = itemSchedule?.session;
        const workshops = itemSchedule?.workshop ?? [];
        schedules = [
          ...schedules,
          {
            no: day?.no,
            sessions: [
              {
                no: session?.no,
                workshops,
              },
            ],
          },
        ];
      });
    });
    return makeGroupingSchedule(schedules);
  } else {
    return [];
  }
}
