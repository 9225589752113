import { useMediaQuery } from '@eigen3m/react-base-frontend';
import React from 'react';

interface AddressProps {
  address?: string;
  latitude?: number;
  longitude?: number;
}

interface Props {
  value?: AddressProps;
  addressDescription?: string;
  width?: number | string;
  height?: number | string;
}

export function Maps(props: Props) {
  const { value, width = 400, height = 225, addressDescription } = props;
  const address = value?.address ?? '-';
  const isMobile = useMediaQuery({ media: 'mobile' });

  const mapsUrl = ({ latitude, longitude }: { latitude: number; longitude: number }) => {
    const lat = latitude ?? -6.9477683949803675;
    const lng = longitude ?? 107.60652816536386;
    return `https://maps.google.com/maps?q=${lat},${lng}&iwloc=&output=embed`;
  };

  return (
    <React.Fragment>
      {address && (
        <>
          <h4 style={{ color: '#777' }}>Address</h4>
          <div style={{ display: 'block', marginBottom: '20px' }}>{address}</div>
        </>
      )}
      {addressDescription && (
        <>
          <h4 style={{ color: '#777' }}>Description</h4>
          <div style={{ display: 'block', marginBottom: '20px' }}>{addressDescription}</div>
        </>
      )}
      <iframe
        style={{ marginBottom: '20px' }}
        width={!isMobile ? width : '100%'}
        height={height}
        src={mapsUrl({
          latitude: value?.latitude ?? null,
          longitude: value?.longitude ?? null,
        })}
        frameBorder="0"
        scrolling="no"
      />
    </React.Fragment>
  );
}
