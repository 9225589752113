import React from 'react';
import { Form } from 'antd';
import { DetailBuilder } from '@eigen3m/react-base-frontend';
import { Maps } from '@components/maps';

export default function VenueDetail() {
  return (
    <React.Fragment>
      <DetailBuilder
        columns={[
          {
            fields: [
              {
                gridColumn: { span: 24 },
                label: 'Name',
                name: ['detail_data', 'name'],
                fieldType: 'text',
                renderTextProps: {
                  defaultRender: () => '-',
                },
              },
              {
                gridColumn: { span: 24 },
                renderField(form) {
                  const address = form.getFieldValue(['detail_data', 'address']);
                  return <Maps value={address} width={450} />;
                },
              },
              {
                gridColumn: { span: 24 },
                label: 'Description',
                name: ['detail_data', 'address_description'],
                fieldType: 'text',
                renderTextProps: {
                  defaultRender: () => '-',
                },
              },
            ],
          },
        ]}
      />
    </React.Fragment>
  );
}
