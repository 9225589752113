import React, { useState } from 'react';
import { Card, Form, Tabs } from 'antd';
import { DetailEventInformation } from './detail-information';
import { DetailParticipants } from './detail-participants';
import { DetailPaymentAndPricing } from './detail-payment-and-pricing';
import { DetailSchedules } from './detail-schedules';
import { DetailAttendanceOfficers } from './detail-attendance-officers';
import { DataTabKey } from '../../../helpers';
import { DetailRegistrations } from './detail-registrations';
const { TabPane } = Tabs;

export function DetailTabs() {
  const [activeKey, setActiveKey] = useState('1');
  const onChange = (key: string) => {
    if (key) setActiveKey(key);
  };

  return (
    <React.Fragment>
      <Card>
        <Tabs activeKey={activeKey} onChange={onChange}>
          <TabPane tab="Event Information" key="1" forceRender>
            <Form.Item shouldUpdate noStyle>
              {(form) => {
                const dataInformation = form.getFieldValue(['detail_data', 'information']);
                return (
                  <Form.Item name={DataTabKey.registrations} noStyle>
                    <DetailEventInformation subKey={['detail_data', DataTabKey.information]} />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </TabPane>

          <TabPane tab="Participants" key="2" forceRender>
            <Form.Item name={DataTabKey.participants} noStyle>
              <DetailParticipants />
            </Form.Item>
          </TabPane>

          <TabPane tab="Schedules" key="3" forceRender>
            <Form.Item name={DataTabKey.schedules} noStyle>
              <DetailSchedules />
            </Form.Item>
          </TabPane>

          <TabPane tab="Registration" key="4" forceRender>
            <Form.Item name={DataTabKey.registrations} noStyle>
              <DetailRegistrations />
            </Form.Item>
          </TabPane>

          <TabPane tab="Payment & Pricing" key="5" forceRender>
            <Form.Item shouldUpdate noStyle>
              {(form) => {
                const dataSchedule = form.getFieldValue('schedules');
                return (
                  <Form.Item name={DataTabKey.attendanceOfficers} noStyle>
                    <DetailPaymentAndPricing dataSchedule={dataSchedule} />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </TabPane>

          <TabPane tab="Attendance Officers" key="6" forceRender>
            <Form.Item shouldUpdate noStyle>
              {(form) => {
                const dataSchedule = form.getFieldValue('schedules');
                return (
                  <Form.Item name={DataTabKey.attendanceOfficers} noStyle>
                    <DetailAttendanceOfficers dataSchedule={dataSchedule} />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </TabPane>
        </Tabs>
      </Card>
    </React.Fragment>
  );
}
