import React, { useState } from 'react';
import { FormBuilder } from '@eigen3m/react-base-frontend';
import { Form } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { ApiUrlData } from '@base-configs';
import { CustomUploadImage } from '@components/custom-upload-image';

export function SpeakerForm() {
  const [documentId, setDocumentId] = useState(uuidv4());
  const [imageUrl, setImageUrl] = useState(null);
  return (
    <FormBuilder
      columns={[
        {
          fields: [
            {
              gridColumn: { span: 24 },
              customHidden: () => true,
              renderField(form) {
                const currentDocumentId = form.getFieldValue('document_id');
                if (currentDocumentId) setDocumentId(currentDocumentId);
                return <Form.Item name="document_id" noStyle />;
              },
            },
            {
              gridColumn: { span: 24 },
              customHidden: () => true,
              renderField() {
                return <Form.Item name="company" noStyle />;
              },
            },
            {
              label: 'Name',
              gridColumn: { span: 24 },
              name: ['name'],
              fieldType: 'inputText',
              rules: [{ required: true, message: 'Name is required!' }],
              inputTextProps: {
                placeholder: 'Input Name',
              },
            },
            {
              label: 'Description',
              gridColumn: { span: 24 },
              name: ['description'],
              fieldType: 'textArea',
              rules: [{ required: true, message: 'Description is required!' }],
              textAreaProps: {
                placeholder: 'Input Description',
                rows: 3,
              },
            },
            // {
            //   label: 'Photo',
            //   gridColumn: { span: 24 },
            //   name: ['document_url'],
            //   rules: [
            //     ({ getFieldValue, setFieldValue }) => ({
            //       validator(_, value) {
            //         const currentDocumentId = getFieldValue('document_id');
            //         const newDocumentId = currentDocumentId ?? documentId;
            //         if (value && newDocumentId) {
            //           setFieldValue('document_id', newDocumentId);
            //           const assetUrl = process.env.REACT_APP_BASE_ASSET_URL;
            //           const imageUrl = assetUrl && value ? assetUrl + value : null;
            //           setImageUrl(imageUrl);
            //         } else {
            //           setImageUrl(null);
            //         }
            //         return Promise.resolve();
            //       },
            //     }),
            //   ],
            //   fieldType: 'uploadImage',
            //   valuePropName: 'imageUrl',
            //   uploadImageProps: {
            //     notes: 'Max size 700kb, Image type .jpg and .png',
            //     requestUrl: ApiUrlData.speakerUpload,
            //     imageSize: 0.7,
            //     errorSizeMessage: 'Maximum size is 700kB!',
            //     requestFileKey: 'document_url',
            //     imageType: ['image/jpeg', 'image/png'],
            //     transformPayloadRequest(payload) {
            //       const formData = new FormData();
            //       formData.append('document_url', payload);
            //       formData.append('document_id', documentId);
            //       formData.append('data', JSON.stringify({ id: 2, name: 2 }));
            //       return formData;
            //     },
            //     imageUrl: imageUrl,
            //   },
            // },

            {
              gridColumn: { span: 24 },
              renderField(form) {
                return (
                  <Form.Item
                    label="Photo"
                    name={['document_url']}
                    valuePropName="imageUrl"
                    rules={[
                      ({ getFieldValue, setFieldValue }) => ({
                        validator(_, value) {
                          const currentDocumentId = getFieldValue('document_id');
                          const newDocumentId = currentDocumentId ?? documentId;
                          if (value && newDocumentId) {
                            setFieldValue('document_id', newDocumentId);
                            const assetUrl = process.env.REACT_APP_BASE_ASSET_URL;
                            const imageUrl = assetUrl && value ? assetUrl + value : null;
                            setImageUrl(imageUrl);
                          } else {
                            setImageUrl(null);
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <CustomUploadImage
                      showRemove
                      notes={'Max size 700kb, Image type .jpg and .png'}
                      requestUrl={ApiUrlData.speakerUpload}
                      imageSize={0.7}
                      errorSizeMessage={'Maximum size is 700kB!'}
                      requestFileKey={'document_url'}
                      imageType={['image/jpeg', 'image/png']}
                      imageUrl={imageUrl}
                      transformPayloadRequest={(payload) => {
                        const formData = new FormData();
                        formData.append('document_url', payload);
                        formData.append('document_id', documentId);
                        formData.append('data', JSON.stringify({ id: 2, name: 2 }));
                        return formData;
                      }}
                    />
                  </Form.Item>
                );
              },
            },
          ],
        },
      ]}
    />
  );
}
