import React from 'react';
import { Button, Dropdown, Menu, Popconfirm, Space, Tooltip } from 'antd';
import { PlusOutlined, FormOutlined, DeleteOutlined, EyeOutlined, MoreOutlined } from '@ant-design/icons';
import { ButtonActionProps } from './button-action.entity';
import { useMediaQuery } from '@eigen3m/react-base-frontend';

export function ButtonAction(props: ButtonActionProps) {
  const {
    row,
    key,
    handleClickCreate,
    handleClickUpdate,
    handleClickDetail,
    handleClickDelete,
    actionIgnored = [],
    actionDisabled = [],
    additionalActions,
    actionTitle,
  } = props;

  const ActionMenuDesktop = ({ row }: { row: any }) => {
    return (
      <Space>
        {!actionIgnored.includes('create') && (
          <Tooltip placement="bottom" title={actionTitle?.create ?? 'Create'}>
            <Button
              type="default"
              size="small"
              icon={<PlusOutlined />}
              onClick={() => {
                if (handleClickCreate) handleClickCreate(row);
              }}
            />
          </Tooltip>
        )}
        {!actionIgnored.includes('update') && (
          <Tooltip placement="bottom" title={actionTitle?.update ?? 'Update'}>
            <Button
              type="default"
              size="small"
              icon={<FormOutlined />}
              onClick={() => {
                if (handleClickUpdate) handleClickUpdate(row);
              }}
            />
          </Tooltip>
        )}
        {!actionIgnored.includes('detail') && (
          <Tooltip placement="bottom" title={actionTitle?.detail ?? 'Detail'}>
            <Button
              type="default"
              size="small"
              icon={<EyeOutlined />}
              onClick={() => {
                if (handleClickDetail) handleClickDetail(row);
              }}
            />
          </Tooltip>
        )}
        {!actionIgnored.includes('delete') && (
          <Tooltip placement="bottom" title={actionTitle?.delete ?? 'Delete'}>
            <Popconfirm
              title="Sure to delete?"
              placement="right"
              onConfirm={() => {
                if (handleClickDelete) handleClickDelete(row);
              }}
            >
              <Button type="default" size="small" icon={<DeleteOutlined />} />
            </Popconfirm>
          </Tooltip>
        )}
      </Space>
    );
  };

  const ActionMenuMobile = ({ row }: { row: any }) => {
    return (
      <Menu
        style={{ minWidth: '120px' }}
        items={[
          {
            label: actionTitle?.create ?? 'Create',
            key: 'create',
            icon: <PlusOutlined />,
            onClick(e) {
              e.domEvent.stopPropagation();
              if (handleClickCreate) handleClickCreate(row);
            },
          },
          {
            label: actionTitle?.update ?? 'Update',
            key: 'update',
            icon: <FormOutlined />,
            onClick(e) {
              e.domEvent.stopPropagation();
              if (handleClickUpdate) handleClickUpdate(row);
            },
          },
          {
            label: actionTitle?.detail ?? 'Detail',
            key: 'detail',
            icon: <EyeOutlined />,
            onClick(e) {
              e.domEvent.stopPropagation();
              if (handleClickDetail) handleClickDetail(row);
            },
          },
          {
            label: actionTitle?.delete ?? 'Delete',
            key: 'delete',
            icon: <DeleteOutlined />,
            onClick(e) {
              e.domEvent.stopPropagation();
              if (handleClickDelete) handleClickDelete(row);
            },
          },
          ...(additionalActions ? additionalActions(row) : []),
        ]
          .filter((item: any) => !actionIgnored.includes(item.key.toLowerCase()))
          .map((item: any) => {
            if (!actionDisabled.includes(item.key.toLowerCase())) return item;
            return {
              ...item,
              disabled: true,
            };
          })}
      />
    );
  };
  return (
    <React.Fragment>
      <Dropdown overlay={<ActionMenuMobile row={row} />} placement="bottom" key={key}>
        <Button type="default" size="small" icon={<MoreOutlined />} onClick={(e) => e.stopPropagation()} />
      </Dropdown>
    </React.Fragment>
  );
}
