import { useEffect, useState } from 'react';
import { Button, Card, Form, Modal, Spin, Tooltip } from 'antd';
import { useRecoilState } from 'recoil';
import {
  AntdIconWrapper,
  authHelper,
  DetailPageWrapper,
  notificationFailed,
  notificationSuccess,
  usePageProvider,
} from '@eigen3m/react-base-frontend';
import { BiEdit, BiCheck, BiX } from 'react-icons/bi';
import { mockCompanies } from '@states';
import * as _ from 'lodash';

import DetailSetting from '../components/detail-setting';
import FormSetting from '../components/form-setting';
import { useAccessRight } from 'shared/hooks';
import { AiOutlineWarning } from 'react-icons/ai';

export default function IndexPage() {
  const [companies] = useRecoilState(mockCompanies);
  const [formType, setFormType] = useState<string>('detail');
  const [loading, setLoading] = useState<boolean>(false);

  const [formIntense] = Form.useForm();
  const [detailIntense] = Form.useForm();

  const { dataSource, moduleKey } = usePageProvider();

  const { accessView, accessEdit } = useAccessRight({ moduleKey });

  function handleOnEdit(): void {
    setFormType('update');
  }
  async function handleOnSave(): Promise<void> {
    const id = formIntense.getFieldValue('id');

    try {
      const payload = await formIntense.validateFields();
      const newPayload = _.omit(payload, ['id']);
      setFormType('detail');

      await dataSource.handleUpdate(id, newPayload, {
        onSuccess: ({ response }: any): void => {
          dataSource.handleConfirmProcess(response, {
            onSuccess: ({ response }: any): void => {
              detailIntense.setFieldsValue(response);
              formIntense.setFieldsValue(response);
              notificationSuccess(['Succesfully Updated data']);
              setFormType('detail');
            },
            onFailed: ({ message }: any): void => {
              console.error(message);
            },
          });
          // handleConfirm(response);
        },
        onFailed: ({ message }: any): void => {
          notificationFailed([`Error when Update data: ${message}`]);
        },
      });
    } catch (error) {
      console.error(error);
    }
  }

  function handleOnCancel(): void {
    handleGetDataIndex();
    setFormType('detail');
  }

  function onSave() {
    Modal.confirm({
      mask: true,
      okText: 'Confirm',
      title: 'Save Confirmation',
      content: 'Are you sure you want to save data ?',
      onOk: () => handleOnSave(),
    });
  }

  async function handleGetDataIndex(): Promise<void> {
    setLoading(true);
    await dataSource.handleGetIndex({
      onSuccess: ({ response }: any) => {
        const items = response.items;
        const dataConfig = items[0];

        detailIntense.setFieldsValue(dataConfig);
        formIntense.setFieldsValue(dataConfig);
        setLoading(false);
      },
      onFailed: ({ message }: any) => {
        console.error(message);
        setLoading(false);
      },
    });
  }

  useEffect(() => {
    handleGetDataIndex();
  }, []);

  function ButtonStyles(margin = 12) {
    return {
      marginLeft: margin,
      marginRight: margin,
      fontSize: 12,
    };
  }

  return (
    <DetailPageWrapper
      pageToolbarProps={{
        pageTitle: 'Email Setting',
        showSearch: false,
        showFilter: false,
        showNewData: false,
        showUpdate: false,
        showDuplicate: false,
        showDelete: false,
        showTagStatus: false,
        showBack: false,
        buttonRightContent: (
          <>
            <div className="separator-toolbar-content"></div>
            {formType === 'detail' && (
              <Tooltip placement="bottom" title="Edit">
                <Button
                  type="text"
                  shape="circle"
                  style={ButtonStyles(30)}
                  onClick={handleOnEdit}
                  disabled={!accessEdit}
                  icon={
                    <AntdIconWrapper>
                      <BiEdit />
                    </AntdIconWrapper>
                  }
                >
                  Edit
                </Button>
              </Tooltip>
            )}

            {formType === 'update' && (
              <>
                <Tooltip placement="bottom" title="Cancel">
                  <Button
                    type="text"
                    shape="circle"
                    style={ButtonStyles()}
                    onClick={handleOnCancel}
                    disabled={!accessEdit}
                    icon={
                      <AntdIconWrapper>
                        <BiX />
                      </AntdIconWrapper>
                    }
                  >
                    Cancel
                  </Button>
                </Tooltip>
                <div className="separator-toolbar-content"></div>
                <Tooltip placement="bottom" title="Save">
                  <Button
                    type="text"
                    shape="circle"
                    style={ButtonStyles(30)}
                    onClick={onSave}
                    disabled={!accessEdit}
                    icon={
                      <AntdIconWrapper>
                        <BiCheck />
                      </AntdIconWrapper>
                    }
                  >
                    Save
                  </Button>
                </Tooltip>
                <div className="separator-toolbar-content"></div>
              </>
            )}
          </>
        ),
      }}
    >
      <Card loading={loading}>
        <Form
          form={formIntense}
          layout="vertical"
          initialValues={{
            company: companies[0],
            with_child_region: false,
          }}
          onFinish={onSave}
        >
          <Form.Item noStyle name="company" />
          <Form.Item noStyle name="id" />
          <Form.Item noStyle name="with_child_region" />

          {formType === 'update' ? (
            <Spin
              style={{ backdropFilter: 'blur(5px)' }}
              spinning={!accessEdit}
              indicator={null}
              tip={
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <AiOutlineWarning style={{ marginRight: '10px', fontSize: '40px' }} />
                  Access Edit Blocked
                </div>
              }
            >
              <FormSetting />
            </Spin>
          ) : (
            <Spin
              style={{ backdropFilter: 'blur(5px)' }}
              spinning={!accessView}
              indicator={null}
              tip={
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <AiOutlineWarning style={{ marginRight: '10px', fontSize: '40px' }} />
                  Access View Blocked
                </div>
              }
            >
              <DetailSetting />
            </Spin>
          )}
        </Form>
      </Card>
    </DetailPageWrapper>
  );
}
