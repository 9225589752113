export enum registrationSchedule {
  day_1_session_1 = 'Day 1 Session 1',
  day_1_session_2 = 'Day 1 Session 2',
  day_2_session_1 = 'Day 2 Session 1',
  day_2_session_2 = 'Day 2 Session 2',
}

export enum booleanSelect {
  yes = 'true',
  no = 'false',
}

export enum statuses {
  active = 'active',
  cancel = 'cancel',
  checked_in = 'checked in',
  closed = 'closed',
  declined = 'declined',
  deleted = 'deleted',
  done = 'done',
  draft = 'draft',
  in_process = 'in process',
  in_progress = 'in progress',
  inactive = 'inactive',
  not_come = 'not come',
  open = 'open',
  partial = 'partial',
  pending = 'pending',
  requested = 'requested',
  waiting = 'waiting',
}

export const registrationScheduleOptions = [
  { label: 'Day 1 Session 1', value: registrationSchedule.day_1_session_1 },
  { label: 'Day 1 Session 2', value: registrationSchedule.day_1_session_2 },
  { label: 'Day 2 Session 1', value: registrationSchedule.day_2_session_1 },
  { label: 'Day 2 Session 2', value: registrationSchedule.day_2_session_2 },
];

export const booleanSelectOptions = [
  { label: 'Yes', value: booleanSelect.yes },
  { label: 'No', value: booleanSelect.no },
];

export const statusesOptions = [
  { label: 'Active', value: statuses.active },
  { label: 'Cancel', value: statuses.cancel },
  { label: 'Checked In', value: statuses.checked_in },
  { label: 'Closed', value: statuses.closed },
  { label: 'Declined', value: statuses.declined },
  { label: 'Deleted', value: statuses.deleted },
  { label: 'Done', value: statuses.done },
  { label: 'Draft', value: statuses.draft },
  { label: 'In Process', value: statuses.in_process },
  { label: 'In Progress', value: statuses.in_progress },
  { label: 'Inactive', value: statuses.inactive },
  { label: 'Not Come', value: statuses.not_come },
  { label: 'Open', value: statuses.open },
  { label: 'Partial', value: statuses.partial },
  { label: 'Pending', value: statuses.pending },
  { label: 'Requested', value: statuses.requested },
  { label: 'Waiting', value: statuses.waiting },
];

export const statusesParticipantOptions = [
  { label: 'Active', value: statuses.active },
  { label: 'Cancel', value: statuses.cancel },
  { label: 'Closed', value: statuses.closed },
  { label: 'Done', value: statuses.done },
  { label: 'Draft', value: statuses.draft },
  { label: 'Inactive', value: statuses.inactive },
  { label: 'Pending', value: statuses.pending },
  { label: 'Waiting', value: statuses.waiting },
];

export const statusesPaymentOptions = [
  { label: 'Cancel', value: statuses.cancel },
  { label: 'Closed', value: statuses.closed },
  { label: 'Done', value: statuses.done },
  { label: 'Pending', value: statuses.pending },
  { label: 'Waiting', value: statuses.waiting },
];

export const statusesAttendanceOptions = [
  { label: 'Checked In', value: statuses.checked_in },
  { label: 'Waiting', value: statuses.waiting },
  { label: 'Not Come', value: statuses.not_come },
];
