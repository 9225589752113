import React, { useState } from 'react';

import {
  LineChartOutlined,
  AreaChartOutlined,
  PieChartOutlined,
  TableOutlined,
  SettingOutlined,
  EyeOutlined,
  CodeOutlined,
  OrderedListOutlined,
  BarChartOutlined,
} from '@ant-design/icons';
import { Button, Card, Form, Select, Popover, Input, InputNumber, Radio } from 'antd';
import { PreviewQuery } from './preview-query';
import { ContentRender } from './preview-chart';
import { IChartType } from '../helpers';

const optChartTypes = [
  {
    value: 'table',
    label: (
      <React.Fragment>
        <TableOutlined />
        <span style={{ marginLeft: 10 }}>Table</span>
      </React.Fragment>
    ),
  },
  {
    value: 'line',
    label: (
      <React.Fragment>
        <LineChartOutlined />
        <span style={{ marginLeft: 10 }}>Line</span>
      </React.Fragment>
    ),
  },
  {
    value: 'area',
    label: (
      <React.Fragment>
        <AreaChartOutlined />
        <span style={{ marginLeft: 10 }}>Area</span>
      </React.Fragment>
    ),
  },
  {
    value: 'bar',
    label: (
      <React.Fragment>
        <BarChartOutlined />
        <span style={{ marginLeft: 10 }}>Bar</span>
      </React.Fragment>
    ),
  },
  {
    value: 'pie',
    label: (
      <React.Fragment>
        <PieChartOutlined />
        <span style={{ marginLeft: 10 }}>Pie</span>
      </React.Fragment>
    ),
  },
];

interface Props {
  ignoreChartType?: IChartType[];
}

export function PreviewQueryBuilder(props: Props) {
  const { ignoreChartType = [] } = props;
  const [pageType, setPageType] = useState('chart');

  return (
    <React.Fragment>
      <Card
        style={{ height: 500 }}
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: 20 }}>Settings:</span>
            <div style={{ padding: 3, border: '1px solid #f1f1f1' }}>
              <Form.Item noStyle name={['configuration', 'chartType']} style={{ width: 100 }}>
                <Select
                  suffixIcon
                  size="small"
                  bordered={false}
                  options={optChartTypes.filter((item) => !ignoreChartType.includes(item.value as IChartType))}
                ></Select>
              </Form.Item>
            </div>
            <div style={{ padding: 3, border: '1px solid #f1f1f1' }}>
              <Popover
                content={
                  <div>
                    <Form.Item noStyle name={['configuration', 'query', 'limit']} style={{ width: 100 }}>
                      <InputNumber style={{ width: 150 }} placeholder="Input limit" />
                    </Form.Item>
                  </div>
                }
                placement="bottom"
              >
                <Button size="small" type="text" icon={<SettingOutlined />}>
                  Limit
                </Button>
              </Popover>
            </div>
            <div style={{ padding: 3, border: '1px solid #f1f1f1' }}>
              <Button size="small" type="text" icon={<OrderedListOutlined />}>
                Order
              </Button>
            </div>
          </div>
        }
        extra={
          <div>
            <Radio.Group value={pageType} onChange={(e) => setPageType(e.target.value)}>
              <Radio.Button value="chart">
                <BarChartOutlined />
                <span style={{ marginLeft: 5 }}>Chart</span>
              </Radio.Button>
              <Radio.Button value="query">
                <CodeOutlined />
                <span style={{ marginLeft: 5 }}>JSON Query</span>
              </Radio.Button>
            </Radio.Group>
          </div>
        }
      >
        {pageType === 'query' && (
          <Form.Item noStyle name={['configuration']} valuePropName="configuration">
            <PreviewQuery />
          </Form.Item>
        )}
        {pageType === 'chart' && (
          <Form.Item noStyle name={['configuration']} valuePropName="configuration">
            <ContentRender />
          </Form.Item>
        )}
      </Card>
    </React.Fragment>
  );
}
