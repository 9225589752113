import { Form } from 'antd';
import React from 'react';
import BankAccountsField from './bak-account-field';
import BankField from './bank-field';

export default function BankForm() {
  return (
    <React.Fragment>
      <Form.Item noStyle name={['detail_data', 'id']}></Form.Item>
      <Form.Item noStyle name={['detail_data', 'key']}></Form.Item>
      <Form.Item noStyle name={['detail_data', 'document_id']}></Form.Item>
      <Form.Item noStyle name={['detail_data', 'field_type']}></Form.Item>
      <Form.Item noStyle name={['detail_data', 'company']}></Form.Item>

      <Form.Item shouldUpdate noStyle>
        {({ getFieldValue }) => {
          const fieldType = getFieldValue(['detail_data', 'field_type']);
          if (fieldType === 'bank') return <BankField />;
          return <BankAccountsField />;
        }}
      </Form.Item>
    </React.Fragment>
  );
}
