import { FormBuilder } from '@eigen3m/react-base-frontend';

export default function FormSetting(): JSX.Element {
  return (
    <FormBuilder
      columns={[
        {
          fields: [
            {
              name: 'email_sender',
              label: 'Email Sender',
              fieldType: 'inputText',
              gridColumn: { span: 6 },
              rules: [
                { required: true, message: 'Email sender is required.' },
                { type: 'email', message: 'Invalid email.' },
              ],
              inputTextProps: {
                placeholder: 'Input Email Sender',
              },
            },
            {
              name: 'gmail_token',
              label: 'Gmail Auth Token',
              rules: [{ required: true, message: 'Token is required.' }],
              fieldType: 'inputText',
              gridColumn: { span: 6 },
              inputTextProps: {
                placeholder: 'Input Email Sender',
              },
            },
          ],
        },
      ]}
    />
  );
}
