import {
  ActionProps,
  IndexPageWrapper,
  notificationFailed,
  notificationSuccess,
  SelectPaginate,
  SinglePage,
  usePageProvider,
} from '@eigen3m/react-base-frontend';
import { useState } from 'react';
import * as lodash from 'lodash';
import { paymentsColumn, statusesPaymentOptions } from '@admin-app/pages/events/event-activity/presentation/helpers';
import moment from 'moment';
import { getAccessRight } from 'shared/helpers/user-category-transformer';
import { ApiUrlData, moduleKey, subModuleKey } from '@base-configs';
import { PaymentSummary } from './card-summary';
import { DrawerForm } from './drawer-form';
import { BiX } from 'react-icons/bi';
import { DrawerDetail } from './drawer-detail';
import { OptionsYesOrNo } from '@helpers';
import { Form } from 'antd';

export default function IndexTable(): JSX.Element {
  const [visibleDrawerForm, setVisibleDrawerForm] = useState<boolean>(false);
  const [actionForm, setActionForm] = useState<ActionProps>('create');
  const [visibleDrawerDetail, setVisibleDrawerDetail] = useState(false);
  const [showTable, setShowTable] = useState(true);

  const { id: eventId, dataSource } = usePageProvider();

  const accessRight = getAccessRight(moduleKey.EVENT_ACTIVITY, subModuleKey.event_activity.PAYMENT);
  const accessConfirm = accessRight?.confirm_process;
  const accessChangeStatus = accessRight?.change_status;
  const accessDelete = accessRight?.delete;
  const accessEdit = accessRight?.edit;

  let actionDisabled = [];
  if (!accessConfirm) actionDisabled = [...actionDisabled, 'confirm_process'];
  if (!accessChangeStatus) actionDisabled = [...actionDisabled, 'activate', 'deactivate'];
  if (!accessDelete) actionDisabled = [...actionDisabled, 'delete'];
  if (!accessEdit) actionDisabled = [...actionDisabled, 'update'];

  const onShowDrawerForm = (action: ActionProps): void => {
    setVisibleDrawerForm(true);
    setActionForm(action);
  };
  const onCloseDrawerForm = (): void => {
    setVisibleDrawerForm(false);
  };

  const onShowDrawerDetail = () => {
    setVisibleDrawerDetail(true);
  };
  const onCloseDrawerDetail = () => {
    setVisibleDrawerDetail(false);
  };

  async function handleConfirmProcess(payload): Promise<void> {
    await dataSource.handleConfirmProcess(payload, {
      onSuccess: () => {
        // notificationSuccess['Successfully update payment.'];
      },
      onFailed: ({ message }: any) => {
        // notificationFailed(message);
      },
    });
  }

  async function functionUpdate(payload: any): Promise<void> {
    const cleanData = lodash.omit(payload, ['paymentId']);
    const paymentDate = moment(payload?.payment_date).valueOf();
    const newPayload = {
      ...cleanData,
      payment_date: paymentDate,
      event: {
        id: payload.event_id,
      },
    };

    await dataSource.handleUpdate(payload?.paymentId, newPayload, {
      onSuccess: async ({ response }: any): Promise<void> => {
        notificationSuccess['Successfully update payment.'];
        await handleConfirmProcess(response);
        setShowTable(false);
        setTimeout(() => {
          setShowTable(true);
        }, 0);
      },
      onFailed: async ({ message }: any): Promise<void> => {
        notificationFailed([message]);
        setShowTable(false);
        setTimeout(() => {
          setShowTable(true);
        }, 0);
      },
    });
  }

  async function handleCancel(id: string): Promise<void> {
    await dataSource.handleCustomRequest({
      paramRequest: {
        method: 'PATCH',
        url: process.env.REACT_APP_BASE_URL + ApiUrlData.payment + `/${id}/confirm-cancel`,
      },
      onSuccess: () => {
        notificationSuccess(['Successfully cancel data.']);
      },
      onFailed: ({ message }: any) => {
        notificationFailed([message]);
      },
    });
  }

  return (
    <IndexPageWrapper
      showPageHeader={false}
      padding={[]}
      showPageHeaderSimple={true}
      drawerFilterProp={{
        width: 600,
        formBuilderProps: {
          columns: [
            {
              fields: [
                {
                  label: 'Day',
                  name: 'day',
                  gridColumn: { xl: 8 },
                  fieldType: 'selectPaginate',
                  selectPaginateProps: {
                    dataSourceUrl: ApiUrlData.eventData + `/${eventId}/schedules/day`,
                    classNamePrefix: 'react-select-custom-prefix-radius',
                    customLabel: (row) => `Day ${row?.no_day}`,
                    isClearable: true,
                  },
                },
                {
                  gridColumn: { xl: 8 },
                  renderField(form) {
                    const day = form.getFieldValue('day');
                    const dayId = day?.id;
                    const hasSession = day?.has_session;
                    return (
                      <Form.Item name="session" label="Session">
                        <SelectPaginate
                          classNamePrefix="react-select-custom-prefix-radius"
                          dataSourceUrl={ApiUrlData.eventData + `/${dayId}/schedules/session`}
                          customLabel={(row) => `Session ${row?.no_session}`}
                          filterRequest={{ event_id: eventId }}
                          isDisabled={!hasSession}
                          isClearable
                        />
                      </Form.Item>
                    );
                  },
                },
                {
                  gridColumn: { xl: 8 },
                  renderField(form) {
                    const session = form.getFieldValue('session');
                    const sessionId = session?.id;
                    const hasWorkshop = session?.has_workshop;
                    return (
                      <Form.Item name="workshop" label="Workshop">
                        <SelectPaginate
                          classNamePrefix="react-select-custom-prefix-radius"
                          dataSourceUrl={ApiUrlData.eventData + `/${sessionId}/schedules/workshop`}
                          customLabel={(row) => row?.name}
                          filterRequest={{ event_id: eventId }}
                          isDisabled={!hasWorkshop}
                          isClearable
                        />
                      </Form.Item>
                    );
                  },
                },
                // {
                //   label: 'Member',
                //   name: 'member',
                //   gridColumn: { xl: 8 },
                //   fieldType: 'select',
                //   selectProps: {
                //     options: OptionsYesOrNo,
                //     classNamePrefix: 'react-select-custom-prefix-radius',
                //     customLabel: (value) => OptionsYesOrNo?.find((item) => item.value === value).label,
                //     isClearable: true,
                //   },
                // },
                // {
                //   label: 'Check In',
                //   name: 'check_in',
                //   gridColumn: { xl: 8 },
                //   fieldType: 'select',
                //   selectProps: {
                //     options: OptionsYesOrNo,
                //     classNamePrefix: 'react-select-custom-prefix-radius',
                //     customLabel: (value) => OptionsYesOrNo?.find((item) => item.value === value).label,
                //   },
                // },
                {
                  label: 'Statuses',
                  name: 'statuses',
                  gridColumn: { xl: 8 },
                  fieldType: 'select',
                  selectProps: {
                    isMulti: true,
                    options: statusesPaymentOptions,
                    classNamePrefix: 'react-select-custom-prefix-radius',
                    customLabel: (value) => statusesPaymentOptions?.find((item) => item.value === value).label,
                  },
                },
              ],
            },
          ],
        },
      }}
      pageToolbarProps={{ showNewData: false, showImport: false, showPrint: true, showBack: false }}
      drawerImportProps={{
        customModuleKey: 'registration',
        defaultValueFilter: {
          event_ids: !!eventId ? [eventId] : [],
        },
      }}
    >
      <div className="no-print">
        <PaymentSummary />
      </div>

      <SinglePage
        autoConfirmAfterAction={true}
        drawerDetailProps={{
          children: <DrawerDetail />,
          width: 600,
          title: 'Payment',
          visible: visibleDrawerDetail,
          onClose: onCloseDrawerDetail,
          onShow: onShowDrawerDetail,
          showDataTypeSelector: false,
        }}
        drawerFormProps={{
          // functionUpdate,
          action: actionForm,
          visible: visibleDrawerForm,
          onClose: onCloseDrawerForm,
          onShow: onShowDrawerForm,
          forceRequest: true,
          children: <DrawerForm />,
          width: 600,
          showDataTypeSelector: false,
          handleAfterSave: async (payload, { onReloadTable }) => {
            await dataSource.handleConfirmProcess(payload, {
              onSuccess: () => {
                onReloadTable();
              },
              onFailed: () => {
                console.log('failed');
              },
            });
          },
        }}
        dataTableProps={{
          columns: paymentsColumn,
          pagination: false,
          rowActionProps: {
            actionIgnored: ['delete', 'duplicate', 'activate', 'deactivate', 'confirm_process', 'update'],
            actionDisabled: actionDisabled,
            additionalActions(row) {
              return [
                {
                  key: 'Cancel',
                  icon: <BiX />,
                  label: 'Cancel',
                  onClick(e) {
                    e.domEvent.stopPropagation();
                    handleCancel(row?.id);
                  },
                },
              ];
            },
            customActionIgnored(row: any): any[] {
              if (['done', 'closed', 'cancel']?.includes(row?.status?.toLowerCase())) {
                return ['cancel'];
              }
            },
          },
        }}
      />
    </IndexPageWrapper>
  );
}
