import { convertDateTime, convertEpochToMoment, convertToMomentTime } from './helpers';

export const transformerGetDataInformation = (payload: any): any => {
  const cleanVal = {
    ...payload,
    banner_url: payload?.document_url ?? payload?.banner_url,
    main_banner:
      payload?.banner_in_homepage && payload?.banner_in_event_category
        ? ['Homepage', 'Event Category']
        : payload?.banner_in_homepage
        ? ['Homepage']
        : payload?.banner_in_event_category
        ? ['Event Category']
        : null,
  };
  return cleanVal;
};

export const transformerGetDataParticipant = (payload: any): any => {
  const isPublicEvent = payload?.public_event;
  const cleanVal = {
    ...payload,
    public_event: isPublicEvent ? 'true' : 'false',
  };
  return cleanVal;
};

export const transformerGetDataSchedules = (payload: any): any => {
  const days = payload?.days ?? [];
  const newDays = days?.map((item) => {
    const date = item?.event_date ? convertEpochToMoment({ date: item?.event_date }) : null;
    const sessions = item?.sessions ?? [];
    const newSessions = sessions?.map((itemSession) => {
      const workshops = itemSession?.workshops ?? [];
      const newWorkshops = workshops?.map((itemWorkshop) => {
        return {
          key: itemWorkshop?.id ?? itemWorkshop?.key,
          id: itemWorkshop?.id,
          no: itemWorkshop?.no_workshop,
          name: itemWorkshop?.name,
          description: itemWorkshop?.description,
          time: convertToMomentTime({ date, startTime: itemWorkshop?.start_time, endTime: itemWorkshop?.end_time }),
          speakers: itemWorkshop?.speakers,
          workshop_agendas: [],
          schedule: {
            day_key: item?.id,
            session_key: itemSession?.id,
            workshop_key: null,
          },
        };
      });
      return {
        key: itemSession?.id ?? itemSession?.key,
        id: itemSession?.id,
        no: itemSession?.no_session,
        name: itemSession?.name,
        description: itemSession?.description,
        no_of_agenda: itemSession?.no_of_agenda,
        time: convertToMomentTime({ date, startTime: itemSession?.start_time, endTime: itemSession?.end_time }),
        workshops: newWorkshops,
        speakers: itemSession?.speakers,
        schedule: {
          day_key: item?.id,
          session_key: null,
          workshop_key: null,
        },
      };
    });
    return {
      key: item?.id ?? item?.key,
      id: item?.id,
      no: item?.no_day,
      name: item?.name,
      description: item?.description,
      no_of_session: item?.no_of_session,
      date: date,
      time: convertToMomentTime({ date, startTime: item?.start_time, endTime: item?.end_time }),
      sessions: newSessions ?? [],
      speakers: item?.speakers ?? [],
      schedule: {
        day_key: null,
        session_key: null,
        workshop_key: null,
      },
    };
  });
  return {
    id: payload?.id,
    description: payload?.description,
    day_held: payload?.day_held,
    days: newDays,
  };
};

export const transformerGetDataRegistration = (payload: any): any => {
  const registrationDetails = payload?.registration_details ?? [];

  //Registration Information
  const registInformation = payload?.registration_information;
  const openDate = registInformation?.open_date;
  const openTime = registInformation?.open_time;
  const registrationOpen = convertDateTime({ date: openDate, time: openTime });

  const closingDate = registInformation?.closing_date;
  const closingTime = registInformation?.closing_time;
  const registrationClose = convertDateTime({ date: closingDate, time: closingTime });

  const newRegistrationDetails = registrationDetails?.map((item) => {
    const date = item?.date ? convertEpochToMoment({ date: item?.date }) : null;
    const sessions = item?.sessions ?? [];
    const newSessions = sessions?.map((itemSession) => {
      const workshops = itemSession?.workshops ?? [];
      const registrationOpen = convertDateTime({ date: itemSession?.open_date, time: itemSession?.open_time });
      const registrationClose = convertDateTime({ date: itemSession?.closing_date, time: itemSession?.closing_time });
      const newWorkshops = workshops?.map((itemWorkshop) => {
        return {
          key: itemWorkshop?.id ?? itemWorkshop?.key,
          id: itemWorkshop?.id,
          no: itemWorkshop?.workshop,
          name: itemWorkshop?.description,
          time: convertToMomentTime({ date, startTime: itemWorkshop?.start_time, endTime: itemWorkshop?.end_time }),
          speakers: itemWorkshop?.speakers,
          workshop_agendas: [],
          schedule: {
            day_key: item?.id ?? item?.key,
            session_key: itemSession?.id ?? itemSession?.key,
            agenda_key: itemSession?.id ?? itemSession?.key,
            workshop_key: null,
          },
          max_registrants: itemWorkshop?.max_registrants,
        };
      });
      return {
        key: itemSession?.id ?? itemSession?.key,
        id: itemSession?.id,
        no: itemSession?.session,
        name: itemSession?.name ?? itemSession?.description,
        no_of_workshop: itemSession?.no_of_workshop,
        time: convertToMomentTime({ date, startTime: itemSession?.start_time, endTime: itemSession?.end_time }),
        workshops: newWorkshops,
        registration_open: registrationOpen,
        registration_close: registrationClose,
        speakers: itemSession?.speakers,
        schedule: {
          day_key: item?.id ?? item?.key,
          session_key: itemSession?.id ?? itemSession?.key,
          agenda_key: null,
          workshop_key: null,
        },
        max_registrants: itemSession?.max_registrants,
      };
    });
    const registrationOpen = convertDateTime({ date: item?.open_date, time: item?.open_time });
    const registrationClose = convertDateTime({ date: item?.closing_date, time: item?.closing_time });
    return {
      key: item?.id ?? item?.key,
      id: item?.id,
      no: item?.day,
      name: item?.description,
      no_of_session: item?.no_of_session,
      date: date,
      time: convertToMomentTime({ date, startTime: item?.start_time, endTime: item?.end_time }),
      speakers: sessions?.length === 1 ? sessions[0]?.speakers : [],
      registration_open: registrationOpen,
      registration_close: registrationClose,
      max_registrants: item?.max_registrants,
      event_link: item?.event_link,
      event_code: item?.event_code,
      event_password: item?.event_password,
      sessions: newSessions,
      schedule: {
        day_key: null,
        session_key: null,
        agenda_key: null,
        workshop_key: null,
      },
    };
  });
  const cleanVal = {
    ...payload,
    registration_details: newRegistrationDetails,
    registration_information: {
      registration_open: registrationOpen,
      registration_close: registrationClose,
      max_registrants: registInformation?.max_registrants,
      event_link: registInformation?.event_link,
      event_code: registInformation?.event_code,
      event_password: registInformation?.event_password,
    },
  };
  return cleanVal;
};

export const transformerGetDataPaymentAndPricing = (payload: any): any => {
  const paymentMainPricings = payload?.payment_pricing_datas ?? [];
  const paymentSubPricings = payload?.payment_pricing_subs ?? [];
  const accounts = payload?.accounts ?? [];
  const banks = payload?.banks ?? [];
  const accountNumbers = banks?.map((item) => {
    const account = accounts?.find((itemAccount) => itemAccount?.bank_id === item?.id) ?? null;
    return {
      bank: item,
      account: account,
    };
  });

  const paymentPricings = (values: any[]): any[] => {
    if (!!values && !!values?.length) {
      return values?.map((item) => {
        const daySessionWorkshop = item?.day_session;
        const newDaySessionWorkshop = daySessionWorkshop?.map((itemDay) => {
          const workshops = itemDay?.workshop ?? [];
          return {
            key: itemDay?.id,
            ...itemDay,
            day: {
              no: itemDay?.day,
            },
            session: itemDay?.session
              ? {
                  no: itemDay?.session,
                }
              : null,
            workshop:
              !!workshops && !!workshops?.length
                ? workshops?.map((itemWorkshop) => {
                    return {
                      name: itemWorkshop,
                    };
                  })
                : [],
          };
        });
        return {
          key: item?.id,
          ...item,
          day_session_workshops: newDaySessionWorkshop,
        };
      });
    }
    return [];
  };

  const cleanVal = {
    ...payload,
    account_numbers: !!accountNumbers && !!accountNumbers?.length ? accountNumbers : [{ bank: null, account: null }],
    payment_pricing_datas: paymentPricings(paymentMainPricings),
    payment_pricing_subs: paymentPricings(paymentSubPricings),
    pricing_sub_required: payload?.pricing_sub_required ? 'true' : 'false',
  };
  return cleanVal;
};

export const transformerGetDataAttendanceOfficer = (payload: any): any => {
  const officerDatas = payload?.officer_datas ?? [];
  const newOfficerDatas = officerDatas?.map((item) => {
    const daySessionWorkshop = item?.day_session_agenda_workshops ?? payload?.day_session_workshops;
    const newDaySessionWorkshop = daySessionWorkshop?.map((item) => {
      return {
        ...item,
        day: {
          no: item?.day,
        },
        session: item?.session
          ? {
              no: item?.session,
            }
          : null,
        workshop:
          !!item?.workshop && !!item?.workshop?.length
            ? item?.workshop?.map((itemWorkshop) => {
                return {
                  name: itemWorkshop,
                };
              })
            : [],
      };
    });

    const mainDaySessionWorkshop = newDaySessionWorkshop?.filter((item) => !item?.is_sub_event);
    const subDaySessionWorkshop = newDaySessionWorkshop?.filter((item) => !!item?.is_sub_event);
    return {
      ...item,
      key: item?.id,
      day_session_workshops: mainDaySessionWorkshop,
      sub_day_session_workshops: subDaySessionWorkshop,
    };
  });

  const cleanVal = {
    ...payload,
    use_qr: payload?.use_qr ? 'true' : 'false',
    officer_datas: newOfficerDatas,
  };
  return cleanVal;
};
