import { LoginTemplate } from '../components';
import BackgroundImage from '../assets/background-3.png';
import { useNavigate, useParams } from 'react-router-dom';
import { WebUrlData, ApiUrlData, moduleKey } from '@base-configs';
import { authHelper, makeCommonDataSource, notificationFailed } from '@eigen3m/react-base-frontend';
import { useEffect, useState } from 'react';
import Logo from '../assets/logo.png';
import { getAccessRight, userCategoryTransformer } from 'shared/helpers/user-category-transformer';

const superAdminKey = '982f9a63-cf46-4d0c-9a96-01fa33dee7a1';

export default function LoginPage(): JSX.Element {
  const navigate = useNavigate();
  const dataSource = makeCommonDataSource({ apiUrl: ApiUrlData.user_login });
  const dataSourceLoginToken = makeCommonDataSource({ apiUrl: ApiUrlData.user_login_token });
  const [loading, setLoading] = useState(false);
  const { token } = useParams();

  async function getDetailUserCategory(id) {
    setLoading(true);
    let detail = null;
    await dataSource.handleCustomRequest({
      paramRequest: {
        baseURL: `${process.env.REACT_APP_ACCESS_CONTROL_URL}v1/user-category/${id}?tab_names[]=access_right`,
      },
      async onSuccess({ response }: any) {
        detail = response;
        setLoading(false);
      },
      async onFailed({ message }) {
        await authHelper.clearStorageLogout();
        notificationFailed([message as string]);
        setLoading(false);
      },
    });
    return detail;
  }

  const dataSourceUserCategory = makeCommonDataSource({
    apiUrl: 'v1/user-category/generate-format?tab_names[]=access_right',
    // baseUrl: 'https://api.testing.erp.eigen.co.id/api/',
  });

  async function getDataModule() {
    let access = [];
    setLoading(true);
    await dataSourceUserCategory.handleGetIndex({
      onSuccess: ({ response }: any) => {
        access = response.access_right;
      },
      onFailed: ({ message }: any) => {
        console.error(message);
      },
    });
    return access;
  }

  const navigateByUserAccess = () => {
    const listModule = [
      moduleKey.BANK_ACCOUNT,
      moduleKey.EMAIL_TEMPLATE,
      moduleKey.EMAIL_SETTING,
      moduleKey.USER_CATEGORY,
      moduleKey.USER,
      moduleKey.SPEAKER,
      moduleKey.VENUE,
      moduleKey.EVENT_CATEGORY,
      moduleKey.EVENT_DATA,
      moduleKey.EVENT_ACTIVITY,
      moduleKey.EVENT_ACTIVITY,
      moduleKey.REPORT,
    ];
    const access = getAccessRight(moduleKey.EVENT_DATA);
    const hasAccessEventData = access?.view ?? false;
    if (hasAccessEventData) {
      return navigate(WebUrlData.event_data);
    } else {
      listModule?.forEach((item) => {
        const access = getAccessRight(item);
        const hasAccessView = access?.view ? true : false;
        if (hasAccessView) {
          if (item === moduleKey.BANK_ACCOUNT) {
            return navigate(WebUrlData.bank_accounts);
          } else if (item === moduleKey.EMAIL_TEMPLATE) {
            return navigate(WebUrlData.email_template);
          } else if (item === moduleKey.EMAIL_SETTING) {
            return navigate(WebUrlData.email_setting);
          } else if (item === moduleKey.USER_CATEGORY) {
            return navigate(WebUrlData.user_categories);
          } else if (item === moduleKey.USER) {
            return navigate(WebUrlData.users);
          } else if (item === moduleKey.SPEAKER) {
            return navigate(WebUrlData.speaker);
          } else if (item === moduleKey.VENUE) {
            return navigate(WebUrlData.venue);
          } else if (item === moduleKey.EVENT_CATEGORY) {
            return navigate(WebUrlData.even_category);
          } else if (item === moduleKey.EVENT_DATA) {
            return navigate(WebUrlData.event_data);
          } else if (item === moduleKey.EVENT_ACTIVITY) {
            return navigate(WebUrlData.event_activity);
          } else if (item === moduleKey.REPORT) {
            return navigate(WebUrlData.report);
          }
        }
      });
    }
  };

  async function handleAfterLogin(response: any): Promise<void> {
    const userCategoryId = response?.user?.user_category?.id ?? response?.user?.user_category?.uuid;
    if (userCategoryId === superAdminKey) {
      await authHelper.setAccount(response);
      const userCategory = await getDataModule();
      const dataUser = {
        ...response,
        access_right: userCategoryTransformer({ access_right: userCategory, id: userCategoryId }),
      };
      await authHelper.setAccount(dataUser);
      navigate(WebUrlData.event_data);
    } else {
      const userCategory = await getDetailUserCategory(response?.user?.user_category?.id);
      const dataUser = {
        ...response,
        user: {
          ...response?.user,
          user_category: {
            ...response?.user?.user_category,
            ...userCategory,
          },
        },

        access_right: userCategoryTransformer(userCategory ?? response?.user?.user_category),
      };
      await authHelper.setAccount(dataUser);
      navigateByUserAccess();
      // navigate(WebUrlData.bank_accounts);
    }
  }

  async function onFinish(payload) {
    setLoading(true);
    await dataSource.handleCreate(
      { ...payload, company: 'E' },
      {
        async onSuccess({ response }: any) {
          await handleAfterLogin(response);
          setLoading(false);
        },
        async onFailed({ message }) {
          await authHelper.clearStorageLogout();
          notificationFailed([message as string]);
          setLoading(false);
        },
      },
    );
  }

  async function loginToken(token) {
    setLoading(true);
    await dataSourceLoginToken.handleCreate(
      { token },
      {
        async onSuccess({ response }) {
          await handleAfterLogin(response);
          setLoading(false);
        },
        async onFailed({ message }) {
          await authHelper.clearStorageLogout();
          notificationFailed([message as string]);
          setLoading(false);
        },
      },
    );
  }

  useEffect(() => {
    if (token) {
      const decodeToken = decodeURIComponent(token);
      loginToken(decodeToken);
    }
  }, [token]);

  return (
    <LoginTemplate
      loadingLogin={loading}
      onSubmitLogin={onFinish}
      onClickManualRegister={() => navigate(WebUrlData.register)}
      rightContentImage={BackgroundImage}
      loginWithGoogleUrl={`${process.env.REACT_APP_BASE_SERVICE_URL}${ApiUrlData.login_google}`}
      registerWithGoogleUrl={`${process.env.REACT_APP_BASE_SERVICE_URL}${ApiUrlData.register_google}`}
      logoImage={Logo}
      formBuilderProps={{
        columns: [
          {
            fields: [
              {
                label: 'Email',
                name: 'username',
                gridColumn: { span: 24 },
                fieldType: 'inputText',
                rules: [{ required: true, message: 'Please input your email!' }],
                inputTextProps: {
                  type: 'email',
                  size: 'large',
                  placeholder: 'Enter your mail',
                  className: 'login-field',
                },
              },
              {
                label: 'Password',
                name: 'password',
                gridColumn: { span: 24 },
                fieldType: 'inputPassword',
                rules: [{ required: true, message: 'Please input your password!' }],
                inputPasswordProps: {
                  size: 'large',
                  placeholder: 'Enter your password',
                },
              },
            ],
          },
        ],
      }}
    />
  );
}
