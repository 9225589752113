import {
  AntdIconWrapper,
  DetailBuilder,
  DetailPageWrapper,
  RenderTag,
  usePageProvider,
} from '@eigen3m/react-base-frontend';
import { Button, Card, Form, Tabs, Tooltip } from 'antd';
import moment from 'moment';
import { AiOutlineQrcode } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { useAccessRight } from 'shared/hooks';
import { RelativeComponent } from '../components/relatives';
const grid = { xxl: 4, xl: 4, lg: 8, md: 8, sm: 12, xs: 24 };

const defaultButtonStyle = {
  marginLeft: '12px',
  marginRight: '12px',
  fontSize: '12px',
};

export default function DetailPage() {
  const { id } = usePageProvider();

  const { moduleKey } = usePageProvider();
  const { accessCreate, accessEdit, accessImport, accessChangeStatus, accessDelete, accessView, accessPrint } =
    useAccessRight({
      moduleKey,
    });
  let actionIgnored: any[] = ['confirm_process'];
  if (!accessCreate) actionIgnored = [...actionIgnored, 'duplicate'];
  if (!accessEdit) actionIgnored = [...actionIgnored, 'update'];
  if (!accessChangeStatus) actionIgnored = [...actionIgnored, 'activate', 'deactivate'];
  if (!accessView) actionIgnored = [...actionIgnored, 'detail'];
  if (!accessDelete) actionIgnored = [...actionIgnored, 'delete'];

  const AdditionalToolbar = () => {
    return (
      <Tooltip placement="bottom" title="QR Code">
        <Link to={`/qr-code/detail?id=${id}`}>
          <Button
            type="text"
            shape="circle"
            style={defaultButtonStyle}
            icon={
              <AntdIconWrapper>
                <AiOutlineQrcode />
              </AntdIconWrapper>
            }
          ></Button>
        </Link>
      </Tooltip>
    );
  };
  return (
    <DetailPageWrapper
      pageToolbarProps={{
        pageTitle: 'Detail User',
        showDataTypeSelector: false,
        showConfirmProcess: false,
        disableActivate: !accessChangeStatus,
        disableDeactivate: !accessChangeStatus,
        disableUpdate: !accessEdit,
        disableNewData: !accessCreate,
        disableDuplicate: !accessCreate,
        disableDelete: !accessDelete,
        // showBack: false,
        // buttonRightContent: <AdditionalToolbar />,
      }}
    >
      <Card style={{ marginBottom: 12 }}>
        <DetailBuilder
          columns={[
            {
              title: 'INDIVIDUAL INFORMATION',
              fields: [
                {
                  gridColumn: grid,
                  label: 'Name',
                  name: ['detail_data', 'name'],
                  fieldType: 'text',
                  renderTextProps: {
                    defaultRender: () => '-',
                  },
                },
                {
                  gridColumn: grid,
                  label: 'User Category',
                  name: ['detail_data', 'user_category', 'name'],
                  fieldType: 'text',
                  renderTextProps: {
                    defaultRender: () => '-',
                  },
                },
                {
                  gridColumn: grid,
                  label: 'Event',
                  name: ['detail_data'],
                  fieldType: 'text',
                  renderTextProps: {
                    defaultRender: () => '-',
                    renderValue(value) {
                      return RenderTag({ value: value?.event_categories, renderType: 'multi', keyValue: 'name' });
                    },
                  },
                },
                {
                  gridColumn: grid,
                  label: 'Email',
                  name: ['detail_data', 'email'],
                  fieldType: 'text',
                  renderTextProps: {
                    defaultRender: () => '-',
                  },
                },
              ],
            },
          ]}
        />
      </Card>
      <Form.Item shouldUpdate noStyle hidden>
        {({ getFieldsValue }) => {
          const values = getFieldsValue();
          return (
            <Card>
              <Tabs>
                <Tabs.TabPane tab="Relative">
                  <Form.Item name={['detail_data', 'relatives']} noStyle>
                    <RelativeComponent />
                  </Form.Item>
                </Tabs.TabPane>
              </Tabs>
            </Card>
          );
        }}
      </Form.Item>
    </DetailPageWrapper>
  );
}
