import React, { useEffect, useState } from 'react';
import { Button, Drawer, DrawerProps, FormInstance, Form, Spin, Checkbox } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { capitalize, omit } from 'lodash';
import { Store } from 'antd/lib/form/interface';
import { useMediaQuery } from '@eigen3m/react-base-frontend';
import { v4 as uuid } from 'uuid';

export type ActionProps = 'create' | 'update' | 'duplicate' | 'detail';
export interface DrawerFormProps extends DrawerProps {
  action?: ActionProps;
  name?: string;
  value?: any;
  initialValues?: Store;
  onValuesChange?(item: any, values: any, form: FormInstance): void | Promise<void>;
  handleAfterSave?: (response?: any) => boolean;
  onChange?: (payload: any) => void;
}

export function DrawerForm(props: DrawerFormProps) {
  const { action, name, value, initialValues, children, onValuesChange, handleAfterSave, onClose, onChange } = props;
  const [form] = Form.useForm();
  const [loadingForm, setLoadingForm] = useState(false);
  const [isCreateAnother, setIsCreateAnother] = useState(false);

  const isTablet = useMediaQuery({ media: 'tablet' });
  const width = props.width ?? 500;
  const responsiveWidth = isTablet ? '100%' : width;

  const title = props?.title ?? 'Data';
  const drawerTitle = `${capitalize(action)} ${title}`;

  const InitDrawerProps: DrawerProps = {
    ...omit(props ?? {}, ['value']),
    width: responsiveWidth,
    title: drawerTitle,
  };

  useEffect(() => {
    if (value)
      form.setFieldsValue({
        ...value,
      });
  }, [value]);

  const onFinish = (values: any) => {
    form.resetFields();
    if (!isCreateAnother) onClose(undefined);
    onChange(values);
  };

  async function onReset() {
    form.resetFields();
  }

  function FooterDrawer() {
    return (
      <div
        style={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <Checkbox checked={isCreateAnother} onChange={(e) => setIsCreateAnother(e.target.checked)}>
            Create Another
          </Checkbox>
        </div>
        <div>
          <Button
            style={{ marginRight: '5px' }}
            onClick={() => {
              onReset();
            }}
          >
            Reset
          </Button>
          <Button type="primary" onClick={() => form.submit()}>
            Save
          </Button>
        </div>
      </div>
    );
  }

  return (
    <Drawer
      style={{ overflow: 'hidden' }}
      {...InitDrawerProps}
      footer={<FooterDrawer />}
      footerStyle={{ height: '50px' }}
    >
      <Spin spinning={loadingForm} indicator={<LoadingOutlined />} style={{ minHeight: '50vh' }}>
        <Form
          form={form}
          layout="vertical"
          name={`drawer-form-${name}`}
          scrollToFirstError
          onFinish={onFinish}
          onValuesChange={(item, values) => {
            if (onValuesChange) onValuesChange(item, values, form);
          }}
          initialValues={initialValues}
        >
          <Form.Item name="key" noStyle hidden />
          <Form.Item name="id" noStyle hidden />
          {children}
        </Form>
      </Spin>
    </Drawer>
  );
}
