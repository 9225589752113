import { useParams } from 'react-router-dom';
import { WebUrlData } from '@base-configs';
import { RouteEntity } from '@eigen3m/react-base-frontend';

//configuration
import BankAccounts from '@admin-app/pages/configuration/bank-accounts/presentation/factory';
import EmailTemplate from '@admin-app/pages/configuration/email-template/presentation/factory';
import EmailSetting from '@admin-app/pages/configuration/email-settings/presentation/factories';
import UserCategories from '@admin-app/pages/configuration/user-categories/presentation/factory';
import Users from '@admin-app/pages/configuration/users/presentation/factories';

//event
import Speaker from '@admin-app/pages/events/speaker/presentation/factories';
import Venue from '@admin-app/pages/events/venue/presentation/factories';
import Event from '@admin-app/pages/events/event-data/presentation/factories';
import EventCategory from '@admin-app/pages/events/event-category/presentation/factories';
import EventActivity from '@admin-app/pages/events/event-activity/presentation/factories';
import Report from '@admin-app/pages/report/presentation/factories';

import PaymentConfirmation from '@public-app/pages/invoice/presentation/factory';

export const DefaultPage = (): JSX.Element => {
  const params = useParams();
  return <div>You are in default page {params.action}</div>;
};

export const DefaultPath = WebUrlData.bank_accounts;
export const AuthRoutes: RouteEntity[] = [
  //configuration
  {
    label: 'Bank & Accounts',
    path: WebUrlData.bank_accounts,
    element: BankAccounts,
  },
  {
    label: 'Email Template',
    path: WebUrlData.email_template,
    element: EmailTemplate,
  },
  {
    label: 'Email Setting',
    path: WebUrlData.email_setting,
    element: EmailSetting,
  },
  {
    label: 'User Categories',
    path: WebUrlData.user_categories,
    element: UserCategories,
  },

  {
    label: 'User',
    path: WebUrlData.users,
    element: Users,
  },

  //event

  {
    label: 'Speaker',
    path: WebUrlData.speaker,
    element: Speaker,
  },
  {
    label: 'Venue',
    path: WebUrlData.venue,
    element: Venue,
  },
  {
    label: 'Event',
    path: WebUrlData.event_data,
    element: Event,
  },
  {
    label: 'Event Category',
    path: WebUrlData.even_category,
    element: EventCategory,
  },
  {
    label: 'Event Activity',
    path: WebUrlData.event_activity,
    element: EventActivity,
  },
  {
    label: 'Report',
    path: WebUrlData.report,
    element: Report,
  },
];
