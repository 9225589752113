import React from 'react';
import { Card, Skeleton } from 'antd';
import './event-card.less';

export function SkeletonEventCard() {
  return (
    <Card
      bodyStyle={{ padding: '15px' }}
      style={{ marginBottom: '20px', borderRadius: '8px', overflow: 'hidden' }}
      className="event-card"
      cover={
        <div className="event-card-image" style={{ overflow: 'hidden' }}>
          <Skeleton.Image active style={{ width: '350px', height: '150px' }} />
        </div>
      }
    >
      <Skeleton active paragraph />
    </Card>
  );
}
