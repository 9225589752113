import { ApiUrlData } from '@base-configs';
import { Card, Col, Row } from 'antd';
import { RenderCurrency, usePageProvider } from '@eigen3m/react-base-frontend';
import { useDataIndex } from '@admin-app/pages/events/event-activity/presentation/hooks/useDataIndex';

export function PaymentSummary(): JSX.Element {
  const { id } = usePageProvider();

  const { data } = useDataIndex(id, ApiUrlData.eventActivities + '/summary');

  const grid = { xxl: 3, xl: 3, lg: 6, md: 6, sm: 12, xs: 24 };

  return (
    <Card style={{ marginBottom: 30 }}>
      {data ? (
        data?.map((payment) => (
          <Row key={payment?.id}>
            <Col {...grid}>
              <h3># Invoices</h3>
              <span>{payment?.invoice ?? '-'}</span>
            </Col>
            <Col {...grid}>
              <h3># Total Invoices</h3>
              {payment?.total_invoice && payment?.total_invoice !== 0
                ? RenderCurrency({
                    value: payment?.total_invoice,
                    currencyOptions: {
                      minimumFractionDigits: 0,
                    },
                  })
                : '0'}
            </Col>
            {/* <Col {...grid}>
              <h3># Total Payments</h3>
              {payment?.total_payment && payment?.total_payment !== 0
                ? RenderCurrency({
                    value: payment?.total_payment,
                    currencyOptions: {
                      minimumFractionDigits: 0,
                    },
                  })
                : '0'}
            </Col> */}
            <Col {...grid}>
              <h3># Pending</h3>
              <span>{payment?.total_pending_payment ?? '0'}</span>
            </Col>
            {/* <Col {...grid}>
              <h3># Waiting</h3>
              <span>{payment?.total_waiting_payment ?? '0'}</span>
            </Col> */}
            <Col {...grid}>
              <h3># Done</h3>
              <span>{payment?.total_done_payment ?? '0'}</span>
            </Col>
            <Col {...grid}>
              <h3># Cancelled</h3>
              <span>{payment?.total_cancel_payment ?? '0'}</span>
            </Col>
            <Col {...grid}>
              <h3># Closed</h3>
              <span>{payment?.total_close_payment ?? '0'}</span>
            </Col>
          </Row>
        ))
      ) : (
        <h2 style={{ textAlign: 'center' }}>No Data</h2>
      )}
    </Card>
  );
}
