import { ApiUrlData } from '@base-configs';
import { FormBuilder, usePageProvider } from '@eigen3m/react-base-frontend';
import { Col, Row, Form } from 'antd';

type Props = {
  documentID: string;
};

export default function BankField(props: Props): JSX.Element {
  const { documentID } = props;
  const { dataSource } = usePageProvider();

  return (
    <Row>
      <Col span={24}>
        <Form.Item shouldUpdate noStyle>
          {({ getFieldsValue }) => {
            const values = getFieldsValue();
            const data_id = values?.data_id;
            const form_action = values?.form_action;
            const isUpdate = form_action === 'update';

            return (
              <FormBuilder
                columns={[
                  {
                    fields: [
                      {
                        name: 'document_url',
                        label: 'Bank Logo',
                        fieldType: 'uploadImage',
                        valuePropName: 'imageUrl',
                        rules: [{ required: true, message: 'Bank logo is required ' }],
                        gridColumn: { span: 6 },
                        uploadImageProps: {
                          notes: 'Max size 500kb, Image type .JPG and .PNG',
                          imageSize: 0.5,
                          errorSizeMessage: 'Maximum Size is 500kb!',
                          imageType: ['image/jpeg', 'image/png'],
                          className: 'avatar-uploader',
                          requestUrl: ApiUrlData.banks_upload,
                          requestFileKey: 'document_url',
                          transformPayloadRequest(payload) {
                            const formData = new FormData();
                            formData.append('document_url', payload);
                            formData.append('document_id', documentID);
                            return formData;
                          },
                        },
                      },
                      {
                        name: 'name',
                        label: 'Bank Name',
                        fieldType: 'inputText',
                        gridColumn: { span: 18 },
                        rules: [
                          { required: true, message: 'Bank name is required ' },
                          {
                            validator: async (_, value: any) => {
                              let nameAlreadyExist = false;
                              await dataSource.handleCustomRequest({
                                paramRequest: {
                                  method: 'GET',
                                  url: process.env.REACT_APP_BASE_SERVICE_URL + ApiUrlData.banks,
                                  params: {
                                    name: value,
                                  },
                                },
                                onSuccess({ response }: any) {
                                  const banks = response?.items ?? [];
                                  nameAlreadyExist = false;
                                  if (banks.length > 0) {
                                    // const findId = banks.find((item) => item.id === data_id);
                                    const hasName = banks?.find(
                                      (item) => item?.name?.toLowerCase() === value?.toLowerCase(),
                                    );

                                    if (isUpdate && hasName) nameAlreadyExist = false;
                                    else if (hasName) nameAlreadyExist = true;
                                  } else {
                                    nameAlreadyExist = false;
                                  }
                                },
                              });
                              if (nameAlreadyExist) {
                                return Promise.reject(new Error(`${value} already exist!`));
                              } else {
                                return Promise.resolve();
                              }
                            },
                          },
                        ],
                        inputTextProps: {
                          placeholder: 'Ex BCA',
                        },
                      },
                    ],
                  },
                ]}
              />
            );
          }}
        </Form.Item>
      </Col>
    </Row>
  );
}
