import { omit } from 'lodash';
import { EventActivityEntity } from '../entities';
import { BaseEntity, BaseTransformer, makeArrayIds } from '@eigen3m/react-base-frontend';

import * as lodash from 'lodash';
import moment from 'moment';

function omitValues(obj: any = {}, keyValues: string[] = []) {
  return lodash.omit(obj, keyValues);
}

export class EventActivityTransformer<E extends BaseEntity = EventActivityEntity> extends BaseTransformer<E> {
  transformerUpdate(payload: E): E {
    if (!payload) return payload;
    const cleanVal: any = omit(payload, ['id', 'form_action']);
    Object.assign(cleanVal, { title_id: 1 });
    return cleanVal;
  }

  transformerCreate(payload: E): E {
    const cleanVal = this.transformerUpdate(payload);
    return payload;
  }

  transformerDuplicate(payload: E): E {
    return this.transformerCreate(payload);
  }

  transformerGetData(payload: E): E {
    const information = payload?.information ?? null;
    const participants = payload?.participants ?? null;
    const paymentPricings = payload?.payment_pricings ?? null;
    const schedules = payload?.schedules ?? null;

    const scheduleDay = schedules?.days.map((day: any) => {
      const dayValues = omitValues(day, ['created_at', 'deleted_at', 'schedule_id', 'updated_at']);
      const sessions = day?.sessions?.map((session: any) => {
        const sessionValue = omitValues(session, ['created_at', 'day_event_id', 'deleted_at', 'updated_at']);

        return sessionValue;
      });

      return {
        ...dayValues,
        sessions,
      };
    });

    const newSchedules = {
      ...schedules,
      days: scheduleDay,
    };

    const informationValue = omitValues(information, [
      'category_id',
      'created_at',
      'deleted_at',
      'event_id',
      'updated_at',
      'venue_id',
    ]);

    const participantsValue = omitValues(participants, [
      'created_at',
      'deleted_at',
      'description',
      'event_id',
      'job_positions',
      'public_event',
      'updated_at',
    ]);

    const paymentPricingsValue = omitValues(paymentPricings, [
      'created_at',
      'deleted_at',
      'event_id',
      'highest_price',
      'updated_at',
    ]);

    const schedulesValue = omitValues(newSchedules, [
      'created_at',
      'date_end',
      'date_start',
      'deleted_at',
      'event_id',
      'has_agenda',
      'no_session',
      'no_workshop',
      'updated_at',
    ]);

    const newPayload = {
      ...payload,
      information: informationValue,
      participants: participantsValue,
      payment_pricings: paymentPricingsValue,
      schedules: schedulesValue,
    };

    return newPayload;
  }
  transformerFilterIndexTable(payload: any) {
    if (!payload) return payload;
    return {
      ...payload,
      category_ids: makeArrayIds({ data: payload?.event_category }),
      event_category: undefined,
      venue_ids: makeArrayIds({ data: payload?.venue }),
      venue: undefined,
      sepaker_ids: makeArrayIds({ data: payload?.speakers }),
      speakers: undefined,
      date_from: payload?.date?.[0] ? moment(payload?.date?.[0]).format('YYYY-MM-DD') : null,
      date_to: payload?.date?.[1] ? moment(payload?.date?.[1]).format('YYYY-MM-DD') : null,
      date: undefined,
      time_from: payload?.time?.[0] ? moment(payload?.date?.[1]).format('HH:mm') : null,
      time_to: payload?.time?.[1] ? moment(payload?.date?.[1]).format('HH:mm') : null,
      time: undefined,
    };
  }
}
