import { FormColumnProps } from '@eigen3m/react-base-frontend/dist/base/presentation/components/form-components/form-builder';
import { Form, Input, InputNumber } from 'antd';
import { ApiUrlData } from '@base-configs';

const optionsStatus = [
  {
    label: 'Draft',
    value: 'draft',
  },
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Inactive',
    value: 'inactive',
  },
  {
    label: 'Closed',
    value: 'closed',
  },
  {
    label: 'In Process',
    value: 'in_process',
  },
  {
    label: 'Cancel',
    value: 'cancel',
  },
];

const optionsYesOrNo = [
  {
    label: 'Yes',
    value: 'true',
  },
  {
    label: 'No',
    value: 'false',
  },
];
export const Filter: FormColumnProps[] = [
  {
    fields: [
      {
        label: 'Event Category',
        name: ['event_category'],
        gridColumn: { xl: 12, sm: 24, xs: 24 },
        fieldType: 'selectPaginate',
        selectPaginateProps: {
          classNamePrefix: 'react-select-custom-prefix-radius',
          dataSourceUrl: ApiUrlData.eventCategory,
          customLabel(row) {
            return row?.name;
          },
          keySearch: 'name',
          customFilterRequest() {
            return { statuses: ['active'] };
          },
          isMulti: true,
        },
      },
      {
        label: 'Event Type',
        gridColumn: { xl: 12, sm: 24, xs: 24 },
        name: ['event_type'],
        fieldType: 'selectPaginate',
        selectPaginateProps: {
          classNamePrefix: 'react-select-custom-prefix-radius',
          dataSourceUrl: ApiUrlData.enum_event_type,
          customLabel(row) {
            return row;
          },
        },
      },
      {
        label: 'Venue',
        gridColumn: { xl: 12, sm: 24, xs: 24 },
        name: ['venue'],
        fieldType: 'selectPaginate',
        selectPaginateProps: {
          classNamePrefix: 'react-select-custom-prefix-radius',
          dataSourceUrl: ApiUrlData.venues,
          customLabel(row) {
            return row?.name;
          },
          keySearch: 'name',
          customFilterRequest() {
            return { statuses: ['active'] };
          },
          isMulti: true,
        },
      },
      {
        label: 'Participant Type',
        gridColumn: { xl: 12, sm: 24, xs: 24 },
        name: ['participant_type'],
        fieldType: 'selectPaginate',
        selectPaginateProps: {
          classNamePrefix: 'react-select-custom-prefix-radius',
          isClearable: true,
          dataSourceUrl: ApiUrlData.enum_participant_type,
          customLabel: (value) => value,
          defaultValue: 'true',
        },
      },

      {
        renderField() {
          return (
            <Form.Item label="Age">
              <Input.Group compact style={{ position: 'relative', width: '100%', display: 'flex' }}>
                <Form.Item name="age_from" noStyle>
                  <InputNumber placeholder="Min" min={1} style={{ width: 'calc(100% - 15px)' }} />
                </Form.Item>
                <div
                  style={{
                    position: 'relative',
                    display: 'table',
                    textAlign: 'center',
                    width: '70px',
                    color: 'rgba(0, 0, 0, 0.25)',
                    background: '#f5f5f5',
                    border: '1px solid #d9d9d9',
                    boxShadow: 'none',
                    cursor: 'not-allowed',
                    margin: '0 -1px',
                  }}
                >
                  <span style={{ display: 'table-cell', verticalAlign: 'middle' }}>-</span>
                </div>
                <Form.Item dependencies={['age_from']} noStyle>
                  {(form) => {
                    const ageFrom = form.getFieldValue('age_from');
                    return (
                      <Form.Item name="age_to" noStyle>
                        <InputNumber placeholder="Max" style={{ width: 'calc(100% - 15px)' }} min={ageFrom} />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Input.Group>
            </Form.Item>
          );
        },
        gridColumn: { xl: 12, sm: 24, xs: 24 },
      },
      {
        label: 'Gender',
        gridColumn: { xl: 12, sm: 24, xs: 24 },
        name: ['gender_type'],
        fieldType: 'selectPaginate',
        selectPaginateProps: {
          classNamePrefix: 'react-select-custom-prefix-radius',
          dataSourceUrl: ApiUrlData.enum_gender,
          isClearable: true,
          customLabel: (value) => value,
        },
      },
      {
        label: 'Marital Status',
        gridColumn: { xl: 12, sm: 24, xs: 24 },
        name: ['marital_status'],
        fieldType: 'selectPaginate',
        selectPaginateProps: {
          classNamePrefix: 'react-select-custom-prefix-radius',
          dataSourceUrl: ApiUrlData.enum_marital_status,
          isClearable: true,
          customLabel: (value) => value,
        },
      },
      {
        label: 'Date',
        name: ['date'],
        gridColumn: { xl: 12, sm: 24, xs: 24 },
        fieldType: 'dateRangePicker',
      },
      // {
      //   label: 'Time',
      //   name: ['time'],
      //   gridColumn: { xl: 12, sm: 24, xs: 24 },
      //   fieldType: 'timeRangePicker',
      //   timeRangePickerProps: {
      //     format: 'HH:mm',
      //   },
      // },
      // {
      //   renderField() {
      //     return (
      //       <Form.Item label="No of Session">
      //         <Input.Group compact style={{ position: 'relative', width: '100%', display: 'flex' }}>
      //           <Form.Item name="session_from" noStyle>
      //             <InputNumber placeholder="Min" min={1} style={{ width: 'calc(100% - 15px)' }} />
      //           </Form.Item>
      //           <div
      //             style={{
      //               position: 'relative',
      //               display: 'table',
      //               textAlign: 'center',
      //               width: '70px',
      //               color: 'rgba(0, 0, 0, 0.25)',
      //               background: '#f5f5f5',
      //               border: '1px solid #d9d9d9',
      //               boxShadow: 'none',
      //               cursor: 'not-allowed',
      //               margin: '0 -1px',
      //             }}
      //           >
      //             <span style={{ display: 'table-cell', verticalAlign: 'middle' }}>-</span>
      //           </div>
      //           <Form.Item dependencies={['session_from']} noStyle>
      //             {(form) => {
      //               const sessionFrom = form.getFieldValue('session_from');
      //               return (
      //                 <Form.Item name="session_to" noStyle>
      //                   <InputNumber placeholder="Max" style={{ width: 'calc(100% - 15px)' }} min={sessionFrom} />
      //                 </Form.Item>
      //               );
      //             }}
      //           </Form.Item>
      //         </Input.Group>
      //       </Form.Item>
      //     );
      //   },
      //   gridColumn: { xl: 12, sm: 24, xs: 24 },
      // },
      // {
      //   gridColumn: { xl: 12, sm: 24, xs: 24 },
      //   label: 'Speakers',
      //   name: ['speakers'],
      //   fieldType: 'selectPaginate',
      //   selectPaginateProps: {
      //     isMulti: true,
      //     classNamePrefix: 'react-select-custom-prefix-radius',
      //     dataSourceUrl: ApiUrlData.speaker,
      //     customFilterRequest: () => {
      //       return {
      //         is_speaker: true,
      //         statuses: ['active'],
      //       };
      //     },
      //     keySearch: 'name',
      //     customLabel(row) {
      //       return row?.name;
      //     },
      //   },
      // },

      {
        label: 'Price Type',
        gridColumn: { xl: 12, sm: 24, xs: 24 },
        name: ['price_type'],
        fieldType: 'selectPaginate',
        selectPaginateProps: {
          classNamePrefix: 'react-select-custom-prefix-radius',
          dataSourceUrl: ApiUrlData.enum_price_configuration,
          isClearable: false,
          customLabel: (value) => value,
          isMulti: true,
          transformOptions: (options) => {
            return options?.filter((item) => !item?.includes('Free Event'));
          },
        },
      },
      // {
      //   renderField() {
      //     return (
      //       <Form.Item label="Price">
      //         <Input.Group compact style={{ position: 'relative', width: '100%', display: 'flex' }}>
      //           <Form.Item name="price_from" noStyle>
      //             <InputNumber placeholder="Min" min={1} style={{ width: 'calc(100% - 15px)' }} />
      //           </Form.Item>
      //           <div
      //             style={{
      //               position: 'relative',
      //               display: 'table',
      //               textAlign: 'center',
      //               width: '70px',
      //               color: 'rgba(0, 0, 0, 0.25)',
      //               background: '#f5f5f5',
      //               border: '1px solid #d9d9d9',
      //               boxShadow: 'none',
      //               cursor: 'not-allowed',
      //               margin: '0 -1px',
      //             }}
      //           >
      //             <span style={{ display: 'table-cell', verticalAlign: 'middle' }}>-</span>
      //           </div>
      //           <Form.Item dependencies={['price_from']} noStyle>
      //             {(form) => {
      //               const priceFrom = form.getFieldValue('price_from');
      //               return (
      //                 <Form.Item name="price_to" noStyle>
      //                   <InputNumber placeholder="Max" style={{ width: 'calc(100% - 15px)' }} min={priceFrom} />
      //                 </Form.Item>
      //               );
      //             }}
      //           </Form.Item>
      //         </Input.Group>
      //       </Form.Item>
      //     );
      //   },
      //   gridColumn: { xl: 12, sm: 24, xs: 24 },
      // },
      // {
      //   renderField() {
      //     return (
      //       <Form.Item label="Max Registrants">
      //         <Input.Group compact style={{ position: 'relative', width: '100%', display: 'flex' }}>
      //           <Form.Item name="registrant_from" noStyle>
      //             <InputNumber placeholder="Min" min={1} style={{ width: 'calc(100% - 15px)' }} />
      //           </Form.Item>
      //           <div
      //             style={{
      //               position: 'relative',
      //               display: 'table',
      //               textAlign: 'center',
      //               width: '70px',
      //               color: 'rgba(0, 0, 0, 0.25)',
      //               background: '#f5f5f5',
      //               border: '1px solid #d9d9d9',
      //               boxShadow: 'none',
      //               cursor: 'not-allowed',
      //               margin: '0 -1px',
      //             }}
      //           >
      //             <span style={{ display: 'table-cell', verticalAlign: 'middle' }}>-</span>
      //           </div>
      //           <Form.Item dependencies={['registrant_from']} noStyle>
      //             {(form) => {
      //               const registrantFrom = form.getFieldValue('registrant_from');
      //               return (
      //                 <Form.Item name="registrant_to" noStyle>
      //                   <InputNumber placeholder="Max" style={{ width: 'calc(100% - 15px)' }} min={registrantFrom} />
      //                 </Form.Item>
      //               );
      //             }}
      //           </Form.Item>
      //         </Input.Group>
      //       </Form.Item>
      //     );
      //   },
      //   gridColumn: { xl: 12, sm: 24, xs: 24 },
      // },

      // {
      //   label: 'Use QR',
      //   gridColumn: { xl: 12, sm: 24, xs: 24 },
      //   name: ['use_qr'],
      //   fieldType: 'select',
      //   selectProps: {
      //     classNamePrefix: 'react-select-custom-prefix-radius',
      //     isClearable: false,
      //     options: optionsYesOrNo,
      //     customLabel: (value) => optionsYesOrNo.find((item) => item?.value === value)?.label,
      //     defaultValue: 'true',
      //   },
      // },
      {
        label: 'Attendance',
        gridColumn: { xl: 12, sm: 24, xs: 24 },
        name: ['attendances'],
        fieldType: 'selectPaginate',
        selectPaginateProps: {
          classNamePrefix: 'react-select-custom-prefix-radius',
          dataSourceUrl: ApiUrlData.enum_attendance_type,
          isClearable: false,
          customLabel: (value) => value,
          isMulti: true,
        },
      },
      {
        label: 'Status',
        name: ['statuses'],
        gridColumn: { xl: 12, sm: 24, xs: 24 },
        fieldType: 'select',
        selectProps: {
          classNamePrefix: 'react-select-custom-prefix-radius',
          options: optionsStatus,
          customLabel: (value) => optionsStatus.find((item) => item?.value === value)?.label,
          isMulti: true,
        },
      },
    ],
  },
];
