import { omit } from 'lodash';
import { UserCategoriesEntity } from '../entities';
import { BaseEntity, BaseTransformer } from '@eigen3m/react-base-frontend';
import * as _ from 'lodash';

import { transformUserCategory } from '../../presentation/helpers/transform-data';

const AccessRightKey = [
  'cancel',
  'change_status',
  'confirm_process',
  'create',
  'delete',
  'edit',
  'export',
  'import',
  'print',
  'reverse',
  'view',
  'view_log',
  'view_notes',
];

export class UserCategoriesTransformer<E extends BaseEntity = UserCategoriesEntity> extends BaseTransformer<E> {
  makeCreateAccessRight(item: any): any {
    if (!item) return {};
    const dataKey = _.pick(item, AccessRightKey);
    const accessKey = Object.keys(dataKey);
    const newAccessKey = accessKey.map((key) => {
      return {
        name: key,
        allowed: item[key],
      };
    });
    return newAccessKey;
  }

  transformerUpdate(payload: E): E {
    if (!payload) return payload;
    const newPayload = this.transformerCreate(payload);
    const cleanVal: any = omit(newPayload, ['id', 'form_action']);
    return {
      ...cleanVal,
      company: payload?.company
        ? {
            company_id: payload?.company?.id,
            company_name: payload?.company?.name,
            company_code: payload?.company?.code,
          }
        : undefined,
    };
  }

  transformerCreate(payload: E): E {
    const newAccessRight = payload?.access_right?.map((access: any) => {
      const itemNoSub = _.omit(access, [...AccessRightKey, 'children', 'group_key', 'module_key', 'module_name']);
      const transformSubModule = access?.children?.map((subModules: any) => {
        const cleanSubModule = _.omit(subModules, [
          ...AccessRightKey,
          'children',
          'group_key',
          'module_key',
          'sub_module_key',
          'module_name',
          'sub_module_name',
        ]);

        const transformActivities = subModules?.children?.map((activity: any) => {
          const cleanActivities = _.omit(activity, [
            ...AccessRightKey,
            'children',
            'group_key',
            'module_key',
            'sub_module_key',
            'activity_key',
            'module_name',
            'sub_module_name',
          ]);
          return {
            ...cleanActivities,
            access_right: this.makeCreateAccessRight(activity),
          };
        });

        return {
          ...cleanSubModule,
          access_right: this.makeCreateAccessRight(subModules),
          activities: transformActivities,
        };
      });

      return {
        ...itemNoSub,
        access_right: this.makeCreateAccessRight(access),
        sub_modules: transformSubModule,
      };
    });

    const newTransformData = {
      ...payload,
      company: undefined,
      companies: payload?.companies?.map((item) => {
        return {
          ...item,
          company_id: item?.id ?? item?.company_id,
          company_name: item?.name ?? item?.company_name,
          company_code: item?.code ?? item?.company_code,
        };
      }),
      code: payload?.code?.toUpperCase(),
      access_right: newAccessRight,
    };

    // console.log({ newAccessRight });
    // return undefined;

    // console.log(newTransformData);

    return newTransformData;
    // return undefined;
  }

  transformerDuplicate(payload: E): E {
    const data = this.transformerCreate(payload);
    return data;
  }

  transformerGetData(payload: E): E {
    if (!payload) return payload;
    const dataAccessRight = transformUserCategory(payload?.access_right);
    const newData = {
      ...payload,
      access_right: dataAccessRight,
      companies: payload?.company
        ? [
            {
              company_id: payload?.company?.id,
              company_name: payload?.company?.name,
              company_code: payload?.company?.code,
            },
          ]
        : [],
    };

    return newData;
  }
}
