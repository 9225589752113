import { DetailPageWrapper, DetailBuilder, usePageProvider } from '@eigen3m/react-base-frontend';
import { Card, Form } from 'antd';
import { useAccessRight } from 'shared/hooks';

import DetailTabs from '../components/detail-tabs';

export default function DetailPage(): JSX.Element {
  const { moduleKey } = usePageProvider();
  const { accessCreate, accessEdit, accessImport, accessChangeStatus, accessDelete, accessPrint } = useAccessRight({
    moduleKey,
  });

  const params = {
    tab_names: ['access_right'],
  };

  return (
    <DetailPageWrapper
      pageToolbarProps={{
        pageTitle: 'Detail User Categories',
        showConfirmProcess: false,
        showFilter: false,
        // showBack: false,
        showDataTypeSelector: false,

        disablePrint: !accessPrint,
        disableImport: !accessImport,
        disableNewData: !accessCreate,

        disableUpdate: !accessEdit,
        disableDelete: !accessDelete,
        disableActivate: !accessChangeStatus,
        disableDeactivate: !accessChangeStatus,
      }}
      paramGetData={params}
    >
      <Card style={{ marginBottom: 12 }}>
        <DetailBuilder
          columns={[
            {
              fields: [
                {
                  name: ['detail_data', 'code'],
                  label: 'Code',
                  fieldType: 'text',
                  // gridColumn: { span: 6 },
                },
                {
                  name: ['detail_data', 'name'],
                  label: 'Name',
                  fieldType: 'text',
                  gridColumn: { span: 6 },
                },
              ],
            },
          ]}
        />
      </Card>

      <Card>
        <Form.Item shouldUpdate noStyle>
          {() => {
            return (
              <Form.Item name={['detail_data', 'access_right']}>
                <DetailTabs />
              </Form.Item>
            );
          }}
        </Form.Item>
      </Card>
    </DetailPageWrapper>
  );
}
