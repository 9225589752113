import { ApiUrlData, subModuleKey, WebUrlData } from '@base-configs';
import { InitialValuePageProviderEntity, PageProvider, usePageProvider } from '@eigen3m/react-base-frontend';
import { ParticipantEntity } from '@admin-app/pages/events/event-activity/domain/entities';
import { makeParticipantTransformer } from '@admin-app/pages/events/event-activity/domain/factories/participant-factory';
import IndexTable from './components/index-table';

export const TabParticipant = (): JSX.Element => {
  const { id } = usePageProvider();
  const defaultFiler = { event_ids: id ? [id] : [], statuses: undefined };
  const transformer = makeParticipantTransformer<ParticipantEntity>(defaultFiler);
  const InitialValue: InitialValuePageProviderEntity<ParticipantEntity> = {
    transformer,
    moduleKey: subModuleKey.event_activity.PARTICIPANT,
    webUrl: WebUrlData.event_activity,
    apiUrl: ApiUrlData.participant,
  };

  return (
    <PageProvider initialValue={InitialValue}>
      <IndexTable />
    </PageProvider>
  );
};
