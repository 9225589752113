import { authHelper, DetailPageWrapper, RenderText, usePageProvider } from '@eigen3m/react-base-frontend';
import { Card, Form } from 'antd';
import { CubeProvider } from '@cubejs-client/react';
import { TableRenderer } from '../components/cube-query-builder/preview-query-builder/preview-chart/chart-render/tables/table-renderer';
import { cubejsApi } from '../helpers';
import { divide } from 'lodash';
import { getAccessRight } from 'shared/helpers/user-category-transformer';
import { useAccessRight } from 'shared/hooks';

export default function DetailPage() {
  const account = authHelper.getAccount();
  const isAdmin = account?.user?.username === process.env.REACT_APP_ADMIN_EMAIL;

  const { moduleKey } = usePageProvider();
  const { accessCreate, accessView, accessEdit } = useAccessRight({ moduleKey });
  // const accessRight = getAccessRight(moduleKey);
  let actionDisabled: any[] = [];

  if (!accessView) actionDisabled = [...actionDisabled, 'detail'];
  if (!accessEdit) actionDisabled = [...actionDisabled, 'update'];

  return (
    <DetailPageWrapper
      pageToolbarProps={{
        pageTitle: 'Detail Report',
        showTagStatus: false,
        showActivate: false,
        showDeactivate: false,
        showDelete: isAdmin,
        disableNewData: !accessCreate,
        disableDuplicate: !accessCreate,
        disableUpdate: !accessEdit,
        // showBack: false,
      }}
    >
      <Card>
        <Form.Item label="Report Name" name={['detail_data', 'name']}>
          <RenderText />
        </Form.Item>
      </Card>
      <div style={{ marginBottom: 10 }}></div>
      <Form.Item shouldUpdate>
        {({ getFieldValue }) => {
          const detailData = getFieldValue('detail_data');

          return (
            <Card>
              <CubeProvider cubejsApi={cubejsApi}>
                {detailData?.configuration ? (
                  <TableRenderer configuration={detailData?.configuration ?? {}} />
                ) : (
                  <div style={{ textAlign: 'center' }}>Configuration report not found.</div>
                )}
              </CubeProvider>
            </Card>
          );
        }}
      </Form.Item>
    </DetailPageWrapper>
  );
}
