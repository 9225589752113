import { ApiUrlData } from '@base-configs';
import { authHelper, FormBuilder, FormPageWrapper, usePageProvider } from '@eigen3m/react-base-frontend';
import { Form, Select, Tabs } from 'antd';
import { useRecoilState } from 'recoil';
import { UserForm } from 'shared/components';
import { mockCompanies } from '@states';
import { phoneNumberPrefixOptions } from '@helpers';
const { Option } = Select;

const prefixPhoneSelector = (
  <Form.Item name="prefix_phone" noStyle>
    <Select
      style={{ width: 70 }}
      defaultValue={'+62'}
      showSearch
      filterOption={(input, option) =>
        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
      }
    >
      {/* <Option value="+62">+62</Option> */}
      {phoneNumberPrefixOptions?.map((item, index) => {
        return (
          <Option key={index} value={item?.dial_code}>
            {item?.dial_code}
          </Option>
        );
      })}
    </Select>
  </Form.Item>
);

export default function FormPage() {
  const { isCreate, isUpdate, isDuplicate, navigation } = usePageProvider();
  const [companies] = useRecoilState(mockCompanies);
  const title = isCreate ? 'New' : isUpdate ? 'Update' : isDuplicate ? 'Duplicate' : '';

  function handleAfterUpdate(account) {
    const existAccount = authHelper.getAccount();
    const accountId = account?.id;
    const existAccountId = existAccount?.id;
    if (account && accountId === existAccountId) authHelper.updateAccount(account);
    navigation.goToDetail({ id: account.id });
  }

  function onChangeForm(item, values) {
    // logic for override form values
    // console.log({ values });
    return undefined;
  }

  const initialValues = {
    is_leader: false,
    is_hcc: false,
    is_ihc: false,
    is_icc: false,
    is_speaker: false,
    prefix_phone: '+62',
    has_join_care_group: 'false',
    has_attend_ifgf: 'false',
    company: !!companies?.length ? companies[0] : null,
    relatives: [],
  };

  return (
    <FormPageWrapper
      autoConfirmAfterAction={true}
      pageToolbarProps={{
        pageTitle: `${title} User`,
        showDataTypeSelector: false,
        // showBack: false
      }}
      onChangeFormValues={onChangeForm}
      // handleAfterUpdate={handleAfterUpdate}
      ignoreKeyUpdate={['password']}
      ignoreKeyDuplicate={['password', 'relatives', 'id']}
      formInstance={{ initialValues: initialValues }}
    >
      <UserForm isCreate={isCreate} />
    </FormPageWrapper>
  );
}
