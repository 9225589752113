import React, { useEffect, useState } from 'react';
import { ButtonNewData } from '@components/button';
import { DataTable } from '@components/table';
import { columnsDaySchedule, columnsSessionSchedule, columnsWorkshopSchedule } from '../../../../helpers';
import { DrawerScheduleForm } from './drawer-schedule.form';
import { v4 as uuidV4 } from 'uuid';
import './style.less';
import { orderBy } from 'lodash';
interface Props {
  onChange?: (value: any) => any;
  value?: any;
}
export function ScheduleListForm(props: Props) {
  const { value, onChange } = props;
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [typeDrawer, setTypeDrawer] = useState('day');
  const [actionDrawer, setActionDrawer] = useState('create');
  const [dataDrawer, setDataDrawer] = useState(null);
  const [dataSchedule, setDataSchedule] = useState([]);

  useEffect(() => {
    if (value) setDataSchedule(value);
  }, [value]);

  const onShowDrawer = () => {
    setVisibleDrawer(true);
    setTypeDrawer('day');
    setActionDrawer('create');
    setDataDrawer(null);
  };

  const handleClickDelete = (row: any) => {
    const newData = dataSchedule?.filter((item) => item?.key !== row?.key);
    onValuesChange(newData);
  };

  const handleClickUpdate = (row: any) => {
    setVisibleDrawer(true);
    setTypeDrawer('day');
    setActionDrawer('update');
    setDataDrawer(row);
  };

  const onCloseDrawer = () => {
    setVisibleDrawer(false);
  };

  const onValuesChange = (value) => {
    if (onChange) {
      onChange(value);
      setDataSchedule(value);
    }
  };

  const onChangeDrawer = (action: string, type: string, payload: any) => {
    let newData = null;
    const newPayload = {
      key: payload?.id ?? payload?.key,
      id: payload?.id,
      no: payload?.no,
      name: payload?.name,
      description: payload?.description,
      date: payload?.date,
      time: payload?.time,
      speakers: payload?.speakers,
      schedule: payload?.schedule,
    };
    const schedule = payload?.schedule;
    const dayKey = schedule?.day_key;
    const sessionKey = schedule?.session_key;
    if (type === 'day') {
      if (action === 'create') {
        const maxNumber =
          !!dataSchedule && !!dataSchedule?.length ? Math.max(...dataSchedule?.map((item) => item?.no)) : 0;
        const number = maxNumber + 1;
        newData = [
          ...dataSchedule,
          {
            ...newPayload,
            no: number,
          },
        ];
      } else if (action === 'update') {
        newData = dataSchedule?.map((item) => {
          if (item?.key !== newPayload.key) return item;
          return {
            ...newPayload,
            sessions: item?.sessions ?? [],
          };
        });
      }

      //Sorting day by date
      newData = orderBy(
        newData,
        function (item) {
          return item?.date.format('YYYYMMDD');
        },
        ['asc'],
      );
    } else if (type === 'session') {
      if (action === 'create') {
        newData = dataSchedule?.map((item) => {
          if (dayKey === item?.key) {
            const sessions = item?.sessions ?? [];
            const maxNumber = !!sessions && !!sessions?.length ? Math.max(...sessions?.map((item) => item?.no)) : 0;
            const number = maxNumber + 1;
            return {
              ...item,
              sessions: [...sessions, { ...newPayload, no: number }],
            };
          } else {
            return item;
          }
        });
      } else if (action === 'update') {
        newData = dataSchedule?.map((item) => {
          if (item?.key === dayKey) {
            const sessions = item?.sessions ?? [];
            const newSessions = sessions?.map((itemSession) => {
              if (itemSession?.key === newPayload?.key)
                return {
                  ...itemSession,
                  ...newPayload,
                };
              else return itemSession;
            });
            return {
              ...item,
              sessions: newSessions,
            };
          } else {
            return item;
          }
        });
      }
    } else if (type === 'workshop') {
      if (action === 'create') {
        newData = dataSchedule?.map((item) => {
          if (dayKey === item?.key) {
            const sessions = item?.sessions ?? [];
            const newSessions = sessions?.map((itemSession) => {
              if (itemSession?.key === sessionKey) {
                const workshops = itemSession?.workshops ?? [];
                const maxNumber =
                  !!workshops && !!workshops?.length ? Math.max(...workshops?.map((item) => item?.no)) : 0;
                const number = maxNumber + 1;
                return {
                  ...itemSession,
                  workshops: [...workshops, { ...newPayload, no: number }],
                };
              } else {
                return itemSession;
              }
            });
            return {
              ...item,
              sessions: newSessions,
            };
          } else {
            return item;
          }
        });
      } else if (action === 'update') {
        newData = dataSchedule?.map((item) => {
          if (item?.key === dayKey) {
            const sessions = item?.sessions ?? [];
            const newSessions = sessions?.map((itemSession) => {
              if (itemSession?.key === sessionKey) {
                const workshops = itemSession?.workshops ?? [];
                const newWorkshops = workshops?.map((itemWorkshop) => {
                  if (itemWorkshop?.key === newPayload?.key) {
                    return {
                      ...itemWorkshop,
                      ...newPayload,
                    };
                  } else {
                    return itemWorkshop;
                  }
                });
                return {
                  ...itemSession,
                  workshops: newWorkshops,
                };
              } else {
                return itemSession;
              }
            });
            return {
              ...item,
              sessions: newSessions,
            };
          } else {
            return item;
          }
        });
      }
    }
    if (!!newData?.length) onValuesChange(newData);
  };

  //Session
  const handleClickCreateSession = (row: any) => {
    setVisibleDrawer(true);
    setTypeDrawer('session');
    setActionDrawer('create');
    setDataDrawer({
      schedule: {
        day_key: row?.key,
        session_key: null,
        agenda_key: null,
      },
    });
  };
  const handleClickUpdateSession = (row: any) => {
    setVisibleDrawer(true);
    setTypeDrawer('session');
    setActionDrawer('update');
    setDataDrawer(row);
  };

  const handleClickDeleteSession = (row: any) => {
    const newData = dataSchedule?.map((item) => {
      const sessions = item?.sessions ?? [];
      const newSessions = sessions?.filter((itemSession) => itemSession?.key !== row?.key);
      const dayKey = row?.schedule?.day_key;
      if (item?.key === dayKey) {
        return {
          ...item,
          sessions: newSessions,
        };
      } else {
        return item;
      }
    });
    onValuesChange(newData);
  };

  //Workshop
  const handleClickCreateWorkshop = (row: any) => {
    const sessionKey = row?.key ?? row?.id;
    const agenda = !!row?.agendas && !!row?.agendas?.length ? row?.agendas[0] : null;
    const agendaKey = agenda?.key ?? agenda?.id ?? uuidV4();
    setVisibleDrawer(true);
    setTypeDrawer('workshop');
    setActionDrawer('create');
    setDataDrawer({
      schedule: {
        ...row?.schedule,
        session_key: sessionKey,
        agenda_key: agendaKey,
      },
    });
  };
  const handleClickUpdateWorkshop = (row: any) => {
    setVisibleDrawer(true);
    setTypeDrawer('workshop');
    setActionDrawer('update');
    setDataDrawer(row);
  };

  const handleClickDeleteWorkshop = (row: any) => {
    const dayKey = row?.schedule?.day_key;
    const sessionKey = row?.schedule?.session_key;
    const newData = dataSchedule?.map((item) => {
      if (item?.key === dayKey) {
        const sessions = item?.sessions ?? [];
        const newSessions = sessions?.map((itemSession) => {
          if (itemSession?.key === sessionKey) {
            const workshops = itemSession?.workshops ?? [];
            const newWorkshops = workshops?.filter((itemWorkshop) => itemWorkshop?.key !== row?.key);
            return {
              ...itemSession,
              workshops: newWorkshops,
            };
          } else {
            return itemSession;
          }
        });
        return {
          ...item,
          sessions: newSessions,
        };
      } else {
        return item;
      }
    });
    onValuesChange(newData);
  };

  return (
    <React.Fragment>
      <DrawerScheduleForm
        visible={visibleDrawer}
        action={actionDrawer}
        data={dataDrawer}
        type={typeDrawer}
        onClose={onCloseDrawer}
        onChangeDrawer={onChangeDrawer}
        days={value}
      />

      <ButtonNewData onClick={() => onShowDrawer()} style={{ marginBottom: '10px' }} />

      <DataTable
        className="antd-table-day"
        buttonActionProps={{
          actionIgnored: ['detail'],
          actionTitle: {
            create: 'New Session',
          },
          handleClickCreate(row) {
            handleClickCreateSession(row);
          },
          handleClickUpdate(row) {
            handleClickUpdate(row);
          },
          handleClickDelete(row) {
            handleClickDelete(row);
          },
        }}
        columns={columnsDaySchedule}
        dataSource={dataSchedule}
        expandable={{
          rowExpandable: (record) => (record?.sessions?.length >= 1 ? true : false),
          expandedRowRender: (record) => {
            const sessions = record?.sessions ?? [];
            return (
              <DataTable
                className="antd-table-session"
                buttonActionProps={{
                  actionIgnored: ['detail'],
                  actionTitle: {
                    create: 'New Workshop',
                  },
                  handleClickCreate(row) {
                    handleClickCreateWorkshop(row);
                  },
                  handleClickUpdate(row) {
                    handleClickUpdateSession(row);
                  },
                  handleClickDelete(row) {
                    handleClickDeleteSession(row);
                  },
                }}
                columns={columnsSessionSchedule}
                dataSource={sessions}
                pagination={false}
                expandable={{
                  rowExpandable: (record) => {
                    const workshops = record?.workshops ?? [];
                    return workshops?.length >= 1 ? true : false;
                  },
                  expandedRowRender: (recordSession) => {
                    const workshops = recordSession?.workshops ?? [];
                    return (
                      <DataTable
                        className="antd-table-workshop"
                        columns={columnsWorkshopSchedule}
                        buttonActionProps={{
                          actionIgnored: ['detail', 'create'],
                          handleClickUpdate(row) {
                            handleClickUpdateWorkshop(row);
                          },
                          handleClickDelete(row) {
                            handleClickDeleteWorkshop(row);
                          },
                        }}
                        dataSource={workshops}
                        pagination={false}
                        style={{ marginLeft: '50px' }}
                      />
                    );
                  },
                }}
              />
            );
          },
        }}
        pagination={false}
      />
    </React.Fragment>
  );
}
