import { RenderCurrency } from '@eigen3m/react-base-frontend';

export function CalculateDetail({ price, index }: any): JSX.Element {
  const priceType = price?.pricing_type;
  const filteredMainEvent = priceType.filter((item) => !item?.is_sub_event && item?.price?.price !== 0);
  const filteredSubEvent = priceType.filter((item) => item?.is_sub_event && item?.price?.price !== 0);

  return (
    <div style={{ fontSize: 13 }}>
      <h3 style={{ fontWeight: 'bold' }}>
        Participant {index + 1} : {price?.registran}
      </h3>
      {!!filteredMainEvent?.length && <h3 style={{ fontSize: 13, fontWeight: 'bold' }}>Main Event</h3>}
      {filteredMainEvent?.map((type) => {
        return (
          <div style={{ display: price?.total_invoice === 0 ? 'none' : 'flex', marginBottom: 14 }} key={type?.event_id}>
            <div style={{ marginRight: 4 }}>
              {!type?.day ? (
                <span>All Event</span>
              ) : (type?.day && type?.session) || type?.workshop ? (
                <span>
                  Day {type?.day}, Session {type?.session} {type?.workshop && `, Workshop: ${type?.workshop}`}
                </span>
              ) : (
                <span>Day {type?.day}</span>
              )}
            </div>
            <div style={{ marginRight: 4 }}>&#61;</div>
            <div style={{ display: 'flex' }}>
              <div style={{ marginRight: 2 }}>Rp.</div>
              {type?.price?.price === 0
                ? 0
                : RenderCurrency({
                    value: type?.price?.price,
                    currencyOptions: {
                      style: 'currency',
                      currency: 'USD',
                    },
                  })}
              <strong style={{ marginLeft: 4 }}>({type?.price?.name})</strong>
            </div>
          </div>
        );
      })}

      {!!filteredSubEvent?.length && <h3 style={{ fontSize: 13, fontWeight: 'bold', marginTop: 20 }}>Sub Event</h3>}
      {filteredSubEvent?.map((type) => {
        return (
          <div key={type?.event_id}>
            <div
              style={{ display: price?.total_invoice === 0 ? 'none' : 'flex', marginBottom: 14 }}
              key={type?.event_id}
            >
              <div style={{ marginRight: 4 }}>
                {!type?.day ? (
                  <span>All Event</span>
                ) : (type?.day && type?.session) || type?.workshop ? (
                  <span>
                    Day {type?.day}, Session {type?.session} {type?.workshop && `, Workshop: ${type?.workshop}`}
                  </span>
                ) : (
                  <span>Day {type?.day}</span>
                )}
              </div>
              <div style={{ marginRight: 4 }}>&#61;</div>
              <div style={{ display: 'flex' }}>
                <div style={{ marginRight: 2 }}>Rp.</div>
                {type?.price?.price === 0
                  ? 0
                  : RenderCurrency({
                      value: type?.price?.price,
                      currencyOptions: {
                        style: 'currency',
                        currency: 'USD',
                      },
                    })}
                <strong style={{ marginLeft: 4 }}>({type?.price?.name})</strong>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
