import React from 'react';
import { FormBuilder } from '@eigen3m/react-base-frontend';
import { Form } from 'antd';

export function VenueForm() {
  return (
    <FormBuilder
      className="mt-0"
      columns={[
        {
          fields: [
            {
              gridColumn: { span: 24 },
              customHidden: () => true,
              renderField() {
                return <Form.Item name="company" noStyle />;
              },
            },
            {
              label: 'Name',
              gridColumn: { span: 24 },
              name: ['name'],
              fieldType: 'inputText',
              rules: [{ required: true, message: 'Name is required!' }],
              inputTextProps: {
                placeholder: 'Input Name',
              },
            },
            {
              label: 'Address',
              gridColumn: { span: 24 },
              name: ['address'],
              fieldType: 'inputAddress',
              rules: [{ required: true, message: 'Address is required!' }],
              inputAddressProps: {
                showMapIcon: true,
                placeholder: 'Input Address',
                manualAddressConfig: {
                  showCountry: false,
                  showProvince: false,
                  showCity: false,
                  showDistrict: false,
                  showVillage: false,
                  showHamlet: false,
                  showPostalCode: false,
                },
              },
            },

            {
              label: 'Description',
              gridColumn: { span: 24 },
              name: ['address_description'],
              fieldType: 'textArea',
              textAreaProps: {
                placeholder: 'Input',
                rows: 4,
              },
            },
          ],
        },
      ]}
    />
  );
}
