export const moduleKey = {
  BANK_ACCOUNT: 'bank accounts',
  EMAIL_TEMPLATE: 'email templates',
  EMAIL_SETTING: 'settings',
  USER_CATEGORY: 'user categories',
  USER: 'users',

  SPEAKER: 'speakers',
  VENUE: 'venues',
  EVENT_CATEGORY: 'event categories',
  EVENT_DATA: 'event datas',
  EVENT_ACTIVITY: 'event activities',

  REPORT: 'reports',
};

export const subModuleKey = {
  event_activity: {
    PARTICIPANT: 'participants',
    PAYMENT: 'payments',
    PARTICIPANT_ATTENDANCE: 'participant attendances',
    OFFICER_ATTENDANCE: 'officer attendances',
  },
  event_data: {
    EVENT_INFORMATION: 'event information',
    PARTICIPANT: 'participants',
    SCHEDULE: 'schedules',
    REGISTRATION: 'registrations',
    PAYMENT: 'payment pricing',
    ATTENDANCE_OFFICER: 'officer attendances',
  },
};
