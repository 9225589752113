import React from 'react';
import { FormBuilder, makeArrayIds, Select, SelectPaginate } from '@eigen3m/react-base-frontend';
import { Button, Form, Tabs } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { FormPricing } from './form-pricing';
import { ApiUrlData } from '@base-configs';
import { FormSubPricing } from './form-sub-pricing';
import { OptionsYesOrNo } from '@helpers';
interface Props {
  dataRegistration?: any;
  dataSchedule?: any;
  dataParticipant?: any;
  dataInformation?: any;
}
export function FormPaymentAndPricing(props: Props) {
  const { dataRegistration, dataSchedule, dataParticipant, dataInformation } = props;
  const registType = dataRegistration?.registration_config?.toLowerCase();
  const days = dataSchedule?.days ?? [];
  const isPublicEvent = dataParticipant?.public_event === 'true' ? true : false;
  const eventType = dataInformation?.event_type;

  return (
    <React.Fragment>
      <Form.Item name={['payment_pricings', 'id']} hidden noStyle />
      <FormBuilder
        title="Payment & Pricing Configuration"
        columns={[
          {
            fields: [
              {
                label: 'Price & Configuration',
                gridColumn: { xl: 4, lg: 6, md: 12, sm: 24, xs: 24 },
                name: ['payment_pricings', 'price_configuration'],
                fieldType: 'selectPaginate',
                selectPaginateProps: {
                  classNamePrefix: 'react-select-custom-prefix-radius',
                  dataSourceUrl: ApiUrlData.enum_price_configuration,
                  isClearable: true,
                  key: JSON.stringify(registType),
                  transformOptions(options) {
                    const newOptions = options?.filter((item) => !item?.toLowerCase()?.includes('free'));
                    if (registType?.includes('event')) {
                      return newOptions.filter((item) => item === 'Free Event' || item === 'Price per Event');
                    } else if (registType?.includes('day')) {
                      return newOptions.filter((item) => item === 'Free Event' || item === 'Price per Day');
                    } else if (registType?.includes('session')) {
                      return newOptions.filter((item) => item === 'Free Event' || item === 'Price per Session');
                    } else {
                      return [];
                    }
                  },
                  customLabel: (value) => value,
                },
                // customHidden: () => {
                //   return true;
                // },
              },
              {
                gridColumn: { xl: 4, lg: 6, md: 12, sm: 24, xs: 24 },
                renderField() {
                  return (
                    <Form.Item label="Need Sub Pricing" name={['payment_pricings', 'pricing_sub_required']}>
                      <Select
                        options={OptionsYesOrNo}
                        classNamePrefix="react-select-custom-prefix-radius"
                        isClearable
                        customLabel={(value) => OptionsYesOrNo.find((item) => item?.value === value)?.label}
                      />
                    </Form.Item>
                  );
                },
              },
              {
                label: 'Payment Information',
                gridColumn: { span: 24 },
                name: ['payment_pricings', 'payment_information'],
                fieldType: 'textArea',
                textAreaProps: {
                  placeholder: 'Input',
                  rows: 4,
                },
              },
            ],
          },
        ]}
      />
      <Form.Item shouldUpdate noStyle>
        {(form) => {
          const pricingSubRequired = form?.getFieldValue(['payment_pricings', 'pricing_sub_required']);
          const isHiddenSubPricing = pricingSubRequired === 'true' ? true : false;
          return (
            <Tabs>
              <Tabs.TabPane tab="Main Pricing" key="1" forceRender>
                <Form.Item name={['payment_pricings', 'payment_pricing_datas']} noStyle>
                  <FormPricing optionsDay={days} registType={registType} isPublicEvent={isPublicEvent} />
                </Form.Item>
              </Tabs.TabPane>

              {isHiddenSubPricing && (
                <Tabs.TabPane tab="Sub Pricing" key="2" forceRender>
                  <Form.Item name={['payment_pricings', 'payment_pricing_subs']} noStyle>
                    <FormSubPricing
                      optionsDay={days}
                      registType={registType}
                      isPublicEvent={isPublicEvent}
                      eventType={eventType}
                    />
                  </Form.Item>
                </Tabs.TabPane>
              )}
            </Tabs>
          );
        }}
      </Form.Item>
    </React.Fragment>
  );
}
