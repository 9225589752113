import { DetailBuilder, DetailPageWrapper, usePageProvider } from '@eigen3m/react-base-frontend';
import { Card } from 'antd';
import { useAccessRight } from 'shared/hooks';
const grid = { xs: 24, sm: 10, md: 7, lg: 5, xl: 5 };

export default function DetailPage() {
  const { moduleKey } = usePageProvider();
  const { accessCreate, accessView, accessEdit, accessDelete } = useAccessRight({ moduleKey });

  return (
    <DetailPageWrapper
      pageToolbarProps={{
        pageTitle: 'Detail Template',
        disableNewData: !accessCreate,
        disableDuplicate: !accessCreate,
        disableUpdate: !accessEdit,
        disableDelete: !accessDelete,
        // showBack: false,
      }}
    >
      <Card>
        <DetailBuilder
          columns={[
            {
              title: 'INDIVIDUAL INFORMATION',
              fields: [
                {
                  gridColumn: grid,
                  label: 'Name',
                  name: ['detail_data', 'first_name'],
                  fieldType: 'text',
                  renderTextProps: {
                    defaultRender: () => '-',
                  },
                },
                {
                  gridColumn: grid,
                  label: 'Phone Number',
                  name: ['detail_data', 'phone'],
                  fieldType: 'text',
                  renderTextProps: {
                    defaultRender: () => '-',
                  },
                },

                {
                  gridColumn: grid,
                  label: 'Email',
                  name: ['detail_data', 'email'],
                  fieldType: 'text',
                  renderTextProps: {
                    defaultRender: () => '-',
                  },
                },
              ],
            },
          ]}
        />
      </Card>
    </DetailPageWrapper>
  );
}
