import { useState, useEffect } from 'react';
import { Checkbox, Table, Tabs, Spin } from 'antd';
import { RenderText, stringFormatter } from '@eigen3m/react-base-frontend';
import { changeDataActivity, changeDataModule, changeDataSubModule } from '../../helpers';
import { ColumnsType } from 'antd/lib/table';

type FormTabsProps = {
  dataTab?: any[];
  loading?: boolean;
  value?: any;
  onChange?(payload: any): any;
  dataGeneral?: any;
  tabName?: any;
};

function checkGroupKey(item: any, row: any, key: any): string {
  if (row.group_key === key) return stringFormatter.capitalizeEachWord(item);
  return ' ';
}

export default function FormTabs({ value = [], loading, onChange, tabName = [] }: FormTabsProps): JSX.Element {
  const [activeTab, setActiveTab] = useState<string>('all');
  const [dataTabValue, setDataTabValue] = useState<any[]>([]);

  function onChangeData(data: unknown) {
    if (onChange) onChange(data);
  }

  function renderCheckbox({ value, row, action }): JSX.Element {
    return (
      <Checkbox checked={value} onChange={(e) => handleChangeCheckbox({ row, value: e.target.checked, action })} />
    );
  }

  const handleChangeModule = ({ data, row, action, value }) => {
    const newData = changeDataModule({ data, row, action, value });
    onChangeData(newData);
  };

  const handleChangeSubModule = ({ data, row, action, value }) => {
    const newData = changeDataSubModule({ data, row, action, value });
    onChangeData(newData);
  };

  const handleChangeActivity = ({ data, row, action, value }) => {
    const newData = changeDataActivity({ data, row, action, value });
    onChangeData(newData);
  };

  function handleChangeCheckbox({ row, value, action }) {
    if (row.group_key === 'module') {
      handleChangeModule({ data: dataTabValue, row, action, value });
    } else if (row.group_key === 'sub_module') {
      handleChangeSubModule({ data: dataTabValue, row, action, value });
    } else if (row.group_key === 'activity') {
      handleChangeActivity({ data: dataTabValue, row, action, value });
    }
  }

  function onTabsChange(key: string) {
    setActiveTab(key);
  }

  function filterDataTable(data: any[] = [], moduleTab: string) {
    if (!data) return [];
    return data.filter((item) => item.name === moduleTab);
  }

  useEffect(() => {
    if (value) {
      const newDataValue = value;
      setDataTabValue(newDataValue);
    }
  }, [value]);

  const dataTabColumn: ColumnsType<any> = [
    {
      title: 'Module',
      ellipsis: true,
      width: 100,
      key: 'name',
      dataIndex: 'name',
      fixed: true,
      render: (item, row): JSX.Element => RenderText({ value: checkGroupKey(item, row, 'module') }),
    },
    {
      title: 'Sub Module',
      ellipsis: true,
      width: 120,
      key: 'name',
      dataIndex: 'name',
      fixed: true,
      render: (item, row): JSX.Element =>
        RenderText({
          value: checkGroupKey(item, row, 'sub_module'),
        }),
    },
    {
      title: 'Activity',
      ellipsis: true,
      width: 120,
      key: 'name',
      dataIndex: 'name',
      fixed: true,
      render: (item, row): JSX.Element =>
        RenderText({
          value: checkGroupKey(item, row, 'activity'),
        }),
    },
    {
      title: 'Create',
      align: 'center',
      ellipsis: true,
      width: 70,
      key: 'create',
      dataIndex: 'create',
      render: (item, row): JSX.Element => renderCheckbox({ value: item, row, action: 'create' }),
    },

    {
      title: 'View',
      align: 'center',
      ellipsis: true,
      width: 70,
      key: 'view',
      dataIndex: 'view',
      render: (item, row): JSX.Element => renderCheckbox({ value: item, row, action: 'view' }),
    },
    {
      title: 'Edit',
      align: 'center',
      ellipsis: true,
      width: 70,
      key: 'edit',
      dataIndex: 'edit',
      render: (item, row): JSX.Element => renderCheckbox({ value: item, row, action: 'edit' }),
    },
    {
      title: 'Delete',
      align: 'center',
      ellipsis: true,
      width: 70,
      key: 'delete',
      dataIndex: 'delete',
      render: (item, row): JSX.Element => renderCheckbox({ value: item, row, action: 'delete' }),
    },

    {
      title: 'Cancel',
      align: 'center',
      ellipsis: true,
      width: 70,
      key: 'cancel',
      dataIndex: 'cancel',
      render: (item, row): JSX.Element => renderCheckbox({ value: item, row, action: 'cancel' }),
    },

    // {
    //   title: 'Rollback',
    //   align: 'center',
    //   ellipsis: true,
    //   width: 70,
    //   key: 'reverse',
    //   dataIndex: 'reverse',
    //   render: (item, row): JSX.Element => renderCheckbox({ value: item, row, action: 'reverse' }),
    // },

    {
      title: 'Confirm',
      align: 'center',
      ellipsis: true,
      width: 70,
      key: 'confirm_process',
      dataIndex: 'confirm_process',
      render: (item, row): JSX.Element => renderCheckbox({ value: item, row, action: 'confirm_process' }),
    },

    // {
    //   title: 'Print',
    //   align: 'center',
    //   ellipsis: true,
    //   width: 70,
    //   key: 'print',
    //   dataIndex: 'print',
    //   render: (item, row): JSX.Element => renderCheckbox({ value: item, row, action: 'print' }),
    // },
    // {
    //   title: 'Export',
    //   align: 'center',
    //   ellipsis: true,
    //   width: 70,
    //   key: 'export',
    //   dataIndex: 'export',
    //   render: (item, row): JSX.Element => renderCheckbox({ value: item, row, action: 'export' }),
    // },
    // {
    //   title: 'Import',
    //   align: 'center',
    //   ellipsis: true,
    //   width: 70,
    //   key: 'import',
    //   dataIndex: 'import',
    //   render: (item, row): JSX.Element => renderCheckbox({ value: item, row, action: 'import' }),
    // },
    {
      title: 'Change Status',
      align: 'center',
      ellipsis: true,
      width: 120,
      key: 'change_status',
      dataIndex: 'change_status',
      render: (item, row): JSX.Element => renderCheckbox({ value: item, row, action: 'change_status' }),
    },
    // {
    //   title: 'View Log',
    //   align: 'center',
    //   ellipsis: true,
    //   width: 100,
    //   key: 'view_log',
    //   dataIndex: 'view_log',
    //   render: (item, row): JSX.Element => renderCheckbox({ value: item, row, action: 'view_log' }),
    // },
    // {
    //   title: 'View notes',
    //   align: 'center',
    //   ellipsis: true,
    //   width: 100,
    //   key: 'view_notes',
    //   dataIndex: 'view_notes',
    //   render: (item, row): JSX.Element => renderCheckbox({ value: item, row, action: 'view_notes' }),
    // },
  ];

  return (
    <Spin spinning={loading}>
      <Tabs tabPosition="left" onChange={onTabsChange} defaultActiveKey="all">
        {tabName?.length > 0 &&
          tabName?.map((data) => (
            <Tabs.TabPane tab={stringFormatter.capitalize(data)} key={data} forceRender>
              <Table
                style={{ minHeight: '300px' }}
                columns={dataTabColumn}
                dataSource={filterDataTable(dataTabValue, activeTab)}
                loading={loading}
                pagination={false}
                sticky={true}
                size="small"
                scroll={{ x: 'fit-content' }}
                expandable={{
                  expandedRowKeys: dataTabValue.map((data) => data?.key),
                  showExpandColumn: false,
                }}
              />
            </Tabs.TabPane>
          ))}
      </Tabs>
    </Spin>
  );
}
