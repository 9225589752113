import { InvoiceDetail } from '@components/invoice-detail';
import { Col, Form, Row } from 'antd';
import React from 'react';

export function DrawerDetail(): JSX.Element {
  return (
    <React.Fragment>
      <Form.Item name={['detail_data', 'paymentId']} noStyle hidden />
      <Form.Item name={['detail_data', 'code']} noStyle hidden />
      <Form.Item name={['detail_data', 'payment_date']} noStyle hidden />
      <Form.Item name={['detail_data', 'total_invoice']} noStyle hidden />
      <Form.Item name={['detail_data', 'is_free']} noStyle hidden />
      <Form.Item name={['detail_data', 'need_confirm']} noStyle hidden />
      <Form.Item name={['detail_data', 'total_payment']} noStyle hidden />
      <Form.Item name={['detail_data', 'payment_type']} noStyle hidden />
      <Form.Item name={['detail_data', 'payer_name']} noStyle hidden />
      <Form.Item name={['detail_data', 'no_payer_account']} noStyle hidden />
      <Form.Item name={['detail_data', 'destination_bank']} noStyle hidden />
      <Form.Item name={['detail_data', 'bank_account']} noStyle hidden />
      <Form.Item name={['detail_data', 'image_url']} noStyle hidden />
      <Form.Item name={['detail_data', 'registrans']} noStyle hidden />
      <Form.Item name={['detail_data', 'event_id']} noStyle hidden />
      <Form.Item name={['detail_data', 'status']} noStyle hidden />

      <Row gutter={[16, 4]}>
        <Col span={24}>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldsValue }) => {
              const data = getFieldsValue()?.detail_data;
              return <InvoiceDetail data={data} />;
            }}
          </Form.Item>
        </Col>
      </Row>
    </React.Fragment>
  );
}
