import { EventActivitiesDetail } from '@components/event-activities-detail';
import { Col, Form, Row } from 'antd';
import RegistrationForm from '@components/registration-form';
import { transformerGetDataRegistration } from '@admin-app/pages/events/event-data/domain/transformer/transformer.get';
import { usePageProvider } from '@eigen3m/react-base-frontend';
import { getAccessRight } from 'shared/helpers/user-category-transformer';
import { moduleKey } from '@base-configs';

export const TabInformation = (): JSX.Element => {
  const { id } = usePageProvider();
  const accessRight = getAccessRight(moduleKey.EVENT_ACTIVITY);
  const accessCreate = accessRight.create;
  const accessDetail = accessRight.view;

  return (
    <Form.Item shouldUpdate noStyle>
      {({ getFieldsValue }) => {
        const values = getFieldsValue();
        const payment_pricings = values?.payment_pricings;
        const participants = values?.participants;

        return (
          <Row gutter={24}>
            <Col xl={12} lg={12} md={24} sm={24} style={{ borderRight: '1px solid #fafafa' }} hidden={!accessDetail}>
              <EventActivitiesDetail value={values} showBanner showName />
            </Col>
            <Col xl={12} lg={12} md={24} sm={24} hidden={!accessDetail || !accessCreate}>
              <RegistrationForm
                payments={payment_pricings}
                participants={participants}
                registration={transformerGetDataRegistration(values?.registrations)}
                id={id}
                withOnlyAdmin={false}
              />
            </Col>
          </Row>
        );
      }}
    </Form.Item>
  );
};
