import { RenderImage } from '@eigen3m/react-base-frontend';
import { makeAssetUrl } from '@helpers';
import { TableColumnsType, Image } from 'antd';

export const BankColumns: TableColumnsType<any> = [
  {
    title: 'Name',
    key: 'name',
    width: 200,
    dataIndex: 'name',
    ellipsis: true,
    sorter: true,
  },
  {
    title: 'Logo',
    key: 'document_url',
    width: 200,
    dataIndex: 'document_url',
    ellipsis: true,
    render(record) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ width: '40px' }}>
            <RenderImage
              imageUrl={record}
              preview={false}
              style={{ width: '100%', textAlign: 'center', margin: '5px 0' }}
            />
          </div>
        </div>
      );
    },
  },

  // {
  //   title: 'Logo',
  //   key: 'document_url',
  //   width: 200,
  //   dataIndex: 'document_url',
  //   ellipsis: true,
  //   render(item) {
  //     if (!item) return <div>No Logo</div>;
  //     return <Image height={22} src={makeAssetUrl(item)} preview={false} />;
  //   },
  // },
];
