import moment from 'moment';
import { ColumnsType } from 'antd/lib/table';
import {
  IndexPageWrapper,
  AntdDataTable,
  stringFormatter,
  DataTablePrint,
  usePageProvider,
  RenderTag,
} from '@eigen3m/react-base-frontend';
import { ApiUrlData } from '@base-configs';
import { FormColumnProps } from '@eigen3m/react-base-frontend/dist/base/presentation/components/form-components/form-builder';
import { Card, MenuItemProps } from 'antd';
import { BiMailSend } from 'react-icons/bi';
import { OptionGender, OptionsYesOrNo } from '@helpers';
import PrintProvider, { Print as ReactPrint } from 'react-easy-print';
import { DrawerImport } from '../components/drawer-import';
import { useState } from 'react';
import { useAccessRight } from 'shared/hooks';

const statusOptions = [
  'active',
  //  'active_generated',
  'inactive',
].map((item) => {
  return {
    label: stringFormatter.capitalizeEachWord(item.split('_').join(' ')),
    value: item,
  };
});

const columns: ColumnsType<any> = [
  {
    title: 'Name',
    key: 'name',
    width: 200,
    dataIndex: 'name',
    ellipsis: true,
    sorter: true,
  },
  {
    title: 'User Category ',
    key: 'companies',
    width: 150,
    dataIndex: 'companies',
    ellipsis: true,
    sorter: false,
    render(item) {
      if (!item) return '-';
      const userCategories = item?.map((data) => data?.user_category?.name).filter(Boolean);
      return userCategories?.join(', ');
    },
  },
  {
    title: 'Event',
    key: 'event_categories',
    width: 150,
    dataIndex: 'event_categories',
    ellipsis: true,
    sorter: false,
    render(value) {
      return RenderTag({ value, renderType: 'multi', keyValue: 'name' });
    },
  },
  {
    title: 'Email',
    key: 'email',
    width: 200,
    dataIndex: 'email',
    sorter: true,
    ellipsis: true,
  },
  // {
  //   width: 150,
  //   title: 'Date of Birth',
  //   dataIndex: ['date_of_birth'],
  //   render(item) {
  //     if (!item) return '-';
  //     return moment(item).format('DD-MM-YYYY');
  //   },
  // },
  // {
  //   width: 100,
  //   title: 'Gender',
  //   dataIndex: ['gender'],
  //   render(item) {
  //     if (!item) return '-';
  //     return stringFormatter.capitalizeEachWord(item);
  //   },
  // },
  // {
  //   width: 150,
  //   title: 'Phone Number',
  //   dataIndex: ['phone_number'],
  //   render(item, row) {
  //     if (!item) return '-';
  //     return `${row?.prefix_phone ?? ''} ${item}`;
  //   },
  // },

  // {
  //   width: 100,
  //   title: 'Join Care Group?',
  //   dataIndex: ['has_join_care_group'],
  //   render(item) {
  //     if (!item) return '-';
  //     if (item === true) return 'Yes';
  //   },
  // },
  // {
  //   width: 100,
  //   title: 'Has Attend IFGF',
  //   dataIndex: ['has_attend_ifgf'],
  //   render(item) {
  //     if (!item) return '-';
  //     if (item === true) return 'Yes';
  //   },
  // },
  // {
  //   width: 100,
  //   title: 'Last Attendance',
  //   dataIndex: ['last_attendance'],
  //   render(item) {
  //     if (!item) return '-';
  //     return moment(item).format('DD-MM-YYYY HH:mm');
  //   },
  // },
  // {
  //   width: 100,
  //   title: 'Last Email Sent',
  //   dataIndex: ['last_email_Send'],
  //   render(item) {
  //     if (!item) return '-';
  //     return moment(item).format('DD-MM-YYYY HH:mm');
  //   },
  // },
];

const Filter: FormColumnProps[] = [
  {
    fields: [
      // {
      //   label: 'Gender',
      //   name: 'gender',
      //   fieldType: 'select',
      //   gridColumn: { span: 12 },
      //   selectProps: {
      //     isClearable: true,
      //     options: OptionGender,
      //     classNamePrefix: 'react-select-custom-prefix-radius',
      //     customLabel: (value) => OptionGender.find((item) => item.value === value?.toLowerCase())?.label,
      //   },
      // },
      // {
      //   label: 'Has join care group?',
      //   name: 'has_join_care_group',
      //   gridColumn: { span: 12 },
      //   fieldType: 'select',
      //   selectProps: {
      //     isClearable: true,
      //     options: OptionsYesOrNo,
      //     classNamePrefix: 'react-select-custom-prefix-radius',
      //     customLabel: (value) => OptionsYesOrNo.find((item) => item.value === value)?.label,
      //   },
      // },
      // {
      //   label: 'Care Group Leader',
      //   name: 'care_group_leader',
      //   gridColumn: { span: 12 },
      //   fieldType: 'selectPaginate',
      //   selectPaginateProps: {
      //     isMulti: true,
      //     dataSourceUrl: ApiUrlData.contact,
      //     customLabel(row) {
      //       return row?.name;
      //     },
      //     customFilterRequest: () => {
      //       return {
      //         is_leader: true,
      //       };
      //     },
      //   },
      // },
      // {
      //   label: 'Has attend IFGF?',
      //   name: 'has_attend_ifgf',
      //   gridColumn: { span: 12 },
      //   fieldType: 'select',
      //   selectProps: {
      //     isClearable: true,
      //     options: OptionsYesOrNo,
      //     classNamePrefix: 'react-select-custom-prefix-radius',
      //     customLabel: (value) => OptionsYesOrNo.find((item) => item.value === value)?.label,
      //   },
      // },
      // {
      //   label: 'Discipleship Center',
      //   name: 'discipleship_center',
      //   gridColumn: { span: 12 },
      //   fieldType: 'selectPaginate',
      //   selectPaginateProps: {
      //     isMulti: true,
      //     dataSourceUrl: ApiUrlData.region,
      //     classNamePrefix: 'react-select-custom-prefix-radius',
      //   },
      // },
      {
        label: 'User Categories',
        name: 'user_categories',
        gridColumn: { span: 12 },
        fieldType: 'selectPaginate',
        selectPaginateProps: {
          isMulti: true,
          dataSourceUrl: ApiUrlData.user_category,
          baseDataSourceUrl: process.env.REACT_APP_ACCESS_CONTROL_URL,
          classNamePrefix: 'react-select-custom-prefix-radius',
          customLabel(row) {
            return `${row?.name} (${row?.code})`;
          },
          keySearch: 'search',
        },
      },
      {
        label: 'Statuses',
        name: 'statuses',
        gridColumn: { span: 12 },
        fieldType: 'select',
        selectProps: {
          isMulti: true,
          options: statusOptions,
          classNamePrefix: 'react-select-custom-prefix-radius',
          customLabel(item) {
            return statusOptions.find((status) => status.value === item)?.label;
          },
        },
      },
      // {
      //   label: 'HCC',
      //   name: 'hcc',
      //   gridColumn: { span: 12 },
      //   fieldType: 'selectPaginate',
      //   selectPaginateProps: {
      //     isMulti: true,
      //     dataSourceUrl: ApiUrlData.contact,
      //     classNamePrefix: 'react-select-custom-prefix-radius',
      //     customLabel(row) {
      //       return row?.name;
      //     },
      //     customFilterRequest: () => {
      //       return {
      //         is_hcc: true,
      //       };
      //     },
      //   },
      // },
      // {
      //   label: 'ICC Name',
      //   name: 'icc',
      //   gridColumn: { span: 12 },
      //   fieldType: 'selectPaginate',
      //   selectPaginateProps: {
      //     isMulti: true,
      //     dataSourceUrl: ApiUrlData.contact,
      //     classNamePrefix: 'react-select-custom-prefix-radius',
      //     customLabel(row) {
      //       return row?.name;
      //     },
      //     customFilterRequest: () => {
      //       return {
      //         is_icc: true,
      //       };
      //     },
      //   },
      // },
      {
        label: 'Created at',
        name: 'created_at',
        gridColumn: { span: 12 },
        fieldType: 'dateRangePicker',
      },
      {
        label: 'Updated at',
        name: 'updated_at',
        gridColumn: { span: 12 },
        fieldType: 'dateRangePicker',
      },
    ],
  },
];
export default function IndexPage() {
  async function handleSendEmail(data) {
    console.log(data);
  }

  const additionalRowActions = (row): MenuItemProps[] => {
    const columns = [
      {
        key: 'Send Email',
        icon: <BiMailSend />,
        label: 'Send Email',
        onClick(e) {
          e.domEvent.stopPropagation();
          handleSendEmail(row);
        },
      },
    ];
    return columns;
  };

  const { moduleKey } = usePageProvider();
  const { accessCreate, accessEdit, accessImport, accessChangeStatus, accessDelete, accessView, accessPrint } =
    useAccessRight({
      moduleKey,
    });
  let actionIgnored: any[] = ['confirm_process'];
  if (!accessCreate) actionIgnored = [...actionIgnored, 'duplicate'];
  if (!accessEdit) actionIgnored = [...actionIgnored, 'update'];
  if (!accessChangeStatus) actionIgnored = [...actionIgnored, 'activate', 'deactivate'];
  if (!accessView) actionIgnored = [...actionIgnored, 'detail'];
  if (!accessDelete) actionIgnored = [...actionIgnored, 'delete'];

  return (
    <IndexPageWrapper
      pageToolbarProps={{
        pageTitle: 'User',
        disablePrint: !accessPrint,
        disableImport: !accessImport,
        disableNewData: !accessCreate,
        showConfirmProcess: false,

        disableDelete: !accessDelete,
        disableActivate: !accessChangeStatus,
        disableDeactivate: !accessChangeStatus,
        showBack: false,
      }}
      drawerFilterProp={{
        formBuilderProps: {
          columns: Filter,
        },
      }}
    >
      <Card>
        <AntdDataTable
          columns={columns as any}
          rowActionProps={{ actionIgnored: actionIgnored }}
          {...(!accessView
            ? {
                onRow: null,
              }
            : {})}
          defaultFilterRequestData={{
            is_speaker: false,
          }}
        />
      </Card>
    </IndexPageWrapper>
  );
}
