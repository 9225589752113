import { SpeakerEntity } from '../entities';
import { BaseEntity, BaseTransformer } from '@eigen3m/react-base-frontend';

export class SpeakerTransformer<E extends BaseEntity = SpeakerEntity> extends BaseTransformer<E> {
  transformerUpdate(payload: E): E {
    return this.transformerCreate(payload);
  }

  transformerCreate(payload: E): E {
    if (!payload) return payload;
    const cleanVal: any = {
      company: payload?.company,
      name: payload?.name,
      description: payload?.description,
      document_id: payload?.document_id,
      document_url: payload?.document_url,
      is_speaker: true,
      relatives: [],
    };
    return cleanVal;
  }

  transformerDuplicate(payload: any): any {
    return this.transformerCreate(payload);
  }

  transformerGetData(payload: any): any {
    if (!payload) return payload;
    const cleanVal = {
      ...payload,
      key: payload?.id,
      company: payload?.company
        ? payload?.company
        : payload?.company_id
        ? {
            id: payload?.company_id,
            code: payload?.company_code,
            name: payload?.company_name,
          }
        : null,
    };
    return cleanVal;
  }
}
