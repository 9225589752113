import React, { useEffect, useState } from 'react';
import { DataTable } from '@components/table';
import { columnsDayRegistration, columnsSessionRegistration, columnsWorkshopRegistration } from '../../../../helpers';
import { DrawerScheduleForm } from './drawer-schedule.form';
import './style.less';
import { filterColumnsByEventType } from './helpers';
import { orderBy } from 'lodash';

interface Props {
  onChange?: (value: any) => any;
  value?: any;
  eventType?: string;
  registConfig?: string;
}
export function ScheduleListForm(props: Props) {
  const { value, onChange, eventType, registConfig } = props;
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [typeDrawer, setTypeDrawer] = useState('day');
  const [actionDrawer, setActionDrawer] = useState('create');
  const [dataDrawer, setDataDrawer] = useState(null);
  const [dataSchedule, setDataSchedule] = useState([]);

  useEffect(() => {
    let newValue = value ?? [];
    if (!!newValue?.length) {
      newValue = newValue?.map((item) => {
        const newItem = {
          key: item?.key ?? item?.id ?? item.uuid,
          ...item,
        };
        return newItem;
      });
    }

    setDataSchedule(newValue);
  }, [value]);

  const onCloseDrawer = () => {
    setVisibleDrawer(false);
  };

  const onValuesChange = (value) => {
    if (onChange) {
      onChange(value);
    }
  };

  const onChangeDrawer = (action: string, type: string, payload: any) => {
    let newData = null;
    const newPayload = {
      key: payload?.id ?? payload?.key,
      id: payload?.id,
      no: payload?.no,
      name: payload?.name,
      description: payload?.description,
      date: payload?.date,
      time: payload?.time,
      speakers: payload?.speakers,
      schedule: payload?.schedule,
      registration_open: payload?.registration_open,
      registration_close: payload?.registration_close,
      event_link: payload?.event_link,
      event_code: payload?.event_code,
      event_password: payload?.event_password,
      max_registrants: payload?.max_registrants,
    };
    const schedule = payload?.schedule;
    const dayKey = schedule?.day_key;
    const sessionKey = schedule?.session_key;
    if (type === 'day') {
      if (action === 'create') {
        const maxNumber =
          !!dataSchedule && !!dataSchedule?.length ? Math.max(...dataSchedule?.map((item) => item?.no)) : 0;
        const number = maxNumber + 1;
        newData = [
          ...dataSchedule,
          {
            ...newPayload,
            no: number,
          },
        ];
      } else if (action === 'update') {
        newData = dataSchedule?.map((item) => {
          if (item?.key !== newPayload.key) return item;
          return {
            ...newPayload,
            sessions: item?.sessions ?? [],
          };
        });
      }
    } else if (type === 'session') {
      if (action === 'create') {
        newData = dataSchedule?.map((item) => {
          if (dayKey === item?.key) {
            const sessions = item?.sessions ?? [];
            const maxNumber = !!sessions && !!sessions?.length ? Math.max(...sessions?.map((item) => item?.no)) : 0;
            const number = maxNumber + 1;
            return {
              ...item,
              sessions: [...sessions, { ...newPayload, no: number }],
            };
          } else {
            return item;
          }
        });
      } else if (action === 'update') {
        newData = dataSchedule?.map((item) => {
          if (item?.key === dayKey) {
            const sessions = item?.sessions ?? [];
            const newSessions = sessions?.map((itemSession) => {
              if (itemSession?.key === newPayload?.key)
                return {
                  ...itemSession,
                  ...newPayload,
                };
              else return itemSession;
            });
            return {
              ...item,
              sessions: newSessions,
            };
          } else {
            return item;
          }
        });
      }
    } else if (type === 'workshop') {
      if (action === 'create') {
        newData = dataSchedule?.map((item) => {
          if (dayKey === item?.key) {
            const sessions = item?.sessions ?? [];
            const newSessions = sessions?.map((itemSession) => {
              if (itemSession?.key === sessionKey) {
                const workshops = itemSession?.workshops ?? [];
                const maxNumber =
                  !!workshops && !!workshops?.length ? Math.max(...workshops?.map((item) => item?.no)) : 0;
                const number = maxNumber + 1;
                return {
                  ...itemSession,
                  workshops: [...workshops, { ...newPayload, no: number }],
                };
              } else {
                return itemSession;
              }
            });
            return {
              ...item,
              sessions: newSessions,
            };
          } else {
            return item;
          }
        });
      } else if (action === 'update') {
        newData = dataSchedule?.map((item) => {
          if (item?.key === dayKey) {
            const sessions = item?.sessions ?? [];
            const newSessions = sessions?.map((itemSession) => {
              if (itemSession?.key === sessionKey) {
                const workshops = itemSession?.workshops ?? [];
                const newWorkshops = workshops?.map((itemWorkshop) => {
                  if (itemWorkshop?.key === newPayload?.key) {
                    return {
                      ...itemWorkshop,
                      ...newPayload,
                    };
                  } else {
                    return itemWorkshop;
                  }
                });
                return {
                  ...itemSession,
                  workshops: newWorkshops,
                };
              } else {
                return itemSession;
              }
            });
            return {
              ...item,
              sessions: newSessions,
            };
          } else {
            return item;
          }
        });
      }
    }
    if (!!newData?.length) onValuesChange(newData);
  };

  const handleClickUpdate = (row: any) => {
    setVisibleDrawer(true);
    setTypeDrawer('day');
    setActionDrawer('update');
    setDataDrawer(row);
  };

  const handleClickUpdateSession = (row: any) => {
    setVisibleDrawer(true);
    setTypeDrawer('session');
    setActionDrawer('update');
    setDataDrawer(row);
  };

  const handleClickUpdateWorkshop = (row: any) => {
    setVisibleDrawer(true);
    setTypeDrawer('workshop');
    setActionDrawer('update');
    setDataDrawer(row);
  };

  const hideUpdate = registConfig === 'registration per event';
  let actionIgnored: any[] = ['detail', 'create', 'delete'];
  if (hideUpdate) actionIgnored = [...actionIgnored, 'update'];

  return (
    <React.Fragment>
      <DrawerScheduleForm
        visible={visibleDrawer}
        action={actionDrawer}
        data={dataDrawer}
        type={typeDrawer}
        onClose={onCloseDrawer}
        onChangeDrawer={onChangeDrawer}
        eventType={eventType?.toLowerCase()}
        registConfig={registConfig}
      />

      <DataTable
        className="antd-table-day"
        buttonActionProps={{
          // actionIgnored: ['detail', 'create', 'delete'],
          showAction: [
            'registration per event',
            'registration per each session',
            'registration manually by admin',
          ].includes(registConfig)
            ? false
            : true,
          actionIgnored: actionIgnored,
          handleClickUpdate(row) {
            handleClickUpdate(row);
          },
        }}
        columns={filterColumnsByEventType({ columns: columnsDayRegistration, type: eventType })}
        dataSource={dataSchedule}
        expandable={{
          rowExpandable: (record) => (record?.sessions?.length >= 1 ? true : false),
          expandedRowRender: (record) => {
            const sessions = record?.sessions ?? [];
            return (
              <DataTable
                className="antd-table-session"
                buttonActionProps={{
                  actionIgnored: ['detail', 'create', 'delete'],
                  showAction: [
                    'registration per event',
                    'registration per each day',
                    'registration manually by admin',
                  ].includes(registConfig)
                    ? false
                    : true,
                  handleClickUpdate(row) {
                    handleClickUpdateSession(row);
                  },
                }}
                columns={filterColumnsByEventType({ columns: columnsSessionRegistration, type: eventType })}
                dataSource={sessions}
                pagination={false}
                expandable={{
                  rowExpandable: (record) => {
                    const workshops = record?.workshops ?? [];
                    return workshops?.length >= 1 ? true : false;
                  },
                  expandedRowRender: (recordSession) => {
                    const workshops = recordSession?.workshops ?? [];
                    return (
                      <DataTable
                        className="antd-table-workshop"
                        columns={filterColumnsByEventType({ columns: columnsWorkshopRegistration, type: eventType })}
                        buttonActionProps={{
                          actionIgnored: ['detail', 'create', 'delete'],
                          showAction: [
                            'registration per event',
                            'registration per each day',
                            'registration manually by admin',
                          ].includes(registConfig)
                            ? false
                            : true,
                          handleClickUpdate(row) {
                            handleClickUpdateWorkshop(row);
                          },
                        }}
                        dataSource={workshops}
                        pagination={false}
                        style={{ marginLeft: '50px' }}
                      />
                    );
                  },
                }}
              />
            );
          },
        }}
        pagination={false}
        style={{ borderColor: 'black' }}
      />
    </React.Fragment>
  );
}
