import { omit } from 'lodash';
import { BankEntity } from '../entities';
import { BaseEntity, BaseTransformer } from '@eigen3m/react-base-frontend';

export class BankTransformer<E extends BaseEntity = BankEntity> extends BaseTransformer<E> {
  transformerUpdate(payload: E): E {
    if (!payload) return payload;
    const cleanVal: any = omit(payload, ['form_action']);
    return cleanVal;
  }

  transformerCreate(payload: E): E {
    const cleanVal = this.transformerUpdate(payload);
    return cleanVal;
  }

  transformerDuplicate(payload: E): E {
    return this.transformerCreate(payload);
  }

  transformerGetData(payload: E): E {
    return {
      ...payload,
      data_id: payload?.id,
      field_type: 'bank',
      requested_data: payload?.requested_data
        ? {
            ...payload.requested_data,
            data_id: payload?.id,
            field_type: 'bank',
          }
        : undefined,
    };
  }

  transformerGetIndex(payload: E[]): E[] {
    return payload.map((item) => {
      return {
        ...item,
        field_type: 'bank',
        data_id: item.id,
        requested_data: item?.requested_data
          ? { ...item.requested_data, data_id: item.id, field_type: 'bank' }
          : undefined,
      };
    });
  }
}
