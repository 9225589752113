import React from 'react';
import { FormBuilder } from '@eigen3m/react-base-frontend';
import { ApiUrlData } from '@base-configs';
import { Form, Checkbox, Tabs, Button } from 'antd';
import { ScheduleListForm } from './schedule.form';
import { AntdIconWrapper } from '@eigen3m/react-base-frontend';
import { ArrowsCounterClockwise } from 'phosphor-react';
import moment from 'moment';

interface Props {
  dataInformation?: any;
  dataParticipant?: any;
  dataSchedule?: any;
  dataRegistration?: any;
  setFieldValue?: any;
}
export function FormRegistration(props: Props) {
  const { dataInformation, dataParticipant, dataSchedule, dataRegistration, setFieldValue } = props;
  const eventType = dataInformation?.event_type;
  const dayHeld = !!dataSchedule?.days && !!dataSchedule?.days?.length ? dataSchedule?.days?.length : 0;
  const days = dataSchedule?.days ?? [];
  const hasMoreThanOneEventDay = dayHeld > 1 ? true : false;
  const hasMoreThanOneEventSession = days?.some((item) => item?.sessions?.length > 1);
  const registrationOpen = dataRegistration?.registration_information?.registration_open;
  const registConfig = dataRegistration?.registration_config;

  const hasGender = !!dataParticipant?.gender;
  const hasMaritalStatus = !!dataParticipant?.marital_status;
  const hasAge = !!dataParticipant?.age_min || !!dataParticipant?.age_max;

  const hasDepartment = dataInformation?.department ? true : false;

  function disableDate(date: any, registOpen: moment.Moment): boolean {
    if (!registOpen) return false;
    else if (date < registOpen) return true;
    return false;
  }

  const generateRegistration = ({ days, setFieldValue }: { days: any[]; setFieldValue: any }): void => {
    setFieldValue(['registrations', 'registration_details'], days);
  };

  return (
    <React.Fragment>
      <Form.Item name={['registrations', 'id']} hidden noStyle />
      <Form.Item shouldUpdate noStyle>
        {({ getFieldValue }) => {
          const registrationConfig = getFieldValue(['registrations', 'registration_config']);

          return (
            <FormBuilder
              title="Registration Configuration"
              columns={[
                {
                  fields: [
                    {
                      label: 'Registration Configuration',
                      name: ['registrations', 'registration_config'],
                      gridColumn: { xxl: 5, xl: 8, lg: 10, md: 12, sm: 24, xs: 24 },
                      fieldType: 'selectPaginate',
                      selectPaginateProps: {
                        key: JSON.stringify([dataSchedule, dayHeld, hasDepartment]),
                        classNamePrefix: 'react-select-custom-prefix-radius',
                        dataSourceUrl: ApiUrlData.enum_registration_type,
                        customLabel: (value) => value,
                        transformOptions(options) {
                          const newOptions = options?.filter((item) => !item?.toLowerCase()?.includes('admin'));
                          if (hasDepartment) {
                            return newOptions?.filter((item) => item?.toLowerCase()?.includes('event'));
                          } else if (hasMoreThanOneEventDay && !hasMoreThanOneEventSession) {
                            return newOptions?.filter((item) => item !== 'Registration per Each Session');
                          } else if (!hasMoreThanOneEventDay && hasMoreThanOneEventSession) {
                            return newOptions?.filter((item) => !['Registration per Each Day'].includes(item));
                          } else if (!hasMoreThanOneEventDay && !hasMoreThanOneEventSession) {
                            return newOptions?.filter(
                              (item) => !['Registration per Each Session', 'Registration per Each Day'].includes(item),
                            );
                          }
                          return newOptions;
                        },
                      },
                      rules: [{ required: true, message: 'Registration Configuration is required.' }],
                    },
                    {
                      label: 'Only Admin',
                      gridColumn: { xxl: 19, xl: 16, lg: 14, md: 12, sm: 24, xs: 24 },
                      name: ['registrations', 'only_admin'],
                      fieldType: 'checkbox',
                      valuePropName: 'checked',
                    },
                    {
                      label: 'Registration Description',
                      gridColumn: { span: 24 },
                      name: ['registrations', 'description'],
                      fieldType: 'textArea',
                      textAreaProps: {
                        placeholder: 'Input',
                        rows: 3,
                      },
                    },
                    {
                      label: 'Registration Open',
                      gridColumn: { xl: 4, lg: 4, md: 8, sm: 24 },
                      name: ['registrations', 'registration_information', 'registration_open'],
                      rules: [
                        {
                          required:
                            !!registrationConfig && registrationConfig.toLowerCase() === 'registration per event',
                          message: 'Registration Open is required',
                        },
                      ],
                      fieldType: 'datePicker',
                      datePickerProps: {
                        style: { width: '100%' },
                        // showTime: true,
                        format: 'DD-MM-YYYY',
                      },
                      customHidden(form) {
                        const registConfig = form.getFieldValue(['registrations', 'registration_config']);
                        return !!registConfig && registConfig?.toLowerCase() === 'registration per event'
                          ? false
                          : true;
                      },
                    },
                    {
                      label: 'Registration Close',
                      gridColumn: { xl: 4, lg: 4, md: 8, sm: 24 },
                      name: ['registrations', 'registration_information', 'registration_close'],
                      rules: [
                        {
                          required:
                            !!registrationConfig && registrationConfig.toLowerCase() === 'registration per event',
                          message: 'Registration Close is required',
                        },
                      ],
                      fieldType: 'datePicker',
                      datePickerProps: {
                        style: { width: '100%' },
                        // showTime: true,
                        format: 'DD-MM-YYYY',
                        disabledDate(date) {
                          return disableDate(date, registrationOpen);
                        },
                      },
                      customHidden(form) {
                        const registConfig = form.getFieldValue(['registrations', 'registration_config']);
                        return !!registConfig && registConfig?.toLowerCase() === 'registration per event'
                          ? false
                          : true;
                      },
                    },
                    {
                      label: 'Max Registrants',
                      gridColumn: { xl: 4, lg: 4, md: 8, sm: 24 },
                      name: ['registrations', 'registration_information', 'max_registrants'],
                      rules: [
                        {
                          required:
                            !!registrationConfig && registrationConfig.toLowerCase() === 'registration per event',
                          message: 'Max Registrants is required',
                        },
                      ],
                      fieldType: 'inputNumber',
                      customHidden(form) {
                        const registConfig = form.getFieldValue(['registrations', 'registration_config']);
                        return !!registConfig && registConfig?.toLowerCase() === 'registration per event'
                          ? false
                          : true;
                      },
                    },
                    {
                      label: 'Event Link',
                      gridColumn: { xl: 4, lg: 4, md: 8, sm: 24 },
                      name: ['registrations', 'registration_information', 'event_link'],
                      rules: [
                        {
                          required:
                            !!eventType &&
                            eventType?.toLowerCase() === 'online' &&
                            !!registrationConfig &&
                            registrationConfig.toLowerCase() === 'registration per event',
                          message: 'Event Link is required',
                        },
                      ],
                      fieldType: 'inputText',
                      customHidden(form) {
                        const registConfig = form.getFieldValue(['registrations', 'registration_config']);
                        return !!registConfig && registConfig?.toLowerCase() === 'registration per event'
                          ? !!eventType && eventType?.toLowerCase() === 'online'
                            ? false
                            : true
                          : true;
                      },
                    },
                    {
                      label: 'Event Code',
                      gridColumn: { xl: 4, lg: 4, md: 8, sm: 24 },
                      name: ['registrations', 'registration_information', 'event_code'],
                      rules: [
                        {
                          required:
                            !!eventType &&
                            eventType?.toLowerCase() === 'online' &&
                            !!registrationConfig &&
                            registrationConfig.toLowerCase() === 'registration per event',
                          message: 'Event Code is required',
                        },
                      ],
                      fieldType: 'inputText',
                      customHidden(form) {
                        const registConfig = form.getFieldValue(['registrations', 'registration_config']);
                        return !!registConfig && registConfig?.toLowerCase() === 'registration per event'
                          ? !!eventType && eventType?.toLowerCase() === 'online'
                            ? false
                            : true
                          : true;
                      },
                    },
                    {
                      label: 'Event Password',
                      gridColumn: { xl: 4, lg: 4, md: 8, sm: 24 },
                      name: ['registrations', 'registration_information', 'event_password'],
                      rules: [
                        {
                          required:
                            !!eventType &&
                            eventType?.toLowerCase() === 'online' &&
                            !!registrationConfig &&
                            registrationConfig.toLowerCase() === 'registration per event',
                          message: 'Event Password is required',
                        },
                      ],
                      fieldType: 'inputText',
                      customHidden(form) {
                        const registConfig = form.getFieldValue(['registrations', 'registration_config']);
                        return !!registConfig && registConfig?.toLowerCase() === 'registration per event'
                          ? !!eventType && eventType?.toLowerCase() === 'online'
                            ? false
                            : true
                          : true;
                      },
                    },
                  ],
                },
              ]}
            />
          );
        }}
      </Form.Item>

      {!registConfig?.includes('Event') && (
        <Tabs>
          <Tabs.TabPane tab="Days to close the registration">
            <>
              <Button
                icon={
                  <AntdIconWrapper>
                    <ArrowsCounterClockwise />
                  </AntdIconWrapper>
                }
                onClick={() => generateRegistration({ days, setFieldValue })}
                style={{ marginBottom: '10px' }}
              >
                Generate Registration
              </Button>
              <Form.Item name={['registrations', 'registration_details']} noStyle>
                <ScheduleListForm eventType={eventType?.toLowerCase()} registConfig={registConfig?.toLowerCase()} />
              </Form.Item>
            </>
          </Tabs.TabPane>
        </Tabs>
      )}
      <FormBuilder
        columns={[
          {
            title: 'Registration Form Configuration',
            fields: [
              {
                gridColumn: { span: 24 },
                renderField() {
                  return <div style={{ marginBottom: '10px' }}>Field checked will be required</div>;
                },
              },
              {
                gridColumn: { span: 24 },
                renderField: () => {
                  return (
                    <Form.Item name={['registrations', 'title_name_required']} noStyle valuePropName="checked">
                      <Checkbox>Title and Name</Checkbox>
                    </Form.Item>
                  );
                },
              },
              {
                gridColumn: { span: 24 },
                renderField: () => {
                  return (
                    <Form.Item name={['registrations', 'date_of_birth_required']} noStyle valuePropName="checked">
                      <Checkbox disabled={hasAge}>Date of Birth</Checkbox>
                    </Form.Item>
                  );
                },
              },
              {
                gridColumn: { span: 24 },
                renderField: () => {
                  return (
                    <Form.Item name={['registrations', 'gender_required']} noStyle valuePropName="checked">
                      <Checkbox disabled={hasGender}>Gender</Checkbox>
                    </Form.Item>
                  );
                },
              },
              {
                gridColumn: { span: 24 },
                renderField: () => {
                  return (
                    <Form.Item name={['registrations', 'email_address_required']} noStyle valuePropName="checked">
                      <Checkbox disabled>Email Address</Checkbox>
                    </Form.Item>
                  );
                },
              },
              {
                gridColumn: { span: 24 },
                renderField: () => {
                  return (
                    <Form.Item name={['registrations', 'mobile_phone_required']} noStyle valuePropName="checked">
                      <Checkbox>Mobile Phone</Checkbox>
                    </Form.Item>
                  );
                },
              },
              {
                gridColumn: { span: 24 },
                renderField: () => {
                  return (
                    <Form.Item name={['registrations', 'marital_status_required']} noStyle valuePropName="checked">
                      <Checkbox disabled={hasMaritalStatus}>Marital Status</Checkbox>
                    </Form.Item>
                  );
                },
              },
            ],
          },
        ]}
      />
    </React.Fragment>
  );
}
