export const AuthWebUrl = {
  template: '/admin/template',
  users: '/admin/users',
  report: '/admin/report',
  qr: '/admin/qr-code',
  attendance: '/admin/attendances',

  //configuration
  bank_accounts: '/admin/configuration/bank-accounts',
  email_setting: '/admin/configuration/email-setting',
  email_template: '/admin/configuration/email-template',
  user_categories: '/admin/configuration/user-categories',

  //event
  speaker: '/admin/event/speaker',
  venue: '/admin/event/venue',
  event_data: '/admin/event/event-data',
  even_category: '/admin/event/event-category',
  event_activity: '/admin/event/event-activity',
  user_category: '/admin/event/user-category',
  setting: '/admin/event/setting',
};
