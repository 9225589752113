import { FormPageWrapper, usePageProvider } from '@eigen3m/react-base-frontend';
import { mockCompanies } from '@states';
import { Form, FormInstance } from 'antd';
import { useState } from 'react';
import { useRecoilState } from 'recoil';
import { GrNext, GrPrevious } from 'react-icons/gr';

import { StepField } from '../components/step/step-field';
import { STEPKEY, stepContent, validationFormByStepper } from '../helpers';

const stepItem = stepContent?.map((content) => ({
  key: content?.title,
  title: content?.title,
  // description: content?.description,
}));

function onChangeForm(item, values) {
  let newValues = null;
  if (Object.keys(item).includes('company')) {
  } else {
    const subKey: any = Object.keys(item)[0];
    if (Object.values(STEPKEY).includes(subKey)) {
      if (Object.keys(item[subKey]).includes('department')) {
        const information = values?.information;
        const registrations = values?.registrations;
        const paymentPricings = values?.payment_pricings;
        const hasDepartment = !!information?.department ? true : false;
        newValues = {
          ...values,
          information: {
            ...information,
            department_class: null,
          },
          registrations: {
            ...registrations,
            registration_config: hasDepartment ? 'Registration per Event' : registrations?.registration_config,
            registration_details: hasDepartment ? [] : registrations?.registration_details,
          },
          payment_pricings: {
            ...paymentPricings,
            payment_pricing_datas: hasDepartment ? [] : paymentPricings?.payment_pricing_datas,
            payment_pricing_subs: hasDepartment ? [] : paymentPricings?.payment_pricing_subs,
          },
        };
      } else if (Object.keys(item[subKey]).includes('event_type')) {
        const information = values?.information;
        newValues = {
          ...values,
          information: {
            ...information,
            venue: null,
          },
        };
      } else if (Object.keys(item[subKey]).includes('gender')) {
        const participant = values?.participants;
        const registrations = values?.registrations;
        const hasGender = !!participant?.gender;
        newValues = {
          ...values,
          registrations: {
            ...registrations,
            gender_required: hasGender,
          },
        };
      } else if (Object.keys(item[subKey]).includes('participant_type')) {
        const participants = values?.participants;
        newValues = {
          ...values,
          participants: {
            ...participants,
            departments: [],
            ministries: [],
          },
        };
      } else if (Object.keys(item[subKey]).includes('marital_status')) {
        const participant = values?.participants;
        const registrations = values?.registrations;
        const hasMaritalStatus = !!participant?.marital_status;
        newValues = {
          ...values,
          registrations: {
            ...registrations,
            marital_status_required: hasMaritalStatus,
          },
        };
      } else if (Object.keys(item[subKey]).includes('registration_config')) {
        const subValues = values[subKey];
        const paymentPricings = values?.payment_pricings;
        const registConfig = subValues?.registration_config?.toLowerCase();
        let priceConfig = null;
        if (!!registConfig) {
          if (registConfig?.includes('event')) {
            priceConfig = 'Price per Event';
          } else if (registConfig?.includes('day')) {
            priceConfig = 'Price per Day';
          } else if (registConfig?.includes('session')) {
            priceConfig = 'Price per Session';
          }
        }
        newValues = {
          ...values,
          registrations: {
            ...subValues,
            registration_details: [],
            registration_open: null,
            registration_close: null,
            max_registrants: null,
          },
          payment_pricings: {
            ...paymentPricings,
            price_configuration: priceConfig,
            pricing_sub_required: 'false',
            payment_pricing_datas: [],
            payment_pricing_subs: [],
          },
        };
      } else if (Object.keys(item[subKey]).includes('departments')) {
        const participants = values?.participants;
        newValues = {
          ...values,
          participants: {
            ...participants,
            ministries: null,
          },
        };
      } else if (Object.keys(item[subKey]).includes('age_min') || Object.keys(item[subKey]).includes('age_max')) {
        const participant = values?.participants;
        const registrations = values?.registrations;
        const hasAge = !!participant?.age_min || !!participant?.age_max;

        newValues = {
          ...values,
          registrations: {
            ...registrations,
            date_of_birth_required: hasAge,
          },
        };
      } else if (Object.keys(item[subKey]).includes('price_configuration')) {
        const paymentPricings = values?.payment_pricings;
        newValues = {
          ...values,
          payment_pricings: {
            ...paymentPricings,
            payment_pricing_datas: [],
            payment_pricing_subs: [],
          },
        };
      } else if (Object.keys(item[subKey]).includes('pricing_sub_required')) {
        const paymentPricings = values?.payment_pricings;
        newValues = {
          ...values,
          payment_pricings: {
            ...paymentPricings,
            payment_pricing_subs: [],
          },
        };
      } else if (Object.keys(item[subKey]).includes('attendance_configuration')) {
        const attendanceOfficers = values?.attendance_officers;
        const attendanceConfig = attendanceOfficers?.attendance_configuration;
        let useQr = null;
        if (attendanceConfig?.toLowerCase()?.includes('none')) {
          useQr = 'false';
        } else {
          useQr = 'true';
        }
        newValues = {
          ...values,
          attendance_officers: {
            ...attendanceOfficers,
            use_qr: useQr,
            officer_datas: [],
          },
        };
      }
      // else if (Object.keys(item[subKey]).includes('use_qr')) {
      //   const attendanceOfficers = values?.attendance_officers;
      //   const useQr = attendanceOfficers?.use_qr;
      //   newValues = {
      //     ...values,
      //     attendance_officers: {
      //       ...attendanceOfficers,
      //       officer_datas: useQr === 'false' ? [] : attendanceOfficers?.officer_datas,
      //     },
      //   };
      // }
    }
  }
  return newValues;
}
export default function FormStep(): JSX.Element {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [companies] = useRecoilState(mockCompanies);
  const [form, setForm] = useState<FormInstance>(undefined);

  const { isCreate, isUpdate, isDuplicate } = usePageProvider();
  const title = isCreate ? 'New' : isUpdate ? 'Update' : isDuplicate ? 'Duplicate' : '';

  async function nextStep(): Promise<void> {
    const passValidation = await validationFormByStepper({ form, step: currentStep });
    if (passValidation) {
      setCurrentStep((prevState) => prevState + 1);
    }
  }

  function previousStep(): void {
    setCurrentStep((prevState) => prevState - 1);
  }

  return (
    <FormPageWrapper
      pageToolbarProps={{
        pageTitle: `${title} Event Data`,
        showDataTypeSelector: false,
        showTagStatus: isCreate || isDuplicate ? false : true,
        showSave: currentStep === stepContent.length - 1,
        additionalButtonAction: [
          {
            icon: <GrPrevious />,
            children: <span>Previous</span>,
            handleClick: () => previousStep(),
            hidden: currentStep <= 0,
          },
          {
            children: (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: 8 }}>Next</span>
                <GrNext />
              </div>
            ),
            handleClick: () => nextStep(),
            hidden: currentStep === stepContent.length - 1,
          },
        ],
      }}
      ignoreKeyDuplicate={['status', 'information.department_class', 'information.department_class_id']}
      formInstance={{
        initialValues: {
          company: !!companies?.length ? companies[0] : null,
          registrations: {
            email_address_required: true,
            mobile_phone_required: true,
            title_name_required: true,
          },
        },
      }}
      autoConfirmAfterAction={isUpdate ? true : false}
      onChangeFormValues={onChangeForm}
      getFormInstance={setForm}
    >
      <Form.Item name="company" hidden noStyle />

      <Form.Item shouldUpdate noStyle>
        {({ setFieldValue }) => {
          return (
            <StepField
              setFieldValue={setFieldValue}
              currentStep={currentStep}
              stepItem={stepItem}
              stepContent={stepContent}
            />
          );
        }}
      </Form.Item>
    </FormPageWrapper>
  );
}
