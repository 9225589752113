import { PageProvider } from '@eigen3m/react-base-frontend';
import { PageController } from '@eigen3m/react-base-frontend';
import { InitialValuePageProviderEntity } from '@eigen3m/react-base-frontend';

import PageIndex from '../pages';
import PageForm from '../pages/form';
import PageDetail from '../pages/detail';

import { UserEntity } from '../../domain/entities';
import { makeUserTransformer } from '../../domain/factories';
import { ApiUrlData, moduleKey, WebUrlData } from '@base-configs';

import { getAccessRight } from 'shared/helpers/user-category-transformer';
import { ForbiddenPage } from '@components/forbidden-page';

export default function FactoryPage() {
  const transformer = makeUserTransformer<UserEntity>();
  const InitialValue: InitialValuePageProviderEntity<UserEntity> = {
    transformer,
    moduleKey: moduleKey.USER,
    webUrl: WebUrlData.users,
    apiUrl: ApiUrlData.contact,
  };

  const access = getAccessRight(InitialValue?.moduleKey);
  if (!access.view) return <ForbiddenPage />;
  return (
    <PageProvider initialValue={InitialValue}>
      <PageController
        PageIndex={PageIndex}
        PageDetail={PageDetail}
        PageCreate={PageForm}
        PageUpdate={PageForm}
        PageDuplicate={PageForm}
      />
    </PageProvider>
  );
}
