import { Avatar, Dropdown, Layout, Menu } from 'antd';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { AntdIconWrapper, authHelper } from '@eigen3m/react-base-frontend';
import { itemMenu } from './item-menu';
import { WebUrlData } from '@base-configs';
import { SignOut, HouseLine } from 'phosphor-react';
import Logo from '@assets/images/navbar-logo.png';
import { Sidebar } from './sidebar';
import { NavbarMenu } from './navbar-menu';
import { useRecoilState } from 'recoil';
import { collapseMenu } from './atom';
import React from 'react';
const { Header } = Layout;
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

interface Props {
  children: JSX.Element;
  accessFromMainApp: boolean;
}
export function AuthLayout({ children }: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [collapsed, setCollapsed] = useRecoilState(collapseMenu);
  const account = authHelper.getAccount();
  const getUser = account?.user?.code;
  // console.log(getUser.slice(0, 3));

  async function handleLogout() {
    await authHelper.clearStorageLogout();
    navigate(WebUrlData.login);
  }

  function makeAvatarMenu() {
    return (
      <Menu style={{ width: '150px' }}>
        {/* <Menu.Item
          icon={
            <AntdIconWrapper>
              <HouseLine style={{ marginRight: 10 }} />
            </AntdIconWrapper>
          }
          onClick={() => navigate('/')}
        >
          Events
        </Menu.Item> */}
        <Menu.Item icon={<SignOut />} onClick={() => handleLogout()}>
          Logout
        </Menu.Item>
      </Menu>
    );
  }

  return (
    <div className={`main-app-wrapper ${collapsed ? 'main-app-wrapper-collapsed' : 'main-app-wrapper-no-collapsed'}`}>
      <Layout>
        <Sidebar />

        <Layout>
          <Layout
            style={{
              minHeight: '100vh',
              paddingLeft: collapsed ? 80 : 250,
              // background: '#fff'
            }}
          >
            <Header className={`main-app-header ${collapsed ? 'collapsed' : 'no-collapsed'}`}>
              <div>
                {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                  className: 'trigger',
                  style: { fontSize: 14 },
                  onClick: () => setCollapsed(!collapsed),
                })}
              </div>

              <NavbarMenu />
              <div className="navbar-right-content">
                <div className="navbar-right-item">
                  <Dropdown overlay={makeAvatarMenu()} placement="bottomLeft" arrow={{ pointAtCenter: true }}>
                    <div>
                      <Avatar className="custom-avatar">{getUser?.slice(0, 3)}</Avatar>
                      <span style={{ marginLeft: 10, fontWeight: 600 }}>{account?.user?.code}</span>
                    </div>
                  </Dropdown>
                </div>
              </div>
            </Header>
            <div style={{ marginTop: '64px' }}></div>
            <div style={{ background: 'transparent' }}>{children}</div>
          </Layout>
        </Layout>
      </Layout>
    </div>
  );
}
