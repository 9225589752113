import { TableColumnsType } from 'antd';

export const columnsScheduleRegistration: TableColumnsType<any> = [
  {
    title: 'Day',
    key: 'day',
    dataIndex: 'day',
    ellipsis: true,
    render: (item) => item,
  },
  {
    title: 'Session',
    key: 'day',
    dataIndex: 'day',
    ellipsis: true,
    render: (item) => item,
  },
  {
    title: 'Agenda',
    key: 'agenda',
    dataIndex: 'agenda',
    ellipsis: true,
    render: (item) => item,
  },
  {
    title: 'Workshop',
    key: 'workshop',
    dataIndex: 'workshop',
    ellipsis: true,
    render: (item) => item,
  },
  {
    title: 'Description',
    key: 'description',
    dataIndex: 'description',
    ellipsis: true,
    render: (item) => item,
  },
  {
    title: 'Date',
    key: 'date',
    dataIndex: 'date',
    ellipsis: true,
    render: (item) => item,
  },
  {
    title: 'Start Time',
    key: 'start_time',
    dataIndex: 'start_time',
    ellipsis: true,
    render: (item) => item,
  },
  {
    title: 'End Time',
    key: 'end_time',
    dataIndex: 'end_time',
    ellipsis: true,
    render: (item) => item,
  },
  {
    title: 'Registration Closing Date',
    key: 'closing_date',
    dataIndex: 'closing_date',
    ellipsis: true,
    render: (item) => item,
  },
  {
    title: 'Registration Closing Time',
    key: 'workshop',
    dataIndex: 'workshop',
    ellipsis: true,
    render: (item) => item,
  },
  {
    title: 'Max Registrants',
    key: 'max_registrants',
    dataIndex: 'max_registrants',
    ellipsis: true,
    render: (item) => item,
  },
];

export const filterColumnsByEventType = ({ columns, type }: { columns: any[]; type: string }): any[] => {
  if (!!columns && !!columns?.length && type !== 'online')
    return columns?.filter((item) => !['event_code', 'event_link', 'event_password'].includes(item?.key));
  return columns;
};
