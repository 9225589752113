import { DetailPageWrapper, usePageProvider } from '@eigen3m/react-base-frontend';
import { useAccessRight } from 'shared/hooks';
import { DetailTabs } from '../components/detail/detail-tabs';

export default function DetailPage() {
  const { moduleKey, detailData } = usePageProvider();
  const { accessCreate, accessEdit, accessDelete, accessConfirmProcess } = useAccessRight({
    moduleKey,
  });

  return (
    <DetailPageWrapper
      pageToolbarProps={{
        pageTitle: 'Detail Event Data',
        showDataTypeSelector: false,
        disableNewData: !accessCreate,
        disableDuplicate: !accessCreate,
        disableDelete: !accessDelete,
        disableUpdate: !accessEdit,
        // showBack: false,
        disableConfirmProcess: !accessConfirmProcess,
        showUpdate: detailData?.status === 'closed' ? false : true,
      }}
    >
      <DetailTabs />
    </DetailPageWrapper>
  );
}
