import { capitalize, omit } from 'lodash';
import { UserEntity } from '../entities';
import { BaseEntity, BaseTransformer, makeArrayIds } from '@eigen3m/react-base-frontend';
import moment from 'moment';

const getTransformer = (payload) => {
  if (!payload) return null;
  return {
    ...payload,
    // README: Yang di comment dibawah breaking karena data yang di hasilkan sudah berupa string boolean
    // has_join_care_group: payload?.has_join_care_group === true ? 'true' : 'false',
    // has_attend_ifgf: payload?.has_attend_ifgf === true ? 'true' : 'false',
    user_category: payload?.companies?.[0]?.user_category,
    has_join_care_group: payload?.has_join_care_group ? 'true' : 'false',
    has_attend_ifgf: payload?.has_attend_ifgf ? 'true' : 'false',
    date_of_birth: payload?.date_of_birth ? moment(payload?.date_of_birth) : null,
    anniversary_date: payload?.anniversary_date ? moment(payload?.anniversary_date) : null,
    relatives: payload?.relatives?.map((item) => {
      return {
        ...item,
        key: item?.id,
        contact_relative: item?.contact_relative ? getTransformer(item?.contact_relative) : null,
      };
    }),
  };
};

export class UserTransformer<E extends BaseEntity = UserEntity> extends BaseTransformer<E> {
  transformerUpdate(payload: E): E {
    if (!payload) return payload;
    const cleanVal: any = omit(payload, ['id', 'form_action']);
    return {
      ...cleanVal,
      // README: Yang di comment dibawah breaking karena data yang di hasilkan sudah berupa string boolean
      has_join_care_group: payload?.has_join_care_group === 'true' ? true : false,
      has_attend_ifgf: payload?.has_attend_ifgf === 'true' ? true : false,
      gender: payload?.gender ? capitalize(payload?.gender) : null,
      password: payload?.password ? payload?.password : undefined,
      retype_password: payload?.retype_password ? payload?.retype_password : undefined,
      companies: [
        {
          company: {
            id: '5cae313e-aef1-42fc-9491-366a51b3984b',
            code: 'IFGF',
            name: 'International Full Gospel Fellowship',
            uuid: '5cae313e-aef1-42fc-9491-366a51b3984b',
          },
          user_category: payload?.user_category ?? null,
        },
      ],
      relatives:
        payload?.relatives?.map((item) => {
          return {
            ...item,
            contact_relative: this.transformerUpdate(item?.contact_relative),
          };
        }) ?? [],
    };
  }

  transformerCreate(payload: E): E {
    const cleanVal = this.transformerUpdate(payload);
    return cleanVal;
  }

  transformerDuplicate(payload: E): E {
    return this.transformerCreate(payload);
  }

  transformerGetData(payload: E): E {
    if (!payload) return payload;
    const data = {
      ...getTransformer(payload),
      requested_data: getTransformer(payload?.requested_data),
    };
    return data;
  }

  transformerFilterIndexTable(payload: any) {
    if (!payload) return payload;
    return {
      ...payload,
      user_categories: [],
      user_category_ids: makeArrayIds({ data: payload?.user_categories }),
      created_from: payload?.created_at?.[0] ? new Date(payload?.created_at?.[0]).valueOf() : null,
      created_to: payload?.created_at?.[1] ? new Date(payload?.created_at?.[1]).valueOf() : null,
      created_at: [],
      updated_from: payload?.updated_at?.[0] ? new Date(payload?.updated_at?.[0]).valueOf() : null,
      updated_to: payload?.updated_at?.[1] ? new Date(payload?.updated_at?.[1]).valueOf() : null,
      updated_at: [],
    };
  }
}
