import React from 'react';
import { FormBuilder } from '@eigen3m/react-base-frontend';
import { Form, Tabs } from 'antd';
import { FormOfficer } from './form-officer';
import { ApiUrlData } from '@base-configs';
import { OptionsYesOrNo } from '@helpers';
import { getSchedulePricing } from '../../../../helpers/helpers';

interface Props {
  dataInformation?: any;
  dataSchedule?: any;
  registConfig?: string;
  dataPricing?: any;
}

export function FormAttendanceOfficers(props: Props) {
  const { dataInformation, dataSchedule, registConfig, dataPricing } = props;
  const department = dataInformation?.department ?? null;
  const days = dataSchedule?.days ?? [];
  const dayHeld = !!days && !!days?.length ? days?.length : 0;
  const hasMoreThanOneEventDay = dayHeld > 1 ? true : false;
  const hasMoreThanOneEventSession = days?.some((item) => item?.sessions?.length > 1);

  const hasDay = dayHeld >= 1 ? true : false;
  const hasSession = days?.some((item) => item?.sessions?.length >= 1);

  return (
    <React.Fragment>
      <Form.Item name={['attendance_officers', 'id']} hidden noStyle />
      <FormBuilder
        title="Attendance  Configuration"
        columns={[
          {
            fields: [
              {
                label: 'How The attendance will be taken ?',
                gridColumn: { xxl: 4, xl: 6, lg: 6, md: 6, sm: 12, xs: 24 },
                name: ['attendance_officers', 'attendance_configuration'],
                fieldType: 'selectPaginate',
                rules: [{ required: true, message: 'How The attendance will be taken ? is required.' }],
                selectPaginateProps: {
                  key: registConfig,
                  dataSourceUrl: ApiUrlData.enum_attendance_type,
                  isClearable: true,
                  customLabel: (value) => value,
                  classNamePrefix: 'react-select-custom-prefix-radius',
                  transformOptions(options) {
                    const registrationConfiguration = registConfig?.toLowerCase();
                    if (registrationConfiguration?.includes('event')) {
                      if (hasDay && hasSession) {
                        return options;
                      } else if (hasDay) {
                        return options?.filter((item) => item !== 'Each Session');
                      } else {
                        return options?.filter((item) => item !== 'Each Day');
                      }
                    } else if (registrationConfiguration?.includes('day')) {
                      const newOptions = options?.filter((item) => !item?.toLowerCase()?.includes('event'));
                      if (hasDay && hasSession) {
                        return newOptions;
                      } else if (hasDay) {
                        return newOptions?.filter((item) => item !== 'Each Session');
                      } else {
                        return newOptions?.filter((item) => item !== 'Each Day');
                      }
                    } else if (registrationConfiguration?.includes('session')) {
                      const newOptions = options?.filter(
                        (item) => !item?.toLowerCase()?.includes('event') && !item?.toLowerCase()?.includes('day'),
                      );
                      if (hasSession) {
                        return newOptions;
                      } else {
                        return newOptions?.filter((item) => item !== 'Each Session');
                      }
                    } else {
                      return [];
                    }
                  },
                },
              },
              {
                label: 'Use QR Code for attendance?',
                gridColumn: { xxl: 4, xl: 4, lg: 4, md: 6, sm: 12, xs: 24 },
                name: ['attendance_officers', 'use_qr'],
                fieldType: 'select',
                rules: [{ required: false, message: 'Use QR Code for attendance? is required.' }],
                selectProps: {
                  isClearable: false,
                  classNamePrefix: 'react-select-custom-prefix-radius',
                  options: OptionsYesOrNo,
                  customLabel: (value) => OptionsYesOrNo.find((item) => item?.value === value)?.label,
                },
                customHidden(form) {
                  return true;
                  const attendanceConfig = form?.getFieldValue(['attendance_officers', 'attendance_configuration']);
                  return attendanceConfig?.toLowerCase()?.includes('none') ? true : false;
                },
              },
            ],
          },
        ]}
      />
      <Form.Item dependencies={['attendance_officers', 'attendance_configuration']} noStyle>
        {(form) => {
          const optionsDay = dataSchedule?.days ?? [];
          const optionsDayMain = dataPricing?.payment_pricing_datas?.length
            ? getSchedulePricing(dataPricing?.payment_pricing_datas)
            : [];
          const optionsDaySub = dataPricing?.payment_pricing_subs?.length
            ? getSchedulePricing(dataPricing?.payment_pricing_subs)
            : [];
          const attendanceConfig = form.getFieldValue(['attendance_officers', 'attendance_configuration']);
          const isHidden = attendanceConfig?.toLowerCase()?.includes('none') ? true : false;
          return !isHidden ? (
            <Tabs>
              <Tabs.TabPane tab="Officer" key="1" forceRender active={true}>
                <Form.Item name={['attendance_officers', 'officer_datas']} noStyle>
                  <FormOfficer
                    department={department}
                    attendanceConfig={attendanceConfig}
                    optionsDayMain={optionsDayMain}
                    optionsDaySub={optionsDaySub}
                    optionsDay={optionsDay}
                  />
                </Form.Item>
              </Tabs.TabPane>
            </Tabs>
          ) : (
            <></>
          );
        }}
      </Form.Item>
    </React.Fragment>
  );
}
