import { EventCategoryEntity } from '../entities';
import { BaseEntity, BaseTransformer } from '@eigen3m/react-base-frontend';

export class EventCategoryTransformer<E extends BaseEntity = EventCategoryEntity> extends BaseTransformer<E> {
  transformerUpdate(payload: E): E {
    return this.transformerCreate(payload);
  }

  transformerCreate(payload: E): E {
    if (!payload) return payload;
    const cleanVal: any = {
      company: payload?.company,
      name: payload?.name,
      show_to_home: payload?.show_to_home === 'yes' ? true : false,
    };
    return cleanVal;
  }

  transformerDuplicate(payload: any): any {
    return this.transformerCreate(payload);
  }

  transformerGetData(payload: any): any {
    if (!payload) return payload;
    const cleanVal = {
      key: payload?.id,
      ...payload,
      company: payload?.company
        ? payload?.company
        : payload?.company_id
        ? {
            id: payload?.company_id,
          }
        : null,
      show_to_home: payload?.show_to_home ? 'yes' : 'no',
    };
    return cleanVal;
  }
}
