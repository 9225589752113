import React from 'react';
import { FormBuilder } from '@eigen3m/react-base-frontend';
import { Form, Tabs, Checkbox } from 'antd';
import { ScheduleListForm } from './schedule.form';

export function FormSchedules() {
  return (
    <React.Fragment>
      <Form.Item name={['schedules', 'id']} hidden noStyle />
      <FormBuilder
        columns={[
          {
            fields: [
              {
                label: 'Schedule Description',
                gridColumn: { span: 24 },
                name: ['schedules', 'description'],
                fieldType: 'textArea',
                textAreaProps: {
                  placeholder: 'Input',
                  rows: 4,
                },
              },
              {
                label: 'How many days the event will be held?',
                gridColumn: { xl: 4, lg: 4, md: 24, sm: 24, xs: 24 },
                name: ['schedules', 'day_held'],
                fieldType: 'inputNumber',
                // rules: [{ required: true, message: 'Day held is required.' }],
                inputNumberProps: {
                  min: 1,
                },
                customHidden: () => {
                  return true;
                },
              },
              {
                gridColumn: { xl: 20, lg: 20, md: 24, sm: 24, xs: 24 },
                renderField() {
                  return (
                    <Form.Item label="Showing Schedules">
                      <Form.Item name={['schedules', 'showing_schedule', 'show_day']} noStyle valuePropName="checked">
                        <Checkbox>Day</Checkbox>
                      </Form.Item>
                      <Form.Item
                        name={['schedules', 'showing_schedule', 'show_session']}
                        noStyle
                        valuePropName="checked"
                      >
                        <Checkbox>Session</Checkbox>
                      </Form.Item>
                      <Form.Item
                        name={['schedules', 'showing_schedule', 'show_workshop']}
                        noStyle
                        valuePropName="checked"
                      >
                        <Checkbox>Workshop</Checkbox>
                      </Form.Item>
                    </Form.Item>
                  );
                },
                customHidden: () => {
                  return true;
                },
              },
            ],
          },
        ]}
      />
      <Tabs>
        <Tabs.TabPane tab="Schedules">
          <Form.Item name={['schedules', 'days']} noStyle>
            <ScheduleListForm />
          </Form.Item>
        </Tabs.TabPane>
      </Tabs>
    </React.Fragment>
  );
}
