import React from 'react';
import { Col, Form, Input, Row, Select, Space, Button, Card } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { PreviewQueryBuilder } from '../preview-query-builder';
import { IChartType } from '../helpers';
import { typeOf } from 'mathjs';
import { makeArrayIds } from '@eigen3m/react-base-frontend';

function makeOptions(availableMember = {}, keyMember) {
  const members = availableMember[keyMember] ?? [];
  return members.map((item) => {
    const itemMembers = item.members ?? [];
    return {
      label: item.cubeTitle,
      options: itemMembers.map((itemMember) => {
        return {
          key: JSON.stringify(itemMember),
          label: itemMember.shortTitle,
          value: itemMember.name,
        };
      }),
    };
  });
}

interface Props {
  ignoreChartType?: IChartType[];
  [key: string]: any;
}
export function CustomQueryBuilder(props: Props) {
  const { availableMembers, isFetchingMeta, ignoreChartType } = props;

  const optDimensions = makeOptions(availableMembers, 'dimensions');
  const optMeasures = makeOptions(availableMembers, 'measures');
  const optSegments = makeOptions(availableMembers, 'segments');
  const optTimeDimensions = makeOptions(availableMembers, 'timeDimensions');

  const optFilters = makeOptions(props, 'availableFilterMembers');
  const optDateRanges = [
    // { label: 'Custom', value: 'custom' },
    // { label: 'All time', value: undefined },
    { label: 'Today', value: 'Today' },
    { label: 'Yesterday', value: 'Yesterday' },
    { label: 'This week', value: 'This week' },
    { label: 'This month', value: 'This month' },
    { label: 'This quarter', value: 'This quarter' },
    { label: 'This year', value: 'This year' },
    { label: 'Last 7 days', value: 'Last 7 days' },
    { label: 'Last 30 days', value: 'Last 30 days' },
    { label: 'Last week', value: 'Last week' },
    { label: 'Last month', value: 'Last month' },
    { label: 'Last quarter', value: 'Last quarter' },
    { label: 'Last year', value: 'Last year' },
  ];

  const optGranularity = [
    // { title: 'w/o grouping' },
    { value: 'second', label: 'Second' },
    { value: 'minute', label: 'Minute' },
    { value: 'hour', label: 'Hour' },
    { value: 'day', label: 'Day' },
    { value: 'week', label: 'Week' },
    { value: 'month', label: 'Month' },
    { value: 'quarter', label: 'Quarter' },
    { value: 'year', label: 'Year' },
  ];

  const optOperator = [
    { value: 'equals', label: 'equals' },
    { value: 'notEquals', label: 'does not equal' },
    { value: 'inDateRange', label: 'in date range' },
    { value: 'notInDateRange', label: 'not in date range' },
    { value: 'afterDate', label: 'after date' },
    { value: 'beforeDate', label: 'before date' },
  ];

  const optOperatorNumber = [
    { value: 'equals', label: 'equals' },
    { value: 'notEquals', label: 'does not equal' },
    { value: 'set', label: 'is set' },
    { value: 'notSet', label: 'is not set' },
    { value: 'gt', label: '>' },
    { value: 'gte', label: '>=' },
    { value: 'lt', label: '<' },
    { value: 'lte', label: '<=' },
  ];

  const optModule = availableMembers?.dimensions?.map((event) => {
    return {
      label: event?.cubeTitle,
      value: event?.cubeTitle,
    };
  });

  function makeOptionOperator(key = '{}') {
    if (JSON.parse(key).type === 'number') return optOperatorNumber;
    return optOperator;
  }

  return (
    <React.Fragment>
      <Card>
        <Form.Item dependencies={['configuration', 'modules']} noStyle>
          {({ getFieldsValue }) => {
            const values = getFieldsValue();
            let modules = [];
            const moduleSelected = values?.configuration?.modules;
            if (Array.isArray(moduleSelected)) modules = moduleSelected;
            else if (typeOf(moduleSelected) === 'Object') modules = [moduleSelected];
            const isDisabled = !isFetchingMeta && modules?.length >= 1 ? false : true;

            const filterOptMeasures = optMeasures?.filter((x) =>
              modules?.find((y) => x?.label?.toLowerCase() === y?.label?.toLowerCase()),
            );

            const filterOptDimensions = optDimensions?.filter((x) =>
              modules?.find((y) => x?.label?.toLowerCase() === y?.label?.toLowerCase()),
            );

            const filterOptTimeDimensions = optTimeDimensions?.filter((x) =>
              modules?.find((y) => x?.label?.toLowerCase() === y?.label?.toLowerCase()),
            );

            return (
              <Row gutter={[8, 2]} wrap={true}>
                <Col {...{ xs: 24, sm: 8, md: 6, lg: 5, xl: 5 }}>
                  <Form.Item
                    label="Module"
                    name={['configuration', 'modules']}
                    rules={[{ required: true, message: 'Module is required.' }]}
                  >
                    <Select
                      allowClear
                      disabled={isFetchingMeta}
                      placeholder="Choose module"
                      maxTagCount="responsive"
                      labelInValue
                      // mode="multiple"
                      options={optModule}
                    />
                  </Form.Item>
                </Col>
                <Col {...{ xs: 24, sm: 8, md: 6, lg: 4, xl: 4 }}>
                  <Form.Item
                    label="Measures"
                    name={['configuration', 'query', 'measures']}
                    // rules={[{ required: true, message: 'Measures is required.' }]}
                  >
                    <Select
                      allowClear
                      disabled={isDisabled}
                      placeholder="Choose measures"
                      maxTagCount="responsive"
                      labelInValue
                      mode="multiple"
                      options={filterOptMeasures}
                    />
                  </Form.Item>
                </Col>
                <Col {...{ xs: 24, sm: 16, md: 12, lg: 8, xl: 8 }}>
                  <Form.Item
                    label="Dimensions"
                    name={['configuration', 'query', 'dimensions']}
                    rules={[{ required: true, message: 'Dimensions is required.' }]}
                  >
                    <Select
                      allowClear
                      disabled={isDisabled}
                      placeholder="Choose dimensions"
                      maxTagCount="responsive"
                      labelInValue
                      mode="multiple"
                      options={filterOptDimensions}
                    />
                  </Form.Item>
                </Col>
                {/* <Col {...{ xs: 24, sm: 8, md: 6, lg: 4, xl: 4 }}>
            <Form.Item label="Segments" name={['configuration', 'query', 'segments']}>
              <Select
                allowClear
                disabled={isFetchingMeta}
                placeholder="Choose segments"
                maxTagCount="responsive"
                labelInValue
                mode="multiple"
                options={optSegments}
              />
            </Form.Item>
          </Col> */}
                <Col {...{ xs: 24, sm: 8, md: 6, lg: 4, xl: 4 }}>
                  <Form.Item label="Time" name={['configuration', 'query', 'timeDimensions', 'dimension']}>
                    <Select
                      allowClear
                      disabled={isDisabled}
                      placeholder="Choose time"
                      maxTagCount="responsive"
                      labelInValue
                      options={filterOptTimeDimensions}
                    />
                  </Form.Item>
                </Col>
                <Form.Item shouldUpdate noStyle>
                  {({ getFieldValue }) => {
                    const timeDimension = getFieldValue(['configuration', 'query', 'timeDimensions', 'dimension']);
                    return (
                      <React.Fragment>
                        <Col hidden={!timeDimension} {...{ xs: 24, sm: 8, md: 4, lg: 3, xl: 2 }}>
                          <Form.Item label="Time for" name={['configuration', 'query', 'timeDimensions', 'dateRange']}>
                            <Select
                              allowClear
                              disabled={isFetchingMeta}
                              placeholder="Choose time"
                              maxTagCount="responsive"
                              labelInValue
                              options={optDateRanges}
                            />
                          </Form.Item>
                        </Col>
                        <Col hidden={!timeDimension} {...{ xs: 24, sm: 8, md: 4, lg: 3, xl: 2 }}>
                          <Form.Item label="Time by" name={['configuration', 'query', 'timeDimensions', 'granularity']}>
                            <Select
                              allowClear
                              disabled={isFetchingMeta}
                              placeholder="Choose time"
                              maxTagCount="responsive"
                              labelInValue
                              options={optGranularity}
                            />
                          </Form.Item>
                        </Col>
                      </React.Fragment>
                    );
                  }}
                </Form.Item>

                <Col {...{ span: 24 }}>
                  <Form.Item label="Filters" shouldUpdate>
                    {({ getFieldValue }) => {
                      const filterCount = getFieldValue(['configuration', 'query', 'filters']);
                      return (
                        <Form.List name={['configuration', 'query', 'filters']}>
                          {(fields, { add, remove }) => (
                            <>
                              {fields.map(({ key, name, ...restField }) => {
                                const currentItem = filterCount[key];
                                // console.log({ currentItem, filterCount, key });
                                return (
                                  <Row gutter={[8, 2]} wrap={true} key={key}>
                                    <Col {...{ xs: 24, sm: 8, md: 6, lg: 4, xl: 4 }}>
                                      <Form.Item {...restField} name={[name, 'member']}>
                                        <Select
                                          allowClear
                                          disabled={isFetchingMeta}
                                          placeholder="Choose filters"
                                          maxTagCount="responsive"
                                          labelInValue
                                          options={optFilters}
                                        />
                                      </Form.Item>
                                    </Col>

                                    <Col {...{ xs: 24, sm: 8, md: 6, lg: 4, xl: 4 }}>
                                      <Form.Item {...restField} name={[name, 'operator']}>
                                        <Select
                                          allowClear
                                          labelInValue
                                          placeholder="Choose operator"
                                          options={makeOptionOperator(currentItem?.member?.key)}
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col {...{ xs: 21, sm: 8, md: 6, lg: 4, xl: 4 }}>
                                      <Form.Item {...restField} name={[name, 'values']}>
                                        <Select allowClear labelInValue mode="tags" placeholder="Input value filter" />
                                      </Form.Item>
                                    </Col>
                                    <div style={{ marginLeft: 10, paddingTop: 6 }}>
                                      <DeleteOutlined style={{ fontSize: 16 }} onClick={() => remove(name)} />
                                    </div>
                                  </Row>
                                );
                              })}
                              <Col {...{ xs: 24, sm: 8, md: 6, lg: 4, xl: 4 }}>
                                <Form.Item>
                                  <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    Add filter
                                  </Button>
                                </Form.Item>
                              </Col>
                            </>
                          )}
                        </Form.List>
                      );
                    }}
                  </Form.Item>
                </Col>
              </Row>
            );
          }}
        </Form.Item>
      </Card>
      <div style={{ marginBottom: 10 }}></div>
      <PreviewQueryBuilder ignoreChartType={ignoreChartType} />
    </React.Fragment>
  );
}
