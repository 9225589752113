import { AntdDataTable, IndexPageWrapper, usePageProvider } from '@eigen3m/react-base-frontend';
import { Card } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useAccessRight } from 'shared/hooks';

const column: ColumnsType<any> = [
  {
    title: 'Code',
    key: 'code',
    dataIndex: 'code',
    ellipsis: true,
    sorter: true,
    width: 200,
  },
  {
    title: 'Name',
    key: 'name',
    dataIndex: 'name',
    ellipsis: true,
    sorter: true,
    width: 200,
  },
];

export default function IndexPage() {
  const { moduleKey } = usePageProvider();
  const { accessCreate, accessEdit, accessImport, accessChangeStatus, accessDelete, accessView, accessPrint } =
    useAccessRight({
      moduleKey,
    });
  let actionIgnored: any[] = ['confirm_process'];
  if (!accessCreate) actionIgnored = [...actionIgnored, 'duplicate'];
  if (!accessEdit) actionIgnored = [...actionIgnored, 'update'];
  if (!accessChangeStatus) actionIgnored = [...actionIgnored, 'activate', 'deactivate'];
  if (!accessView) actionIgnored = [...actionIgnored, 'detail'];
  if (!accessDelete) actionIgnored = [...actionIgnored, 'delete'];

  return (
    <IndexPageWrapper
      pageToolbarProps={{
        pageTitle: 'User Categories',
        showConfirmProcess: false,
        showFilter: false,
        showBack: false,
        disablePrint: !accessPrint,
        disableImport: !accessImport,
        disableNewData: !accessCreate,

        disableDelete: !accessDelete,
        disableActivate: !accessChangeStatus,
        disableDeactivate: !accessChangeStatus,
      }}
    >
      <Card>
        <AntdDataTable
          columns={column as any}
          rowActionProps={{ actionIgnored: actionIgnored, customActionIgnored: () => ['confirm_process'] }}
        />
      </Card>
    </IndexPageWrapper>
  );
}
