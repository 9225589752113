import { omit } from 'lodash';
import { EmailTemplateEntity } from '../entities';
import { BaseEntity, BaseTransformer } from '@eigen3m/react-base-frontend';
import sample from '../../presentation/components/email-editor/default-template.json';

function generateEmailDesign(data) {
  try {
    return JSON.parse(data);
  } catch (error) {
    return sample;
  }
}

export class EmailTemplateTransformer<E extends BaseEntity = EmailTemplateEntity> extends BaseTransformer<E> {
  transformerUpdate(payload: E): E {
    if (!payload) return payload;
    const cleanVal: any = omit(payload, ['id', 'form_action']);
    return cleanVal;
  }

  transformerCreate(payload: E): E {
    if (!payload) return payload;
    const cleanVal: any = omit(payload, ['id', 'form_action']);
    return cleanVal;
  }

  transformerDuplicate(payload: E): E {
    return this.transformerCreate(payload);
  }

  transformerGetData(payload: E): E {
    if (!payload) return payload;
    return payload;

    const email_design = generateEmailDesign(payload?.email_design);
    const data = { ...payload, email_design };
    console.log({ data });
    return data;
  }
}
