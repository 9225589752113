import { dateFormatter } from '@eigen3m/react-base-frontend';
import moment, { Moment } from 'moment';
import { typeOf } from 'mathjs';

export const toEpoch = (date?: any): number => {
  const newDate = date ? moment(date).valueOf() : undefined;
  return newDate ? newDate : undefined;
};

export const toTimeOnly = (time?: any): string => {
  const newTime = dateFormatter(time).toLocal('HH:mm');
  return newTime;
};

export const convertToMomentTime = ({
  date,
  startTime,
  endTime,
}: {
  date: any;
  startTime: string;
  endTime: string;
}) => {
  if (date && startTime && endTime) {
    const currentDate = moment(date).format('YYYY-MM-DD');
    const newStartTime = currentDate + ' ' + startTime;
    const newEndTime = currentDate + ' ' + endTime;
    const newTime = [moment(newStartTime, 'YYYY-MM-DD HH:mm'), moment(newEndTime, 'YYYY-MM-DD HH:mm')];
    return newTime;
  } else {
    return undefined;
  }
};

export const convertToMomentTimeOnly = ({ date, time }: { date: any; time: string }) => {
  if (!!date && !!time) {
    const currentDate = moment(date).format('YYYY-MM-DD');
    const currentTime = currentDate + ' ' + time;
    const newTime = moment(currentTime);
    return newTime;
  } else {
    return undefined;
  }
};

export const convertEpochToMoment = ({ date }: { date: any }) => {
  if (!!date && (typeOf(date) === 'number' || typeOf(date) === 'string')) {
    const currentDate = typeOf(date) === 'string' ? parseInt(date) : date;
    return moment(currentDate);
  } else {
    return undefined;
  }
};

interface DateTimeProps {
  date: string | number;
  time: string;
}

export const convertDateTime = ({ date, time }: DateTimeProps) => {
  const newDate = convertEpochToMoment({ date });
  const dateTime = !!newDate && !!time ? `${newDate.format('DD-MM-YYYY')} ${time}` : undefined;
  return !!dateTime ? moment(dateTime, 'DD-MM-YYYY HH:mm') : undefined;
};

interface DateTimeEpochProps {
  openDate: Moment;
  closeDate: Moment;
}
export const convertDateTimeEpoch = ({ openDate, closeDate }: DateTimeEpochProps) => {
  if (!!openDate && !!closeDate)
    return {
      open_date: openDate ? moment(openDate).valueOf() : null,
      open_time: openDate ? '00:00' : null,
      closing_date: closeDate ? moment(closeDate).valueOf() : null,
      closing_time: closeDate ? '23:59' : null,
    };
  else
    return {
      open_date: null,
      open_time: null,
      closing_date: null,
      closing_time: null,
    };
};
