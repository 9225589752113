import * as _ from 'lodash';
import { v4 as uuidV4 } from 'uuid';

function transformAccessRight(item) {
  if (!item) return {};
  const newItem = {};
  item.forEach((element: any) => {
    Object.assign(newItem, { [`${element.name}`]: element.allowed });
  });
  return newItem;
}

export function transformUserCategory(dataSource: any[]): any {
  if (!dataSource) return dataSource;

  const newData = dataSource?.map((data) => {
    const module_key = uuidV4();
    const item_no_sub = _.omit(data, ['sub_modules']);

    const data_sub_module = data?.sub_modules?.map((subModule: any) => {
      const subNoAc = _.omit(subModule, ['activities']);
      const sub_module_key = uuidV4();

      const data_activity = subModule?.activities?.map((activity: any) => {
        const activity_key = uuidV4();
        return {
          ...activity,
          ...transformAccessRight(activity?.access_right),
          activity_key,
          module_key,
          sub_module_key,
          group_key: 'activity',
          module_name: data.name,
          sub_module_name: subModule.name,
        };
      });

      return {
        ...subNoAc,
        ...transformAccessRight(subModule?.access_right),
        module_key,
        sub_module_key,
        group_key: 'sub_module',
        module_name: data.name,
        sub_module_name: subModule.name,
        children: data_activity,
      };
    });

    return {
      ...item_no_sub,
      ...transformAccessRight(data?.access_right),
      module_key,
      group_key: 'module',
      module_name: data?.name,
      children: data_sub_module,
    };
  });

  return newData;
}
