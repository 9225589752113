import React from 'react';
import { Table, TableColumnsType } from 'antd';
import { DataTableProps } from './data-table.entity';
import { ColumnType } from 'antd/lib/table';
import { ButtonAction } from 'shared/components/button';

export function DataTable(props: DataTableProps) {
  const columnAction: ColumnType<any> = {
    key: 'action',
    fixed: true,
    align: 'center',
    width: 50,
    render: (_: any, row: any) => <ButtonAction row={row} {...props?.buttonActionProps} />,
  };
  const columnsMerge: TableColumnsType<any> = [columnAction, ...props?.columns];
  const showColumnAction = props?.buttonActionProps?.showAction ?? true;
  return (
    <React.Fragment>
      <Table
        {...props}
        columns={!showColumnAction ? columnsMerge?.filter((item) => item?.key !== 'action') : columnsMerge}
        size="small"
        bordered={true}
      />
    </React.Fragment>
  );
}
