import { ParticipantEntity } from '../entities';
import { BaseEntity, BaseTransformer } from '@eigen3m/react-base-frontend';
import moment from 'moment';
import { generateAge } from '@helpers';

export class ParticipantTransformer<E extends BaseEntity = ParticipantEntity> extends BaseTransformer<E> {
  transformerUpdate(payload: E): E {
    return this.transformerCreate(payload);
  }

  transformerGetData(payload: any): any {
    if (!payload) return payload;
    const schedules = payload?.registran?.schedule ?? [];
    const registConfig = !!schedules?.length ? schedules[0]?.registration_config : null;

    const mainSchedules = schedules?.filter((item) => !item?.is_sub_event);
    const newMainSchedules = mainSchedules?.map((item) => {
      return {
        is_sub_event: false,
        registration_config: item?.registration_config,
        day: item?.day
          ? {
              no_day: item?.day,
            }
          : null,
        session: item?.session
          ? {
              no_session: item?.session,
            }
          : null,
        workshop: item?.workshop
          ? {
              name: item?.workshop,
            }
          : null,
      };
    });

    const subSchedules = schedules?.filter((item) => !!item?.is_sub_event);
    const newSubSchedules = subSchedules?.map((item) => {
      return {
        is_sub_event: true,
        registration_config: item?.registration_config,
        day: item?.day
          ? {
              no_day: item?.day,
            }
          : null,
        session: item?.session
          ? {
              no_session: item?.session,
            }
          : null,
        workshop: item?.workshop
          ? {
              name: item?.workshop,
            }
          : null,
      };
    });
    const cleanVal = {
      ...payload,
      registration_config: registConfig,
      registran: {
        ...payload?.registran,
        date_of_birth: payload?.registran?.date_of_birth
          ? moment(payload?.registran?.date_of_birth, 'YYYY-MM-DD')
          : null,
        schedule: newMainSchedules,
        sub_schedule: newSubSchedules,
        event: payload?.event,
      },
    };
    return cleanVal;
  }

  transformerCreate(payload: any): any {
    if (!payload) return payload;
    const registConfig = payload?.registration_config;
    const mainSchedules = !!payload?.registran?.schedule?.length ? payload?.registran?.schedule : [];
    const newMainSchedules = !!mainSchedules?.length
      ? mainSchedules?.map((item) => {
          return {
            ...item,
            registration_config: registConfig,
          };
        })
      : [
          {
            registration_config: registConfig,
          },
        ];
    const subSchedules = !!payload?.registran?.sub_schedule?.length ? payload?.registran?.sub_schedule : [];
    const newSubSchedule = subSchedules?.map((item) => {
      return {
        ...item,
        is_sub_event: true,
      };
    });

    const allSchedules = [...newMainSchedules, ...newSubSchedule];
    const cleanVal = {
      event: payload?.event,
      registran: payload?.registran
        ? {
            ...payload?.registran,
            registration_date: moment().valueOf(),
            schedule: allSchedules?.map((item) => {
              return {
                is_sub_event: item?.is_sub_event ? true : false,
                registration_config: item?.is_sub_event ? null : item?.registration_config,
                day: item?.day?.no_day,
                session: item?.session?.no_session,
                workshop: item?.workshop?.name,
              };
            }),
            age: generateAge(payload?.date_of_birth),
            event: payload?.event,
            sub_schedule: undefined,
          }
        : null,
    };
    return cleanVal;
  }
}
