import moment, { Moment } from 'moment';

export function makeAssetUrl(url: string): string {
  if (!url) return 'https://www.slntechnologies.com/wp-content/uploads/2017/08/ef3-placeholder-image.jpg';
  return process.env.REACT_APP_BASE_ASSET_URL + url;
}

export function parseEpochToMoment(epoch: any): Moment {
  if (!epoch) return undefined;
  const newEpoch = parseInt(epoch);
  return moment(newEpoch);
}
