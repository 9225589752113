import { Avatar, Col, Comment, Row } from 'antd';
import { UserOutlined } from '@ant-design/icons';

const assetUrl = process.env.REACT_APP_BASE_ASSET_URL;
export function SpeakerList({ value = [] }: any) {
  function truncate({ str, maxWord }) {
    return str.length > maxWord ? str?.slice(0, maxWord - 1) + '...' : str;
  }
  return !!value && !!value?.length ? (
    <Row gutter={[8, 8]}>
      {value?.map((item, key) => {
        const avatarUrl = !!item?.document_url ? assetUrl + item?.document_url : null;
        return (
          <Col xxl={8} xl={12} lg={12} md={12} sm={12} xs={24} key={key}>
            <Comment
              author={item?.name}
              avatar={<Avatar icon={<UserOutlined />} {...(avatarUrl ? { src: avatarUrl } : {})} alt="Han Solo" />}
              content={<p>{!!item?.description ? truncate({ str: item?.description, maxWord: 70 }) : '-'}</p>}
              style={{ background: '#FAFAFA', borderRadius: '8px', padding: '0 10px' }}
            />
          </Col>
        );
      })}
    </Row>
  ) : (
    <div>-</div>
  );
}
