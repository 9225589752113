import {
  AntdIconWrapper,
  FormBuilder,
  RenderAddress,
  RowAction,
  stringFormatter,
  useMediaQuery,
} from '@eigen3m/react-base-frontend';
import { Button, Checkbox, Drawer, Form, Select, Table } from 'antd';
import { Plus } from 'phosphor-react';
import { useEffect, useState } from 'react';
import { v4 as uuidV4 } from 'uuid';
import moment from 'moment';
import { omit } from 'lodash';
import { ApiUrlData } from '@base-configs';
import { useParams } from 'react-router-dom';
import { OptionGender, OptionsYesOrNo, phoneNumberPrefixOptions } from '@helpers';

const { Option } = Select;
interface RelativeProps {
  value?: any[];
  onChange?(value: [any]): void;
}

const prefixPhoneSelector = (
  <Form.Item name="prefix_phone" noStyle>
    <Select
      style={{ width: 70 }}
      defaultValue={'+62'}
      showSearch
      filterOption={(input, option) =>
        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
      }
    >
      {/* <Option value="+62">+62</Option> */}
      {phoneNumberPrefixOptions?.map((item, index) => {
        return (
          <Option key={index} value={item?.dial_code}>
            {item?.dial_code}
          </Option>
        );
      })}
    </Select>
  </Form.Item>
);

export function RelativeComponent(props: RelativeProps) {
  const { value = [], onChange } = props;
  const isMobile = useMediaQuery({ media: 'mobile' });
  const params = useParams();
  const isDetail = params?.action === 'detail';

  const [isCreateAnother, setIsCreateAnother] = useState(false);
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const onShowDrawer = () => setVisibleDrawer(true);
  const onCloseDrawer = () => setVisibleDrawer(false);
  const [form] = Form.useForm();

  function onChangeValue(newValue) {
    if (onChange) onChange(newValue);
  }

  function onSubmitForm(payload) {
    let newValue = [];
    const cleanVal = omit(payload, ['form_action']);
    if (payload.form_action === 'create') {
      newValue = [{ ...cleanVal, key: payload.key ?? uuidV4() }, ...value];
    } else {
      newValue = value.map((item) => {
        if (item.key !== payload.key) return item;
        return {
          ...cleanVal,
          key: payload.key ?? uuidV4(),
        };
      });
    }
    onChangeValue(newValue);
    form.resetFields();
    if (!isCreateAnother) onCloseDrawer();
  }

  function handleUpdate(row) {
    form.setFieldsValue({
      ...row,
      form_action: 'update',
    });
    onShowDrawer();
  }

  function handleDelete(row) {
    const newValue = value.filter((item) => item.key !== row.key);
    onChangeValue(newValue);
  }
  const columns = [
    ...(isDetail
      ? []
      : [
          {
            width: '20px',
            fixed: true,
            render(row) {
              return (
                <RowAction
                  actionIgnored={['activate', 'deactivate', 'detail', 'duplicate']}
                  handleClickUpdate={() => handleUpdate(row)}
                  handleClickDelete={() => handleDelete(row)}
                />
              );
            },
          },
        ]),
    { width: 150, title: 'Relation', dataIndex: 'relation_type' },
    { width: 200, title: 'Name', dataIndex: ['contact', 'name'] },
    { width: 200, title: 'Email', dataIndex: ['contact', 'email'] },
    {
      width: 150,
      title: 'Date of Birth',
      dataIndex: ['contact', 'date_of_birth'],
      render(item) {
        if (!item) return '-';
        return moment(item).format('DD-MM-YYYY');
      },
    },
    {
      width: 100,
      title: 'Gender',
      dataIndex: ['contact', 'gender'],
      render(item) {
        if (!item) return '-';
        return stringFormatter.capitalizeEachWord(item);
      },
    },
    {
      width: 130,
      title: 'Anniversary Date',
      dataIndex: ['contact', 'anniversary_date'],
      render(item) {
        if (!item) return '-';
        return moment(item).format('DD-MM-YYYY');
      },
    },
    {
      width: 200,
      title: 'Address',
      dataIndex: ['contact', 'address', 'address'],
      render(item) {
        if (!item) return '-';
        return RenderAddress({ value: item });
      },
    },
    {
      width: 150,
      title: 'Phone Number',
      dataIndex: ['contact', 'phone_number'],
      render(item, row) {
        if (!item) return '-';
        return `${row?.contact?.prefix_phone ?? ''} ${item}`;
      },
    },
    {
      width: 200,
      title: 'Has Join Care Group?',
      dataIndex: ['contact', 'has_join_care_group'],
      render(item) {
        if (!item || item === 'false') return 'No';
        if (item === 'true') return 'Yes';
        // return stringFormatter.capitalizeEachWord(item);
      },
    },
    {
      width: 150,
      title: 'Care Group Leader',
      dataIndex: ['contact', 'care_group_leader'],
      render(item) {
        if (!item) return '-';
        return item?.name;
      },
    },
    {
      width: 150,
      title: 'Has Attend IFGF',
      dataIndex: ['contact', 'has_attend_ifgf'],
      render(item) {
        if (!item || item === 'false') return 'No';
        if (item === 'true') return 'Yes';
        // return stringFormatter.capitalizeEachWord(item);
      },
    },
    {
      width: 150,
      title: 'Discipleship Center',
      dataIndex: ['contact', 'discipleship_center'],
      render(item) {
        if (!item) return '-';
        return item?.name;
      },
    },
    {
      width: 150,
      title: 'Role Position',
      dataIndex: ['contact', 'role_position'],
      render(item) {
        if (!item) return '-';
        return item?.code;
      },
    },
    {
      width: 150,
      title: 'HCC',
      dataIndex: ['contact', 'hcc'],
      render(item) {
        if (!item) return '-';
        return item?.name;
      },
    },
    {
      width: 150,
      title: 'IHC Name',
      dataIndex: ['contact', 'ihc'],
      render(item) {
        if (!item) return '-';
        return item?.name;
      },
    },
    {
      width: 150,
      title: 'ICC Name',
      dataIndex: ['contact', 'icc'],
      render(item) {
        if (!item) return '-';
        return item?.name;
      },
    },
  ];

  function transformData(data = []) {
    return data.map((item) => {
      return {
        ...item,
        key: item.key ?? item.id ?? uuidV4(),
      };
    });
  }

  return (
    <div>
      <div hidden={isDetail}>
        <Button
          onClick={() => {
            form.setFieldsValue({
              prefix_phone: '+62',
              form_action: 'create',
            });
            onShowDrawer();
          }}
          icon={
            <AntdIconWrapper>
              <Plus />
            </AntdIconWrapper>
          }
        >
          New Data
        </Button>
      </div>

      <div style={{ marginTop: '10px' }}></div>
      <Table
        columns={columns}
        dataSource={transformData(value)}
        pagination={false}
        scroll={{ x: 'fit-content', y: 500 }}
      />

      <Drawer
        title="New Relative"
        width={isMobile ? '100%' : 500}
        visible={visibleDrawer}
        onClose={onCloseDrawer}
        footer={
          <div style={{ display: 'flex' }}>
            <Checkbox checked={isCreateAnother} onChange={(e) => setIsCreateAnother(e.target.checked)}>
              Create another
            </Checkbox>

            <Button style={{ marginLeft: 'auto' }} onClick={() => onCloseDrawer()}>
              Cancel
            </Button>
            <Button type="primary" style={{ marginLeft: 10 }} onClick={() => form.submit()}>
              Save
            </Button>
          </div>
        }
      >
        <Form form={form} onFinish={onSubmitForm} layout="vertical">
          <Form.Item noStyle name="key"></Form.Item>
          <Form.Item noStyle name="form_action"></Form.Item>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldsValue }) => {
              const values = getFieldsValue();
              const contact = values?.contact;
              const hasJoinCareGroup = contact?.has_join_care_group === 'true';
              const hasAttendIfgf = contact?.has_attend_ifgf === 'true';
              return (
                <FormBuilder
                  columns={[
                    {
                      fields: [
                        {
                          label: 'Relation',
                          gridColumn: { span: 12 },
                          name: ['relation_type'],
                          fieldType: 'selectPaginate',
                          rules: [{ required: true, message: 'Relation is require!' }],
                          selectPaginateProps: {
                            dataSourceUrl: ApiUrlData.enum_relation,
                            customLabel: (row) => row,
                          },
                        },
                        {
                          label: 'Name',
                          gridColumn: { span: 12 },
                          name: ['contact', 'name'],
                          fieldType: 'inputText',
                          rules: [{ required: true, message: 'Name is required!' }],
                          inputTextProps: {
                            allowClear: true,
                          },
                        },
                        {
                          label: 'Email',
                          gridColumn: { span: 12 },
                          name: ['contact', 'email'],
                          fieldType: 'inputText',
                          rules: [
                            { type: 'email', message: 'Email is not a valid email' },
                            { required: true, message: 'Email is required!' },
                          ],
                          inputTextProps: {
                            allowClear: true,
                          },
                        },
                        {
                          label: 'Password',
                          gridColumn: { span: 12 },
                          name: ['contact', 'password'],
                          fieldType: 'inputPassword',
                          // rules: [
                          //   ({ getFieldValue }) => ({
                          //     validator(_, value) {
                          //       const action = getFieldValue('form_action');
                          //       if (action === 'create' || action === 'duplicate') {
                          //         if (!value) return Promise.reject(new Error('Password is required!'));
                          //         else return Promise.resolve();
                          //       } else return Promise.resolve();
                          //     },
                          //   }),
                          // ],
                          inputPasswordProps: {
                            allowClear: true,
                            autoComplete: 'new-password',
                          },
                        },
                        {
                          label: 'Confirm Password',
                          gridColumn: { span: 12 },
                          name: ['contact', 'retype_password'],
                          fieldType: 'inputPassword',
                          dependencies: ['password'],
                          hasFeedback: true,
                          rules: [
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                const password = getFieldValue(['contact', 'password']);
                                if (!password) return Promise.resolve();
                                else if (password === value) return Promise.resolve();
                                else if (!value) return Promise.reject(new Error('Please confirm your password!'));
                                else return Promise.reject(new Error("Passwords doesn't match!"));
                              },
                            }),
                          ],
                          inputPasswordProps: {
                            allowClear: true,
                            autoComplete: 'new-password',
                          },
                        },

                        {
                          label: 'Date of Birth',
                          gridColumn: { span: 6 },
                          name: ['contact', 'date_of_birth'],
                          rules: [{ required: true, message: 'Date of birth is required!' }],
                          fieldType: 'datePicker',
                        },
                        {
                          label: 'Anniversary Date',
                          gridColumn: { span: 6 },
                          name: ['contact', 'anniversary_date'],
                          fieldType: 'datePicker',
                        },
                        {
                          gridColumn: { span: 12 },
                          label: 'Gender',
                          name: ['contact', 'gender'],
                          rules: [{ required: true, message: 'Gender is required!' }],
                          fieldType: 'select',
                          selectProps: {
                            isClearable: false,
                            options: OptionGender,
                            customLabel: (value) =>
                              OptionGender.find((item) => item.value === value?.toLowerCase())?.label,
                          },
                        },
                        {
                          gridColumn: { span: 12 },

                          label: 'Phone Number',
                          name: ['contact', 'phone_number'],
                          rules: [
                            { pattern: /^[0-9]*$/, message: 'Phone number is invalid!' },
                            { required: true, message: 'Phone number is required!' },
                          ],
                          fieldType: 'inputText',
                          inputTextProps: {
                            allowClear: true,
                            addonBefore: prefixPhoneSelector,
                          },
                        },
                        {
                          gridColumn: { span: 12 },
                          label: 'Address',
                          name: ['contact', 'address'],
                          rules: [{ required: true, message: 'Address is required!' }],
                          fieldType: 'inputAddress',
                          inputAddressProps: {
                            showMapIcon: false,
                          },
                        },

                        {
                          gridColumn: { span: 12 },
                          label: 'Has join care group?',
                          name: ['contact', 'has_join_care_group'],
                          fieldType: 'select',
                          rules: [{ required: true, message: 'Has join care group is required!' }],
                          selectProps: {
                            isClearable: false,
                            options: OptionsYesOrNo,
                            customLabel: (value) => OptionsYesOrNo.find((item) => item.value === value)?.label,
                          },
                        },
                        {
                          gridColumn: { span: 12 },
                          label: 'Care Group Leader',
                          name: ['contact', 'care_group_leader'],
                          fieldType: 'selectPaginate',
                          customHidden(form) {
                            const hasJoin = form.getFieldValue(['contact', 'has_join_care_group']);
                            return !hasJoin || hasJoin === 'false';
                          },
                          rules: [
                            { required: hasJoinCareGroup },
                            // ({ getFieldValue }) => ({
                            //   validator(_, value) {
                            //     const hasJoin = getFieldValue(['contact', 'has_join_care_group']);
                            //     const isRequired = hasJoin === 'true';
                            //     const noValue = !value;
                            //     if (isRequired && noValue)
                            //       return Promise.reject(new Error('Care group leader is required!'));
                            //     return Promise.resolve();
                            //   },
                            // }),
                          ],
                          selectPaginateProps: {
                            dataSourceUrl: ApiUrlData.contact,
                            customLabel(row) {
                              return row?.name;
                            },
                            customFilterRequest: () => {
                              return {
                                is_leader: true,
                              };
                            },
                          },
                        },
                        {
                          gridColumn: { span: 12 },
                          label: 'Has attend IFGF?',
                          name: ['contact', 'has_attend_ifgf'],
                          fieldType: 'select',
                          rules: [{ required: true, message: 'Has attend IFGF is required!' }],
                          selectProps: {
                            isClearable: false,
                            options: OptionsYesOrNo,
                            customLabel: (value) => OptionsYesOrNo.find((item) => item.value === value)?.label,
                          },
                        },
                        {
                          gridColumn: { span: 12 },
                          label: 'Discipleship Center',
                          name: ['contact', 'discipleship_center'],
                          fieldType: 'selectPaginate',
                          customHidden(form) {
                            const hasAttend = form.getFieldValue(['contact', 'has_attend_ifgf']);
                            return !hasAttend || hasAttend === 'false';
                          },
                          rules: [
                            { required: hasAttendIfgf },
                            // ({ getFieldValue }) => ({
                            //   validator(_, value) {
                            //     const hasAttend = getFieldValue(['contact', 'has_attend_ifgf']);
                            //     const isRequired = hasAttend === 'true';
                            //     const noValue = !value;
                            //     if (isRequired && noValue)
                            //       return Promise.reject(new Error('Discipleship center is required!'));
                            //     return Promise.resolve();
                            //   },
                            // }),
                          ],
                          selectPaginateProps: {
                            dataSourceUrl: ApiUrlData.region,
                          },
                        },
                        {
                          gridColumn: { span: 12 },
                          label: 'Role Position',
                          name: ['contact', 'role_position'],
                          fieldType: 'selectPaginate',
                          selectPaginateProps: {
                            dataSourceUrl: ApiUrlData.user_level,
                          },
                        },
                        {
                          gridColumn: { span: 12 },
                          label: 'HCC',
                          name: ['contact', 'hcc'],
                          fieldType: 'selectPaginate',
                          selectPaginateProps: {
                            dataSourceUrl: ApiUrlData.contact,
                            customLabel(row) {
                              return row?.name;
                            },
                            customFilterRequest: () => {
                              return {
                                is_hcc: true,
                              };
                            },
                          },
                        },
                        {
                          gridColumn: { span: 12 },
                          label: 'IHC Name',
                          name: ['contact', 'ihc'],
                          fieldType: 'selectPaginate',
                          selectPaginateProps: {
                            dataSourceUrl: ApiUrlData.contact,
                            customLabel(row) {
                              return row?.name;
                            },
                            customFilterRequest: () => {
                              return {
                                is_ihc: true,
                              };
                            },
                          },
                        },
                        {
                          gridColumn: { span: 12 },
                          label: 'ICC Name',
                          name: ['contact', 'icc'],
                          fieldType: 'selectPaginate',
                          selectPaginateProps: {
                            dataSourceUrl: ApiUrlData.contact,
                            customLabel(row) {
                              return row?.name;
                            },
                            customFilterRequest: () => {
                              return {
                                is_icc: true,
                              };
                            },
                          },
                        },
                      ],
                    },
                  ]}
                />
              );
            }}
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
}
