import { WebUrlData } from '@base-configs';
import { RouteEntity } from '@eigen3m/react-base-frontend';
import LoginPage from '@auth-app/login/presentation/factory';
import RegisterPage from '@auth-app/register/presentation/factories';

export const NoAuthRoutes: RouteEntity[] = [
  {
    label: 'Login',
    path: WebUrlData.login,
    element: LoginPage,
  },
  {
    label: 'Login Token',
    path: WebUrlData.login_token,
    element: LoginPage,
  },
  {
    label: 'Register',
    path: WebUrlData.register,
    element: RegisterPage,
  },
  {
    label: 'Register Token',
    path: WebUrlData.register_token,
    element: RegisterPage,
  },
];
