import { RouteEntity } from '@eigen3m/react-base-frontend';
import { WebUrlData } from '@base-configs';
import Events from '@public-app/pages/homepage/presentation/factories';
import Category from '@public-app/pages/category/presentation/factories';
import PaymentConfirmation from '@public-app/pages/invoice/presentation/factory';
export const PublicRoutes: RouteEntity[] = [
  {
    label: 'Home',
    path: WebUrlData.homepage,
    element: Events,
  },
  {
    label: 'Category',
    path: WebUrlData.category + '/:slug',
    element: Category,
  },
  {
    label: 'Payment Confirmation',
    path: WebUrlData.invoice,
    element: PaymentConfirmation,
  },
];
