import Footer from '@components/layouts/footer';
import { Layout } from 'antd';
import React from 'react';
import { FooterApp } from './footer';
import { HeaderPage } from './header/header';

export function PublicLayout({ children }: { children: JSX.Element }) {
  return (
    <React.Fragment>
      <HeaderPage />
      <Layout style={{ minHeight: '80vh' }}>{children}</Layout>
      <FooterApp />
    </React.Fragment>
  );
}
