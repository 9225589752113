import { RenderCurrency } from '@eigen3m/react-base-frontend';

export function TotalInvoice({ priceType }: any): JSX.Element {
  return (
    <div style={{ fontSize: 14, display: priceType?.total_invoices === 0 ? 'none' : 'flex' }}>
      <div style={{ marginRight: 4 }}>
        <strong>Total Invoice</strong>
      </div>
      <div style={{ marginRight: 4 }}>&#61;</div>
      <div style={{ display: 'flex' }}>
        <span style={{ marginRight: 2 }}>Rp.</span>
        {priceType?.total_invoices === 0
          ? 0
          : RenderCurrency({ value: priceType?.total_invoices, currencyOptions: { minimumFractionDigits: 0 } })}
      </div>
    </div>
  );
}
