import { Fragment } from 'react';
import { Button, Card, Col, DatePicker, Divider, Form, Input, InputNumber, Row } from 'antd';
import { MinusOutlined } from '@ant-design/icons';
import { FormBuilder, InputAddress, SelectPaginate } from '@eigen3m/react-base-frontend';

import { ApiUrlData } from '@base-configs';
import { disabledDateOfBirth } from '@helpers';
import moment from 'moment';

type Props = {
  registration?: any;
  priceConfig?: any;
  payments?: any;
  loading?: boolean;
  checked?: boolean;
  onCheckedChange?: any;
  calculateInvoices?: any;
  id?: string;
  participants?: any;
  withOnlyAdmin?: boolean;
};

export function RegistrationFormInput({
  registration,
  payments,
  id,
  participants,
  withOnlyAdmin = true,
}: Props): JSX.Element {
  const registrationConfig = registration?.registration_config;
  const isOnlyAdmin = !!withOnlyAdmin && registration?.only_admin;
  const newRegistrationConfig = registration ? registration : {};

  const {
    address_required,
    date_of_birth_required,
    email_address_required,
    gender_required,
    marital_status_required,
    mobile_phone_required,
    title_name_required,
  } = newRegistrationConfig;

  const pricingSub = payments?.payment_pricing_subs ?? [];

  function calculateAge(date) {
    if (date) {
      const diff = moment(date).diff(moment(), 'year', true);
      const age = Math.abs(diff);
      return Math.floor(age);
    }
    return null;
  }
  return (
    <div style={{ display: isOnlyAdmin ? 'none' : 'block' }}>
      <h3>Participant Information</h3>
      <FormBuilder
        columns={[
          {
            fields: [
              {
                gridColumn: { span: 24 },
                renderField({ getFieldValue, setFieldValue }) {
                  return (
                    <Form.List name="registrans">
                      {(fields, { add, remove }) => {
                        return (
                          <>
                            {fields.map((field, index) => {
                              let age = 13;
                              const ageMin = participants?.age_min;
                              const ageMax = participants?.age_max;
                              const defaultDateOfBirth = ageMin ?? ageMax ?? null;
                              const startOfBirth =
                                ageMin || ageMin ? moment().subtract(defaultDateOfBirth, 'years') : null;

                              const dateOfBirth = getFieldValue(['registrans', index, 'date_of_birth']);
                              if (dateOfBirth) {
                                age = calculateAge(dateOfBirth);
                                setFieldValue(['registrans', index, 'age'], age);
                              }

                              return (
                                <div
                                  style={{ borderBottom: '1px solid #f0f0f0', marginBottom: '20px' }}
                                  key={field.key}
                                >
                                  <Divider orientation="center">
                                    <strong>Participant {index + 1}</strong>
                                  </Divider>
                                  <Card
                                    style={{ marginBottom: 20, backgroundColor: '#f9f9f9' }}
                                    hidden={registrationConfig === 'Registration per Event'}
                                    title="Choose Main Event"
                                    headStyle={{ padding: '0 15px' }}
                                    bodyStyle={{ padding: '15px' }}
                                  >
                                    {/* <h3 style={{ marginBottom: 10 }}>Choose Your Main Event</h3> */}
                                    <Form.List name={[field.name, 'schedule']}>
                                      {(schedules, { add, remove }) => {
                                        return (
                                          <div>
                                            {schedules.map((schedule, scheduleIndex) => {
                                              const day = getFieldValue([
                                                'registrans',
                                                index,
                                                'schedule',
                                                scheduleIndex,
                                                'day',
                                              ]);
                                              const session = getFieldValue([
                                                'registrans',
                                                index,
                                                'schedule',
                                                scheduleIndex,
                                                'session',
                                              ]);

                                              const dayId = day?.id;
                                              const sessionId = session?.id;
                                              const hasWorkshop = session?.has_workshop;

                                              const isRequiredDay = [
                                                'registration per each day',
                                                'registration per each session',
                                              ].includes(registrationConfig?.toLowerCase())
                                                ? true
                                                : false;

                                              const isRequiredSession =
                                                registrationConfig?.toLowerCase().includes('session') && !!day
                                                  ? true
                                                  : false;

                                              const isRequiredWorkshop = hasWorkshop;

                                              // Reset Form
                                              if (!day) {
                                                setFieldValue(
                                                  ['registrans', index, 'schedule', scheduleIndex, 'day'],
                                                  null,
                                                );
                                                setFieldValue(
                                                  ['registrans', index, 'schedule', scheduleIndex, 'workshop'],
                                                  null,
                                                );
                                              } else if (!session) {
                                                setFieldValue(
                                                  ['registrans', index, 'schedule', scheduleIndex, 'workshop'],
                                                  null,
                                                );
                                              }
                                              return (
                                                <Form.Item key={schedule.key}>
                                                  <Row gutter={[8, 8]}>
                                                    <Col
                                                      span={registrationConfig === 'Registration per Each Day' ? 21 : 7}
                                                    >
                                                      <Form.Item
                                                        {...schedule}
                                                        name={[schedule.name, 'day']}
                                                        fieldKey={[schedule.fieldKey, 'day']}
                                                        label="Day"
                                                        rules={[
                                                          {
                                                            required: isRequiredDay,
                                                            message: 'Day is required.',
                                                          },
                                                        ]}
                                                      >
                                                        <SelectPaginate
                                                          baseDataSourceUrl={process.env.REACT_APP_BASE_SERVICE_URL}
                                                          dataSourceUrl={ApiUrlData.eventData + `/${id}/list-day`}
                                                          classNamePrefix="react-select-custom-prefix-radius"
                                                          customLabel={(row) =>
                                                            `Day ${row?.no_day}${row?.name ? ': ' + row?.name : ''}`
                                                          }
                                                        />
                                                      </Form.Item>
                                                    </Col>
                                                    <Col
                                                      span={registrationConfig === 'Registration per Each Day' ? 0 : 7}
                                                    >
                                                      <Form.Item
                                                        {...schedule}
                                                        name={[schedule.name, 'session']}
                                                        fieldKey={[schedule.fieldKey, 'session']}
                                                        label="Session"
                                                        hidden={registrationConfig === 'Registration per Each Day'}
                                                        rules={[
                                                          {
                                                            required: isRequiredSession,
                                                            message: 'Session is required.',
                                                          },
                                                        ]}
                                                      >
                                                        <SelectPaginate
                                                          baseDataSourceUrl={process.env.REACT_APP_BASE_SERVICE_URL}
                                                          key={JSON.stringify(dayId)}
                                                          dataSourceUrl={
                                                            ApiUrlData.eventData + `/${dayId}/list-session`
                                                          }
                                                          classNamePrefix="react-select-custom-prefix-radius"
                                                          customLabel={(row) =>
                                                            `Session ${row?.no_session}${
                                                              row?.name ? ': ' + row?.name : ''
                                                            }`
                                                          }
                                                          filterRequest={{
                                                            event_id: id,
                                                          }}
                                                          isDisabled={!isRequiredSession}
                                                        />
                                                      </Form.Item>
                                                    </Col>
                                                    <Col
                                                      span={registrationConfig === 'Registration per Each Day' ? 0 : 8}
                                                    >
                                                      <Form.Item
                                                        {...schedule}
                                                        name={[schedule.name, 'workshop']}
                                                        fieldKey={[schedule.fieldKey, 'workshop']}
                                                        hidden={registrationConfig === 'Registration per Each Day'}
                                                        label="Workshop"
                                                        rules={[
                                                          {
                                                            required: isRequiredWorkshop,
                                                            message: 'Workshop is required',
                                                          },
                                                        ]}
                                                      >
                                                        <SelectPaginate
                                                          baseDataSourceUrl={process.env.REACT_APP_BASE_SERVICE_URL}
                                                          key={JSON.stringify(sessionId)}
                                                          dataSourceUrl={
                                                            ApiUrlData.eventData + `/${sessionId}/list-workshop`
                                                          }
                                                          classNamePrefix="react-select-custom-prefix-radius"
                                                          customLabel={(row) => row?.name}
                                                          isDisabled={!hasWorkshop}
                                                          filterRequest={{
                                                            event_id: id,
                                                          }}
                                                        />
                                                      </Form.Item>
                                                    </Col>
                                                    <Col span={2}>
                                                      <Form.Item label=" ">
                                                        <Button
                                                          block
                                                          type="primary"
                                                          danger
                                                          onClick={() => {
                                                            remove(schedule.name);
                                                          }}
                                                          icon={<MinusOutlined />}
                                                        />
                                                      </Form.Item>
                                                    </Col>
                                                  </Row>
                                                </Form.Item>
                                              );
                                            })}

                                            <Form.Item noStyle>
                                              <Button
                                                type="primary"
                                                onClick={() => {
                                                  add();
                                                }}
                                                block
                                              >
                                                Add Schedule
                                              </Button>
                                            </Form.Item>
                                          </div>
                                        );
                                      }}
                                    </Form.List>
                                  </Card>

                                  <Card
                                    style={{ marginBottom: 20, backgroundColor: '#f9f9f9' }}
                                    hidden={pricingSub?.length === 0}
                                    title="Choose Sub Event"
                                    headStyle={{ padding: '0 15px' }}
                                    bodyStyle={{ padding: '15px' }}
                                  >
                                    {/* <h3 style={{ marginBottom: 10 }}>Choose Your Sub Event</h3> */}
                                    <Form.List name={[field.name, 'sub_schedule']}>
                                      {(subSchedules, { add, remove }) => {
                                        return (
                                          <div>
                                            {subSchedules.map((sub, subIndex) => {
                                              const subDay = getFieldValue([
                                                'registrans',
                                                index,
                                                'sub_schedule',
                                                subIndex,
                                                'sub_day',
                                              ]);
                                              const subSession = getFieldValue([
                                                'registrans',
                                                index,
                                                'sub_schedule',
                                                subIndex,
                                                'sub_session',
                                              ]);

                                              const subDayId = subDay?.id;
                                              const subSessionId = subSession?.id;

                                              const hasSession = subDay?.has_session;
                                              const hasWorkshop = subSession?.has_workshop;

                                              // Reset Form
                                              if (!subDay) {
                                                setFieldValue(
                                                  ['registrans', index, 'sub_schedule', subIndex, 'sub_session'],
                                                  null,
                                                );
                                                setFieldValue(
                                                  ['registrans', index, 'sub_schedule', subIndex, 'sub_workshop'],
                                                  null,
                                                );
                                              } else if (!subSession) {
                                                setFieldValue(
                                                  ['registrans', index, 'sub_schedule', subIndex, 'sub_workshop'],
                                                  null,
                                                );
                                              }
                                              return (
                                                <Form.Item key={sub.key}>
                                                  <Row gutter={[8, 8]}>
                                                    <Col span={7}>
                                                      <Form.Item
                                                        {...sub}
                                                        name={[sub.name, 'sub_day']}
                                                        fieldKey={[sub.fieldKey, 'sub_day']}
                                                        label="Day"
                                                        rules={[{ required: true, message: 'Sub Day is required' }]}
                                                      >
                                                        <SelectPaginate
                                                          baseDataSourceUrl={process.env.REACT_APP_BASE_SERVICE_URL}
                                                          dataSourceUrl={ApiUrlData.eventData + `/${id}/list-sub-day`}
                                                          classNamePrefix="react-select-custom-prefix-radius"
                                                          customLabel={(row) =>
                                                            `Day ${row?.no_day}${row?.name ? ': ' + row?.name : ''}`
                                                          }
                                                        />
                                                      </Form.Item>
                                                    </Col>
                                                    <Col span={8}>
                                                      <Form.Item
                                                        {...sub}
                                                        name={[sub.name, 'sub_session']}
                                                        fieldKey={[sub.fieldKey, 'sub_session']}
                                                        label="Session"
                                                        rules={[
                                                          { required: hasSession, message: 'Sub Session is required' },
                                                        ]}
                                                      >
                                                        <SelectPaginate
                                                          baseDataSourceUrl={process.env.REACT_APP_BASE_SERVICE_URL}
                                                          key={JSON.stringify(subDayId)}
                                                          classNamePrefix="react-select-custom-prefix-radius"
                                                          dataSourceUrl={
                                                            ApiUrlData.eventData + `/${subDayId}/list-sub-session`
                                                          }
                                                          customLabel={(row) =>
                                                            `Session ${row?.no_session}${
                                                              row?.name ? ': ' + row?.name : ''
                                                            }`
                                                          }
                                                          filterRequest={{
                                                            event_id: id,
                                                          }}
                                                          isDisabled={hasSession ? false : true}
                                                        />
                                                      </Form.Item>
                                                    </Col>
                                                    <Col span={7}>
                                                      <Form.Item
                                                        {...sub}
                                                        name={[sub.name, 'sub_workshop']}
                                                        fieldKey={[sub.fieldKey, 'sub_workshop']}
                                                        label="Workshop"
                                                        rules={[
                                                          {
                                                            required: hasWorkshop,
                                                            message: 'Sub Workshop is required',
                                                          },
                                                        ]}
                                                      >
                                                        <SelectPaginate
                                                          baseDataSourceUrl={process.env.REACT_APP_BASE_SERVICE_URL}
                                                          key={JSON.stringify(subSessionId)}
                                                          classNamePrefix="react-select-custom-prefix-radius"
                                                          dataSourceUrl={
                                                            ApiUrlData.eventData + `/${subSessionId}/list-sub-workshop`
                                                          }
                                                          customLabel={(row) => row?.name}
                                                          filterRequest={{
                                                            event_id: id,
                                                          }}
                                                          isDisabled={hasWorkshop ? false : true}
                                                        />
                                                      </Form.Item>
                                                    </Col>
                                                    <Col span={2}>
                                                      <Form.Item label=" ">
                                                        <Button
                                                          block
                                                          type="primary"
                                                          danger
                                                          onClick={() => {
                                                            remove(sub.name);
                                                          }}
                                                          icon={<MinusOutlined />}
                                                        />
                                                      </Form.Item>
                                                    </Col>
                                                  </Row>
                                                </Form.Item>
                                              );
                                            })}

                                            <Form.Item noStyle>
                                              <Button
                                                type="primary"
                                                onClick={() => {
                                                  add();
                                                }}
                                                block
                                              >
                                                Add Sub Event
                                              </Button>
                                            </Form.Item>
                                          </div>
                                        );
                                      }}
                                    </Form.List>
                                  </Card>

                                  <Form.Item key={field.key} noStyle>
                                    <Row gutter={[8, 8]}>
                                      <Col xxl={4} xl={4} md={12} sm={24} xs={24}>
                                        <Form.Item
                                          {...field}
                                          name={[field.name, 'title']}
                                          label="Title"
                                          rules={[{ required: true, message: 'Title is required' }]}
                                        >
                                          <Input placeholder="Ex: Mr" style={{ width: '100%' }} />
                                        </Form.Item>
                                      </Col>
                                      <Col xxl={20} xl={20} md={12} sm={24} xs={24}>
                                        <Form.Item
                                          {...field}
                                          name={[field.name, 'name']}
                                          label="Full Name"
                                          rules={[{ required: true, message: 'Full Name is required' }]}
                                        >
                                          <Input placeholder="Input" style={{ width: '100%' }} />
                                        </Form.Item>
                                      </Col>
                                      <Col xxl={12} xl={12} md={12} sm={24} xs={24}>
                                        <Form.Item
                                          {...field}
                                          name={[field.name, 'date_of_birth']}
                                          label="Date of Birth"
                                          rules={[
                                            { required: date_of_birth_required, message: 'Date of Birth is required.' },
                                          ]}
                                        >
                                          <DatePicker
                                            style={{ width: '100%' }}
                                            placeholder="Select"
                                            disabledDate={(current) => {
                                              const ageMin = participants?.age_min ?? null;
                                              const ageMax = participants?.age_max ?? null;
                                              return disabledDateOfBirth({ current, ageMin, ageMax });
                                            }}
                                            defaultPickerValue={startOfBirth}
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col xxl={12} xl={12} md={12} sm={24} xs={24}>
                                        <Form.Item {...field} name={[field.name, 'age']} label="Age">
                                          <InputNumber min={1} style={{ width: '100%' }} disabled={true} />
                                        </Form.Item>
                                      </Col>
                                      <Col xxl={12} xl={12} md={12} sm={24} xs={24}>
                                        <Form.Item
                                          {...field}
                                          name={[field.name, 'gender']}
                                          label="Gender"
                                          rules={[{ required: gender_required, message: 'Age is Required' }]}
                                        >
                                          <SelectPaginate
                                            baseDataSourceUrl={process.env.REACT_APP_BASE_SERVICE_URL}
                                            dataSourceUrl={ApiUrlData.enum_gender}
                                            customLabel={(row) => row}
                                            classNamePrefix="react-select-custom-prefix-radius"
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col xxl={12} xl={12} md={12} sm={24} xs={24}>
                                        <Form.Item
                                          name={[field.name, 'marital_status']}
                                          label="Marital Status"
                                          rules={[
                                            {
                                              required: marital_status_required,
                                              message: 'Marital Status is Required',
                                            },
                                          ]}
                                        >
                                          <SelectPaginate
                                            baseDataSourceUrl={process.env.REACT_APP_BASE_SERVICE_URL}
                                            dataSourceUrl={ApiUrlData?.enum_marital_status}
                                            customLabel={(row) => row}
                                            classNamePrefix="react-select-custom-prefix-radius"
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col xxl={24} xl={24} md={24} sm={24} xs={24}>
                                        <Form.Item
                                          {...field}
                                          name={[field.name, 'address']}
                                          label="Address"
                                          rules={[{ required: address_required, message: 'Address is required.' }]}
                                        >
                                          <InputAddress
                                            manualAddressConfig={{
                                              showCountry: false,
                                              showProvince: false,
                                              showCity: false,
                                              showDistrict: false,
                                              showVillage: false,
                                              showHamlet: false,
                                              showPostalCode: false,
                                            }}
                                            placeholder="Input"
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col xxl={12} xl={12} md={12} sm={24} xs={24}>
                                        <Form.Item
                                          {...field}
                                          name={[field.name, 'email']}
                                          label="Email Address"
                                          rules={[
                                            {
                                              required: index > 0 && age > 12 ? true : index === 0 ? true : false,
                                              message: 'Email Address is required.',
                                            },
                                            { type: 'email', message: 'Email is not a valid email.' },
                                          ]}
                                        >
                                          <Input type="email" placeholder="Input" />
                                        </Form.Item>
                                      </Col>
                                      <Col xxl={12} xl={12} md={12} sm={24} xs={24}>
                                        <Form.Item
                                          {...field}
                                          name={[field.name, 'phone_number']}
                                          label="Mobile Phone"
                                          rules={[
                                            {
                                              required: index > 0 && age > 12 ? true : index === 0 ? true : false,
                                              message: 'Mobile Phone is required.',
                                            },
                                            {
                                              pattern: /^[0][0-9]*$/,
                                              min: 3,
                                              message: 'Mobile Phone is not a valid phone number.',
                                            },
                                          ]}
                                        >
                                          <Input placeholder="Input" style={{ width: '100%' }} type="number" />
                                        </Form.Item>
                                      </Col>
                                      <Col xxl={24} xl={24} md={24} sm={24} xs={24}>
                                        <Form.Item label=" " hidden={index < 1}>
                                          <Button block onClick={() => remove(field.name)} danger type="primary">
                                            Remove Participant
                                          </Button>
                                        </Form.Item>
                                      </Col>
                                    </Row>
                                  </Form.Item>
                                </div>
                              );
                            })}
                            <Form.Item noStyle hidden={fields?.length === 10}>
                              <Button onClick={() => add()} block type="primary">
                                Add Participant
                              </Button>
                            </Form.Item>
                          </>
                        );
                      }}
                    </Form.List>
                  );
                },
              },
            ],
          },
        ]}
      />
    </div>
  );
}
