import { BaseEntity, BaseTransformer } from '@eigen3m/react-base-frontend';
import { PaymentEntity } from '../entities';
import { convertEpochToMoment, toEpoch } from '@admin-app/pages/events/event-data/domain/transformer/helpers';
export class PaymentTransformer<E extends BaseEntity = PaymentEntity> extends BaseTransformer<E> {
  transformerUpdate(payload: E): E {
    if (!payload) return payload;
    const imageUrls = payload?.image_url?.filter((item) => !!item?.image_url && !!item?.uid) ?? [];
    const newImageUrls = imageUrls?.map((item) => {
      return {
        ...item,
        document_id: item?.uid,
      };
    });
    const cleanVal: any = {
      destination_bank: payload?.destination_bank?.name,
      bank_account: payload?.bank_account?.account_no,
      payment_type: payload?.payment_type,
      payer_name: payload?.payer_name,
      no_payer_account: payload?.no_payer_account,
      image_url: newImageUrls,
      payment_date: payload?.payment_date ? toEpoch(payload?.payment_date) : null,
      total_payment: !!payload?.total_payment ? payload?.total_payment : undefined,
    };
    return cleanVal;
  }

  transformerGetData(payload: E): E {
    const newPayload = {
      ...payload,
      // destination_bank: payload?.destination_bank
      //   ? {
      //       name: payload?.destination_bank,
      //     }
      //   : null,
      // bank_account: payload?.bank_account
      //   ? {
      //       account_no: payload?.bank_account,
      //     }
      //   : null,
      payment_date: payload?.payment_date ? convertEpochToMoment({ date: payload?.payment_date }) : null,
      paymentId: payload?.id,
    };

    return newPayload;
  }

  transformerGetIndex(payload: E[]): E[] {
    if (!payload) return payload;
    return payload.map((item) => {
      return {
        ...item,
        paymentId: item.id,
      };
    });
  }
}
