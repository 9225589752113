import { ActionProps, IndexPageWrapper, SinglePage, usePageProvider } from '@eigen3m/react-base-frontend';
import { useState } from 'react';
import { SpeakerForm } from '../components/speaker.form';
import { columnsSpeaker } from '../helpers';
import { mockCompanies } from '@states';
import { useRecoilState } from 'recoil';
import SpeakerDetail from '../components/speaker.detail';
import { Card } from 'antd';
import { useAccessRight } from 'shared/hooks';

export default function IndexPage() {
  const [visibleDrawerForm, setVisibleDrawerForm] = useState(false);
  const [actionForm, setActionForm] = useState<ActionProps>('create');
  const [visibleDrawerDetail, setVisibleDrawerDetail] = useState(false);
  const { dataSource } = usePageProvider();
  const [companies] = useRecoilState(mockCompanies);

  const { moduleKey } = usePageProvider();
  const { accessCreate, accessEdit, accessImport, accessChangeStatus, accessDelete, accessView, accessPrint } =
    useAccessRight({
      moduleKey,
    });
  let actionIgnored: any[] = ['confirm_process'];
  if (!accessCreate) actionIgnored = [...actionIgnored, 'duplicate'];
  if (!accessEdit) actionIgnored = [...actionIgnored, 'update'];
  if (!accessChangeStatus) actionIgnored = [...actionIgnored, 'activate', 'deactivate'];
  if (!accessView) actionIgnored = [...actionIgnored, 'detail'];
  if (!accessDelete) actionIgnored = [...actionIgnored, 'delete'];

  async function handleAfterSave(response: any, configs: any) {
    await handleConfirmProcess(response, configs);
  }

  async function handleConfirmProcess(payload, configs): Promise<void> {
    await dataSource.handleConfirmProcess(payload, {
      onSuccess: () => {
        if (configs?.onReloadTable) configs?.onReloadTable();
        if (!configs?.isCreateAnother) if (configs.onClose) configs.onClose(undefined);
        if (configs?.form) configs.form.resetFields();
      },
      onFailed: () => {
        console.log('failed');
      },
    });
  }

  const onShowDrawerForm = (action: ActionProps) => {
    setVisibleDrawerForm(true);
    setActionForm(action);
  };
  const onCloseDrawerForm = () => {
    setVisibleDrawerForm(false);
  };

  const onShowDrawerDetail = () => {
    setVisibleDrawerDetail(true);
  };
  const onCloseDrawerDetail = () => {
    setVisibleDrawerDetail(false);
  };

  return (
    <IndexPageWrapper
      pageToolbarProps={{
        pageTitle: 'Speaker',
        handleClickNewData() {
          onShowDrawerForm('create');
        },
        showFilter: false,
        showConfirmProcess: false,

        disablePrint: !accessPrint,
        disableImport: !accessImport,
        disableNewData: !accessCreate,

        disableDelete: !accessDelete,
        disableActivate: !accessChangeStatus,
        disableDeactivate: !accessChangeStatus,
        showBack: false,
      }}
    >
      <Card>
        <SinglePage
          autoConfirmAfterAction={true}
          drawerFormProps={{
            title: 'Speaker',
            action: actionForm,
            visible: visibleDrawerForm,
            onClose: onCloseDrawerForm,
            // forceRequest: true,
            children: <SpeakerForm />,
            onShow: onShowDrawerForm,
            initialValues: { company: !!companies?.length ? companies[0] : null },
            handleAfterSave: handleAfterSave,
            showDataTypeSelector: false,
          }}
          dataTableProps={{
            defaultFilterRequestData: {
              is_speaker: true,
            },
            columns: columnsSpeaker,
            rowActionProps: {
              actionIgnored: actionIgnored,
            },
            ...(!accessView
              ? {
                  onRow: null,
                }
              : {}),
          }}
          drawerDetailProps={{
            title: 'Speaker',
            visible: visibleDrawerDetail,
            onClose: onCloseDrawerDetail,
            forceRequest: true,
            children: <SpeakerDetail />,
            onShow: onShowDrawerDetail,
            showDataTypeSelector: false,
          }}
        />
      </Card>
    </IndexPageWrapper>
  );
}
