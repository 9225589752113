import React, { useEffect, useState } from 'react';
import { columnsDaySessionWorkshop, columnsPricing } from '../../../../helpers';
import { ActionProps, FormBuilder, makeArrayIds, Select, SelectPaginate } from '@eigen3m/react-base-frontend';
import { ApiUrlData } from '@base-configs';
import { v4 as uuidv4 } from 'uuid';
import { ButtonNewData } from '@components/button';
import { DrawerForm } from '@components/drawer';
import { DataTable } from '@components/table';
import { Button, Form, FormInstance, Table } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { makeLabelValue } from './helpers';
import { FormBuilderOnsite } from './form-builder/onsite-type-form';
import { FormBuilderOnline } from './form-builder/online-type-form';
interface Props {
  value?: any;
  onChange?: (value: any) => any;
  optionsDay?: any[];
  registType?: string;
  isPublicEvent?: boolean;
  eventType?: string;
}

export function FormSubPricing(props: Props) {
  const { value, onChange, optionsDay, registType, isPublicEvent, eventType } = props;
  const [action, setAction] = useState<ActionProps>('create');
  const [visiblePricing, setVisiblePricing] = useState(false);
  const [dataPricing, setDataPricing] = useState([]);
  const [dataFormPricing, setDataFormPricing] = useState(null);

  const columnsPricingOnline =
    eventType?.toLowerCase() === 'online'
      ? [
          ...columnsPricing,
          {
            title: 'Event Link',
            key: 'event_link',
            dataIndex: 'event_link',
            ellipsis: true,
          },
          {
            title: 'Event Code',
            key: 'event_code',
            dataIndex: 'event_code',
            ellipsis: true,
          },
          {
            title: 'Event Password',
            key: 'event_password',
            dataIndex: 'event_password',
            ellipsis: true,
          },
        ]
      : columnsPricing;

  useEffect(() => {
    let newValue = value ?? [];
    if (!!newValue?.length) {
      newValue = newValue?.map((item) => {
        const newItem = {
          key: item?.key ?? item?.id ?? item.uuid,
          ...item,
        };
        return newItem;
      });
    }
    setDataPricing(newValue);
  }, [value]);

  function onChangeValues(value) {
    if (onChange) {
      onChange(value);
    }
  }

  const onShowPricing = () => {
    setAction('create');
    setDataFormPricing(null);
    setVisiblePricing(true);
  };
  const onClosePricing = () => {
    setVisiblePricing(false);
  };

  const onChangePricing = (payload: any) => {
    let newData = dataPricing;
    const daySessionWorkshops = payload?.day_session_workshops ?? [];
    const newPayload = {
      ...payload,
      key: payload?.id ?? payload?.uuid ?? payload?.key ?? uuidv4(),
      day_session_workshops: daySessionWorkshops?.filter((item) => !!item?.day),
      need_confirm: payload?.price > 0 ? false : payload?.need_confirm,
    };
    if (action === 'create') {
      newData = [...dataPricing, newPayload];
    } else {
      newData = dataPricing?.map((item) => {
        if (item?.key === newPayload?.key) {
          return {
            ...item,
            ...newPayload,
          };
        }
        return item;
      });
    }
    onChangeValues(newData);
  };

  const handleClickDelete = (row) => {
    const newData = dataPricing?.filter((item) => item?.key !== row?.key);
    setDataPricing(newData);
    onChangeValues(newData);
  };

  const handleClickUpdate = (row) => {
    setAction('update');
    setVisiblePricing(true);
    setDataFormPricing(row);
  };

  function onValuesChange(item: any, values: any, form: FormInstance): any {
    let newValues = null;
    if (Object.keys(item).includes('departments')) {
      newValues = {
        ...values,
        ministries: null,
      };
    }
    if (newValues) form.setFieldsValue(newValues);
  }

  return (
    <React.Fragment>
      <ButtonNewData onClick={onShowPricing} style={{ marginBottom: '10px' }} />
      <DrawerForm
        width={600}
        action={action}
        value={dataFormPricing}
        visible={visiblePricing}
        onClose={onClosePricing}
        onChange={onChangePricing}
        onValuesChange={onValuesChange}
      >
        <FormBuilderOnsite isPublicEvent={isPublicEvent} optionsDay={optionsDay} />
        {!!eventType && eventType.toLowerCase() === 'online' && <FormBuilderOnline />}
      </DrawerForm>
      <DataTable
        columns={
          isPublicEvent
            ? columnsPricingOnline?.filter((item) => item?.key !== 'participant_condition')
            : columnsPricingOnline
        }
        dataSource={dataPricing}
        buttonActionProps={{
          width: 70,
          actionIgnored: ['detail', 'create'],
          handleClickDelete(row) {
            handleClickDelete(row);
          },
          handleClickUpdate(row) {
            handleClickUpdate(row);
          },
        }}
        size="small"
        bordered
        expandable={{
          rowExpandable: (record) =>
            !!record?.day_session_workshops && !!record?.day_session_workshops?.length ? true : false,
          expandedRowRender: (record) => {
            const daySessionWorkshop = record?.day_session_workshops ?? [];
            return (
              <Table
                columns={columnsDaySessionWorkshop}
                dataSource={daySessionWorkshop}
                pagination={false}
                size="small"
                bordered
              />
            );
          },
        }}
      />
    </React.Fragment>
  );
}
