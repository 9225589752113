import { Table } from 'antd';

export default function AccountNumbersTable({ value }: { value?: any }) {
  return (
    <Table
      columns={[
        {
          title: 'Bank',
          key: 'bank',
          dataIndex: 'bank',
          render(value) {
            return value ? value?.name : '-';
          },
        },
        {
          title: 'Account',
          key: 'account',
          dataIndex: 'account',
          render(value) {
            return value ? value?.account_no : '-';
          },
        },
      ]}
      dataSource={value ?? []}
      size="small"
      bordered
      pagination={false}
    />
  );
}
