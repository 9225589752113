import React, { useEffect, useState } from 'react';
import { columnsDaySessionWorkshop, columnsPricing } from '../../../../helpers';
import { ActionProps, FormBuilder, makeArrayIds, Select, SelectPaginate } from '@eigen3m/react-base-frontend';
import { ApiUrlData } from '@base-configs';
import { v4 as uuidv4 } from 'uuid';
import { ButtonNewData } from '@components/button';
import { DrawerForm } from '@components/drawer';
import { DataTable } from '@components/table';
import { Button, Form, FormInstance, Table } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { makeLabelValue } from './helpers';
interface Props {
  value?: any;
  onChange?: (value: any) => any;
  optionsDay?: any[];
  registType?: string;
  isPublicEvent?: boolean;
}

export function FormPricing(props: Props) {
  const { value, onChange, optionsDay, isPublicEvent, registType } = props;
  const [action, setAction] = useState<ActionProps>('create');
  const [visiblePricing, setVisiblePricing] = useState(false);
  const [dataPricing, setDataPricing] = useState([]);
  const [dataFormPricing, setDataFormPricing] = useState(null);

  useEffect(() => {
    let newValue = value ?? [];
    if (!!newValue?.length) {
      newValue = newValue?.map((item) => {
        const newItem = {
          key: item?.key ?? item?.id ?? item.uuid,
          ...item,
        };
        return newItem;
      });
    }
    setDataPricing(newValue);
  }, [value]);

  function onChangeValues(value) {
    if (onChange) {
      onChange(value);
    }
  }

  const onShowPricing = () => {
    setAction('create');
    setDataFormPricing(null);
    setVisiblePricing(true);
  };
  const onClosePricing = () => {
    setVisiblePricing(false);
  };

  const onChangePricing = (payload: any) => {
    let newData = dataPricing;
    const daySessionWorkshops = payload?.day_session_workshops ?? [];
    const newPayload = {
      ...payload,
      key: payload?.id ?? payload?.uuid ?? payload?.key ?? uuidv4(),
      day_session_workshops: daySessionWorkshops?.filter((item) => !!item?.day),
      need_confirm: payload?.price > 0 ? false : payload?.need_confirm,
    };
    if (action === 'create') {
      newData = [...dataPricing, newPayload];
    } else {
      newData = dataPricing?.map((item) => {
        if (item?.key === newPayload?.key) {
          return {
            ...item,
            ...newPayload,
          };
        }
        return item;
      });
    }
    onChangeValues(newData);
  };

  const handleClickDelete = (row) => {
    const newData = dataPricing?.filter((item) => item?.key !== row?.key);
    setDataPricing(newData);
    onChangeValues(newData);
  };
  const handleClickUpdate = (row) => {
    setAction('update');
    setVisiblePricing(true);
    setDataFormPricing(row);
  };

  function onValuesChange(item: any, values: any, form: FormInstance): any {
    let newValues = null;
    if (Object.keys(item).includes('departments')) {
      newValues = {
        ...values,
        ministries: null,
      };
    }
    if (newValues) form.setFieldsValue(newValues);
  }
  return (
    <React.Fragment>
      <ButtonNewData onClick={onShowPricing} style={{ marginBottom: '10px' }} />
      <DrawerForm
        width={600}
        action={action}
        value={dataFormPricing}
        visible={visiblePricing}
        onClose={onClosePricing}
        onChange={onChangePricing}
        onValuesChange={onValuesChange}
      >
        <FormBuilder
          className="mt-0"
          columns={[
            {
              fields: [
                {
                  label: 'Price Name',
                  name: 'name',
                  fieldType: 'inputText',
                  gridColumn: { xl: 12, lg: 12, md: 24 },
                  rules: [{ required: true, message: 'Price Name is required' }],
                },
                {
                  label: 'Price',
                  name: 'price',
                  fieldType: 'inputNumber',
                  gridColumn: { xl: 12, lg: 12, md: 24 },
                  rules: [{ required: true, message: 'Price  is required' }],
                  inputNumberProps: {
                    formatter(value) {
                      return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                    },
                  },
                },
                {
                  gridColumn: { span: 24 },
                  renderField(form) {
                    return (
                      <Form.List
                        name="day_session_workshops"
                        initialValue={[
                          {
                            day: null,
                            session: null,
                            workshop: null,
                          },
                        ]}
                      >
                        {(fields, { add, remove }) => {
                          return fields.map((field, index) => {
                            const newOptionsDay = makeLabelValue({
                              values: optionsDay,
                              keyLabel: 'no',
                              typeLabel: 'Day',
                            });
                            const day = form.getFieldValue(['day_session_workshops', index, 'day']);
                            const isRequiredDay =
                              !!registType && registType?.toLowerCase().includes('day') ? true : false;
                            const optionsSession = day?.sessions ?? [];
                            const newOptionsSession = makeLabelValue({
                              values: optionsSession,
                              keyLabel: 'no',
                              typeLabel: 'Session',
                            });
                            const isRequiredSession =
                              !!registType && registType?.toLowerCase().includes('session') ? true : false;
                            const isDisabledSession =
                              !!registType && registType?.toLowerCase().includes('day')
                                ? true
                                : !!optionsSession?.length
                                ? false
                                : true;

                            const session = form.getFieldValue(['day_session_workshops', index, 'session']);
                            const optionsWorkshop = session?.workshops ?? [];
                            const newOptionsWorkshop = optionsWorkshop?.map((item) => {
                              return {
                                label: item?.name,
                                value: item,
                              };
                            });
                            const isRequiredWorkshop =
                              !!registType &&
                              registType?.toLowerCase()?.includes('session') &&
                              !!optionsWorkshop?.length
                                ? true
                                : false;
                            const isDisabledWorkshop = !!optionsWorkshop && !!optionsWorkshop?.length ? false : true;

                            //Reset Form
                            if (!day) form.setFieldValue(['day_session_workshops', index, 'session'], null);
                            if (!session) form.setFieldValue(['day_session_workshops', index, 'session'], null);
                            return (
                              <div
                                key={field.key}
                                className="item-day-session"
                                style={{ display: 'flex', justifyContent: 'space-between' }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    width: fields.length > 1 ? 'calc(100% - 80px)' : 'calc(100% - 45px)',
                                  }}
                                >
                                  <Form.Item
                                    label="Day"
                                    name={[field.name, 'day']}
                                    style={{ width: 'calc(50% - 5px)', marginRight: '10px' }}
                                    rules={[{ required: isRequiredDay, message: 'Day is required.' }]}
                                  >
                                    <Select
                                      classNamePrefix="react-select-custom-prefix-radius"
                                      options={newOptionsDay}
                                      customLabel={(value) => {
                                        const noDay = value?.no;
                                        return `Day ${noDay}`;
                                      }}
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    label="Session"
                                    name={[field.name, 'session']}
                                    style={{ width: 'calc(50% - 5px)', marginRight: '10px' }}
                                    rules={[{ required: isRequiredSession, message: 'Session is required.' }]}
                                  >
                                    <Select
                                      classNamePrefix="react-select-custom-prefix-radius"
                                      options={newOptionsSession}
                                      isDisabled={isDisabledSession}
                                      customLabel={(value) => {
                                        const noSession = value?.no;
                                        return `Session ${noSession}`;
                                      }}
                                    />
                                  </Form.Item>

                                  <Form.Item
                                    label="Workshop"
                                    name={[field.name, 'workshop']}
                                    style={{ width: 'calc(50% - 5px)' }}
                                    rules={[{ required: isRequiredWorkshop, message: 'Workshop is required.' }]}
                                  >
                                    <Select
                                      classNamePrefix="react-select-custom-prefix-radius"
                                      options={newOptionsWorkshop}
                                      isDisabled={isDisabledWorkshop}
                                      customLabel={(value) => {
                                        return value?.name;
                                      }}
                                      isMulti
                                    />
                                  </Form.Item>
                                </div>
                                <div style={{ display: 'flex', width: fields.length > 1 ? '70px' : '35px' }}>
                                  <Form.Item label="&#32;">
                                    {fields.length > 1 && (
                                      <Button
                                        icon={<MinusOutlined />}
                                        onClick={() => remove(field.name)}
                                        style={{ width: '30px', marginRight: fields.length > 1 ? '10px' : '0px' }}
                                        type="primary"
                                        danger
                                      />
                                    )}
                                    {fields.length - 1 === index && (
                                      <Button
                                        icon={<PlusOutlined />}
                                        onClick={() => add()}
                                        style={{ width: '30px' }}
                                        type="primary"
                                      />
                                    )}
                                  </Form.Item>
                                </div>
                              </div>
                            );
                          });
                        }}
                      </Form.List>
                    );
                  },
                  customHidden() {
                    const isHidden =
                      !!registType &&
                      (registType?.toLowerCase().includes('day') || registType?.toLowerCase().includes('session'))
                        ? false
                        : true;
                    return isHidden;
                  },
                },
                {
                  label: 'Department',
                  name: 'departments',
                  fieldType: 'selectPaginate',
                  gridColumn: { xl: 12, lg: 12, md: 24 },
                  selectPaginateProps: {
                    keySearch: 'search',
                    classNamePrefix: 'react-select-custom-prefix-radius',
                    dataSourceUrl: ApiUrlData.department,
                    isMulti: true,
                    isClearable: true,
                    customLabel(row) {
                      return row?.name;
                    },
                  },
                  // customHidden: () => {
                  //   return isPublicEvent ? true : false;
                  // },
                },
                {
                  gridColumn: { xl: 12, lg: 12, md: 24 },
                  renderField() {
                    return (
                      <Form.Item shouldUpdate noStyle>
                        {(form) => {
                          const departments = form.getFieldValue('departments');
                          const departmentIds = makeArrayIds({ data: departments, valueWhenNull: 'id' });
                          const isDisabled = !!departments && departments?.length >= 1 ? false : true;
                          return (
                            <Form.Item label="Ministries" name="ministries">
                              <SelectPaginate
                                classNamePrefix="react-select-custom-prefix-radius"
                                dataSourceUrl={ApiUrlData.ministries}
                                key={JSON.stringify(departmentIds)}
                                keySearch="search"
                                isDisabled={isDisabled}
                                customLabel={(row: any) => {
                                  return row?.name;
                                }}
                                filterRequest={{
                                  department_ids: departmentIds,
                                }}
                                isMulti
                                isClearable
                              />
                            </Form.Item>
                          );
                        }}
                      </Form.Item>
                    );
                  },
                },
                {
                  label: 'Registration Condition',
                  name: 'regist_condition',
                  fieldType: 'inputNumber',
                  gridColumn: { xl: isPublicEvent ? 8 : 12, lg: isPublicEvent ? 8 : 12, md: 24 },
                  rules: [{ required: true, message: 'Registration Condition' }],
                  tooltip: 'Days Before Registration Close',
                  inputNumberProps: {
                    min: 0,
                  },
                },
                {
                  label: 'Need Confirm',
                  name: 'need_confirm',
                  fieldType: 'checkbox',
                  gridColumn: { xl: 4, lg: 12, md: 24 },
                  valuePropName: 'checked',
                  customHidden(form) {
                    const price = form.getFieldValue('price');
                    const isHidden = price === 0 ? false : true;
                    return isHidden;
                  },
                },
              ],
            },
          ]}
        />
      </DrawerForm>
      <DataTable
        columns={
          isPublicEvent ? columnsPricing?.filter((item) => item?.key !== 'participant_condition') : columnsPricing
        }
        dataSource={dataPricing}
        buttonActionProps={{
          width: 70,
          actionIgnored: ['detail', 'create'],
          handleClickDelete(row) {
            handleClickDelete(row);
          },
          handleClickUpdate(row) {
            handleClickUpdate(row);
          },
        }}
        size="small"
        bordered
        expandable={{
          rowExpandable: (record) =>
            !!record?.day_session_workshops && !!record?.day_session_workshops?.length ? true : false,
          expandedRowRender: (record) => {
            const daySessionWorkshop = record?.day_session_workshops ?? [];
            return (
              <Table
                columns={columnsDaySessionWorkshop}
                dataSource={daySessionWorkshop}
                pagination={false}
                size="small"
                bordered
              />
            );
          },
        }}
      />
    </React.Fragment>
  );
}
