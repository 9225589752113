import { ApiUrlData, subModuleKey, WebUrlData } from '@base-configs';
import { InitialValuePageProviderEntity, PageProvider, usePageProvider } from '@eigen3m/react-base-frontend';
import { PaymentEntity } from '@admin-app/pages/events/event-activity/domain/entities';
import { makePaymentTransformer } from '@admin-app/pages/events/event-activity/domain/factories/payment-factory';
import IndexTable from './components/index-table';

export const TabPayment = (): JSX.Element => {
  const { id } = usePageProvider();
  const defaultFiler = { event_ids: id ? [id] : [], statuses: undefined };
  const transformer = makePaymentTransformer<PaymentEntity>(defaultFiler);
  const InitialValue: InitialValuePageProviderEntity<PaymentEntity> = {
    transformer,
    moduleKey: subModuleKey.event_activity.PAYMENT,
    webUrl: WebUrlData.event_activity,
    apiUrl: ApiUrlData.payment,
  };

  return (
    <PageProvider initialValue={InitialValue}>
      <IndexTable />
    </PageProvider>
  );
};
