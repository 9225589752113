import { RecoilRoot } from 'recoil';
import AppRoutes from '@main/routes/app-routes';

export default function App() {
  const basePath = '/';
  return (
    <RecoilRoot>
      <AppRoutes basePath={basePath} />
    </RecoilRoot>
  );
}
