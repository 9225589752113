import { PageProvider } from '@eigen3m/react-base-frontend';
import { InitialValuePageProviderEntity } from '@eigen3m/react-base-frontend';

import PageIndex from '../pages/index';
import PageDetail from '../pages/detail';

import { EventPublicEntity } from '../../domain/entities';
import { ApiUrlData, WebUrlData } from '@base-configs';
import { useParams } from 'react-router-dom';

export default function FactoryPage() {
  const InitialValue: InitialValuePageProviderEntity<EventPublicEntity> = {
    moduleKey: 'data-home',
    webUrl: WebUrlData.event,
    apiUrl: ApiUrlData.eventActivities,
  };

  const { id } = useParams();

  return <PageProvider initialValue={InitialValue}>{!id ? <PageIndex /> : <PageDetail />}</PageProvider>;
}
