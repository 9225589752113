import React from 'react';
import { FormBuilder, makeArrayIds } from '@eigen3m/react-base-frontend';
import { Form, FormInstance, Input, InputNumber } from 'antd';
import { ApiUrlData } from '@base-configs';

export function FormParticipants() {
  return (
    <React.Fragment>
      <Form.Item shouldUpdate noStyle>
        {({ getFieldValue, getFieldsValue }) => {
          const department = getFieldValue(['participants', 'departments']);
          const participantType = getFieldValue(['participants', 'participant_type']);
          const departmentIds = makeArrayIds({ data: department, valueWhenNull: 'id' });
          const isDisabledMinistries = department ? false : true;
          return (
            <FormBuilder
              title="Participant Configuration"
              columns={[
                {
                  fields: [
                    {
                      customHidden: () => {
                        return true;
                      },
                      renderField() {
                        return <Form.Item noStyle name={['participants', 'id']} />;
                      },
                    },
                    {
                      label: 'Participant Description',
                      gridColumn: { span: 24 },
                      name: ['participants', 'description'],
                      fieldType: 'textArea',
                      textAreaProps: {
                        placeholder: 'Input',
                        rows: 4,
                      },
                    },
                    {
                      label: 'Participant Type',
                      gridColumn: { xl: 4, lg: 4, md: 12, sm: 24, xs: 24 },
                      name: ['participants', 'participant_type'],
                      fieldType: 'selectPaginate',
                      rules: [{ required: true, message: 'Participant Type is required.' }],
                      selectPaginateProps: {
                        classNamePrefix: 'react-select-custom-prefix-radius',
                        isClearable: false,
                        dataSourceUrl: ApiUrlData.enum_participant_type,
                        customLabel: (value) => value,
                      },
                    },
                    {
                      label: 'Departments',
                      gridColumn: { xl: 4, lg: 6, md: 12, sm: 24, xs: 24 },
                      name: ['participants', 'departments'],
                      fieldType: 'selectPaginate',
                      selectPaginateProps: {
                        keySearch: 'search',
                        dataSourceUrl: ApiUrlData.department,
                        customLabel(row) {
                          return row?.name;
                        },
                        isMulti: true,
                        classNamePrefix: 'react-select-custom-prefix-radius',
                      },
                      customHidden() {
                        if (!!participantType && !['Volunteer'].includes(participantType)) {
                          return true;
                        } else {
                          return false;
                        }
                      },
                    },
                    {
                      label: 'Ministries',
                      gridColumn: { xl: 4, lg: 6, md: 12, sm: 24, xs: 24 },
                      name: ['participants', 'ministries'],
                      fieldType: 'selectPaginate',
                      selectPaginateProps: {
                        keySearch: 'search',
                        dataSourceUrl: ApiUrlData.ministries,
                        customLabel(row) {
                          return row?.name;
                        },
                        isMulti: true,
                        classNamePrefix: 'react-select-custom-prefix-radius',
                        customFilterRequest() {
                          return {
                            department_ids: departmentIds,
                          };
                        },
                        key: JSON.stringify(departmentIds),
                        isDisabled: isDisabledMinistries,
                      },
                      customHidden() {
                        if (!!participantType && !['Volunteer'].includes(participantType)) {
                          return true;
                        } else {
                          return false;
                        }
                      },
                    },
                    {
                      renderField() {
                        return (
                          <Form.Item label="Age Range">
                            <Input.Group compact style={{ position: 'relative', width: '100%', display: 'flex' }}>
                              <Form.Item name={['participants', 'age_min']} noStyle>
                                <InputNumber placeholder="Min" min={1} style={{ width: 'calc(100% - 15px)' }} />
                              </Form.Item>
                              <div
                                style={{
                                  position: 'relative',
                                  display: 'table',
                                  textAlign: 'center',
                                  width: '70px',
                                  color: 'rgba(0, 0, 0, 0.25)',
                                  background: '#f5f5f5',
                                  border: '1px solid #d9d9d9',
                                  boxShadow: 'none',
                                  cursor: 'not-allowed',
                                  margin: '0 -1px',
                                }}
                              >
                                <span style={{ display: 'table-cell', verticalAlign: 'middle' }}>-</span>
                              </div>
                              <Form.Item dependencies={['participants', 'age_min']} noStyle>
                                {(form) => {
                                  const ageMin = form.getFieldValue(['participants', 'age_min']);
                                  return (
                                    <Form.Item name={['participants', 'age_max']} noStyle>
                                      <InputNumber
                                        placeholder="Max"
                                        style={{ width: 'calc(100% - 15px)' }}
                                        min={ageMin}
                                      />
                                    </Form.Item>
                                  );
                                }}
                              </Form.Item>
                            </Input.Group>
                          </Form.Item>
                        );
                      },
                      gridColumn: { xl: 4, lg: 6, md: 12, sm: 24, xs: 24 },
                    },
                    {
                      label: 'Gender',
                      gridColumn: { xl: 3, lg: 4, md: 12, sm: 24, xs: 24 },
                      name: ['participants', 'gender'],
                      fieldType: 'selectPaginate',
                      selectPaginateProps: {
                        classNamePrefix: 'react-select-custom-prefix-radius',
                        dataSourceUrl: ApiUrlData.enum_gender,
                        isClearable: true,
                        customLabel: (value) => value,
                      },
                    },
                    {
                      label: 'Marital Status',
                      gridColumn: { xl: 3, lg: 4, md: 12, sm: 24, xs: 24 },
                      name: ['participants', 'marital_status'],
                      fieldType: 'selectPaginate',
                      selectPaginateProps: {
                        classNamePrefix: 'react-select-custom-prefix-radius',
                        dataSourceUrl: ApiUrlData.enum_marital_status,
                        isClearable: true,
                        customLabel: (value) => value,
                      },
                    },
                  ],
                },
              ]}
            />
          );
        }}
      </Form.Item>
    </React.Fragment>
  );
}
