import { useEffect } from 'react';
import EmailEditor from 'react-email-editor';
import { useRecoilState } from 'recoil';

import { editorState } from '@admin-app/pages/configuration/email-template/presentation/state';

// import sample from './template.json';
import './style.less';

type EmailEditorProps = {
  emailEditorRef?: any;
  value?: any;
};

export function EmailEditorPage({
  emailEditorRef,
  //  value = sample
  value,
}: EmailEditorProps) {
  const [editorReady, setEditorReady] = useRecoilState(editorState);

  const onReady = () => {
    setEditorReady(true);
  };

  const setDefault = (value: any) => {
    console.log({ value });
    emailEditorRef?.current?.editor.loadDesign(value);
  };

  useEffect(() => {
    if (value && editorReady) {
      setDefault(value);
    }
  }, [value, editorReady]);

  return <EmailEditor ref={emailEditorRef} onReady={onReady} editorId="editor-email-template" minHeight="70vh" />;
}
