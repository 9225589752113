import React, { useEffect, useState } from 'react';
import { dateFormatter, DetailBuilder, usePageProvider } from '@eigen3m/react-base-frontend';
import { Checkbox, Form, notification, Spin, Table, Tabs } from 'antd';
import { ApiUrlData } from '@base-configs';
import { transformerGetDataRegistration } from '@admin-app/pages/events/event-data/domain/transformer/transformer.get';
import { columnsDayRegistration, columnsSessionRegistration, columnsWorkshopRegistration } from '../../../../helpers';
import { filterColumnsByEventType } from './helpers';

interface Props {
  value?: any;
  onChange?: (value?: any) => void;
  dataInformation?: any;
}
export function DetailRegistrations(props: Props) {
  const { dataInformation } = props;
  const [form] = Form.useForm();
  const { id, dataSource } = usePageProvider();
  const [loading, setLoading] = useState(false);
  const eventType = dataInformation?.event_type;
  const [dataRegistrationDetails, setDataRegistrationDetails] = useState([]);

  const handleGetDataRegistration = async (id: string) => {
    setLoading(true);
    await dataSource.handleCustomRequest({
      paramRequest: {
        method: 'GET',
        url: process.env.REACT_APP_BASE_SERVICE_URL + ApiUrlData.eventData + `/${id}/registrations`,
      },
      onSuccess(response: any) {
        const dataRegistration = response?.response ? transformerGetDataRegistration(response?.response) : null;
        setDataRegistrationDetails(dataRegistration?.registration_details ?? []);
        form.setFieldsValue({ ...dataRegistration });
      },
      onFailed(response: any) {
        notification.error({
          message: 'Get data registration error',
          description: response,
        });
      },
    });
    setLoading(false);
  };

  useEffect(() => {
    if (id) {
      handleGetDataRegistration(id);
    }
  }, [id]);

  return (
    <React.Fragment>
      <Spin spinning={loading}>
        <Form form={form}>
          <DetailBuilder
            title="Registration Configuration"
            columns={[
              {
                fields: [
                  {
                    gridColumn: { xxl: 5, xl: 8, lg: 10, md: 12, sm: 24, xs: 24 },
                    label: 'Registration Configuration',
                    name: ['registration_config'],
                    fieldType: 'text',
                    renderTextProps: {
                      renderValue(value) {
                        return value;
                      },
                      defaultRender: () => '-',
                    },
                  },
                  {
                    gridColumn: { xxl: 19, xl: 16, lg: 16, md: 12, sm: 24, xs: 24 },
                    label: 'Only Admin',
                    name: ['only_admin'],
                    fieldType: 'text',
                    renderTextProps: {
                      renderValue(value) {
                        return value == true ? 'Yes' : 'No';
                      },
                      defaultRender: () => '-',
                    },
                  },
                  {
                    gridColumn: { span: 24 },
                    label: 'Registration Description',
                    name: ['description'],
                    fieldType: 'text',
                    renderTextProps: {
                      renderValue(value) {
                        return value;
                      },
                      defaultRender: () => '-',
                    },
                  },
                  {
                    label: 'Registration Open',
                    gridColumn: { xl: 4, lg: 4, md: 8, sm: 24 },
                    name: ['registration_information', 'registration_open'],
                    fieldType: 'text',
                    renderTextProps: {
                      renderValue(value) {
                        return dateFormatter(value).toLocal('DD-MM-YYYY') ?? '-';
                      },
                      defaultRender: () => '-',
                    },
                    customHidden(form) {
                      const registConfig = form.getFieldValue(['registration_config']);
                      return !!registConfig && registConfig?.toLowerCase() === 'registration per event' ? false : true;
                    },
                  },
                  {
                    label: 'Registration Close',
                    gridColumn: { xl: 4, lg: 4, md: 8, sm: 24 },
                    name: ['registration_information', 'registration_close'],
                    fieldType: 'text',
                    renderTextProps: {
                      renderValue(value) {
                        return dateFormatter(value).toLocal('DD-MM-YYYY') ?? '-';
                      },
                      defaultRender: () => '-',
                    },
                    customHidden(form) {
                      const registConfig = form.getFieldValue(['registration_config']);
                      return !!registConfig && registConfig?.toLowerCase() === 'registration per event' ? false : true;
                    },
                  },
                  {
                    label: 'Max Registrants',
                    gridColumn: { xl: 4, lg: 4, md: 8, sm: 24 },
                    name: ['registration_information', 'max_registrants'],
                    fieldType: 'text',
                    renderTextProps: {
                      renderValue(value) {
                        return value;
                      },
                      defaultRender: () => '-',
                    },
                    customHidden(form) {
                      const registConfig = form.getFieldValue(['registration_config']);
                      return !!registConfig && registConfig?.toLowerCase() === 'registration per event' ? false : true;
                    },
                  },
                  {
                    label: 'Event Link',
                    gridColumn: { xl: 4, lg: 4, md: 8, sm: 24 },
                    name: ['registration_information', 'event_link'],
                    renderTextProps: {
                      renderValue(value) {
                        return value;
                      },
                      defaultRender: () => '-',
                    },
                    customHidden(form) {
                      const registConfig = form.getFieldValue(['registration_config']);
                      return !!registConfig && registConfig?.toLowerCase() === 'registration per event'
                        ? !!eventType && eventType?.toLowerCase() === 'online'
                          ? false
                          : true
                        : true;
                    },
                  },
                  {
                    label: 'Event Code',
                    gridColumn: { xl: 4, lg: 4, md: 8, sm: 24 },
                    name: ['registration_information', 'event_code'],
                    renderTextProps: {
                      renderValue(value) {
                        return value;
                      },
                      defaultRender: () => '-',
                    },
                    customHidden(form) {
                      const registConfig = form.getFieldValue(['registration_config']);
                      return !!registConfig && registConfig?.toLowerCase() === 'registration per event'
                        ? !!eventType && eventType?.toLowerCase() === 'online'
                          ? false
                          : true
                        : true;
                    },
                  },
                  {
                    label: 'Event Password',
                    gridColumn: { xl: 4, lg: 4, md: 8, sm: 24 },
                    name: ['registration_information', 'event_password'],
                    renderTextProps: {
                      renderValue(value) {
                        return value;
                      },
                      defaultRender: () => '-',
                    },
                    customHidden(form) {
                      const registConfig = form.getFieldValue(['registration_config']);
                      return !!registConfig && registConfig?.toLowerCase() === 'registration per event'
                        ? !!eventType && eventType?.toLowerCase() === 'online'
                          ? false
                          : true
                        : true;
                    },
                  },
                ],
              },
            ]}
          />
          <Form.Item noStyle shouldUpdate>
            {(form) => {
              const registConfig = form.getFieldValue('registration_config')?.toLowerCase();
              return registConfig !== 'registration per event' ? (
                <Tabs>
                  <Tabs.TabPane tab="Days to close the registration">
                    <Table
                      className="antd-table-day"
                      columns={filterColumnsByEventType({ columns: columnsDayRegistration, type: eventType })}
                      dataSource={dataRegistrationDetails}
                      expandable={{
                        rowExpandable: (record) => (record?.sessions?.length >= 1 ? true : false),
                        expandedRowRender: (record) => {
                          const sessions = record?.sessions ?? [];
                          return (
                            <Table
                              className="antd-table-session"
                              columns={filterColumnsByEventType({
                                columns: columnsSessionRegistration,
                                type: eventType,
                              })}
                              dataSource={sessions}
                              expandable={{
                                rowExpandable: (record) => {
                                  const workshops = record?.workshops ?? [];
                                  return workshops?.length >= 1 ? true : false;
                                },
                                expandedRowRender: (recordSession) => {
                                  const workshops = recordSession?.workshops ?? [];
                                  return (
                                    <Table
                                      className="antd-table-workshop"
                                      columns={filterColumnsByEventType({
                                        columns: columnsWorkshopRegistration,
                                        type: eventType,
                                      })}
                                      dataSource={workshops}
                                      pagination={false}
                                      style={{ marginLeft: '50px' }}
                                    />
                                  );
                                },
                              }}
                              pagination={false}
                              bordered
                              size="small"
                            />
                          );
                        },
                      }}
                      pagination={false}
                      bordered
                      size="small"
                      style={{ borderColor: 'black' }}
                    />
                  </Tabs.TabPane>
                </Tabs>
              ) : (
                <></>
              );
            }}
          </Form.Item>
          <DetailBuilder
            columns={[
              {
                title: 'Registration Form Configuration',
                fields: [
                  {
                    gridColumn: { span: 24 },
                    renderField() {
                      return (
                        <div style={{ marginBottom: '10px' }}>
                          Field checked will be shown in registration form dan required to be filled
                        </div>
                      );
                    },
                  },
                  {
                    gridColumn: { span: 24 },
                    renderField: () => {
                      return (
                        <Form.Item name={['title_name_required']} noStyle valuePropName="checked">
                          <Checkbox disabled>Title and Name</Checkbox>
                        </Form.Item>
                      );
                    },
                  },
                  {
                    gridColumn: { span: 24 },
                    renderField: () => {
                      return (
                        <Form.Item name={['date_of_birth_required']} noStyle valuePropName="checked">
                          <Checkbox disabled>Date of Birth</Checkbox>
                        </Form.Item>
                      );
                    },
                  },
                  {
                    gridColumn: { span: 24 },
                    renderField: () => {
                      return (
                        <Form.Item name={['gender_required']} noStyle valuePropName="checked">
                          <Checkbox disabled>Gender</Checkbox>
                        </Form.Item>
                      );
                    },
                  },
                  {
                    gridColumn: { span: 24 },
                    renderField: () => {
                      return (
                        <Form.Item name={['email_address_required']} noStyle valuePropName="checked">
                          <Checkbox disabled>Email Address</Checkbox>
                        </Form.Item>
                      );
                    },
                  },
                  {
                    gridColumn: { span: 24 },
                    renderField: () => {
                      return (
                        <Form.Item name={['mobile_phone_required']} noStyle valuePropName="checked">
                          <Checkbox disabled>Mobile Phone</Checkbox>
                        </Form.Item>
                      );
                    },
                  },
                  {
                    gridColumn: { span: 24 },
                    renderField: () => {
                      return (
                        <Form.Item name={['marital_status_required']} noStyle valuePropName="checked">
                          <Checkbox disabled>Marital Status</Checkbox>
                        </Form.Item>
                      );
                    },
                  },
                ],
              },
            ]}
          />
        </Form>
      </Spin>
    </React.Fragment>
  );
}
