import { dateFormatter, RenderCurrency, RenderTag } from '@eigen3m/react-base-frontend';
import { TableColumnsType } from 'antd';

export const columnsEvent: TableColumnsType<any> = [
  {
    title: 'Start Date',
    key: 'schedules.date_start',
    width: 100,
    dataIndex: 'date_start',
    ellipsis: true,
    sorter: true,
    render(item: any) {
      return item ? `${dateFormatter(item).parseEpoch('DD/MM/YYYY')}` : '-';
    },
  },
  {
    title: 'End Date',
    key: 'schedules.date_end',
    width: 100,
    dataIndex: 'date_end',
    sorter: true,
    ellipsis: true,
    render(item: any) {
      return item ? `${dateFormatter(item).parseEpoch('DD/MM/YYYY')}` : '-';
    },
  },
  {
    title: 'Department',
    key: 'department.name',
    width: 150,
    dataIndex: ['information', 'department', 'name'],
    sorter: true,
    ellipsis: true,
    render: (value) => value ?? '-',
  },
  {
    title: 'Class',
    key: 'department_class.name',
    width: 150,
    dataIndex: ['information', 'department_class', 'name'],
    sorter: true,
    ellipsis: true,
    render: (value) => value ?? '-',
  },
  {
    title: 'Event Name',
    key: 'information.name',
    width: 200,
    dataIndex: 'information',
    sorter: true,
    ellipsis: true,
    render(item: any) {
      return item?.name ?? '-';
    },
  },
  {
    title: 'Price Type',
    key: 'payment_pricings.price_configuration',
    width: 150,
    dataIndex: 'price_configuration',
    sorter: true,
    ellipsis: true,
    render: (item: any) => item ?? '-',
  },
  {
    title: 'Highest Price',
    key: 'payment_pricings.highest_price',
    width: 100,
    dataIndex: 'highest_price',
    sorter: true,
    ellipsis: true,
    render: (value) => (value ? RenderCurrency({ value }) : '-'),
  },
  {
    title: 'Event Type',
    key: 'information.event_type',
    width: 100,
    dataIndex: 'information',
    sorter: true,
    ellipsis: true,
    render: (item: any) => item?.event_type ?? '-',
  },
  {
    title: 'Venue',
    key: 'information.venue.name',
    width: 150,
    dataIndex: 'information',
    sorter: true,
    ellipsis: true,
    render: (item: any) => item?.venue?.name ?? '-',
  },
  {
    title: 'Participant Type',
    key: 'participants.participant_type',
    width: 150,
    dataIndex: 'participant_type',
    sorter: true,
    ellipsis: true,
    render: (item: any) => item ?? '-',
  },
  {
    title: 'Age',
    key: 'participants.age_min',
    width: 100,
    dataIndex: 'age',
    sorter: true,
    ellipsis: true,
    render: (_, record: any) => {
      return !!record?.age_min && !!record?.age_max
        ? `${record?.age_min} - ${record?.age_max}`
        : !!record?.age_min
        ? `${record?.age_min} - above`
        : !!record?.age_max
        ? `${record?.age_max} - below`
        : '-';
    },
  },
  {
    title: 'Gender',
    key: 'participants.gender',
    width: 100,
    dataIndex: 'gender',
    sorter: true,
    ellipsis: true,
    render: (item: any) => item ?? '-',
  },
  {
    title: 'Marital Status',
    key: 'participants.marital_status',
    width: 120,
    dataIndex: 'marital_status',
    sorter: true,
    ellipsis: true,
    render: (item: any) => item ?? '-',
  },
  // {
  //   title: 'QR Code',
  //   key: 'use_qr',
  //   width: 100,
  //   dataIndex: 'use_qr',
  //   sorter: true,
  //   ellipsis: true,
  //   render: (item: any) => (item ? 'Yes' : 'No'),
  // },
  {
    title: 'Attendance',
    key: 'attendance_officers.attendance_configuration',
    width: 100,
    dataIndex: 'attendance_configuration',
    sorter: true,
    ellipsis: true,
    render: (item: any) => item ?? '-',
  },
];

export const columnsDaySchedule: TableColumnsType<any> = [
  {
    title: 'Day',
    key: 'no',
    dataIndex: 'no',
    width: 50,
    ellipsis: true,
    render: (item) => item,
  },
  {
    title: '# Session',
    key: 'no_of_session',
    dataIndex: 'no_of_session',
    ellipsis: true,
    width: 80,
    render: (item, row) => row?.sessions?.length ?? 0,
  },
  {
    title: 'Name',
    key: 'name',
    dataIndex: 'name',
    ellipsis: true,
    render: (item) => item ?? '-',
  },
  {
    title: 'Description',
    key: 'description',
    dataIndex: 'description',
    ellipsis: true,
    render: (item) => item ?? '-',
  },
  {
    title: 'Date',
    key: 'date',
    dataIndex: 'date',
    ellipsis: true,
    render: (item) => (item ? dateFormatter(item).toLocal('DD/MM/YYYY') : '-'),
  },
  {
    title: 'Start Time',
    key: 'time',
    dataIndex: 'time',
    ellipsis: true,
    render: (item: any) => (item ? dateFormatter(item?.[0]).toLocal('HH:mm') : '-'),
  },
  {
    title: 'End Time',
    key: 'time',
    dataIndex: 'time',
    ellipsis: true,
    render: (item: any) => (item ? dateFormatter(item?.[1]).toLocal('HH:mm') : '-'),
  },
  {
    title: 'Speaker',
    key: 'speakers',
    dataIndex: 'speakers',
    ellipsis: true,
    render: (item) => {
      return !!item && !!item?.length ? RenderTag({ value: item, renderType: 'multi', keyValue: 'name' }) : '-';
    },
  },
];

export const columnsSessionSchedule: TableColumnsType<any> = [
  {
    title: 'Session',
    key: 'no',
    dataIndex: 'no',
    width: 70,
    ellipsis: true,
    render: (item) => item,
  },
  {
    title: '# Workshop',
    key: 'no_of_workshop',
    dataIndex: 'no_of_workshop',
    ellipsis: true,
    width: 100,
    render: (item, row) => {
      return row?.workshops?.length ?? 0;
    },
  },
  {
    title: 'Name',
    key: 'name',
    dataIndex: 'name',
    ellipsis: true,
    render: (item) => item ?? '-',
  },
  {
    title: 'Description',
    key: 'description',
    dataIndex: 'description',
    ellipsis: true,
    render: (item) => item ?? '-',
  },
  {
    title: 'Start Time',
    key: 'time',
    dataIndex: 'time',
    ellipsis: true,
    render: (item: any) => (item ? dateFormatter(item?.[0]).toLocal('HH:mm') : '-'),
  },
  {
    title: 'End Time',
    key: 'time',
    dataIndex: 'time',
    ellipsis: true,
    render: (item: any) => (item ? dateFormatter(item?.[1]).toLocal('HH:mm') : '-'),
  },
  {
    title: 'Speaker',
    key: 'speakers',
    dataIndex: 'speakers',
    ellipsis: true,
    render: (item) => {
      return !!item && !!item?.length ? RenderTag({ value: item, renderType: 'multi', keyValue: 'name' }) : '-';
    },
  },
];

export const columnsWorkshopSchedule: TableColumnsType<any> = [
  {
    title: 'Workshop',
    key: 'no',
    dataIndex: 'no',
    width: 100,
    ellipsis: true,
    render: (item) => item,
  },
  {
    title: 'Name',
    key: 'name',
    dataIndex: 'name',
    ellipsis: true,
    render: (item) => item ?? '-',
  },
  {
    title: 'Description',
    key: 'description',
    dataIndex: 'description',
    ellipsis: true,
    render: (item) => item ?? '-',
  },
  {
    title: 'Speaker',
    key: 'speakers',
    dataIndex: 'speakers',
    ellipsis: true,
    render: (item) => {
      return !!item && !!item?.length ? RenderTag({ value: item, renderType: 'multi', keyValue: 'name' }) : '-';
    },
  },
];

export const columnsDayRegistration: TableColumnsType<any> = [
  {
    title: 'Day',
    key: 'no',
    dataIndex: 'no',
    width: 50,
    ellipsis: true,
    render: (item) => item,
  },
  {
    title: '# Session',
    key: 'no_of_session',
    dataIndex: 'no_of_session',
    ellipsis: true,
    width: 100,
    render: (item, row) => row?.sessions?.length ?? 0,
  },
  {
    title: 'Name',
    key: 'name',
    dataIndex: 'name',
    ellipsis: true,
    render: (item) => item ?? '-',
  },
  {
    title: 'Registration Open',
    key: 'registration_open',
    dataIndex: 'registration_open',
    ellipsis: true,
    width: 150,
    render: (item) => (item ? dateFormatter(item).toLocal('DD-MM-YYYY') : '-'),
  },
  {
    title: 'Registration Close',
    key: 'registration_close',
    dataIndex: 'registration_close',
    ellipsis: true,
    width: 150,
    render: (item) => (item ? dateFormatter(item).toLocal('DD-MM-YYYY') : '-'),
  },
  {
    title: 'Max Registrants',
    key: 'max_registrants',
    dataIndex: 'max_registrants',
    ellipsis: true,
    width: 150,
    render: (item) => {
      return item ?? '-';
    },
  },
  {
    title: 'Event Link',
    key: 'event_link',
    dataIndex: 'event_link',
    ellipsis: true,
    render: (item) => {
      return item;
    },
  },
  {
    title: 'Event Code',
    key: 'event_code',
    dataIndex: 'event_code',
    ellipsis: true,
    render: (item) => {
      return item ?? '-';
    },
  },
  {
    title: 'Event Password',
    key: 'event_password',
    dataIndex: 'event_password',
    ellipsis: true,
    render: (item) => {
      return item ?? '-';
    },
  },
];

export const columnsSessionRegistration: TableColumnsType<any> = [
  {
    title: 'Session',
    key: 'no',
    dataIndex: 'no',
    width: 50,
    ellipsis: true,
    render: (item) => item ?? '-',
  },
  {
    title: '# Workshop',
    key: 'no_of_workshop',
    dataIndex: 'no_of_workshop',
    ellipsis: true,
    width: 100,
    render: (item, row) => row?.agendas?.length ?? 0,
  },
  {
    title: 'Name',
    key: 'name',
    dataIndex: 'name',
    ellipsis: true,
    render: (item) => item ?? '-',
  },
  {
    title: 'Registration Open',
    key: 'registration_open',
    dataIndex: 'registration_open',
    ellipsis: true,
    render: (item) => (item ? dateFormatter(item).toLocal('DD-MM-YYYY') : '-'),
  },
  {
    title: 'Registration Close',
    key: 'registration_close',
    dataIndex: 'registration_close',
    ellipsis: true,
    render: (item) => (item ? dateFormatter(item).toLocal('DD-MM-YYYY') : '-'),
  },
  {
    title: 'Max Registrants',
    key: 'max_registrants',
    dataIndex: 'max_registrants',
    ellipsis: true,
    render: (item) => {
      return item ?? '-';
    },
  },
  {
    title: 'Event Link',
    key: 'event_link',
    dataIndex: 'event_link',
    ellipsis: true,
    render: (item) => {
      return item ?? '-';
    },
  },
  {
    title: 'Event Code',
    key: 'event_code',
    dataIndex: 'event_code',
    ellipsis: true,
    render: (item) => {
      return item ?? '-';
    },
  },
  {
    title: 'Event Password',
    key: 'event_password',
    dataIndex: 'event_password',
    ellipsis: true,
    render: (item) => {
      return item ?? '-';
    },
  },
];

export const columnsWorkshopRegistration: TableColumnsType<any> = [
  {
    title: 'Workshop',
    key: 'no',
    dataIndex: 'no',
    width: 50,
    ellipsis: true,
    render: (item) => item ?? '-',
  },
  {
    title: 'Name',
    key: 'name',
    dataIndex: 'name',
    ellipsis: true,
    render: (item) => item ?? '-',
  },
  // {
  //   title: 'Registration Open',
  //   key: 'registration_open',
  //   dataIndex: 'registration_open',
  //   ellipsis: true,
  //   render: (item) => (item ? dateFormatter(item).toLocal('DD-MM-YYYY HH:mm') : '-'),
  // },
  // {
  //   title: 'Registration Close',
  //   key: 'registration_close',
  //   dataIndex: 'registration_close',
  //   ellipsis: true,
  //   render: (item) => (item ? dateFormatter(item).toLocal('DD-MM-YYYY HH:mm') : '-'),
  // },
  {
    title: 'Max Registrants',
    key: 'max_registrants',
    dataIndex: 'max_registrants',
    ellipsis: true,
    render: (item) => {
      return item ?? '-';
    },
  },
  {
    title: 'Event Link',
    key: 'event_link',
    dataIndex: 'event_link',
    ellipsis: true,
    render: (item) => {
      return item ?? '-';
    },
  },
  {
    title: 'Event Code',
    key: 'event_code',
    dataIndex: 'event_code',
    ellipsis: true,
    render: (item) => {
      return item ?? '-';
    },
  },
  {
    title: 'Event Password',
    key: 'event_password',
    dataIndex: 'event_password',
    ellipsis: true,
    render: (item) => {
      return item ?? '-';
    },
  },
];

export const columnsPricing: TableColumnsType<any> = [
  {
    title: 'Price Name',
    key: 'name',
    dataIndex: 'name',
    // width: 100,
    ellipsis: true,
    render: (value: any) => value,
  },
  {
    title: 'Price',
    key: 'price',
    // width: 100,
    dataIndex: 'price',
    ellipsis: true,
    render: (value: any) => (value === 0 ? 0 : RenderCurrency({ value })),
  },
  {
    title: 'Need Confirm',
    key: 'need_confirm',
    // width: 100,
    dataIndex: 'need_confirm',
    ellipsis: true,
    render: (value: any) => (value ? 'Yes' : 'No'),
  },
  {
    title: 'Participant Condition',
    key: 'participant_condition',
    children: [
      {
        title: 'Department',
        key: 'departments',
        dataIndex: 'departments',
        ellipsis: true,
        render: (value: any) => RenderTag({ value, renderType: 'multi', keyValue: 'name' }),
      },
      {
        title: 'Ministries',
        key: 'ministries',
        dataIndex: 'ministries',
        ellipsis: true,
        render: (value: any) => RenderTag({ value, renderType: 'multi', keyValue: 'name' }),
      },
    ],
  },
  {
    title: 'Registration Condition',
    key: 'regist_condition',
    // width: 150,
    dataIndex: 'regist_condition',
    ellipsis: true,
    render: (value: any) => {
      return value
        ? `${value} Days Before Registration Close`
        : value === 0
        ? `${value} Days Before Registration Close`
        : '-';
    },
  },
];

export const columnsDaySessionWorkshop: TableColumnsType<any> = [
  {
    title: 'Day',
    key: 'day',
    dataIndex: 'day',
    width: 100,
    ellipsis: true,
    render: (item) => item?.no ?? '-',
  },
  {
    title: 'Session',
    key: 'session',
    dataIndex: 'session',
    width: 100,
    ellipsis: true,
    render: (item) => item?.no ?? '-',
  },
  {
    title: 'Workshop',
    key: 'workshop',
    dataIndex: 'workshop',
    ellipsis: true,
    render: (value) => RenderTag({ value, keyValue: 'name', renderType: 'multi' }),
  },
];

export const columnsOfficer: TableColumnsType<any> = [
  {
    title: 'Name',
    key: 'officer',
    dataIndex: 'officer',
    ellipsis: true,
    render: (item: any) => item?.name ?? '-',
  },
  {
    title: 'Email',
    key: 'officer',
    dataIndex: 'officer',
    ellipsis: true,
    render: (item: any) => item?.email ?? '-',
  },
];
