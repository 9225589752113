import { RenderCurrency } from '@eigen3m/react-base-frontend';
import { Fragment } from 'react';

export function CalculateDetailSplitPayment({ item, index }: any): JSX.Element {
  const pricingType = item?.pricing_type;

  const hasFreeEvent = pricingType?.some((item) => item?.price?.price === 0);
  const hasFreeMainEvent = pricingType?.some(
    (item) => item?.price?.price === 0 && (!item?.is_sub_event || item?.is_sub_event === false),
  );
  const hasFreeSubEvent = pricingType?.some((item) => item?.price?.price === 0 && item?.is_sub_event === true);
  const hasSubEvent = pricingType?.some((item) => item?.is_sub_event && item?.price?.price !== 0);

  const priceMainWSubEvent = pricingType?.filter((item) => item?.price?.price !== 0);
  const priceMainWOSubEvent = pricingType?.filter((item) => item?.price?.price !== 0 && !item?.is_sub_event);
  const priceFreeMainEvent = pricingType?.filter((item) => item?.price?.price === 0 || item?.is_sub_event === false);
  const priceFreeSubEvent = pricingType?.filter((item) => item?.is_sub_event === true && item?.price?.price === 0);

  const totalMainEvent = priceMainWSubEvent?.reduce((acc, curr) => {
    return acc + curr?.price?.price;
  }, 0);

  return (
    <Fragment>
      <div style={{ fontSize: 13 }}>
        <h3 style={{ fontWeight: 'bold' }}>
          Participant {index + 1}: {item?.registran}
        </h3>
        {!!priceMainWOSubEvent?.length && <h4 style={{ fontWeight: 'bold' }}>Main Event</h4>}
        {priceMainWOSubEvent.map((type) => {
          return (
            <div key={type?.registran} style={{ marginBottom: 14 }}>
              <div style={{ display: 'flex' }}>
                <div style={{ marginRight: 4 }}>
                  {!type?.day ? (
                    <span>All Event</span>
                  ) : (type?.day && type?.session) || type?.workshop ? (
                    <span>
                      Day {type?.day}, Session {type?.session} {type?.workshop && `, Workshop: ${type?.workshop}`}
                    </span>
                  ) : (
                    <span>Day {type?.day}</span>
                  )}
                </div>
                <span style={{ marginRight: 4 }}>&#61;</span>
                <span style={{ display: 'flex' }}>
                  <div style={{ display: 'flex' }}>
                    <span style={{ marginRight: 2 }}>Rp.</span>
                    {type?.price?.price === 0
                      ? 0
                      : RenderCurrency({
                          value: type?.price?.price,
                          currencyOptions: {
                            minimumFractionDigits: 0,
                          },
                        })}
                  </div>
                  <strong style={{ marginLeft: 4 }}>({type?.price?.name})</strong>
                </span>
              </div>
            </div>
          );
        })}
        {hasSubEvent && <h4 style={{ fontWeight: 'bold' }}>Sub Event</h4>}
        {hasSubEvent &&
          pricingType
            ?.filter((item) => item?.is_sub_event && item?.price?.price !== 0)
            .map((type) => {
              return (
                <div key={type?.registran} style={{ marginBottom: 14 }}>
                  <div style={{ display: 'flex' }}>
                    <div style={{ marginRight: 4 }}>
                      {!type?.day ? (
                        <span>All Event</span>
                      ) : (type?.day && type?.session) || type?.workshop ? (
                        <span>
                          Day {type?.day}, Session {type?.session} {type?.workshop && `, Workshop: ${type?.workshop}`}
                        </span>
                      ) : (
                        <span>Day {type?.day}</span>
                      )}
                    </div>
                    <span style={{ marginRight: 4 }}>&#61;</span>
                    <span style={{ display: 'flex' }}>
                      <div style={{ display: 'flex' }}>
                        <span style={{ marginRight: 2 }}>Rp.</span>
                        {type?.price?.price === 0
                          ? 0
                          : RenderCurrency({
                              value: type?.price?.price,
                              currencyOptions: {
                                minimumFractionDigits: 0,
                              },
                            })}
                      </div>
                      <strong style={{ marginLeft: 4 }}>({type?.price?.name})</strong>
                    </span>
                  </div>
                </div>
              );
            })}
      </div>
      <hr />
      <div style={{ marginTop: 14, marginBottom: 8, fontSize: 13, display: 'flex' }}>
        <span style={{ marginRight: 4 }}>
          <strong>Total Participant {index + 1}</strong>
        </span>
        <span style={{ marginRight: 4 }}>&#61;</span>
        <span style={{ display: 'flex' }}>
          <div style={{ marginRight: 2 }}>Rp.</div>
          {pricingType[0]?.price?.price === 0
            ? 0
            : RenderCurrency({
                value: pricingType?.length > 1 ? totalMainEvent : pricingType[0]?.price?.price,
                currencyOptions: {
                  minimumFractionDigits: 0,
                },
              })}
        </span>
      </div>
      {hasFreeEvent && <h3 style={{ fontWeight: 'bold', marginTop: 20 }}>Free Event</h3>}
      {hasFreeMainEvent && <h4 style={{ fontWeight: 'bold', marginTop: 20 }}>Main Event</h4>}
      {hasFreeMainEvent &&
        priceFreeMainEvent?.map((type) => {
          return (
            <div key={type?.registran} style={{ marginBottom: 14 }}>
              <div style={{ display: 'flex' }}>
                <div style={{ marginRight: 4 }}>
                  {!type?.day ? (
                    <span>All Event</span>
                  ) : (type?.day && type?.session) || type?.workshop ? (
                    <span>
                      Day {type?.day}, Session {type?.session} {type?.workshop && `, Workshop ${type?.workshop}`}
                    </span>
                  ) : (
                    <span>Day {type?.day}</span>
                  )}
                </div>
                <span style={{ marginRight: 4 }}>&#61;</span>
                <span style={{ display: 'flex' }}>
                  <div style={{ display: 'flex' }}>
                    <span style={{ marginRight: 2 }}>Rp.</span>
                    {type?.price?.price === 0
                      ? 0
                      : RenderCurrency({
                          value: type?.price?.price || 0,
                          currencyOptions: {
                            minimumFractionDigits: 0,
                          },
                        })}
                  </div>
                  <strong style={{ marginLeft: 4 }}>({type?.price?.name})</strong>
                </span>
              </div>
            </div>
          );
        })}
      {hasFreeSubEvent && <h4 style={{ fontWeight: 'bold', marginTop: 20 }}>Sub Event</h4>}
      {hasFreeSubEvent &&
        priceFreeSubEvent?.map((type) => {
          return (
            <div key={type?.registran} style={{ marginBottom: 14 }}>
              <div style={{ display: 'flex' }}>
                <div style={{ marginRight: 4 }}>
                  {!type?.day ? (
                    <span>All Event</span>
                  ) : (type?.day && type?.session) || type?.workshop ? (
                    <span>
                      Day {type?.day}, Session {type?.session} {type?.workshop && `, Workshop ${type?.workshop}`}
                    </span>
                  ) : (
                    <span>Day {type?.day}</span>
                  )}
                </div>
                <span style={{ marginRight: 4 }}>&#61;</span>
                <span style={{ display: 'flex' }}>
                  <div style={{ display: 'flex' }}>
                    <span style={{ marginRight: 2 }}>Rp.</span>
                    {type?.price?.price === 0
                      ? 0
                      : RenderCurrency({
                          value: type?.price?.price || 0,
                          currencyOptions: {
                            minimumFractionDigits: 0,
                          },
                        })}
                  </div>
                  <strong style={{ marginLeft: 4 }}>({type?.price?.name})</strong>
                </span>
              </div>
            </div>
          );
        })}
      <div style={{ marginBottom: 28 }}>
        <p>
          Link payment confirmation will be sent to{' '}
          <strong>
            Email Address Participant {index + 1} : {item?.registran}{' '}
          </strong>
        </p>
      </div>
    </Fragment>
  );
}
