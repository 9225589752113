import { RenderCurrency } from '@eigen3m/react-base-frontend';
import { Card } from 'antd';
import './style.less';

interface PricingCardProps {
  price: number;
  condition: number;
  type: string;
  pricingList: string[];
  style?: React.CSSProperties;
}
export function PricingCard(props: PricingCardProps) {
  const { price, condition, type, pricingList, style } = props;
  return (
    <Card bodyStyle={{ padding: 0 }} className="pricing-card" style={style}>
      <div style={{ textAlign: 'center', background: '#f0f0f0', padding: '10px', margin: 0 }}>
        <strong style={{ fontSize: '14px' }}>{type}</strong>
        <p style={{ margin: 0 }}>{'Available until ' + condition + ' days before registration close'}</p>
      </div>
      <div style={{ padding: '15px', height: '100%', marginBottom: '50px' }}>
        <ul style={{ listStyle: 'none', paddingLeft: 0, margin: 0 }}>
          {pricingList?.map((item, key) => {
            return (
              <li key={key} style={{ borderBottom: '1px solid #f0f0f0' }}>
                {item}
              </li>
            );
          })}
        </ul>
      </div>
      <div
        style={{
          width: '100%',
          position: 'absolute',
          height: '50px',
          lineHeight: '50px',
          textAlign: 'center',
          background: '#f0f0f0',
          fontSize: '14px',
          bottom: 0,
        }}
      >
        <strong>
          {price > 0
            ? RenderCurrency({
                value: price,
                currencyOptions: {
                  minimumFractionDigits: 0,
                },
              })
            : 'Free'}
        </strong>
      </div>
    </Card>
  );
}
