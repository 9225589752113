import { TableColumnsType } from 'antd';

export const columnsEventCategory: TableColumnsType<any> = [
  {
    title: 'Event Category Name',
    key: 'name',
    width: 200,
    dataIndex: 'name',
    ellipsis: true,
    sorter: true,
  },
  {
    title: 'Show to Home Page Menu',
    key: 'show_to_home',
    width: 150,
    dataIndex: 'show_to_home',
    sorter: true,
    ellipsis: true,
    render(value) {
      return value ? 'Yes' : 'No';
    },
  },
];
