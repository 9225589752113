export const ApiUrlData = {
  template: 'v1/users',
  contact: 'v1/event/contacts',
  report: 'v1/event/play-grounds',
  user_level: 'v1/event/users/levels',
  region: 'v1/event/regions',
  event: 'v1/event/event-data',
  companies: 'v1/event/constants/mock-up/companies',
  check_in: 'v1/event/check-ins',

  enum_gender: 'v1/event/constants/enum/gender',
  enum_relation: 'v1/event/constants/enum/relations',
  enum_event_type: 'v1/event/constants/enum/event-types',
  mock_company: 'v1/event/constants/mock-up/companies',

  enum_marital_status: 'v1/event/constants/enum/marital-status',
  enum_registration_type: 'v1/event/constants/enum/registration-type',
  enum_price_configuration: 'v1/event/constants/enum/price-configuration',
  enum_attendance_type: 'v1/event/constants/enum/attendance-type',
  enum_job_position_i_care: 'v1/event/constants/enum/job-position/i-care',
  enum_job_position_non_i_care: 'v1/event/constants/enum/job-position/non-i-care',
  enum_department: 'v1/event/constants/enum/departments',
  enum_ministry: 'v1/event/constants/enum/ministries',
  enum_discipleship_center: 'v1/event/constants/enum/discipleship-center',
  enum_class: 'v1/event/constants/enum/class',
  enum_term: 'v1/event/constants/enum/term',
  enum_participant_type: 'v1/event/constants/enum/participant-type',

  user_login: 'v1/event/auth',
  user_login_token: 'v1/event/auth/login-token',
  // login_google: 'v1/event/auth/auth/google',
  login_google: 'v1/event/auth/google',
  register_google: 'v1/event/auth/register/google',

  //configuration
  email_template: 'v1/event/email-template',
  accounts: 'v1/event/accounts',
  banks: 'v1/event/banks',
  banks_upload: 'v1/event/banks/upload',
  account_upload: 'v1/event/accounts/upload',
  event_categories: 'v1/event/event-categories',
  event_data: 'v1/event/data',
  general_config: 'v1/event/general-config',
  user_category: 'v1/user-category',

  //event
  venues: 'v1/event/venues',
  eventCategory: 'v1/event/event-categories',
  eventActivities: 'v1/event/event-activities',
  speaker: 'v1/event/contacts',
  speakerUpload: 'v1/event/contacts/upload',
  eventData: 'v1/event/event-data',
  eventDataUpload: 'v1/event/event-data/upload',
  participant: 'v1/event/participant',
  participant_attandance: 'v1/event/participant-attandance',
  payment: 'v1/event/payment',
  payment_upload: 'v1/event/payment/upload',

  // TODO: Testing Purpose
  calculate_invoice: 'v1/event/registration/calculate-invoice',
  registration: 'v1/event/registration',

  send_all_email: 'v1/event/contacts/send-email-promo-event',
  reminder_email: 'v1/event/event-activities/send-event-reminder-email',
  officer_attandances: 'v1/event/officer-attandance',

  department: 'v1/event/departments',
  departmentClass: 'v1/event/departments/class',
  ministries: 'v1/event/ministries',
};
